import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../components/page/Page';
import { getFbCharts, getInstaCharts } from '../redux/clientinfo/user-stats.actions';
import { getActiveCampaigns } from '../redux/campaigns/campaigns.actions';
import { NumberWidget } from '../components/Widget';
import { Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Alert } from 'reactstrap';
import '../styles/components/_user-stats-charts.scss';
import { Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import SocialChartComponent from '../components/charts/SocialChartComponent';
import '../styles/card.scss';

const SocialStats = ({ getFbCharts, getInstaCharts, getActiveCampaigns, activeCampaigns, fbchartData, instaChartData }) => {
    const [campaign, setCampaign] = useState(null);

    const ReactGridLayout = WidthProvider(ResponsiveGridLayout);

    useEffect(() => {
        getFbCharts(campaign);
        getInstaCharts(campaign);
    }, [getFbCharts, getInstaCharts, campaign]);

    useEffect(() => {
        getActiveCampaigns();
    }, [getActiveCampaigns]);

    useEffect(() => {
        if(fbchartData) formatFbData();
        if(instaChartData) formatInData();
    }, [formatFbData, fbchartData, formatInData, instaChartData])

    const handleChange = (event) => {
        // Destroy previous charts
        setCampaign(event.target.value);
    }

    const [finalfbdata, setFinalFb] = useState({});
    const [finalindata, setFinalIn] = useState({});
    const [dateselected, setDateSel] = useState("");

    const handleChangeDate = (e) => setDateSel(e.target.value);

    const [activeTab, setActiveTab] = useState('fb');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    
    const chartdesc = {
        "page_content_activity":"Daily: The number of stories created about your Page. (Total Count)",
        "page_total_actions":"Daily: The number of clicks on your Page's contact info and call-to-action button.",
        "page_consumptions":"Daily: The number of clicks on any of your content. Stories generated without clicks on page content (e.g., liking the page in Timeline) are not included. (Total Count)",
        "page_impressions":"Daily: The number of times any content from your Page or about your Page entered a person's screen. This includes posts, stories, check-ins, ads, social information from people who interact with your Page and more. (Total Count)",
        "page_impressions_paid":"Daily: The number of times any content from your Page or about your Page entered a person's screen through paid distribution such as an ad. (Total Count)",
        "page_impressions_organic":"Daily: The number of times any content from your Page or about your Page entered a person's screen through unpaid distribution. This includes posts, stories, check-ins, social information from people who interact with your Page and more. (Total Count)",
        "page_impressions_viral":"Daily: The number of times any content from your Page or about your Page entered a person's screen with social information attached. Social information displays when a person's friend interacted with your Page, post or story. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page. (Total Count)",
        "page_impressions_nonviral":"Daily: The number of times any content from your Page entered a person's screen. This does not include content created about your Page with social information attached. Social information displays when a person's friend interacted with your Page, post or story. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page. (Total Count)",
        "page_fans":"Lifetime: The total number of people who have liked your Page. (Unique Users)",
        "page_messages_new_conversations_unique":"Daily: The number of messaging conversations on Facebook Messenger that began with people who had never messaged with your business before.",
        "impressions": "The total number of times that the business account's media objects have been viewed.",
        "profile_views": "The total number of users who have viewed the business account's profile within the specified period.",
        "reach": "The total number of times that the business account's media objects have been uniquely viewed."
    }

    const formatFbData = () => {
        const temp = {};
        Object.keys(Object.values(fbchartData)[0]).map(key => {
            if(key!='page_actions_post_reactions_total') {
                Object.keys(fbchartData).map(date => {
                    if(temp[key]==undefined) temp[key] = {};
                    temp[key][date] = fbchartData[date][key];
                })
            }
        })
        setFinalFb(temp);
        setDateSel(Object.keys(fbchartData).slice(-1)[0]);
    }

    const formatInData = () => {
        const temp = {};
        Object.keys(Object.values(instaChartData)[0]).map(key => {
            Object.keys(instaChartData).map(date => {
                if(temp[key]==undefined) temp[key] = {};
                temp[key][date] = instaChartData[date][key];
            })
        })
        setFinalIn(temp);
    }

    const getPercentage = (num) => {
        let total = 0;
        Object.values(fbchartData[dateselected]['page_actions_post_reactions_total']).map(val => total += val);
        return (num*100)/total
    }

    const ctypes = [
        "horizontalBar",
        "bar",
        "line"
    ]

    let j=0;
    let layoutFb = []
    let layoutFSm = []
    let layoutIn = []
    let layoutISm = []
    
    if (finalfbdata) {
        layoutFb = Object.keys(finalfbdata).map(key => {
            const xx = j%2==0?0:6;
            const yy = Math.floor(j/2)*10;
            j++;
            return {i: key, x: xx, y: yy, w: 6, h: 6*1.4, isResizable: false}
        })

        layoutFSm = layoutFb.map(obj => {
            return {...obj, w:obj.w-2, h:obj.h-3}
        })
    }

    j=0;
    if (finalindata) {
        layoutIn = Object.keys(finalindata).map(key => {
            const xx = j%2==0?0:6;
            const yy = Math.floor(j/2)*10;
            j++;
            return {i: key, x: xx, y: yy, w: 6, h: 6*1.4, isResizable: false}
        })

        layoutISm = layoutIn.map(obj => {
            return {...obj, w:obj.w-2, h:obj.h-3}
        })
    }

    return (
        <Page
            className="Audience"
            title="Social Media Stats"
            breadcrumbs={[
                { name: `Social Media Stats`, active: false },
                { name: `Facebook Page`, active: true }
            ]}
        >
            
            <Form style={{
                width: '45vw',
                maxWidth: '400px'
            }}>
                <FormGroup>
                    <Label for="exampleSelect">Select a campaign</Label>
                    <Input type="select" name="select" id="exampleSelect" defaultValue="-1" onChange={handleChange}>
                        <option disabled value="-1">Select a campaign</option>
                        {
                            activeCampaigns && activeCampaigns.map((c) => {
                                return (
                                    <option key={c} value={c}>{c}</option>
                                )
                            })
                        }
                    </Input>
                </FormGroup>
            </Form>
            {campaign &&
                <>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab=='fb'?"active":""}
                                onClick={() => { toggle('fb'); }}>
                                Facebook Stats
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab=='insta'?"active":""}
                                onClick={() => { toggle('insta'); }}>
                                Instagram Stats
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="fb">
                            <div className="widget-stats">
                                {fbchartData && fbchartData[dateselected] &&
                                    <Row>
                                    {fbchartData[dateselected]['page_actions_post_reactions_total']?
                                        Object.entries(fbchartData[dateselected]['page_actions_post_reactions_total']).map(keyv => 
                                        <Col key={keyv[0]} lg={3} md={6} sm={6} xs={12}>
                                            <NumberWidget
                                                title={keyv[0].toUpperCase()}
                                                subtitle={dateselected}
                                                number={keyv[1]}
                                                color="secondary"
                                                progress={{
                                                value: getPercentage(keyv[1]).toFixed(2),
                                                label:"Out of total"
                                                }}
                                            />
                                        </Col>
                                    ):(
                                        <Col style={{paddingTop: "10px"}}>
                                            <Alert color="warning">No reactions for this date</Alert>
                                        </Col>
                                    )}
                                    <Col>
                                        <Label>Select Date</Label>
                                        <Input type="select" defaultValue="-1" onChange={handleChangeDate}>
                                        <option disabled value="-1">Choose date</option>
                                        {Object.keys(fbchartData).map((c) =>
                                            <option key={c} value={c}>{c}</option>
                                        )}
                                        </Input>
                                    </Col>
                                    </Row>
                                }
                            </div>
                            <ReactGridLayout
                                className="layout charts-container"
                                layouts={{
                                    lg: layoutFb,
                                    md: layoutFb,
                                    sm: layoutFSm,
                                    xs: layoutFSm,
                                    xxs: layoutFSm
                                }}
                                draggableCancel=".bar"
                                breakpoints={{ lg: 1080, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                rowHeight={40}
                            >
                                {finalfbdata!={} && Object.keys(finalfbdata).map(obj =>
                                    <div key={obj} className="chart-container">
                                        <SocialChartComponent 
                                            defaultType={_.sample(ctypes)} 
                                            chartname={obj} 
                                            recentChart={finalfbdata[obj]}
                                            chartdesc={chartdesc[obj]}
                                        />
                                    </div>
                                )}
                            </ReactGridLayout>
                        </TabPane>
                        <TabPane tabId="insta">
                            <ReactGridLayout
                                className="layout charts-container"
                                layouts={{
                                    lg: layoutIn,
                                    md: layoutIn,
                                    sm: layoutISm,
                                    xs: layoutISm,
                                    xxs: layoutISm
                                }}
                                draggableCancel=".bar"
                                breakpoints={{ lg: 1080, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                rowHeight={40}
                            >
                                {finalindata!={} && Object.keys(finalindata).map(obj =>
                                    <div key={obj} className="chart-container">
                                        <SocialChartComponent 
                                            defaultType={_.sample(ctypes)} 
                                            chartname={obj} 
                                            recentChart={finalindata[obj]}
                                            chartdesc={chartdesc[obj]}
                                        />
                                    </div>
                                )}
                            </ReactGridLayout>
                        </TabPane>
                    </TabContent>
                </>
            }
        </Page>
    );
}

const mapStateToProps = (state) => {
    return {
        activeCampaigns: state.campaigns.activeCampaigns,
        fbchartData: state.userStats.fbCharts,
        instaChartData: state.userStats.instaCharts
    }
}

const mapDispatchToProps = (dispatch) => ({
    getFbCharts: (campaign) => {
        dispatch(getFbCharts(campaign));
    },
    getInstaCharts: (campaign) => {
        dispatch(getInstaCharts(campaign));
    },
    getActiveCampaigns: () => {
        dispatch(getActiveCampaigns());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialStats);
import React, { useState } from "react";
import { Badge, Col } from 'reactstrap';

const OptionButton = ({ button, handleChange, handleHoverIn, handleHoverOut, checked, size }) => {
  // let checked = this.props.stateArray.includes(button.text);
  //console.log(optionButtons[1]);
  // optionButtons.forEach(btn => console.log(btn.text));
  const { text, id, description, icon } = button



  return (
    <Col>
      <div
        className={`option-button ${size}-label`}
        style={{ width: "100%", minWidth: "130px" }}
        onMouseOver={() => handleHoverIn(icon, description)}
        onMouseLeave={handleHoverOut}
      >
        <input
          value={text}
          disabled={button.badge}
          /* onClick={(event) => { this.props.handleClick(event, this.props.multiple) }} */
          type="checkbox"
          // type={
          //   this.props.multiple ? "checkbox" : "radio"
          // }
          id={id}
          checked={checked}
          onChange={handleChange}
        />
        <label
          // className={button.subOptions ? "with-suboptions" : null}
          htmlFor={id}
        >
          {text}
        </label>
        {button.badge && (
          <Badge className="beta-badge" color="info">
            Beta
          </Badge>
        )}

      </div>
    </Col>
  );
};

export default OptionButton;

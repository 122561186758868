import { firebase } from '../../firebase/firebase';

import { getCampaignDetails } from './campaignDetails/campaignDetails.actions';
import { getProductDetails } from './productDetails/productDetails.actions';
import { getMarketing } from './marketing/marketing.actions';
import { getResponsibilities } from './responsibilities/responsibilities.actions';
import { getSocialMediaTargeting } from './socialMediaTargeting/socialMediaTargeting.actions';

export const pushCacDataToFirbase = (status, callback) => {
    return (dispatch, getState) => {
        // console.log('submitting')
        const { cac, auth } = getState();
        const data = {
            campaignDetails: cac.campaignDetails,
            productDetails: cac.productDetails,
            marketing: cac.marketing,
            responsibilities: cac.responsibilities,
            socialMediaTargeting: cac.socialMediaTargeting
        }

        const campaignsRef = firebase.database().ref(`users/${auth.uid}/cac/campaigns/${cac.campaignDetails.CampaignName}`);
        campaignsRef.set({
            data: {
                ...data
            },
            status: status
        }, (error) => {
            if (error) {
              console.log(error);
            } else {
              callback();
            }
          });
    }
}

export const getData = (name) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        const campaignsRef = firebase.database().ref(`users/${auth.uid}/cac/campaigns/${name}`);
        campaignsRef.once('value')
        .then((snapshot) => {
            const { data } = snapshot.val();
            dispatch(getCampaignDetails(data.campaignDetails));
            dispatch(getProductDetails(data.productDetails));
            dispatch(getMarketing(data.marketing));
            dispatch(getResponsibilities(data.responsibilities));
            dispatch(getSocialMediaTargeting(data.socialMediaTargeting));
        })
        .catch(err => {
            console.log('Waiting for uid', auth.uid);
        });
    }
}


import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { pushCacDataToFirbase } from '../../../../redux/cac/cac.actions';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setIncompleteData } from '../../../../redux/cac/globalCacStates/globalCacStates.actions';

const SubmitErrorModal = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    let history = useHistory();

    return (
        <div>
            {/* <Button color="danger" onClick={props.handleToggle}>{buttonLabel}</Button> */}
            <Modal
                isOpen={props.incompleteCampaignError}
                toggle={() => {
                    props.handleIncompleteCampaignToggle(false)
                }}
                className={className}
                style={{ fontFamily: 'Ubuntu' }}
            >
                <ModalHeader toggle={() => {
                    props.handleIncompleteCampaignToggle(false)
                }}>Insufficuent data</ModalHeader>
                <ModalBody>
                    We are sorry but it seems like you left some questions unanswered. Either you can complete them, or save for later.
                </ModalBody>
                {/* <ModalBody>
                    These are the incomplete questions:
                    {
                        props.IncompleteData.map((d, idx) => (
                            <div key={idx}>{d}</div>
                        ))
                    }
                </ModalBody> 
                <ModalBody>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_5JFpAw.json" background="transparent" speed="1" style={{
                            width: '200px',
                            height: '200px',
                            position: 'static',
                            top: '0',
                            left: '0'
                        }} autoplay loop></lottie-player>
                        <div style={{
                            marginTop: '0',
                            fontSize: '0.8rem',
                            color: '#9a9090'
                        }}>
                            Here's a random cute puppy.
                        </div>
                    </div>
                    </ModalBody>*/}
                <ModalFooter>
                    <Link to='/campaigns'>
                        <Button color="primary" onClick={() => {
                            // props.pushCacDataToFirbase('incomplete');
                            props.pushCacDataToFirbase('incomplete', () => {
                                history.push('/new/submit');
                            });
                            // props.pushToDatabase('incomplete');
                            // props.makeNotification('incomplete');
                            // props.push('/new/submit');
                        }}>Save for later</Button>
                    </Link>
                    <Button color="secondary" onClick={() => {
                        props.handleIncompleteCampaignToggle(false)
                        props.setIncompleteData([]);
                    }}>Complete</Button>
                </ModalFooter>
            </Modal>

            {/* Modal for when a campaign name is empty */}
            <Modal
                isOpen={props.nameError}
                toggle={() => {
                    props.handleNameErrorToggle(false)
                }}
                className={className}
                style={{ fontFamily: 'Ubuntu' }}
            >
                <ModalHeader toggle={() => {
                    props.handleNameErrorToggle(false)
                }}>Empty campaign name</ModalHeader>
                <ModalBody>
                    Campaign name cannot be empty.
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        props.handleNameErrorToggle(false)
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>
            
            {/* Modal for when a campaign name is same */}
            <Modal
                isOpen={props.sameNameError}
                toggle={() => {
                    props.handleSameNameError(false)
                }}
                className={className}
                style={{ fontFamily: 'Ubuntu' }}
            >
                <ModalHeader toggle={() => {
                    props.handleSameNameError(false)
                }}>Name already exists</ModalHeader>
                <ModalBody>
                    The campaign name used already exists, please use a unique value.
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        props.handleSameNameError(false)
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    pushCacDataToFirbase: (status, callback) => dispatch(pushCacDataToFirbase(status, callback)),
    setIncompleteData: (status, callback) => dispatch(setIncompleteData(status, callback))
})

export default connect(null, mapDispatchToProps)(SubmitErrorModal);
import { createSelector } from 'reselect';
import { filterCampaignsByStatus, InhouseManageableCampaigns } from './campaigns.util';

export const selectCampaigns = state => state.campaigns;

export const selectCampaignList = createSelector(
    [selectCampaigns],
    campaigns => !!campaigns.campaignslist ? Object.keys(campaigns.campaignslist).map(campaignID => ({ id: campaignID, ...campaigns.campaignslist[campaignID] })) : []
);

export const selectActiveCampaignList = createSelector(
    [selectCampaignList],
    campaignList => !!campaignList ? filterCampaignsByStatus(campaignList, 'active') : []
);

export const selectApprovedCampaignList = createSelector(
    [selectCampaignList],
    campaignList => !!campaignList ? filterCampaignsByStatus(campaignList, 'approved') : []
);

export const selectPendingCampaignList = createSelector(
    [selectCampaignList],
    campaignList => !!campaignList ? filterCampaignsByStatus(campaignList, 'pending') : []
);

export const selectIncompleteCampaignList = createSelector(
    [selectCampaignList],
    campaignList => !!campaignList ? filterCampaignsByStatus(campaignList, 'incomplete') : []
);

export const selectDisapprovedCampaignList = createSelector(
    [selectCampaignList],
    campaignList => !!campaignList ? filterCampaignsByStatus(campaignList, 'disapproved') : []
);

export const selectInhouseManagedCampaignList = createSelector(
    [selectApprovedCampaignList],
    campaignList => !!campaignList ? InhouseManageableCampaigns(campaignList) : []
);

export const selectFocussedCampaign = createSelector(
    [selectCampaigns],
    campaigns => campaigns.focussedCampaign
);

export const selectIsActivating = createSelector(
    [selectCampaigns],
    campaigns => campaigns.isActivating
);

export const selectError = createSelector(
    [selectCampaigns],
    campaigns => campaigns.error
);
import database from '../../firebase/firebase';


// SET_PROGRESS_CHART
export const setChart = (data = {}) => ({
    type: 'SET_PROGRESS_CHART',
    data
});

export const startSetChart = (campaign) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        return database.ref(`/users/${clientID}/campaigns/${campaign}/dailyStats`).on('value', (snapshot) => {
            const data = snapshot.val();
            console.log(data)

            dispatch(setChart(data));
        })
    }
}
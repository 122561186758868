import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { LOCATIONS, INTERESTS, TARGETING_SPECS } from './remote.types';
import {
    loadLocationSuccess, 
    loadLocationFail, 
    loadInterestsSuccess, 
    loadInterestsFail, 
    loadTargetingSpecsSuccess,
    loadTargetingSpecsFail
} from './remote.actions';
import { fetchLocations } from './api/fetchLocations';
import { fetchInterests } from './api/fetchInterests';
import { fetchTargetingSpecs } from './api/fetchTargetingSpecs';
import { setAudienceSizeFetched } from '../socialMediaTargeting/socialMediaTargeting.actions';
import { setSize } from '../marketing/marketing.actions';

const getLocationValue = state => state.cac.remote.locations.locationsValue;
const getInterestsValue = state => state.cac.remote.interests.interestsValue;
const getTargetingSpecs = state => state.cac.remote.targetingSpecs.targetingSpecs;
const getToken = state => state.firebase.auth_token;

function* handleLocationsLoad() {
    try {
        const value = yield select(getLocationValue);

        // This is to handle the onSelect function in the component. 
        // When we select an option, the data should be removed so that the dropdown closes
        if (value === 'stop-fetching') {
            yield put(loadLocationSuccess([]));
        }
        else {
            const token = yield select(getToken);
            const data = yield call(fetchLocations, value, token);
            yield put(loadLocationSuccess(data));
        }
    }
    catch (error) {
        yield put(loadLocationFail(error));
    }
}

function* handleInterestsLoad() {
    try {
        const value = yield select(getInterestsValue);

        // This is to handle the onSelect function in the component. 
        // When we select an option, the data should be removed so that the dropdown closes
        if (value === 'stop-fetching') {
            yield put(loadInterestsSuccess([]));
        }
        else {
            const token = yield select(getToken);
            const data = yield call(fetchInterests, value, token);
            yield put(loadInterestsSuccess(data));
        }
    }
    catch (error) {
        yield put(loadInterestsFail(error));
    }
}

function* handleTargetingSpecsLoad() {
    try {
        const targetingSpecs = yield select(getTargetingSpecs);
        const token = yield select(getToken);

        const data = yield call(fetchTargetingSpecs, targetingSpecs, token);
        yield put(loadTargetingSpecsSuccess(data));
        yield put(setAudienceSizeFetched(data.users));
        yield put(setSize(data.users));
    }
    catch (error) {
        yield put(loadTargetingSpecsFail(error));
        yield put(setAudienceSizeFetched(null));
        yield put(setSize(''));
    }
}

function* watchLocationsLoad() {
    yield takeLatest(LOCATIONS.LOAD, handleLocationsLoad);
}

function* watchInterestsLoad() {
    yield takeLatest(INTERESTS.LOAD, handleInterestsLoad);
}

function* watchTargetingSpecsLoad() {
    yield takeLatest(TARGETING_SPECS.LOAD, handleTargetingSpecsLoad);
}

export function* remoteSagas() {
    yield all([
        watchLocationsLoad(),
        watchInterestsLoad(),
        watchTargetingSpecsLoad()
    ])
}
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectInventoryStatus, selectInventoryStats } from '../../redux/inventory/inventory.selectors';
import InventoryTable from '../inventory-table/inventory-table.component';
import InventoryStats from '../inventory-stats/inventory-stats.component';
import './inventory-overview.styles.scss';
import { Form, Input, FormGroup, Label, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import { selectActiveCampaignList, selectApprovedCampaignList } from '../../redux/campaigns/campaigns.selectors';

const InventoryOverview = ({ activeCampaigns, approvedCampaigns, inventoryStatus, inventoryStats }) => {
    const [campaigns, setCampaigns] = useState([]);
    const [cities, setCities] = useState([]);
    const [filters, setFilters] = useState({
        campaign: '',
        city: ''
    });

    useEffect(() => {
        const combinedCampaigns = activeCampaigns.concat(approvedCampaigns);
        setCampaigns(combinedCampaigns);
    }, [activeCampaigns, approvedCampaigns]);

    useEffect(() => {
        if (campaigns.length) {
            setFilters({
                campaign: campaigns[0].id,
                city: campaigns[0].data.marketing.Cities[0] 
            })
            setCities(campaigns[0].data.marketing.Cities);
        }
    }, [campaigns]);

    const handleChange = e => {
        const { name, value } = e.target;
        if (name === 'campaign') {
            setFilters({
                [name]: value,
                city: ''
            })
            setCities(_.find(campaigns, campaign => campaign.id == value).data.marketing.Cities)
        } else {
            setFilters({
                ...filters,
                [name]: value
            })
        }
    }

    return (
        <div>
            {campaigns.length === 0 ? (
                <Card>
                    <CardBody>
                        <Alert color="danger">No approved or active campaigns found.</Alert>
                    </CardBody>
                </Card>
            ) : (
                <Card>
                    <CardHeader>
                        <Form inline>
                            <FormGroup>
                                <Label className="ml-2 mr-2" for="campaign"><b>Campaign</b></Label>
                                <Input type="select" onChange={handleChange} value={filters.campaign} name="campaign">
                                    {campaigns.map(({ id }, index) => <option key={index} value={id}>{id}</option>)}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label className="ml-2 mr-2" for="city"><b>City</b></Label>
                                <Input type="select" onChange={handleChange} value={filters.city} name="city">
                                    <option value="">Overall</option>
                                    {cities.map((city, index) => <option key={index} value={city}>{city}</option>)}
                                </Input>
                            </FormGroup>
                        </Form>
                    </CardHeader>
                    <CardBody>
                        <InventoryStats stats={inventoryStats} filters={filters} />
                        <InventoryTable inventoryStatus={inventoryStatus} filters={filters} />            
                    </CardBody> 
                </Card>
            )}        
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    activeCampaigns: selectActiveCampaignList,
    approvedCampaigns: selectApprovedCampaignList,
    inventoryStatus: selectInventoryStatus,
    inventoryStats: selectInventoryStats
});

export default connect(mapStateToProps)(InventoryOverview);
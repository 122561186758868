import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import QuestionWithOptions from '../PredefinedSlides/QuestionWithOptions';
import { setSelectedNumberOfSamples } from '../../../../redux/cac/marketing/marketing.actions';
import { setCustomNumberOfSamples } from '../../../../redux/cac/marketing/marketing.actions';
import InputQuestionComponent from '../PredefinedSlides/InputQuestionComponent';
import TransitionComponentWrapper from '../components/TransitionComponentWrapper.jsx';

const NumberOfSamplesQuestion = ({ size, setSelectedNumberOfSamples, numberOfSamplesInStore, setCustomNumberOfSamples, customNumberOfSamples }) => {
    const [numberOfSamples, setNumberOfSamples] = useState([
        {
            id: 'first-option',
            text: 'First Suggestion'
        },
        {
            id: 'second-option',
            text: 'Second Suggestion'
        },
        {
            id: 'third-option',
            text: 'Third Suggestion'
        },
        {
            id: 'custom',
            text: 'Choose custom'
        }
    ]);

    const [errorText, setErrorText] = useState('');

    const validateForRange = (value) => {
        const min = 1500;
        const max = 1500000;

        if(value < min) {
            setErrorText(`Number of samples should be greater than ${min}`);
            return true;
        }
        else if(value > max) {
            setErrorText('Number of samples should be less than 15 Lakhs');
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        // console.log('re-render');
        if (!!size) {
            // console.log(size);
            let sampleSize1 = (size * 7) / 100;
            let sampleSize2 = (size * 9) / 100;
            let sampleSize3 = (size * 10) / 100;

            if (sampleSize1 < 2500) {
                sampleSize1 = 2500;
                sampleSize2 = 3200;
                sampleSize3 = 3600;
            }

            setNumberOfSamples([
                {
                    id: 'first-option',
                    text: sampleSize1.toString()
                },
                {
                    id: 'second-option',
                    text: sampleSize2.toString()
                },
                {
                    id: 'third-option',
                    text: sampleSize3.toString()
                },
                {
                    id: 'custom',
                    text: 'Choose custom'
                }
            ]);
        }
        else {
            setNumberOfSamples([
                {
                    id: 'first-option',
                    text: 'First Suggestion'
                },
                {
                    id: 'second-option',
                    text: 'Second Suggestion'
                },
                {
                    id: 'third-option',
                    text: 'Third Suggestion'
                },
                {
                    id: 'custom',
                    text: 'Choose custom'
                }
            ]);
        }

    }, [size]);

    return (
        <>
            <QuestionWithOptions
                heading="Suggested number of samples"
                description="Total Number of Samples we suggest you to distribute"
                options={numberOfSamples}
                sendValueToStore={setSelectedNumberOfSamples}
                icons={false}
                storeVariable={numberOfSamplesInStore}
            // size="small"
            />
            <TransitionComponentWrapper conditionField={numberOfSamplesInStore} conditionValue='Choose custom'>
                <InputQuestionComponent
                    heading="Choose custom number of samples"
                    description="Please provide number of samples"
                    placeholder="Enter number of samples here"
                    validateFunction={validateForRange}
                    sendValueToStore={setCustomNumberOfSamples}
                    // required
                    type="number"
                    errorText={errorText}
                    storeVariable={customNumberOfSamples}
                />
            </TransitionComponentWrapper>
        </>
    )

}

const mapStateToProps = (state) => ({
    size: state.cac.marketing.Size,
    numberOfSamplesInStore: state.cac.marketing.SelectedNumberOfSamples,
    customNumberOfSamples: state.cac.marketing.CustomNumberOfSamples
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedNumberOfSamples: (numberOfSamples) => dispatch(setSelectedNumberOfSamples(numberOfSamples)),
    setCustomNumberOfSamples: (customNumberOfSamples) => dispatch(setCustomNumberOfSamples(customNumberOfSamples)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfSamplesQuestion);
import React, { useEffect, useState } from 'react';
import Page from '../components/page/Page';
import { NumberWidget } from '../components/Widget';
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { connect } from 'react-redux';
import { startSetCampaignStats } from '../redux/clientinfo/stats.actions';
import { startSetChart } from '../redux/ads/chart.actions';
import { Line } from "react-chartjs-2";
import { Link } from 'react-router-dom';
import { getActiveCampaigns } from '../redux/campaigns/campaigns.actions';
import { LegendToggle } from '@styled-icons/material-sharp';

const DashboardPage = ({ campaignStats, consumer, getActiveCampaigns, updateStats, activeCampaigns, chartStats, updateChart, chartData, chartOptions }) => {
  const [campaign, setCampaign] = useState(null);
  const handleChange = (event) => {
    // Destroy previous charts
    setCampaign(event.target.value);
  }

  useEffect(() => {
    getActiveCampaigns();
  }, [getActiveCampaigns]);

  useEffect(() => {
    updateStats(campaign);
  }, [campaign]);
  useEffect(() => {

    updateChart(campaign)
  }, [campaign])


  if (chartData && (chartStats[0]['labels'].length === 0)) {

    const newChartData = {};
    const chartKeys = Object.keys(chartData);
    // const alldates = {};
    // Object.keys(chartData).forEach(date => {
    //   const digits = date.match(/\d{1,}/g);
    //   const correctdate = digits[2]+'-'+digits[1]+'-'+digits[0];
    //   const d = new Date(correctdate);
    //   alldates[date] = d;
    // })

    // let sortarr = Object.values(alldates);
    // sortarr.sort((a,b) => b-a);
    // console.log(sortarr)

    const total = {};
    Object.keys(chartData[chartKeys[0]]).forEach(val => total[val] = 0)

    chartKeys.forEach(date => {
      newChartData[date] = {}
      Object.keys(chartData[date]).forEach(val => total[val] += chartData[date][val])
      Object.keys(total).forEach(key => newChartData[date][key] = total[key])
    })

    for (var i in Object.keys(newChartData)) {
      chartStats[0]['labels'].push(Object.keys(newChartData)[i])
      for (var j in chartStats[0].datasets) {
        chartStats[0].datasets[j]['data'].push(Object.values(newChartData)[i][chartStats[0].datasets[j].label]);
      }
    }
    // console.log(Object.values(chartData)[0][chartStats[0].datasets[0].label])
    // for (var j in chartStats[0].datasets) {

    // }
    console.log(newChartData)
  } else {
    for (var j in chartStats[0].datasets) {
      chartStats[0].datasets[j]['data'] = []
    }
    chartStats[0]['labels'] = [];

  }
  // console.log("data", chartData)

  // console.log("stats", chartStats)

  if (activeCampaigns) {
    if (campaignStats) {
      return (
        <Page
          className="DashboardPage"
          title="Dashboard"
          breadcrumbs={[
            {name: 'Campaigns', active: false },
            {name: 'Live campaign', active: true }
        ]}
        >
          <Form style={{
            width: '45vw',
            maxWidth: '400px'
          }}>
            <FormGroup>
              <Label for="exampleSelect">Select a campaign</Label>
              <Input type="select" name="select" id="exampleSelect" defaultValue="-1" onChange={handleChange}>
                <option disabled value="-1">Select a campaign</option>
                {
                  activeCampaigns && activeCampaigns.map((c) => {
                    return (
                      <option key={c} value={c}>{c}</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
          </Form>


          <Row >
            <Col lg={3} md={6} sm={6} xs={12}>
              <NumberWidget
                title="Samples Claimed"
                subtitle="This month"
                number={campaignStats.claimed}
                color="secondary"
                progress={{
                  value: Math.round((campaignStats.claimed / campaignStats.total) * 100 || 0),
                }}
              />
            </Col>

            <Col lg={3} md={6} sm={6} xs={12}>
              <NumberWidget
                title="Samples Delivered"
                subtitle="This month"
                number={campaignStats.delivered}
                color="secondary"
                progress={{
                  value: Math.round((campaignStats.delivered / campaignStats.claimed) * 100 || 0),
                }}
              />
            </Col>

            <Col lg={3} md={6} sm={6} xs={12}>
              <NumberWidget
                title="Happy Customers"
                subtitle="This month"
                number={campaignStats.happy}
                color="secondary"
                progress={{
                  value: Math.round((campaignStats.happy / campaignStats.delivered) * 100 || 0),
                }}
              />
            </Col>

            <Col lg={3} md={6} sm={6} xs={12}>
              <Card>
                <CardBody>
                  <b>End of Campaign</b>
                  <p className="text-primary">To Be Declared</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr />
          <br />
          <br />

          <Row>
            <Col xs="12">      <Line width="460%" height="150vh" data={chartStats[0] || null} options={chartOptions[0]} />
              {/* width = "460%" height = "150vh" */}
            </Col>
            {/* <Col xs="5">      <Doughnut data = {consumer[0]} />
    
          </Col> */}
          </Row>

          {/* <DataStudio reportURL={dataStudioStats.sales} /> */}

        </Page>
      );
    }
    else {
      return (


        <Page
          className="DashboardPage"
          title="Dashboard"
          breadcrumbs={[
            {name: 'Campaigns', active: false },
            {name: 'Live campaign', active: true }
        ]}
        >

          <Form style={{
            width: '45vw',
            maxWidth: '400px'
          }}>
            <FormGroup>
              <Label for="exampleSelect">Select a campaign</Label>
              <Input type="select" name="select" id="exampleSelect" defaultValue="-1" onChange={handleChange}>
                <option disabled value="-1">Select a campaign</option>
                {
                  activeCampaigns && activeCampaigns.map((c) => {
                    return (
                      <option key={c} value={c}>{c}</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
          </Form>

          <Alert color="primary">Select the campaign you want to see stats of.</Alert>

        </Page>
      );
    }

  } else {
    return (
      <Page
        className="DashboardPage"
        title="Dashboard"
        breadcrumbs={[
          {name: 'Campaigns', active: false },
          {name: 'Live campaign', active: true }
      ]}
      >
        <Link to="/new"><Button className="float-right" color="warning">Create A Campaign</Button></Link>
        <Alert color="primary">If you have not created a campaign then create a campaign and get it approved by the admin. If you have created a campaign, then please get it approved.</Alert>

      </Page>
    );
  }

}









DashboardPage.propTypes = {

  chartStats: PropTypes.array,
  chartOptions: PropTypes.array,
  consumer: PropTypes.array,
};

DashboardPage.defaultProps = {

  chartStats: [
    {
      labels: [],
      datasets: [
        {
          label: 'Samples Claimed',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(0, 184, 216,0.4)',
          borderColor: 'rgba(0, 184, 216,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(0, 184, 216,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(0, 184, 216,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'Delivered',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(237,206,69,0.4)',
          borderColor: 'rgba(237,206,69,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(237,206,69,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(237,206,69,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'Happy Customer',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(23,198,113,0.4)',
          borderColor: 'rgba(23,198,113,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(23,198,113,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(23,198,113,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }

  ],
  chartOptions: [
    {

      gridLines: {
        drawBorder: false,
        zeroLineColor: "transparent",
        color: "rgba(0,255,255,0.9)"

      }
    }
  ],
  consumer: [
    {
      label: "Insight into consumer behavior",
      datasets: [{
        data: [30, 50],
        label: ["Happy Customers", "Unhappy Customers"],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
        ]
      },
      {
        data: [100, 300],
        label: ["Purchase Intent", "No Purchase Intent"],
        backgroundColor: [
          '#FF6384',
          "#ffffff"
        ],
        hoverBackgroundColor: [
          '#FF6384',
          "#FFFFFF"
        ]
      }]
    }

  ]

};




const mapStateToProps = (state) => ({
  campaignStats: state.stats.campaignStats,
  chartData: state.chart.chartData,
  dataStudioStats: state.stats.dataStudioStats,
  activeCampaigns: state.campaigns.activeCampaigns,


});

const mapDispatchToProps = (dispatch) => ({
  updateStats: (campaign) => dispatch(startSetCampaignStats(campaign)),
  updateChart: (campaign) => dispatch(startSetChart(campaign)),
  getActiveCampaigns: () => {
    dispatch(getActiveCampaigns());
  }
});





export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
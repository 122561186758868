import React from 'react';
import { User } from '@styled-icons/feather/User';
import { Phone } from '@styled-icons/feather/Phone';
import { Home } from '@styled-icons/feather/Home';
import { connect } from 'react-redux';

const Detail = ({icon, text}) => {
    const Icon = icon;
    return (
        <div className="detail-component">
            <div className="detail-icon">
                <Icon />
                {/* {icon} */}
            </div>
            <div className="detail-text">
                {text}
            </div>
        </div>
    )
}

const BasicDetails = ({ user, user_data }) => {
    return (
        <div className="basic-user-details">
            <h3 className="user-name">{user_data[user]['Full_Name']}</h3>
            <Detail icon={User} text={user_data[user]['fbid']} />
            <Detail icon={Phone} text={user} />
            <Detail icon={Home} text={user_data[user]['Address']} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.stats.activeUser,
    user_data: state.stats.audience
})

export default connect(mapStateToProps, null)(BasicDetails);
import {firebase} from '../../firebase/firebase'

export const getRecentCharts = (campaign) => {
    return (dispatch, getState) => {
        // console.log(campaign);
        const chartsRef = firebase.database().ref(`/users/${getState().auth.uid}/campaigns/${campaign}/dummy-chart-data/current-changes`);
        
        chartsRef.on('value', snapshot => dispatch({
            type: 'GET_RECENT_CHARTS',
            charts: snapshot.val()
        }))
    }
}

export const getPreviousCharts = (campaign) => {
    return (dispatch, getState) => {
        // console.log(campaign);
        const chartsRef = firebase.database().ref(`/users/${getState().auth.uid}/campaigns/${campaign}/chart-data`);
        
        chartsRef.on('value', snapshot => dispatch({
            type: 'GET_PREVIOUS_CHARTS',
            charts: snapshot.val()
        }))
    }
}

export const getFbCharts = (campaign) => {
    return (dispatch, getState) => {
        const chartsRef = firebase.database().ref(`/users/${getState().auth.uid}/campaigns/${campaign}/social/fbStats`);
        chartsRef.on('value', snapshot => dispatch({
            type: 'GET_FB_CHARTS',
            charts: snapshot.val()
        }))
    }
}

export const getInstaCharts = (campaign) => {
    return (dispatch, getState) => {
        const chartsRef = firebase.database().ref(`/users/${getState().auth.uid}/campaigns/${campaign}/social/instaStats`);
        chartsRef.on('value', snapshot => dispatch({
            type: 'GET_INSTA_CHARTS',
            charts: snapshot.val()
        }))
    }
}



import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Alert
  } from 'reactstrap';
import { connect } from 'react-redux';
import { getCampaigns, setSelectedCamp } from '../../redux/campaigns/campaigns.actions';
import { Link } from 'react-router-dom';
import PageSpinner from '../page/PageSpinner';
  
  
const ShowCampaigns = ({showCampaigns, campaignslist, setSelected }) => {
    
    const isLoading = campaignslist===null;

    const sortCampaigns = (type) => {
        const campslist = {};
        if(!isLoading){
            Object.keys(campaignslist).forEach(el => {
            if(campaignslist[el]["status"]===type) campslist[el] = type;
            })
        }
        return campslist;
    }

    useEffect(() => {
        showCampaigns();
    }, [showCampaigns])

    const mainheaders = {                             
        "Name": {
            "campaignDetails":"CampaignName"
        },                        
        "Category": {
            "productDetails":"ProductCategory"
        },
        "Sampling Modules" : {
            "campaignDetails":"SamplingModules"
        },
        "No. of Samples":{
            "marketing":"SelectedNumberOfSamples"
        },
        "Launch": {
            "responsibilities":"Launch"
        }
    }

    const handleSelect = (e) => {
        setSelected(e.target.value);
    }

    const getValue = (p1, p2, p3) => {
        const myvalue = campaignslist[p1]["data"][p3][mainheaders[p2][p3]];
        console.log(myvalue);
        if (typeof(myvalue)!==typeof({})) return myvalue;
        else {
            let val = "";
            Object.values(myvalue).map(i => {
                if (val==="") val = i
                else if (typeof(i)===typeof("")) val = val + ", " + i
            });
            return val;
        }
    }

    return(
        <Card>
            <CardHeader className="h4 text-dark">
                EXISTING CAMAPIGNS
                <Link to="/new"><Button className="float-right" color="warning">Create A Campaign</Button></Link>
            </CardHeader>
            <CardBody>
                <Card>
                    <CardHeader className="bg-light h5 text-dark">
                        ACTIVE CAMPAIGNS
                    </CardHeader>
                    <CardBody className="bg-light">
                        {isLoading && (<PageSpinner />)}
                        {!isLoading && Object.keys(sortCampaigns("active")).length===0 && (<Alert color="primary">No Record Found!</Alert>)}
                        {!isLoading && Object.keys(sortCampaigns("active")).length!==0 && (
                        <Table>
                            <thead className="bg-light">
                                <tr>
                                    {Object.keys(mainheaders).map(key => <th key={key}>{key}</th>)}  
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.keys(sortCampaigns("active")).map(key =>
                                <tr key={key} className="table-success">
                                    {Object.keys(mainheaders).map(info =>
                                    <td key={info}>
                                        {Object.keys(mainheaders[info]).map(subinfo =>
                                            <span key={subinfo}>{getValue(key, info, subinfo)}</span>
                                        )}
                                    </td>
                                    )}
                                    <td><Button color="primary" value={key} onClick={handleSelect}>View Details</Button></td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        )}
                    </CardBody>
                </Card><br />
                <Card>
                    <CardHeader className="bg-light h5 text-dark">
                        CAMPAIGNS AWAITING YOUR ACTION
                    </CardHeader>
                    <CardBody className="bg-light">
                        {isLoading && (<PageSpinner />)}
                        {Object.keys(sortCampaigns("approved")).length===0 && (<Alert color="primary">No Record Found!</Alert>)}
                        {!isLoading && Object.keys(sortCampaigns("approved")).length!==0 && (
                        <Table>
                            <thead className="bg-light">
                                <tr>
                                {Object.keys(mainheaders).map(key => <th key={key}>{key}</th>)}  
                                <th></th>
                                </tr> 
                            </thead>
                            <tbody>
                            {Object.keys(sortCampaigns("approved")).map(key =>
                                <tr key={key} className="table-warning">
                                    {Object.keys(mainheaders).map(info =>
                                    <td key={info}>
                                        {Object.keys(mainheaders[info]).map(subinfo =>
                                            <span key={subinfo}>{getValue(key, info, subinfo)}</span>
                                        )}
                                    </td>
                                    )}
                                    <td><Button color="primary" value={key} onClick={handleSelect}>View Details</Button></td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        )}
                    </CardBody>
                </Card><br />
                <Card>
                    <CardHeader className="bg-light h5 text-dark">
                        CAMPAIGNS AWAITING ADMIN APPROVAL
                    </CardHeader>
                    <CardBody className="bg-light">
                        {isLoading && (<PageSpinner />)}
                        {Object.keys(sortCampaigns("pending")).length===0 && (<Alert color="primary">No Record Found!</Alert>)}
                        {!isLoading && Object.keys(sortCampaigns("pending")).length!==0 && (
                        <Table>
                            <thead>
                                <tr>
                                {Object.keys(mainheaders).map(key => <th key={key}>{key}</th>)}  
                                <th></th>
                                </tr> 
                            </thead>
                            <tbody>
                            {Object.keys(sortCampaigns("pending")).map(key =>
                                <tr key={key} style={{backgroundColor: "#e6f7ff"}}>
                                    {Object.keys(mainheaders).map(info =>
                                    <td key={info}>
                                        {Object.keys(mainheaders[info]).map(subinfo =>
                                            <span key={subinfo}>{getValue(key, info, subinfo)}</span>
                                        )}
                                    </td>
                                    )}
                                    <td>
                                        <Link to={`complete/${key}`}>
                                            <Button color="success" value={key}>Edit</Button>{' '}
                                        </Link>
                                        <Button color="primary" value={key} onClick={handleSelect}>View Details</Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        )}
                    </CardBody>
                </Card><br />
                <Card>
                    <CardHeader className="bg-light h5 text-dark">
                        INCOMPLETE CAMPAIGNS
                    </CardHeader>
                    <CardBody className="bg-light">
                        {isLoading && (<PageSpinner />)}
                        {Object.keys(sortCampaigns("incomplete")).length===0 && (<Alert color="primary">No Record Found!</Alert>)}
                        {!isLoading && Object.keys(sortCampaigns("incomplete")).length!==0 && (
                        <Table>
                            <thead>
                                <tr>
                                {Object.keys(mainheaders).map(key => <th key={key}>{key}</th>)}  
                                <th></th>
                                </tr> 
                            </thead>
                            <tbody>
                            {Object.keys(sortCampaigns("incomplete")).map(key =>
                                <tr key={key} className="table-danger">
                                    {Object.keys(mainheaders).map(info =>
                                    <td key={info}>
                                        {Object.keys(mainheaders[info]).map(subinfo =>
                                            <span key={subinfo}>{getValue(key, info, subinfo)}</span>
                                        )}
                                    </td>
                                    )}
                                    <td>
                                        <Button color="primary" value={key} onClick={handleSelect}>View Details</Button>{' '}
                                        <Link to={`complete/${key}`}>
                                            <Button color="success" value={key}>Complete</Button>
                                        </Link>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        )}
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    )
}

const mapDispatchToProps = (dispatch) => ({
    showCampaigns: () => dispatch(getCampaigns()),
    setSelected: (campname) => dispatch(setSelectedCamp(campname))
});

const mapStateToProps = (state) => ({
    campaignslist: state.campaigns.campaignslist
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowCampaigns);
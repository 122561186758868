const defaultState = {
    socials: {},
    assets: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_CLIENT_SOCIALS':
            return {
                ...state,
                socials: action.socials
            }

        case 'SET_CLIENT_ASSETS':
            return {
                ...state,
                assets: action.assets
            }

        default:
            return state;
    }
}
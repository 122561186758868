import logo from '../../assets/img/sidebar/FS_Logo_White.png'
import sidebarBgImage from '../../assets/img/sidebar/rsz_1sidebar-4.jpg';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Collapse
} from 'reactstrap';
import { getTasksNum } from '../../redux/tasks/tasks.actions';
import { resetFlowList } from '../../redux/chatflow/chatflow.actions';
import { getAllClients } from '../../redux/agency/clientlist.actions';
import { selectClient } from '../../redux/auth/auth.actions';
import bn from '../../utils/bemnames';
import SidebarCollapseItem from './sidebar-collapse-item/sidebar-collapse-item.component.jsx';
import { useLocation } from 'react-router-dom';

import {
  IoIosArrowDown,
  IoIosArrowUp
} from 'react-icons/io';

// Icons from styled components library
// In my opinion this is a better library, it has much more options and also react-icons started giving me errors, so... Fuck it!
import { Megaphone } from '@styled-icons/bootstrap/Megaphone';
import { Barcode } from '@styled-icons/boxicons-regular/Barcode';
import { Graph } from '@styled-icons/octicons/Graph';
import { AddChart } from '@styled-icons/material/AddChart';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { Stack } from '@styled-icons/icomoon/Stack';
import { Users } from '@styled-icons/fa-solid/Users';
import { Insights } from '@styled-icons/material-outlined/Insights';
import { Image } from '@styled-icons/ionicons-outline/Image';
import { Boxes } from '@styled-icons/fa-solid/Boxes';
import { Rupee } from '@styled-icons/boxicons-regular/Rupee';
import { ExtensionPuzzle } from '@styled-icons/ionicons-outline/ExtensionPuzzle';
import { Bell } from '@styled-icons/feather/Bell';
import { Headphones } from '@styled-icons/feather/Headphones';
import { User } from '@styled-icons/feather/User';
import { Graphql } from '@styled-icons/simple-icons/Graphql';
import { MapMarkedAlt } from '@styled-icons/fa-solid/MapMarkedAlt';
import { Settings } from '@styled-icons/feather/Settings';

const bem = bn.create('sidebar');

const Sidebar = ({tasksnum, notifsnum, getNum, getClients, clientslist, chooseClient, selclient, resetFlowListProp, userPic }) => {
  const location = useLocation();

  const [isCampaignDropdownOpen, setIsCampaignDropdownOpen] = useState(false);
  const [isAnalyticsDropdownOpen, setIsAnalyticsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    getNum();
    getClients();
  }, [getNum, tasksnum, notifsnum, getClients]);

  const handleChoose = (e) => {
    chooseClient(e.target.id);
    resetFlowListProp();
  }

  useEffect(() => {
    if(location.pathname.includes('new') || location.pathname.includes('live') || location.pathname.includes('campaigns')) {
      setIsCampaignDropdownOpen(true);
    }
    else {
      setIsCampaignDropdownOpen(false);
    }

    if(location.pathname.includes('user-stats') || location.pathname.includes('ads')) {
      setIsAnalyticsDropdownOpen(true);
    }
    else {
      setIsAnalyticsDropdownOpen(false);
    }
  }, [location]);

  const navItems = [
    {
      title: 'Campaigns',
      SidebarIcon: Megaphone,
      items: [
        { to: '/new', name: 'New Campaign', exact: false, Icon: AddChart },
        { to: '/live', name: 'Live Campaign', exact: true, Icon: Calendar },
        { to: '/campaigns', name: 'All Campaigns', exact: false, Icon: Stack },
      ],
      open: isCampaignDropdownOpen
    },
    { to: '/sku', name: "SKU's", exact: false, Icon: Barcode },
    {
      title: 'Analytics',
      SidebarIcon: Graph,
      items: [
        { to: '/user-stats', name: 'Audience', exact: false, Icon: Users },
        { to: '/ads', name: 'Ads Insights', exact: true, Icon: Insights },
        { to: '/map', name: 'Location', exact: true, Icon: MapMarkedAlt },
        { to: '/social-stats', name: 'Social Media', exact: false, Icon: Graphql }
      ],
      open: isAnalyticsDropdownOpen
    },
    { to: '/graphics-approval-system', name: 'Graphic Approvals', exact: false, Icon: Image },
    { to: '/inventory/status', name: 'Inventory Status', exact: false, Icon: Boxes },
    { to: '/billing', name: 'Billing', exact: false, Icon: Rupee },
    { to: '/integrations', name: 'Integrations', exact: false, Icon: ExtensionPuzzle },
    // { to: '/social', name: 'social media', exact: false, Icon: MdWeb },
    // { to: '/inventory/scheduler', name: 'inventory scheduler', exact: false, Icon: IoMdClock },
    // { to: '/chat', name: 'Chat Approvals', exact: false, Icon: MdForum },
    // { to: '/chatbot', name: 'chatbot integrations', exact: false, Icon: MdChatBubble },
    // { to: '/chatflow', name: 'chatflow builder', exact: false, Icon: MdChatBubble },
    // { to: '/roles', name: 'roles', exact: false, Icon: MdSettings }
  ];

  const bottomNavItems = [
    { to: '/tasks', name: 'Notifications', exact: false, Icon: Bell },
    { to: '/help', name: 'Support', exact: false, Icon: Headphones },
    { to: '/profile', name: 'Profile', exact: false, Icon: User },
    { to: '/settings', name: 'Settings', exact: false, Icon: Settings },
  ];

  return (
    <aside style={{ backgroundColor: "#052762", display: 'flex', flexDirection: 'column' }} className={bem.b()} data-image={sidebarBgImage}>
      {/* <div className={bem.e('background')} style={sidebarBackground} /> */}
      <div className={bem.e('content')}>
        <div>
          <Navbar style={{
            height: '68px',
            borderBottom: '1px solid #ffffff20'
          }}>
            <div id="brand-img"/>
          </Navbar >

          <Nav vertical id="sidebar-navs">
            <NavItem className={bem.e('nav-item')}>
              <div className="client-select" onClick={toggle}>
                <img src={userPic} width="30" height="30" className="rounded-circle user-img" />
                <span className="select-text">
                  {
                    (selclient && clientslist) ? <span>{clientslist[selclient]}</span> : "Select Client"
                  }
                </span>
                <span className="arrow">
                  {isOpen ? <IoIosArrowUp className="float-right mt-1" /> :
                    <IoIosArrowDown className="float-right mt-1" />
                  }
                </span>
              </div>
            </NavItem>
            <Collapse isOpen={isOpen}>
              <div className="client-wrapper">
                {clientslist && Object.keys(clientslist).map(key =>
                  <div className={selclient === key ? "cl-active client-option" : "cl-other client-option"}
                    id={key} key={key} onClick={handleChoose}
                  >
                    <span>{clientslist[key]}</span>
                  </div>
                )}
              </div>
            </Collapse>
            {navItems.map((item, index) => (
              !item.items ? (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${item.name}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    to={item.to}
                    activeClassName="active"
                    exact={item.exact}
                  >
                    <item.Icon className={bem.e('nav-item-icon')} />
                    <span className="nav-item-name">{item.name}</span>
                    {item.name === 'Notifications' && tasksnum > 0 && (
                      <div className="side-badges">
                        <span className="task-badge">{tasksnum}</span>
                        <span className="notif-badge">{notifsnum}</span>
                      </div>
                    )}
                  </BSNavLink>
                </NavItem>
              ) : (
                  <SidebarCollapseItem key={index} item={item} />
                )
            ))}
          </Nav>
        </div>

        <div style={{
          borderTop: '1px solid #ffffff20',
          paddingBottom: '10px'
        }}>
          <Nav vertical id="sidebar-navs">
            {bottomNavItems.map((item, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${item.name}-${index}`}
                  // className="text-uppercase"
                  tag={NavLink}
                  to={item.to}
                  activeClassName="active"
                  exact={item.exact}
                >
                  <item.Icon className={bem.e('nav-item-icon')} />
                  <span className="nav-item-name">{item.name}</span>
                  {item.name === 'tasks/updates' && tasksnum > 0 && (
                    <div className="side-badges">
                      <span className="task-badge">{tasksnum}</span>
                      <span className="notif-badge">{notifsnum}</span>
                    </div>
                  )}
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <p style={{
            marginLeft: 'auto',
            width: '50px',
            marginTop: '10px'
          }}>v1.01</p>
        </div>
      </div>
    </aside >
  );
}

const mapStateToProps = (state) => ({
  tasksnum: state.tasks.tasksnum,
  notifsnum: state.tasks.notifsnum,
  clientslist: state.agency.clientslist,
  selclient: state.auth.uid,
  userPic: state.profile.userpic
});

const mapDispatchToProps = (dispatch) => ({
  getNum: () => dispatch(getTasksNum()),
  getClients: () => dispatch(getAllClients()),
  chooseClient: (uid) => dispatch(selectClient(uid)),
  resetFlowListProp: () => dispatch(resetFlowList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


const InputQuestionComponent = (props) => {
    const [value, setValue] = useState('');
    const [errorText, setErrorText] = useState('');

    const handleChange = (event) => {
        const {value} = event.target;
        setValue(value);
        props.sendValueToStore(value);

        if(props.validateFunction) {
            if(props.validateFunction(value)) {
                event.target.style.border = '1px solid red';
                event.target.nextSibling.classList.add('show');
                setErrorText(props.errorText);
            }
            else {
                event.target.style.border = '1px solid #79797961';
                event.target.nextSibling.classList.remove('show');
            }
        }
    }

    useEffect(() => {
        if(props.storeVariable) {
            setValue(props.storeVariable)
        }
        else {
            setValue('');
        }
    }, [props.storeVariable]);

    const handleBlur = (event) => {
        if (props.required && event.target.value.length === 0) {
            event.target.style.border = '1px solid red';
            event.target.nextSibling.classList.add('show');
            setErrorText('This cannot be empty');
        }
    }

    return (
        <div className="question-container" style={props.center ? ({
            paddingTop: '100px'
        }) : null}>
            <h3 className="question-heading">{props.heading}</h3>
            <p className="question-description">{props.description}</p>
            <input onBlur={handleBlur} value={value} onChange={handleChange} className='question-input' type={props.type} placeholder={props.placeholder} />
            <div className="error-text show">{errorText}</div>
        </div>
    )
}

InputQuestionComponent.defaultProps = {
    type: 'text'
}

export default InputQuestionComponent;
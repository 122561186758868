import { addGeoLocationHelper } from './helperFunctions/addGeoLocation.helper';
import { deleteGeoLocationHelper } from './helperFunctions/deleteGeoLocation.helper';
import { changeLocationTypeHelper } from './helperFunctions/changeLocationType.helper';
import { addInterestHelper } from './helperFunctions/addInterest.helper';
import { deleteInterestHelper } from './helperFunctions/deleteInterest.helper';
import { setGenderHelper } from './helperFunctions/setGender.helper';

const INITIAL_STATE = {
    targeting_specs: {
        age_max: "",
        age_min: "",
        genders: [],
        geo_locations: {
            countries: [
                // Country codes
            ],
            regions: [
                // key
            ],
            cities: [
                // key
            ],
            zips: [
                // key
            ],
            location_types: ["recent", "home"]
            // everyone is same
            // live: only home
            // travelling: travel_in
            // recent: recent
        },
        behaviors: [
            // {
            //     id: '',
            //     name: ''
            // }
        ],
        interests: [
            // {
            //     id: '',
            //     name: ''
            // }
        ]
    },
    AudienceSizeFetched: '',
    LocationsArray: [],
    InterestsArray: [],
    LocationType: ''
}

const socialMediaTargetingReducer = (state = INITIAL_STATE, action) => {
    const object = action.payload;
    const name = object && object[0];
    const type = object && object[1];
    const id = object && object[2];

    switch (action.type) {
        case 'ADD_GEO_LOCATION':
            return addGeoLocationHelper(type, id, state);

        case 'DELETE_GEO_LOCATION':
            return deleteGeoLocationHelper(type, id, state);

        case 'CHANGE_LOCATION_TYPE':
            return changeLocationTypeHelper(action.payload, state);

        case 'ADD_INTEREST':
            return addInterestHelper(id, name, type, state);

        case 'DELETE_INTEREST':
            return deleteInterestHelper(id, type, state);

        case 'SET_AGE_MAX':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    age_max: action.payload
                }
            }

        case 'SET_AGE_MIN':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    age_min: action.payload
                }
            }

        case 'SET_GENDER':
            return setGenderHelper(action.payload[0], state);

        case 'SET_AUDIENCE_SIZE_FETCHED':
            return {
                ...state,
                AudienceSizeFetched: action.payload
            }

        case 'SET_LOCATIONS_ARRAY':
            return {
                ...state,
                LocationsArray: action.payload
            }

        case 'SET_INTERESTS_ARRAY':
            return {
                ...state,
                InterestsArray: action.payload
            }

        case 'GET_SOCIAL_MEDIA_TARGETING':
            return {
                ...state,
                ...action.payload
            }

        case 'RESET_SOCIAL_MEDIA_TARGETING':
            return {
                ...INITIAL_STATE
            }

        default:
            return state
    }
}

export default socialMediaTargetingReducer;
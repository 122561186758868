import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Tooltip } from 'antd';
import './set-config-component.scss';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.addable) {
            this.columns = [
                {
                    title: 'Key Name',
                    dataIndex: 'keyName',
                    // width: 300,
                    editable: props.addable,
                },
                {
                    title: 'Value',
                    dataIndex: 'value',
                    editable: true,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: value => (
                        <Tooltip placement="topLeft" title={value}>
                            {value}
                        </Tooltip>
                    ),
                },
                {
                    title: 'operation',
                    dataIndex: 'operation',
                    width: 200,
                    render: (_, record) =>
                        this.state.dataSource.length >= 1 ? (
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                                <a>Delete</a>
                            </Popconfirm>
                        ) : null,
                },
            ];
        }
        else {
            this.columns = [
                {
                    title: 'Key Name',
                    dataIndex: 'keyName',
                    // width: '30%',
                    editable: props.addable,
                },
                {
                    title: 'Value',
                    dataIndex: 'value',
                    editable: !props.noEdit,
                    // width: 400,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: value => (
                        <Tooltip placement="topLeft" title={value}>
                            {value}
                        </Tooltip>
                    ),
                }
            ];
        }
        this.state = {
            dataSource: [],
            count: 0,
        };
    }

    updateState() {
        let formattedData = [];
        const { data } = this.props;
        if (data) {
            Object.keys(data).forEach((dataKey, idx) => {
                const dataObject = {
                    key: idx,
                    keyName: dataKey,
                    value: data[dataKey].toString()
                };
                formattedData.push(dataObject);
            });
            // console.log(formattedData);
            this.setState({
                ...this.state,
                dataSource: formattedData,
                count: formattedData.length
            });
        }
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.updateState();
        }
    }

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            keyName: 'New key',
            value: 'New value',
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.setState({
            dataSource: newData,
        });
    };

    handlePublish = () => {
        let formattedData = {};

        if (this.props.onlyBoolean) {
            this.state.dataSource.map((data) => {
                formattedData[data["keyName"]] = (data["value"] === "true");
            });
        }
        else {
            this.state.dataSource.map((data) => {
                formattedData[data["keyName"]] = data["value"];
            });
        }


        this.props.onPublish(formattedData);
    }

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse'
                }}>
                    {
                        !this.props.noEdit && (
                            <Button type="primary" onClick={this.handlePublish} style={{
                                marginBottom: 16,
                            }}>Publish</Button>
                        )
                    }
                    {
                        this.props.addable && (
                            <Button
                                onClick={this.handleAdd}
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                Add a row
                            </Button>
                        )
                    }
                </div>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        );
    }
}

export default EditableTable;

import React, { useState } from 'react';
import Page from '../components/page/Page';
import { connect } from 'react-redux';
import { Row, Card, CardHeader, Col, CardBody, CardFooter, Button, Alert } from 'reactstrap';
import { startSetClientAssets } from '../redux/clientinfo/clientInfo.actions';

const BrandAssets = ({ assets, getAssets }) => {

    useState(() => {
        getAssets();
    }, [getAssets]);
    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);

    return (
        <Page
            title="Brand Assets"
            breadcrumbs={[{ name: 'Brand Assets', active: true }]}
        >
        <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            You will be directed to Google Drive
        </Alert>
        <Row>
            {Object.keys(assets).map(key =>
                <Col lg={6} md={6} sm={12} xs={12} id={key}>
                    <Card>
                        <CardHeader>{key.toUpperCase().replace('_', ' ')}</CardHeader>
                        <CardBody>
                            <p>SEE ALL {key.toUpperCase().replace('_', ' ')}</p>
                        </CardBody>
                        <CardFooter>
                            <a href={assets[key]} target="_blank" rel="noopener noreferrer">
                                <Button>Click Here</Button>
                            </a>
                        </CardFooter>
                    </Card>
                </Col>    
            )}
        </Row>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    assets: state.clientInfo.assets
});

const mapDispatchToProps = (dispatch) => ({
    getAssets: () => dispatch(startSetClientAssets())
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandAssets);
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';

const ProfileCard = ({ header, data }) => {
    return (
        <Card outline color="primary">
            <CardHeader>
                <h3>{`${header.toUpperCase()} Details`}</h3>
            </CardHeader>
            <CardBody>
                {Object.keys(data).map((key, index) => {
                    if (data[key] !== undefined) {
                        return (<p key={index}><b>{key.toUpperCase()}:</b> <span>{data[key].toString()}</span></p>);
                    } else {
                        return (<p key={index}><b>{key.toUpperCase()}:</b></p>)
                    }
                })}
            </CardBody>
        </Card>
    );
};

ProfileCard.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
    bgColor: PropTypes.string
};

export default ProfileCard;
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { Button, Spinner } from 'reactstrap';
import PageIcon from '../../assets/img/integrations/fbpage.svg'
import CustomModal from './customModal.component';

const FBLoginComponent = () => {

    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
              appId            : '1275638289498645',
              autoLogAppEvents : true,
              xfbml            : true,
              version          : 'v8.0'
            });
            setLoading(false);
        };
        const script = document.createElement('script');
      
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossorigin = "anonymous";
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []
    );

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [modalStatus, setStatus] = useState('');

    const modalClose = () => {
        setModal(false);
        setLoading(false);
    }

    const handleConnect = () => {
        setLoading(true);
        setStatus("CHOOSE");
        setModal(true);
    }

    return (
        <div className="integration-card">
            <Card className="integration-card">
                <div className="icon">
                    <img src={PageIcon} alt="" />
                </div>
                <div className="content">
                    <h4>FB Page Insights</h4>
                    <p>Facebook Page Access to get data about your facebook page</p>
                </div>
                {loading? <Spinner color="primary" /> : (
                    <Button className="connect-button" onClick={handleConnect}>
                        Connect
                    </Button>
                )}
            </Card>
            <CustomModal 
                isOpen={modal} status={modalStatus} 
                changeStatus={setStatus} toggle={modalClose}
            />
        </div>
    )
}

export default FBLoginComponent;

import Awareness from '../../../assets/img/cac/awareness.svg';
import FlavourPreference from '../../../assets/img/cac/flavour-preference.svg';
import CustomerAdvocacy from '../../../assets/img/cac/customer-advocacy.svg';
import Lauch from '../../../assets/img/cac/launch.svg';
import Audience from '../../../assets/img/cac/audience.svg';
import Community from '../../../assets/img/cac/community.svg';
import FSCommunity from '../../../assets/img/cac/fs-community.svg';
import Influencer from '../../../assets/img/cac/influencer.svg';
import Choice from '../../../assets/img/cac/choice.svg';
import FoodBeverage from '../../../assets/img/cac/food-beverage.svg';
import Household from '../../../assets/img/cac/household.svg';
import OneSku from '../../../assets/img/cac/one-sku.svg';
import Personal from '../../../assets/img/cac/personal.svg';
import Random from '../../../assets/img/cac/random.svg';
import SocialMedia from '../../../assets/img/cac/social-media.svg';
import Special from '../../../assets/img/cac/special.svg';
import HealthyCompetition from '../../../assets/img/cac/healthy-competition.svg';
import OneToTwo from '../../../assets/img/cac/one-to-two.svg';
import HealthCare from '../../../assets/img/cac/vitamin.svg';

export const predefinedData = {
    optionButtonsObjectives: [
        {
            id: 'drive-awareness',
            text: 'Drive Awareness',
            icon: Awareness,
            description: 'Drive Awareness'
        },
        {
            id: 'flavour-analysis',
            text: 'Flavour Preference Analysis',
            icon: FlavourPreference,
            description: 'Flavour Preference Analysis'
        },
        {
            id: 'consumer-advocacy',
            text: 'Boost Consumer Advocacy',
            icon: HealthCare, // Fix it
            description: 'Boost Consumer Advocacy'
        },
        {
            id: 'target-audience',
            text: 'Target Audience Identification',
            icon: Audience,
            description: 'Target Audience Identification'
        },
        {
            id: 'launch',
            text: 'Launch',
            icon: Lauch,
            description: 'Launch',
            subOptions: [
                {
                    id: 'new-audience',
                    text: 'New audience',
                    description: 'New audience',
                },
                {
                    id: 'new-variant',
                    text: 'New variant',
                    description: 'New variant',
                },
                {
                    id: 'new-market',
                    text: 'New market',
                    description: 'New market',
                }
            ]
        }
    ],

    optionButtonsModules: [
        {
            id: 'social-media',
            text: 'Social Media Targeting Campaign',
            icon: SocialMedia,
            description: 'Social Media Targeting Campaign'
        },
        {
            id: 'influencer-driven',
            text: 'Influencer Driven Campaign',
            icon: Influencer,
            description: 'Influencer Driven Campaign'
        },
        {
            // (Existing Consumer Base can be retargeted)
            id: 'fs-community',
            text: 'FS Community',
            icon: FSCommunity,
            description: 'FS Community',
            badge: true
        },
        {
            id: 'existing-community',
            text: 'Your Existing Community',
            icon: Community,
            description: 'Your Existing Community',
            badge: true
        }
        
    ],

    optionButtonsDistributionIfFlavourPreference: [
        {
            id: 'random-split',
            text: 'Healthy Competition : 50:50 Random split',
            icon: HealthyCompetition,
            description: 'Healthy Competition : 50:50 Random split',
        },
        {
            id: 'two-customer',
            text: 'Two SKUs to one consumer and assess the better option',
            icon: OneToTwo,
            description: 'Two SKUs to one consumer and assess the better option',
        },
    ],

    optionButtonsDistributionOther: [
        {
            id: 'one-sku',
            text: 'One SKU to be sampled',
            icon: OneSku,
            description: 'One SKU to be sampled',
        },
        {
            id: 'multiple-sku',
            text: 'Multiple SKUs to be sampled randomly',
            icon: Random,
            description: 'Healthy Competition : 50:50 Random split',
        },
        {
            id: 'multiple-sku-customer-choice',
            text: 'Multiple SKUs to be sampled as per consumer\'s choice',
            icon: Choice,
            description: 'Multiple SKUs to be sampled as per consumer\'s choice',
        },
    ],

    optionButtonsAudienceConnect: [
        {
            id: 'web-app',
            text: 'Web app'
        },
        {
            id: 'chatbot',
            text: 'Chatbot'
        }
    ],

    optionButtonsCategories: [
        {
            id: 'personal-care',
            text: 'Personal Care',
            icon: Personal,
            description: 'Personal Care',
            subOptionsText: 'Search for the personal care category or enter a new one',
            subOptions: [
                {
                    id: 'other-personal-care',
                    text: 'Other',
                    icon: Lauch,
                    description: 'Other',
                },
                {
                    id: 'oral-care',
                    text: 'Oral Care',
                    icon: Lauch,
                    description: 'Oral Care',
                },
                {
                    id: 'hair-care',
                    text: 'Hair Care',
                    icon: Lauch,
                    description: 'Hair Care',
                },
                {
                    id: 'skin-care',
                    text: 'Skin Care',
                    icon: Lauch,
                    description: 'Skin Care',
                },
                {
                    id: 'personal-wash',
                    text: 'Personal Wash',
                    icon: Lauch,
                    description: 'Personal Wash',
                },
                {
                    id: 'cosmetic-toiletries',
                    text: 'Cosmetics & Toiletaries',
                    icon: Lauch,
                    description: 'Cosmetics & Toiletaries',
                },
                {
                    id: 'feminine-hygiene',
                    text: 'Feminine Hygiene',
                    icon: Lauch,
                    description: 'Feminine Hygiene',
                },
                {
                    id: 'paper-product',
                    text: 'Paper Product',
                    icon: Lauch,
                    description: 'Paper Product',
                }
            ]
        },
        {
            id: 'household',
            text: 'Household Care',
            icon: Household,
            description: 'Household Care',
            subOptionsText: 'Search for the household category or enter a new one',
            subOptions: [
                {
                    id: 'other-household',
                    text: 'Other',
                },
                {
                    id: 'fabric-wash',
                    text: 'Fabric Wash',
                },
                {
                    id: 'household-cleaners',
                    text: 'Household Cleaners',
                }
            ]
        },
        {
            id: 'food-bevarages',
            text: 'Food & Beverages',
            icon: FoodBeverage,
            description: 'Food & Beverages',
            subOptionsText: 'Search for the food and beverages category or enter a new one',
            subOptions: [
                {
                    id: 'other-food-beverages',
                    text: 'Other',
                    icon: Lauch,
                    description: 'Other',
                },
                {
                    id: 'health-beverages',
                    text: 'Health Beverages',
                    icon: Lauch,
                    description: 'Health Beverages',
                },
                {
                    id: 'soft-drinks',
                    text: 'Soft Drinks',
                    icon: Lauch,
                    description: 'Soft Drinks',
                },
                {
                    id: 'staples-cereals',
                    text: 'Stapes/Cereals',
                    icon: Lauch,
                    description: 'Stapes/Cereals',
                },
                {
                    id: 'bakery-products',
                    text: 'Bakery Products',
                    icon: Lauch,
                    description: 'Bakery Products',
                },
                {
                    id: 'snack-food',
                    text: 'Snack Food',
                    icon: Lauch,
                    description: 'Snack Food',
                },
                {
                    id: 'chocolate',
                    text: 'Chocolate',
                    icon: Lauch,
                    description: 'Chocolate',
                },
                {
                    id: 'ice-cream',
                    text: 'Ice Cream',
                    icon: Lauch,
                    description: 'Ice Cream',
                },
                {
                    id: 'tea',
                    text: 'Tea',
                    icon: Lauch,
                    description: 'Tea',
                },
                {
                    id: 'coffee',
                    text: 'Coffee',
                    icon: Lauch,
                    description: 'Coffee',
                },
                {
                    id: 'processed-fruits-vegetables',
                    text: 'Processed fruits, vegetables',
                    icon: Lauch,
                    description: 'Processed fruits, vegetables',
                },
                {
                    id: 'dairy-products',
                    text: 'Dairy Products',
                    icon: Lauch,
                    description: 'Dairy Products',
                },
                {
                    id: 'bottled-water',
                    text: 'Bottled Water',
                    icon: Lauch,
                    description: 'Bottled Water',
                },
                {
                    id: 'branded-flour',
                    text: 'Branded Flour',
                    icon: Lauch,
                    description: 'Branded Flour',
                },
                {
                    id: 'branded-rice-sugar-juices',
                    text: 'Branded Rice, Sugar, Juices',
                    icon: Lauch,
                    description: 'Branded Rice, Sugar, Juices',
                }
            ]
        },
        {
            id: 'healthcase',
            text: 'Healthcare',
            icon: HealthCare,
            description: 'Health Care',
        },
        {
            id: 'special-category',
            text: 'Special Category',
            icon: Special,
            description: 'Special Category',
            subOptions: [
                {
                    id: 'other-special-category',
                    text: 'Please mention category',
                    icon: Lauch,
                    description: 'Other',
                }
            ]
        }
    ],

    optionButtonsPrimaryPackaging: [
        {
            id: 'freestand-box-design',
            text: 'Freestand Box',
            description: 'Freestand Box'
        },
        {
            id: 'boxes-ready',
            text: 'We have our box designs ready',
            description: 'We have our box designs ready'
        },
        {
            id: 'custom-packaging',
            text: 'Request Custom',
            description: 'Request Custom',
            subOptionsText: 'Select who will be designing the requested custom box',
            subOptions: [
                {
                    id: 'freestand-box',
                    text: 'Freestand',
                    description: 'Freestand'
                },
                {
                    id: 'partner-agency-box',
                    text: 'Partner agency',
                    description: 'Partner agency'
                },
                {
                    id: 'in-house-box',
                    text: 'In-house',
                    description: 'In-house'
                }
            ]
        }
    ],

    optionButtonsPromotionalContent: [
        {
            id: 'coupon',
            text: 'Coupon',
            description: 'Coupon'
        },
        {
            id: 'pamphlet',
            text: 'Pamphlet',
            description: 'Pamphlet'
        },
        {
            id: 'other-content',
            text: 'Other',
            description: 'Other',
        },
        {
            id: 'none',
            text: 'None',
            description: 'None'
        }
    ],

    optionButtonsPromotionalContentDesigner: [
        {
            id: 'freestand-promotional-content',
            text: 'Freestand',
            description: 'Freestand'
        },
        {
            id: 'partner-agency-promotional-content',
            text: 'Partner agency',
            description: 'Partner agency'
        },
        {
            id: 'in-house-promotional-content',
            text: 'In-house',
            description: 'In-house'
        }
    ],

    optionButtonsPackAndDelivery: [
        {
            id: 'freestand-packaging',
            text: 'Freestand',
            description: 'Freestand'
        },
        {
            id: 'in-house-packaging',
            text: 'In-house',
            description: 'In-house'
        }
    ],

    optionButtonsCreatives: [
        {
            id: 'freestand-creatives',
            text: 'Freestand',
            description: 'Freestand'
        },
        {
            id: 'partner-agency-creatives',
            text: 'Partner agency',
            description: 'Partner agency'
        },
        {
            id: 'in-house-creatives',
            text: 'In-house',
            description: 'In-house'
        }
    ],

    optionButtonsChat: [
        {
            id: 'freestand-chat',
            text: 'Freestand',
            description: 'Freestand'
        },
        {
            id: 'partner-agency-chat',
            text: 'Partner agency',
            description: 'Partner agency'
        },
        {
            id: 'in-house-chat',
            text: 'In-house',
            description: 'In-house'
        }
    ],

    optionButtonsCampaignLaunch: [
        {
            id: 'asap',
            text: 'As soon as possible',
            description: 'As soon as possible'
        },
        {
            id: 'Not-decided',
            text: 'Not decided',
            description: 'Not decided'
        }
    ],

    optionButtonsGender: [
        {
            id: 'all',
            text: 'All',
        },
        {
            id: 'male',
            text: 'Male',
        },
        {
            id: 'female',
            text: 'Female',
        },
    ],

    reachOptions: [
        {
            id: 'email',
            text: 'Emails',
        },
        {
            id: 'facebook',
            text: 'Facebook',
        },
        {
            id: 'website',
            text: 'Website',
        },
        {
            id: 'ph-numbers',
            text: 'Phone numbers',
        },
        // {
        //     id: 'other-reach',
        //     text: 'Other',
        //     subOptions: [
        //         {
        //             id: 'other',
        //             text: 'Please mention',
        //             description: 'Mention',
        //         }
        //     ]
        // },
    ]
}
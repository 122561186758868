const defaultState = {
    campaignslist: null,
    activeCampaigns: null,
    selectedCampaign: ""
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case 'GET_CAMPAIGNS':
            return {
                ...state,
                campaignslist: action.campaignslist
            }

        case 'GET_ACTIVE_CAMPAIGNS':
            return {
                ...state,
                activeCampaigns: action.campaigns
            }

        case 'SET_SELECTED':
            return {
                ...state,
                selectedCampaign: action.selected
            }
            
        default:
            return state;
    }
}
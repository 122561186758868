export const changeLocationTypeHelper = (payload, state) => {
    switch(payload) {
        case 'everyone':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        location_types: ["recent", "home"]
                    }
                },
                LocationType: payload
            }
        case 'live':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        location_types: ["home"]
                    }
                },
                LocationType: payload
            }
        case 'traveling':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        location_types: ["travel_in"]
                    }
                },
                LocationType: payload
            }
        case 'recently-in':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        location_types: ["recent"]
                    }
                },
                LocationType: payload
            }
    }
}
import Page from '../components/page/Page';
import Tasks from '../components/tasks/Tasks.component';
import React from 'react';

const TasksPage = () => {
    return(
        <Page breadcrumbs={[{name: 'Notifications', active: true }]} title='Tasks'>
            <Tasks />
        </Page>
    )
}

export default TasksPage;
import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import componentQueries from 'react-component-queries';
import '../styles/reduction.scss';
import { MainLayout } from '../components/Layout';
import AuthPage from '../pages/AuthenticationPage';
import BrandAssets from '../pages/BrandAssets';
import UserStats from '../pages/UserStats';
import SkuPage from '../pages/SkuPage';
import DashboardPage from '../pages/DashboardPage';
import SocialPage from '../pages/SocialPage';
import SupportPage from '../pages/SupportPage';
import TicketPage from '../pages/TicketPage';
import Mapbox from '../pages/MapPage';
import CampaignsPage from '../pages/CampaignsPage';
import CreateACampaign from '../components/apps/CreateACampaign/CreateACampaign';
import CampaignSubmit from '../components/apps/CreateACampaign/CampaignSubmit';
import GraphicsApprovalSystem from '../components/apps/GraphicsApprovalSystem/index';
import ProfilePage from '../pages/ProfilePage';
import BillingsPage from '../pages/BillingsPage';
import TasksPage from '../pages/TasksPage';
import AdsPage from '../pages/AdsPage';

import PrivateRoute from './PrivateRoute';
import InventoryStatusPage from "../pages/inventory-status/inventory-status-page.component";
import Integrations from '../pages/IntegrationsPage';
import InventorySchedulerPage from '../pages/inventory-scheduler/inventory-scheduler.component';
import Settings from '../pages/SettingsPage';

export const history = createBrowserHistory();

class App extends React.Component {
  /* Move /campaigns & /new out of main layout or make the presensce of sidebar conditional */
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={AuthPage} />
          <MainLayout breakpoint={this.props.breakpoint}>
            <PrivateRoute exact path="/live" component={DashboardPage} />
          {/* <PrivateRoute exact path="/social" component={SocialMediaStats} /> */}
            <PrivateRoute exact path="/user-stats" component={UserStats} />
            <PrivateRoute exact path="/social-stats" component={SocialPage} />
            <PrivateRoute exact path="/assets" component={BrandAssets} />
            <PrivateRoute exact path='/sku' component={SkuPage} />
            <PrivateRoute exact path='/ads' component={AdsPage} />
            <PrivateRoute exact path='/map' component={Mapbox} />
            <PrivateRoute exact path="/help" component={SupportPage} />
            <PrivateRoute exact path="/help/ticket" component={TicketPage} />
            <PrivateRoute exact path="/profile" component={ProfilePage} />
            {/* <PrivateRoute exact path="/roles" component={SettingsPage} /> */}
            <PrivateRoute exact path="/billing" component={BillingsPage} />
            <PrivateRoute exact path="/tasks" component={TasksPage} />
            <PrivateRoute exact path="/campaigns" component={CampaignsPage} />
            <PrivateRoute exact path="/complete/:name" component={CreateACampaign} />
            <PrivateRoute exact path="/new/" component={CreateACampaign} />
            {/* <PrivateRoute exact path="/chat" component={ChatPage} />
            <PrivateRoute exact path="/chat/create" component={CreateChatPage} /> */}
            {/* <PrivateRoute exact path="/chatbot" component={ChatbotIntegrationPage} /> */}
            <PrivateRoute exact path="/new/submit" component={CampaignSubmit} />
            <PrivateRoute path='/graphics-approval-system' component={GraphicsApprovalSystem} />
            <PrivateRoute path='/inventory/status' component={InventoryStatusPage} />
            <PrivateRoute path="/inventory/scheduler" component={InventorySchedulerPage} />
            <PrivateRoute path='/integrations' component={Integrations} />
            <PrivateRoute path='/settings' component={Settings} />
            {/* <PrivateRoute path='/chatflow' component={ChatFlowBuilder} /> */}

          </MainLayout>
        </Switch>
      </Router>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
const INITIAL_STATE = {
    creds: {
        Manychat_token: '',
        Transit_flow_id: '',
        Feedback_flow_id: '',
        PhoneNumber_reconfirmation_flow_id: '',
        Addresses_reconfirmation_flow_id: ''
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_MANYCHAT_CREDS':
            return {
                ...state,
                creds: action.creds
            }

        default:
            return state;
    }
}
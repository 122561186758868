import React, { useState, useEffect, useRef } from 'react';

import { getRegionConfig, setRegionConfig, getActiveVendors, getActiveRegions } from '../../../redux/settings-page/settings.actions';
import { connect } from 'react-redux';

import EditableTable from '../editable-table';

import { getActiveCampaigns } from '../../../redux/campaigns/campaigns.actions';

import { Select } from 'antd';
import { Button } from 'antd';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../../utils/constants';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineWarning } from 'react-icons/ai';

const { Option } = Select;

const RegionConfig = ({ regionConfig, getRegionConfig, getActiveCampaigns, activeCampaigns, setRegionConfig, getActiveVendors, activeVendors, getActiveRegions, activeRegions }) => {
    const [campaign, setCampaign] = useState('');
    const [region, setRegion] = useState('');
    const [vendor, setVendor] = useState('');
    const notificationSystem = useRef();

    useEffect(() => {
        getActiveCampaigns();
        getActiveVendors();
        getActiveRegions();
    }, [getActiveCampaigns, getActiveVendors, getActiveRegions]);

    const handleCampaignSelection = (value) => {
        setCampaign(value);
    }
    const handleVendorSelection = (value) => {
        setVendor(value);
    }
    const handleRegionSelection = (value) => {
        setRegion(value);
    }

    const fetchConfig = () => {
        // console.log(campaign);
        getRegionConfig(campaign, vendor, region);
    }

    const handlePublish = (data) => {
        // console.log(data);
        if (campaign.length === 0 || region.length === 0 || vendor.length === 0) {
            if (!notificationSystem) {
                return;
            }
            notificationSystem.current.addNotification({
                title: <AiOutlineWarning />,
                message: 'Select all parameters before publishing',
                level: 'info',
            });
        }
        else {
            setRegionConfig(campaign, vendor, region, data, (status) => {
                if (status) {
                    if (!notificationSystem) {
                        return;
                    }
                    notificationSystem.current.addNotification({
                        title: <FiCheck />,
                        message: 'Region config is published',
                        level: 'info',
                    });
                }
                else {
                    notificationSystem.current.addNotification({
                        title: <AiOutlineWarning />,
                        message: 'Region config could not be saved',
                        level: 'info',
                    });
                }
            });
        }
    }

    return (
        <>
            <Select defaultValue="Select a campaign" onChange={handleCampaignSelection}>
                <Option value="Select a campaign" disabled>Select a campaign</Option>
                {activeCampaigns && activeCampaigns.map((campaign, idx) => (
                    <Option key={idx} value={campaign}>{campaign}</Option>
                ))}
            </Select>
            <Select defaultValue="Select vendor" onChange={handleVendorSelection}>
                <Option value="Select vendor" disabled>Select vendor</Option>
                {activeVendors && Object.keys(activeVendors).map((vendor, idx) => (
                    <Option key={idx} value={vendor}>{vendor}</Option>
                ))}
            </Select>
            <Select defaultValue="Select region" onChange={handleRegionSelection}>
                <Option value="Select region" disabled>Select region</Option>
                {activeRegions && Object.keys(activeRegions).map((vendor, idx) => (
                    <Option key={idx} value={vendor}>{vendor}</Option>
                ))}
            </Select>
            <Button onClick={fetchConfig} style={{ marginLeft: '15px', marginBottom: '20px' }}>Fetch</Button>
            { regionConfig ? <EditableTable data={regionConfig} onPublish={handlePublish} /> /* <SetConfigComponent configData={config} bothEditable={true} addable={true} onSave={handleSave} /> */ : <p style={{ marginTop: '15px' }}>Data does not exist</p>}
            {/* <Button size='large' style={{ marginTop: '15px', display: 'block' }} onClick={handleAdd}>Add new</Button> */}
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem}
                style={NOTIFICATION_SYSTEM_STYLE}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    activeCampaigns: state.campaigns.activeCampaigns,
    regionConfig: state.settings.region_config,
    activeVendors: state.settings.activeVendors,
    activeRegions: state.settings.activeRegions
});

const mapDispatchToProps = (dispatch) => ({
    getRegionConfig: (campaign, vendor, region) => dispatch(getRegionConfig(campaign, vendor, region)),
    setRegionConfig: (campaign, vendor, region, data, callback) => dispatch(setRegionConfig(campaign, vendor, region, data, callback)),
    getActiveCampaigns: () => dispatch(getActiveCampaigns()),
    getActiveVendors: () => dispatch(getActiveVendors()),
    getActiveRegions: () => dispatch(getActiveRegions())
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionConfig);
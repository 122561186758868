const INITIAL_STATE = {
    PrimaryPackaging: "",
    PromotionalContent: "",
    OtherPromotionalContent: null,
    PromotionalContentDesigner: null,
    PackAndDelivery: "",
    AdsDesign: "",
    InteractionDesign: "",
    Launch: [new Date(Date.now() + (16 * 1000 * 60 * 60 * 24))],
    PackageInhouseManageableCities: null
}

const responsibilitiesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_PRIMARY_PACKAGING':
            return {
                ...state,
                PrimaryPackaging: action.payload
            }
        case 'SET_PROMOTIONAL_CONTENT':
            return {
                ...state,
                PromotionalContent: action.payload,
                OtherPromotionalContent: null,
                PromotionalContentDesigner: null
            }
        case 'SET_PACK_AND_DELIVERY':
            if (action.payload.includes('Freestand')) {
                return {
                    ...state,
                    PackAndDelivery: action.payload,
                    PackageInhouseManageableCities: []
                }
            }
            return {
                ...state,
                PackAndDelivery: action.payload
            }
        case 'SET_ADS_DESIGN':
            return {
                ...state,
                AdsDesign: action.payload
            }
        case 'SET_INTERACTION_DESIGN':
            return {
                ...state,
                InteractionDesign: action.payload
            }
        case 'SET_LAUNCH':
            return {
                ...state,
                Launch: action.payload
            }
        case 'SET_PROMOTIONAL_CONTENT_DESIGNER':
            return {
                ...state,
                PromotionalContentDesigner: action.payload
            }
        case 'SET_OTHER_PROMOTIONAL_CONTENT':
            return {
                ...state,
                OtherPromotionalContent: action.payload
            }
        case 'SET_PACKAGE_INHOUSE_MANAGEABLE_CITIES':
            return {
                ...state,
                PackageInhouseManageableCities: action.payload
            }
        case 'GET_RESPONSIBILITIES':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_RESPONSIBILITIES':
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}

export default responsibilitiesReducer;
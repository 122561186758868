import database, { storage } from '../../firebase/firebase';

//SET_PIC
export const setPic = (profilepic) => ({
    type: 'SET_PIC',
    profilepic
})

//GET_PIC   // add fallback & use uid from auth state
export const getPic = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        storage.ref(`${clientID}/user-pics/`).listAll().then(res => 
            res.items[0].getDownloadURL().then(url => 
                    dispatch(setPic(url))
                ).catch(err => console.log(err))
        ).catch(err => console.log(err));
    }
}

//SET_DATA
export const setUserdata = (userdata) => ({
    type: 'SET_DATA',
    userdata
})

//GET_DATA  //changed db path
export const getUserdata = () => {
    return (dispatch, getState) => {
        let userdata = {};
        const clientID = getState().auth.uid;
        return database.ref(`users/${clientID}/profile`).once('value').then(snapshot => {
            if (snapshot.exists()) userdata = snapshot.val();
            dispatch(setUserdata(userdata))}
        ).catch(err => console.log(err))
    }
}

//SET_PROFILE   // check if updates are valid or not
export const setProfile = (profiledata) => {
    return (dispatch, getState) => {
        const updates = Object.keys(profiledata);
        console.log(updates);
        const validUpdates = ['name', 'phone', 'gst', 'address'];
        const isValidUpdate = updates.every(update => validUpdates.includes(update));
        if (isValidUpdate) {
            const clientID = getState().auth.uid;
            return database.ref(`users/${clientID}/profile`).update(profiledata)
            .then(() => {
                dispatch(getUserdata());
            })
            .catch(err => console.log(err)); 
        } else {
            alert('Invalid Updates!');
        }
    }
}

//SET_PROGRESS
export const setProgress = (progress) => ({
    type: 'SET_PROGRESS',
    progress
})

//SET_PROFILEPIC
export const setProfilepic = (storageLocation, filetoupload) => {
    return (dispatch) => {
        storage.ref(storageLocation).listAll().then(res => 
            res.items[0].delete().then(() => {
                const uploadTask = storage.ref(storageLocation).child(filetoupload.name).put(filetoupload);
                uploadTask.on('state_changed', () => {
                    dispatch(setProgress("Uploading..."));
                }, (err) => {
                    console.log(err);
                }, () => {
                    dispatch(setProgress("FINISHED"));
                    dispatch(getPic());
                });
            }
        ).catch(err => console.log(err))).catch(err => console.log(err))
    }
}
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import { AiOutlineEllipsis } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Modal, ModalBody } from 'reactstrap';
 
const ChartComponent = ({ recentChart, defaultType, chartname, chartdesc }) => {
    let chartRef = useRef();
    let chart = useRef();
    let optionsRef = useRef();
    const [type, setType] = useState(defaultType);

    const isReactions = chartname=='page_actions_post_reactions_total';

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const handleChange = (event) => {
        setType(event.target.id);
    }

    useEffect(() => {
        if (chart.current !== undefined) {
            chart.current.destroy();
        }

        let datasets = [];
        let options = {
            title: {
                display: false,
                text: recentChart && chartname
            },
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
        
        if(isReactions){
            Object.values(recentChart).map(dataval => {
                let datarr = [];
                let labelarr = [];
                Object.entries(dataval).map(val => {
                    labelarr.push(val[0]);
                    datarr.push(val[1]);
                })
                const bg = `rgba(${Math.floor(Math.random() * Math.floor(255))}, ${Math.floor(Math.random() * Math.floor(255))}, ${Math.floor(Math.random() * Math.floor(255))}, 0.7`;      
                datasets.push({
                    label: "",
                    data: datarr,
                    backgroundColor: bg,
                    borderWidth: 1,
                    borderColor: bg,
                    hoverBackgroundColor: bg,
                    fill: type === 'line' ? false : true
                })
            })
        } else {
            const datarr = Object.values(recentChart);
            const bg = `rgba(${Math.floor(Math.random() * Math.floor(255))}, ${Math.floor(Math.random() * Math.floor(255))}, ${Math.floor(Math.random() * Math.floor(255))}, 0.7`;
            datasets.push({
                label: "",
                data: datarr,
                backgroundColor: bg,
                borderWidth: 1,
                borderColor: bg,
                hoverBackgroundColor: bg,
                fill: type === 'line' ? false : true
            })
        }

        let chartData = {
            type: type,
            data: {
                labels: Object.keys(recentChart),
                datasets: datasets
            },
            options: options
        }
        chart.current = new Chart(chartRef.current, chartData);
    }
    );

    return (
        <div className="bar">
            <div className="chart-nav">
                <div>
                    <h5 className="heading">{chartname.replaceAll('_', ' ')}</h5>
                    <BsInfoCircleFill className="info-icon" onClick={toggle} />
                </div>
                <div className="chart-change">
                    <div className="icon-wrapper" onClick={() => {
                        optionsRef.current.classList.toggle('show')
                    }}>
                        <AiOutlineEllipsis className="icon" />
                    </div>
                </div>
                <div className="options chart-options" ref={optionsRef}>
                    <li className="option-description">Convert Chart</li>

                    <li id="bar" className="option" onClick={(e) => {
                        optionsRef.current.classList.remove('show')
                        handleChange(e)
                    }}>Bar</li>

                    <li id="horizontalBar" className="option" onClick={(e) => {
                        optionsRef.current.classList.remove('show')
                        handleChange(e)
                    }}>Horizontal Bar</li>

                    <li id="line" className="option" onClick={(e) => {
                        optionsRef.current.classList.remove('show')
                        handleChange(e)
                    }}>Line</li>
                </div>
            </div>
            <canvas ref={chartRef} className="chart" id="myBarChart"></canvas>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>{chartdesc}</ModalBody>
            </Modal>
        </div>
    )
}

export default ChartComponent;
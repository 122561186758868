import React, { useEffect, useRef } from 'react';

import { getClientConfig, setClientConfig } from '../../../redux/settings-page/settings.actions';
import { connect } from 'react-redux';

// import SetConfigComponent from '../set-config-component';
import EditableTable from '../editable-table';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../../utils/constants';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineWarning } from 'react-icons/ai';

const ClientConfig = ({ clientConfig, getClientConfig, setClientConfig }) => {
    const notificationSystem = useRef();

    useEffect(() => {
        getClientConfig();
    }, [getClientConfig]);

    const handlePublish = (data) => {
        // console.log(data);
        setClientConfig(data, (status) => {
            if (status) {
                if (!notificationSystem) {
                    return;
                }
                notificationSystem.current.addNotification({
                    title: <FiCheck />,
                    message: 'Client config is published',
                    level: 'info',
                });
            }
            else {
                notificationSystem.current.addNotification({
                    title: <AiOutlineWarning />,
                    message: 'Client config could not be saved',
                    level: 'info',
                });
            }
        });
    }

    return (
        clientConfig && (
            <>
                <EditableTable data={clientConfig} onPublish={handlePublish} />
                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem}
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </>
        )
    )
}

const mapStateToProps = (state) => ({
    clientConfig: state.settings.client,
});

const mapDispatchToProps = (dispatch) => ({
    getClientConfig: () => dispatch(getClientConfig()),
    setClientConfig: (data, callback) => dispatch(setClientConfig(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientConfig);
import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Slider from "react-slick";
import { TiImage } from 'react-icons/ti';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import ReactCardFlip from 'react-card-flip';
import { firebase } from '../../../../../firebase/firebase';
import '../../../../../../node_modules//slick-carousel/slick/slick-theme.scss';
import '../../../../../../node_modules//slick-carousel/slick/slick.scss';
import { Card, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import './carousel-style.scss';
import './script.js';
import { connect } from 'react-redux'
import { addTask, addNotif } from '../../../../../redux/tasks/tasks.actions'
// import $ from 'jquery';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Like from '../../../../../assets/img/graphics/approve.svg';
import Dislike from '../../../../../assets/img/graphics/disapprove.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { useRef } from 'react';

const iconVariants = {
  hidden: {
    scale: 0,
    opacity: 0
  },
  appear: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.8
    }
  }
}

const Carousel = ({uid, graphics, isCommentsOpen, handleSidebarToggle, handleCommentsToggle, handleVersionsToggle, isVersionsOpen, addClientNotif, addAdminNotif}) => {
  const [isFlipped, setsFlipped] = useState(false);
  const [selectedGraphic, setSelectedGraphic] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const mainSlider = useRef();
  const navSlider = useRef();

  let {graphicId, versionId} = useParams();

  useEffect(() => {
    if(graphics) {
      setSelectedGraphic(graphics[graphicId])
    }
  }, [graphics, graphicId]);

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {props.title}
      </Tooltip>
    );
  }

  const handleClick = (e) => {
    e.preventDefault();
    setsFlipped(!isFlipped);
  }

  const handleApproval = (event) => {
    const { value } = event.target;
    if(!value) {
      return;
    }
    let graphicRef = firebase.database().ref(`users/${uid}/graphics-data/${graphicId}`);
    let versionRef = graphicRef.child('versions/' + versionId);

    setIsLoading(true);

    versionRef.update({
      approvalStatus: value
    }, () => {
      setIsLoading(false);

      //Create notifications for both the panels
      const $tdate = new Date();
        const taskdata ={
            "name": value==='2'?"Graphic Rejected": value==='1'?"Graphic Approved":"Graphic In-review",
            "description":`The status of the graphic for ${selectedGraphic.name} has been updated to ${value==='2'?'DISAPPROVED':value==='1'?'APPROVED':'IN-REVIEW'}. Please check the Graphics Approval Panel for more details.`,
            "category": "Graphic Approvals",
            "campaign":  "",
            "priority": "low",
            "date": $tdate.getTime(),
            "sticky": false,
            "byline": "Freestand"
        }
        addClientNotif(taskdata);
        const notifdata ={
            "name": value==='2'?"Graphic Rejected": value==='1'?"Graphic Approved":"Graphic In-review",
            "description": `The client has updated the status of a graphic for ${selectedGraphic.name} to ${value==='2'?'DISAPPROVED':value==='1'?'APPROVED':'IN-REVIEW'}. Please check the Graphics Approval Panel for more details.`,
            "category": "Graphic Approvals",
            "priority": "medium",
            "date": $tdate.getTime(),
            "sticky": false
        }
        addAdminNotif(notifdata);
      }
    );
  }

  const settingsForMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    adaptiveHeight: false,
    infinite: false,
    draggable: false
  };

  const settingsForNav = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    adaptiveHeight: true
  }

  return (
    <div className={`sliders-container ${isCommentsOpen ? 'comments-visible' : null}`}>
      <div className="section">
        <div className="section-heading">
          <h1><TiImage className="graphic" />{
            selectedGraphic ? selectedGraphic.name : 'Loading...'
          }</h1>
          <OverlayTrigger
            placement="top"
            delay={{ show: 10, hide: 10 }}
            overlay={renderTooltip({ title: 'Display details' })}
          >
            <div className="info-button" onClick={handleClick}><IoMdInformationCircleOutline /></div>
          </OverlayTrigger>
        </div>
        <div className="section-body">
          <Slider className="main-slider" {...settingsForMain} asNavFor={navSlider.current} ref={mainSlider}>
            {
              selectedGraphic ? (
                Object.entries(selectedGraphic.versions).map((version) => {
                  return (
                    <div className="card" key={version[0]}>
                      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" style={{ backGroundColor: 'white' }}>
                        <div className="image-container">
                          <TransformWrapper>
                            <TransformComponent limitToBounds={true} limitToWrapper={true} centerContent={true}>
                              <img className="card-img" src={version[1].image} alt={version[1].name} />
                            </TransformComponent>
                          </TransformWrapper>
                          <AnimatePresence>
                            {
                              version[1].approvalStatus === '1' && (
                                <motion.div 
                                  className="approval-icon"
                                  variants={iconVariants}
                                  initial="hidden"
                                  animate="appear"
                                  exit="hidden"
                                >
                                  <img src={Like} />
                                  <span style={{color: '#32BEA6'}} className="approval-text">Approved</span>
                                </motion.div>
                              )
                            }
                          </AnimatePresence>
                          <AnimatePresence>
                            {
                              version[1].approvalStatus === '2' && (
                                <motion.div 
                                className="approval-icon"
                                variants={iconVariants}
                                initial="hidden"
                                animate="appear"
                                exit="hidden"
                                >
                                  <img src={Dislike} />
                                  <span style={{color: '#F44336'}} className="approval-text">Disapproved</span>
                                </motion.div>
                              )
                            }
                          </AnimatePresence>
                        </div>
                        <div className='details'>
                          <div>
                            <div>
                              <h4>Name: {version[1].name}</h4>
                              <h5>Resolution: {version[1].resolution}</h5>
                              <h5>Submitted By: {version[1].submittedBy}</h5>
                              <h5>Approval Status:
                                {
                                  version[1].approvalStatus == '0' ? ' In review' : null
                                }
                                {
                                  version[1].approvalStatus === '1' ? ' Approved' : null
                                }
                                {
                                  version[1].approvalStatus === '2' ? ' Disapproved' : null
                                }
                                {
                                  isLoading && <Spinner animation="border" style={{
                                    fontSize: '0.5rem',
                                    height: '20px',
                                    width: '20px',
                                    marginLeft: '10px'
                                  }} />
                                }
                              </h5>
                            </div>
                          </div>
                        </div>
                      </ReactCardFlip>
                    </div>
                  )
                })
              ) : null

            }

          </Slider>
        </div>
      </div>

      <div className="show-versions" onClick={() => {
        handleSidebarToggle(false)
        handleCommentsToggle(false)
        handleVersionsToggle(true)
      }}>
        Show versions
      </div>

      <div className={`versions ${isVersionsOpen ? 'show' : null}`}>
        <IoMdClose className="close-icon" onClick={() => {
          handleVersionsToggle(false)
        }} />
        <Slider className="nav-slider" asNavFor={mainSlider.current} {...settingsForNav} ref={navSlider}>
          {
            selectedGraphic ? (
              Object.entries(selectedGraphic.versions).map((version) => {
                return (
                  <Link to={`/graphics-approval-system/graphicDetail/${graphicId}/version/${version[0]}`} key={version[0]}>
                    <div onClick={() => {
                      handleVersionsToggle(false)
                    }}>
                      <Card className="bg-dark text-white">
                        <Card.Img src={version[1].image} alt='' />
                      </Card>
                    </div>
                  </Link>

                )
              })
            ) : null
          }
        </Slider>
      </div>
      <div className="buttons">
        {
          selectedGraphic ? (
            selectedGraphic.versions[versionId] ? (
              selectedGraphic.versions[versionId].approvalStatus == '0' ? (
                  <>
                    <Button className="button" name="approveBtn" content='Approve' labelPosition='left' icon='like' color='green' value='1' onClick={handleApproval} />
                    <Button className="button" name="approveBtn" content='Disapprove' labelPosition='left' icon='thumbs down outline' color='red' value='2' onClick={handleApproval} />
                  </>
                ) : ( 
                  <Button className="button" name="approveBtn" content='Remove approval' labelPosition='left' icon='delete' color='grey' value='0' onClick={handleApproval} />
                )
              ): null
            ) : null
        }
        <Button className={`button ${isCommentsOpen ? 'comments-visible' : null}`} name="approveBtn" content='Discussions' labelPosition='left' icon='comments outline' color='purple' value='comment' onClick={() => {
          handleSidebarToggle(false)
          handleCommentsToggle()
          handleVersionsToggle(false)
        }} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.uid,
  graphics: state.graphics.graphics
})

const mapDispatchToProps = (dispatch) => ({
  addClientNotif: ($taskdata) => dispatch(addTask($taskdata)),
  addAdminNotif: ($notifdata) => dispatch(addNotif($notifdata))
});

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
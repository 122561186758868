
const firebaseConfig = require('../firebase-config.json').result.sdkConfig;

const cfURL = `https://asia-east2-${firebaseConfig.projectId}.cloudfunctions.net`

const HOSTING_ENV = process.env.NODE_ENV === 'production' ? cfURL : `http://localhost:5001/${firebaseConfig.projectId}/asia-east2`;

const withHostingEnv = functionName => `${HOSTING_ENV}/${functionName}`;

const CLOUD_FUNCTIONS_URLS = {
    // ------------ verified and updated ones -------- 
    remoteCacFacebookCallsOnFetchTargetingSpecs: withHostingEnv('remoteCacFacebookCallsOnFetchTargetingSpecs'),
    remoteCacFacebookCallsOnFetchInterests: withHostingEnv('remoteCacFacebookCallsOnFetchInterests'),
    remoteCacFacebookCallsOnFetchLocation: withHostingEnv('remoteCacFacebookCallsOnFetchLocation'),
    remoteCacFacebookCallsOnGetAdsInsight: withHostingEnv('remoteCacFacebookCallsOnGetAdsInsight'),
    remoteManychatOnFetchManychatDetails: withHostingEnv('remoteManychatOnFetchManychatDetails'),
    dbInventoryManagementOnReadClientInventory: withHostingEnv('dbInventoryManagementOnReadClientInventory'),
    dbInventoryManagementOnReadClientInventoryStatistics: withHostingEnv('dbInventoryManagementOnReadClientInventoryStatistics'),
    dbInventoryManagementOnInventorySchedule: withHostingEnv('dbInventoryManagementOnInventorySchedule'),

    remoteCacFacebookCallsOnPageConnectionRequest: withHostingEnv('remoteCacFacebookCallsOnPageConnectionRequest'),
    remoteCacFacebookCallsOnAdsAccountConnectionRequest: withHostingEnv('remoteCacFacebookCallsOnAdsAccountConnectionRequest'),
    remoteCacFacebookCallsOnPageConnectionValidation: withHostingEnv('remoteCacFacebookCallsOnPageConnectionValidation'),
    dbEmailNotificationsOnEmailNotification : withHostingEnv(`dbEmailNotificationsOnEmailNotification`),
    dbClientSetupOnManychatIntegrations : withHostingEnv(`dbClientSetupOnManychatIntegrations`),

    // -------------- not found/used --------------------
    dbInventoryManagementOnInventorySetup: withHostingEnv('dbInventoryManagementOnInventorySetup'),
    dbClientDetailsOnReadActiveClients : withHostingEnv(`dbClientDetailsOnReadActiveClients`),
    dbClientDetailsOnReadClientProfile : withHostingEnv(`dbClientDetailsOnReadClientProfile`),
    dbClientDetailsOnReadActivePointers : withHostingEnv(`dbClientDetailsOnReadActivePointers`),
    dbOrderCreationOnReadOrders : withHostingEnv(`dbOrderCreationOnReadOrders`),
    dbUserDetailsOnReadUserProfile : withHostingEnv(`dbUserDetailsOnReadUserProfile`),
    dbCampaignStatsOnReadCampaignStat : withHostingEnv('dbCampaignStatsOnReadCampaignStat'),
    dbDailyWaybillsOnReadDailyWaybills : withHostingEnv(`dbDailyWaybillsOnReadDailyWaybills`),
    dbClientSetupOnCampaignActivation : withHostingEnv('dbClientSetupOnCampaignActivation')

};

export default CLOUD_FUNCTIONS_URLS;

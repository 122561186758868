const INITIAL_STATE = {
    CampaignName: "",
    SamplingObjectives: "",
    SamplingModules: "",
    SKUDistribution: "",
}

const campaignDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CAMPAIGN_NAME':
            return {
                ...state,
                CampaignName: action.payload
            }
        case 'SET_CAMPAIGN_OBJECTIVES':
            return {
                ...state,
                SamplingObjectives: action.payload
            }
        case 'SET_SAMPLING_MODULE':
            return {
                ...state,
                SamplingModules: action.payload
            }
        case 'SET_SKU_DISTRIBUTION':
            return {
                ...state,
                SKUDistribution: action.payload
            }
        case 'SET_LAUNCH_SUBOPTIONS':
            return {
                ...state,
                LaunchSubOptions: action.payload
            }
        case 'GET_CAMPAIGN_DETAILS':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_CAMPAIGN_DETAILS':
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}

export default campaignDetailsReducer;
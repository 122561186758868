import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { selectIsFetchingInventory, selectIsFetchingInventoryStatistics } from '../../redux/inventory/inventory.selectors';
import InventoryOverview from './inventory-overview.component';
import WithSpinner from '../with-spinner/with-spinner.component';

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsFetchingInventory && selectIsFetchingInventoryStatistics
});

const InventoryOverviewContainer = compose(
    connect(mapStateToProps),
    WithSpinner
)(InventoryOverview);

export default InventoryOverviewContainer;
import database from '../../firebase/firebase';
import { addTask } from '../tasks/tasks.actions';

export const getSku = (sku = {}) => {
    return (({
        type: 'GET_SKU',
        sku
    }));
};

export const startGetSku = (uid) => {
    return (dispatch, getState) => {
        return database.ref(`users/${uid}/cac/skus`).once('value').then(snapshot => {
            if(snapshot.exists()){
            const sku = snapshot.val();
            dispatch(getSku(sku));
            }
            else{
                dispatch(getSku({}))
            }
        }).catch(err => {
            console.log('Waiting for uid', uid);
        });
    }
}

export const addSku = (uid,payload, keys, callback) => {
    return (dispatch, getState) => {

        const key = Object.values(keys)[0];
        return database.ref(`users/${uid}/cac/skus/${key}`).update(payload,function(err) {
            if(err){
                console.log(err)
            }
            else{        
                const $tdate = new Date();
                const taskdata ={
                    "name": "SKU Added",
                    "description": `Your SKU (${key}) has been added. Please check the SKU panel for more details.`,
                    "category": "SKUs",
                    "campaign": "",
                    "priority": "low",
                    "date": $tdate.getTime(),
                    "sticky":false,
                    "byline": "Freestand"
                }
                dispatch(addTask(taskdata));
                
                dispatch(startGetSku(uid));
                // Creates a notification for SKU addition
                callback();
            }
        })
    }

}

export const deleteSku = (uid,keys) => {
    return (dispatch, getState) =>{
        const key = Object.values(keys) ;
        // console.log(key);
        return database.ref(`users/${uid}/cac/skus/${key}`).remove()
            .then(function() {
                dispatch(startGetSku(uid));
            })
            .catch(function(error) {
                console.log("Remove failed: " + error.message)
            });
    }
}
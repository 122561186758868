import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Lottie from 'react-lottie';

import { selectCampaignList, selectInhouseManagedCampaignList, selectFocussedCampaign } from '../../redux/campaigns/campaigns.selectors';
import { getCampaigns } from '../../redux/campaigns/campaigns.actions';

import Page from '../../components/page/Page';
import InventorySchedulerContainer from '../../components/inventory-scheduler/inventory-scheduler.container';

import animationData from '../../assets/lottie/4664-logistic-warehouse-json-animation.json';
import './inventory-scheduler.styles.scss';
import { Alert, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { selectInventoryStatus } from '../../redux/inventory/inventory.selectors';

const InventorySchedulerPage = ({ location, campaignList, fetchAsyncCampaigns, inhoueManagedCampaignList, inventoryList }) => {
    // console.log(location);
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    const [focussedInventory, setFocussedInventory] = useState(null);

    useEffect(() => {
        if (!campaignList) {
            fetchAsyncCampaigns();
        }
    }, [campaignList, fetchAsyncCampaigns]);

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const id = params.get('id');
        setFocussedInventory(_.find(inventoryList, { id: id }));
    }, [inventoryList]);

    return (
        <Page>
            <Container className="scheduler-container">
                <div className="details-section">
                    <h1 className="title">Inventory Scheduler</h1>
                    <h4 className="subtitle">Schedule your supply to warehouse in one go!</h4>
                    <div className="inventory-animation">
                        <Lottie options={defaultOptions} />
                    </div>
                </div>
                {/*inhoueManagedCampaignList.length !== 0 ? (
                    <div className="scheduler-form">
                        <InventorySchedulerContainer focussedInventory={focussedInventory} />
                    </div>
                ) : (
                    <Alert color="danger">No campaign with inhouse packaging management found. <Link to="/inventory/status">Click Here</Link></Alert>
                )*/}
                {!!focussedInventory && <InventorySchedulerContainer focussedInventory={focussedInventory} />}
            </Container>
        </Page>
    )
};

const mapStateToProps = createStructuredSelector({
    campaignList: selectCampaignList,
    inhoueManagedCampaignList: selectInhouseManagedCampaignList,
    inventoryList: selectInventoryStatus
});

const mapDispatchToProps = dispatch => ({
    fetchAsyncCampaigns: () => dispatch(getCampaigns())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(InventorySchedulerPage);
import { LOCATIONS, INTERESTS, TARGETING_SPECS } from './remote.types';

// A saga is listening to this action: './remote.sagas/watchLocationsLoad'
export const loadLocations = (value) => ({
    type: LOCATIONS.LOAD,
    payload: value
})

export const loadLocationSuccess = (data) => ({
    type: LOCATIONS.SUCCESS,
    payload: data
});

export const loadLocationFail = (error) => ({
    type: LOCATIONS.FAIL,
    payload: error
});

// A saga is listening to this action: './remote.sagas/watchInterestsLoad'
export const loadInterests = (value) => ({
    type: INTERESTS.LOAD,
    payload: value
})

export const loadInterestsSuccess = (data) => ({
    type: INTERESTS.SUCCESS,
    payload: data
});

export const loadInterestsFail = (error) => ({
    type: INTERESTS.FAIL,
    payload: error
});

// A saga is listening to this action: './remote.sagas/watchTargetingSpecsLoad'
export const loadTargetingSpecs = (targetingSpecs) => ({
    type: TARGETING_SPECS.LOAD,
    payload: targetingSpecs
})

export const loadTargetingSpecsSuccess = (data) => ({
    type: TARGETING_SPECS.SUCCESS,
    payload: data
});

export const loadTargetingSpecsFail = (error) => ({
    type: TARGETING_SPECS.FAIL,
    payload: error
});
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import OptionButton from '../components/OptionButton';
import IconsComponent from '../components/IconsComponent';
import Placeholder from '../../../../assets/img/cac/placeholder.svg';
import OptionButtonWithSuboptions from '../components/OptionButtonWithSuboptions';
import { DatePicker } from 'antd';

const QuestionWithOptions = ({ heading, description, icons, options, sendValueToStore, multiple, size, date, storeVariable }) => {
    const [selection, setSelection] = useState([]);
    const [graphicPic, setGraphicPic] = useState(Placeholder);
    const [graphicDescription, setgraphicDescription] = useState('Description will appear here');

    // For date
    var moment = require('moment');

    useEffect(() => {
        if (storeVariable) {
            setSelection(storeVariable)
        }
    });

    const addAString = (checked, value, newSelection) => {
        if (checked) {
            if (multiple) {
                newSelection = [...selection, value];
            }
            else {
                newSelection = [value];
            }
        }
        else {
            if (multiple) {
                let exists = false;
                let index = 0;
                selection.forEach((option, idx) => {
                    // console.log(option, idx);
                    if (typeof option === 'object' && option.option === value) {
                        exists = true;
                        index = idx;
                    }
                });

                if (exists) {
                    newSelection = selection.splice(index, index);
                }
                newSelection = selection.filter(i => i !== value);
            }
            else {
                newSelection = [];
            }
        }

        return newSelection;
    }

    const addAnObject = (value, newSelection) => {
        let exists = false;
        let index = 0;

        selection.forEach((option, idx) => {
            if (typeof option === 'object' && option.option === value.option) {
                exists = true;
                index = idx;
            }
        });

        if (exists) {
            selection[index] = value;
            newSelection = selection;
        }
        else {
            newSelection = [...selection, value];
        }

        return newSelection;
    }

    const handleChange = (event) => {
        const { value, checked } = event.target;

        let newSelection = [];

        if (typeof value === 'string') {
            newSelection = addAString(checked, value, newSelection);
        }

        if (typeof value === 'object') {
            newSelection = addAnObject(value, newSelection);
        }

        sendValueToStore(newSelection);
        setSelection(newSelection);
    }

    const handleHoverIn = (icon, description) => {
        setGraphicPic(icon);
        setgraphicDescription(description);
    }

    const handleHoverOut = () => {
        setGraphicPic(Placeholder);
        setgraphicDescription('Description will appear here');
    }

    return (
        <div className="question-container">
            <h3 className="question-heading">{heading}</h3>
            <p className="question-description">{description}</p>
            <div className='options-graphics-container'>
                <Row>
                    {
                        icons && <>
                            {
                                selection.length !== 0 ? (
                                    <Col lg={4}>
                                        <div className="selected-graphics">
                                            {
                                                options.map((button, idx) => {
                                                    let className;
                                                    if (selection.includes(button.text)) {
                                                        className = "selected";
                                                    }
                                                    else {
                                                        className = "de-selected";
                                                    }
                                                    return (
                                                        <IconsComponent icon={button.icon} alt={button.id} key={idx} className={className} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                ) : (
                                        <Col lg={4}>
                                            <div
                                                className="graphics"
                                                style={{
                                                    backgroundImage: `url(${graphicPic})`,
                                                    backgroundSize: "30%",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "top",
                                                }}
                                            >
                                                <p className="graphic-description">
                                                    {graphicDescription}
                                                </p>
                                            </div>
                                        </Col>
                                    )
                            }
                        </>
                    }
                    <Col>
                        <div className="options">
                            <Row>
                                {
                                    options.map((button, idx) => {
                                        let checked;
                                        if (selection.includes(button.text)) {
                                            checked = true;
                                        }
                                        else {
                                            checked = false;
                                        }

                                        if (button.subOptions) {
                                            return (
                                                <OptionButtonWithSuboptions
                                                    key={idx}
                                                    button={button}
                                                    handleChange={handleChange}
                                                    handleHoverIn={handleHoverIn}
                                                    handleHoverOut={handleHoverOut}
                                                    checked={checked}
                                                    storeVariable={storeVariable}
                                                // multiple
                                                />
                                            )
                                        }
                                        else {
                                            return (
                                                <OptionButton
                                                    key={idx}
                                                    button={button}
                                                    handleChange={handleChange}
                                                    handleHoverIn={handleHoverIn}
                                                    handleHoverOut={handleHoverOut}
                                                    checked={checked}
                                                    size={size}
                                                />
                                            )
                                        }
                                    })
                                }
                                {/* This is a sort of jugaad code, can't think of anything better right now. This works flawlessly (is that even a word?) */}
                                {
                                    date && (
                                        storeVariable.includes('As soon as possible') || storeVariable.includes('Not decided') ? (
                                            <DatePicker
                                                onChange={(date, dateString) => {
                                                    sendValueToStore([dateString]);
                                                    setSelection([dateString]);
                                                }}
                                                className="date-picker"
                                                disabledDate={(current) => {
                                                    if (current.diff(moment(), 'days') <= 14) {
                                                        return true;
                                                    }
                                                    else {
                                                        return false;
                                                    }
                                                }}
                                            />
                                        ) : (
                                                <DatePicker
                                                    onChange={(date, dateString) => {
                                                        sendValueToStore([dateString]);
                                                        setSelection([dateString]);
                                                    }}
                                                    value={moment(storeVariable[0])}
                                                    className="date-picker"
                                                    disabledDate={(current) => {
                                                        if (current.diff(moment(), 'days') <= 14) {
                                                            return true;
                                                        }
                                                        else {
                                                            return false;
                                                        }
                                                    }}
                                                />
                                            )
                                    )
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default QuestionWithOptions;
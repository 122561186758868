import inventoryActionTypes from './inventory.types';

export const fetchInventoryStart = () => ({
    type: inventoryActionTypes.FETCH_INVENTORY_START,
});

export const fetchInventorySuccess = inventory => ({
    type: inventoryActionTypes.FETCH_INVENTORY_SUCCESS,
    payload: inventory
});

export const fetchInventoryFailure = errorMessage => ({
    type: inventoryActionTypes.FETCH_INVENTORY_FAILURE,
    payload: errorMessage
});

export const inventoryScheduleStart = formData => ({
    type: inventoryActionTypes.INVENTORY_SCHEDULE_START,
    payload: formData
});

export const inventoryScheduleSuccess = () => ({
    type: inventoryActionTypes.INVENTORY_SCHEDULE_SUCCESS
});

export const inventoryScheduleFailure = errorMessage => ({
    type: inventoryActionTypes.INVENTORY_SCHEDULE_FAILURE,
    payload: errorMessage
});

export const fetchInventoryStatisticsStart = query => ({
    type: inventoryActionTypes.FETCH_INVENTORY_STATISTICS_START,
    payload: query
});

export const fetchInventoryStatisticsSuccess = stats => ({
    type: inventoryActionTypes.FETCH_INVENTORY_STATISTICS_SUCCESS,
    payload: stats
});

export const fetchInventoryStatisticsFailure = errorMessage => ({
    type: inventoryActionTypes.FETCH_INVENTORY_STATISTICS_FAILURE,
    payload: errorMessage
});
import React from 'react';
import Page from '../components/page/Page';
import { Tabs } from 'antd';

import AnalyticsConfig from '../components/settings-components/tab-components/analytics-config';
import ClientConfig from '../components/settings-components/tab-components/client-config';
import VendorIds from '../components/settings-components/tab-components/vendor-ids';
import ManychatConfig from '../components/settings-components/tab-components/manychat-config';
import RegionConfig from '../components/settings-components/tab-components/region-config';
import SKUConfig from '../components/settings-components/tab-components/sku-config';
import SocialMediaConfig from '../components/settings-components/tab-components/social-media-config';


const { TabPane } = Tabs;

const Settings = () => {

    return (
        <Page
            className="settings"
            title="Settings"
            breadcrumbs={[
                { name: 'Settings', active: true },
            ]}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Analytics config" key="1">
                    <AnalyticsConfig />
                </TabPane>
                <TabPane tab="Client config" key="2">
                    <ClientConfig />
                </TabPane>
                <TabPane tab="Vendor ids" key="3">
                    <VendorIds />
                </TabPane>
                <TabPane tab="Manychat config" key="4">
                    <ManychatConfig />
                </TabPane>
                <TabPane tab="Region config" key="5">
                    <RegionConfig />
                </TabPane>
                <TabPane tab="SKU config" key="6">
                    <SKUConfig />
                </TabPane>
                <TabPane tab="Social media config" key="7">
                    <SocialMediaConfig />
                </TabPane>
            </Tabs>

        </Page>
    )
}

export default Settings;
const INITIAL_STATE = {
    ProductCategory: "",
    SelectedSKU: "",
}

const productDetailsReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_PRODUCT_CATEGORY':
            return {
                ...state,
                ProductCategory: action.payload
            }
        case 'SET_SELECTED_SKU':
            return {
                ...state,
                SelectedSKU: action.payload
            }
        case 'GET_PRODUCT_DETAILS':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PRODUCT_DETAILS':
            return {
                ...INITIAL_STATE
            }
        default: 
            return state;
    }
}

export default productDetailsReducer;
export const setCampaignName = (name) => {
    return {
        type: 'SET_CAMPAIGN_NAME',
        payload: name
    }
}

export const setCampaignObjectives = (objectives) => {
    return {
        type: 'SET_CAMPAIGN_OBJECTIVES',
        payload: objectives
    }
}

export const setSamplingModule = (module) => {
    return {
        type: 'SET_SAMPLING_MODULE',
        payload: module
    }
}

export const setSKUDistribution = (skuDistribution) => {
    return {
        type: 'SET_SKU_DISTRIBUTION',
        payload: skuDistribution
    }
}

export const setLaunchSubOptions = (launchSubOptions) => {
    return {
        type: 'SET_LAUNCH_SUBOPTIONS',
        payload: launchSubOptions
    }
}

export const getCampaignDetails = (data) => {
    return {
        type: 'GET_CAMPAIGN_DETAILS',
        payload: data
    }
}

export const resetCampaignDetails = () => {
    return {
        type: 'RESET_CAMPAIGN_DETAILS',
        payload: null
    }
}
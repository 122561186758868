export const addInterestHelper = (id, name, type, state) => {
    const newObject = {
        id: id,
        name: name
    }

    return {
        ...state,
        targeting_specs: {
            ...state.targeting_specs,
            [type]: [
                ...(state.targeting_specs[type] || []),
                newObject
            ]
        }
    }
}

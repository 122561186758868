import database, { storage } from '../../firebase/firebase';


//GET_AGENTDATA
export const setBillingdata = (billingdata) => ({
    type: 'SET_BILLINGDATA',
    billingdata
})

export const getBillingdata = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`users/${clientID}/campaigns/`).once('value').then(snapshot => {
            if (snapshot.exists()) 
            {
                const alldata = snapshot.val();
                const billingdata = {};
                Object.keys(alldata).forEach(camp => {
                    if(alldata[camp]["billing"]) billingdata[camp] = alldata[camp]["billing"];
                });
                dispatch(setBillingdata(billingdata));
            }
            else dispatch(setBillingdata(null));
        }).catch(err => console.log(err));
    }
}

//GET_FILE
export const getFile = (filename, campID) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        storage.ref(`${clientID}/${campID}/billing-docs/`).child(filename).getDownloadURL().then(
            url => window.open(url)
        ).catch(err => console.log(err));
    }
}

import React from 'react'

const SlideWrapper = (props) => {
    return (
        <div className="question-container">
            <h3 className="question-heading">{props.heading} 
                {
                    props.required && <span className="red-star">*</span>
                }
            </h3>
            <p className="question-description">{props.description}</p>
            {
                props.children
            }
        </div>
    )
}

export default SlideWrapper;
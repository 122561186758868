export const setPrimaryPackaging = (primaryPackaging) => {
    return {
        type: 'SET_PRIMARY_PACKAGING',
        payload: primaryPackaging
    }
}

export const setPromotionalContent = (promotionalContent) => {
    return {
        type: 'SET_PROMOTIONAL_CONTENT',
        payload: promotionalContent
    }
}

export const setPackAndDelivery = (packAndDelivery) => {
    return {
        type: 'SET_PACK_AND_DELIVERY',
        payload: packAndDelivery
    }
}

export const setAdsDesign = (adsDesign) => {
    return {
        type: 'SET_ADS_DESIGN',
        payload: adsDesign
    }
}

export const setInteractionDesign = (interactionDesign) => {
    return {
        type: 'SET_INTERACTION_DESIGN',
        payload: interactionDesign
    }
}

export const setLaunch = (launch) => {
    return {
        type: 'SET_LAUNCH',
        payload: launch
    }
}

export const setPromotionalContentDesigner = (promotionalContentDesigner) => {
    return {
        type: 'SET_PROMOTIONAL_CONTENT_DESIGNER',
        payload: promotionalContentDesigner
    }
}

export const setOtherPromotionalContent = (otherPromotionalContent) => {
    return {
        type: 'SET_OTHER_PROMOTIONAL_CONTENT',
        payload: otherPromotionalContent
    }
}

export const setPackageInhouseManageableCities = (packageInhouseManageableCities) => {
    return {
        type: 'SET_PACKAGE_INHOUSE_MANAGEABLE_CITIES',
        payload: packageInhouseManageableCities
    }
}

export const getResponsibilities = (data) => {
    return {
        type: 'GET_RESPONSIBILITIES',
        payload: data
    }
}

export const resetResponsibilities = () => {
    return {
        type: 'RESET_RESPONSIBILITIES',
        payload: null
    }
}
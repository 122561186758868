import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    Input,
    Label,
    FormGroup,
    Col,
    Row,
    Button
  } from 'reactstrap';
import { connect } from 'react-redux';
import { getUserdata, setProfile, setProfilepic } from '../../redux/profile/profile.actions';
import PageSpinner from '../page/PageSpinner';
import placementSuccess from '../../assets/img/placement-success.gif';
import placementLoader from '../../assets/img/placement-loader.gif';

const Profile = ({ userID, getuserdetails, userdata, picurl, setNewprofile, addProfilepic, progress }) => {

    const [changedrecs, setChangerecs] = useState([]);
    const [actionOngoing, setAction] = useState(true);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const isLoading = userdata===undefined;
    const [inProgress, setInProgress] = useState((progress!=="FINISHED" && progress!==""));
    const isFinished = progress==="FINISHED";
    
    const changethis = (el) => {
        let addval = changedrecs;
        if(!addval.includes(el)) {
            addval.push(el);
            setChangerecs(addval);
        }
    }

    const displaylabels = {
        "line1": "Line 1",
        "line2": "Line 2",
        "city": "City",
        "country": "Country"
    }

    useEffect(() => {
        if(isLoading) getuserdetails();
    });

    const handleSubmit = () => {
        if(changedrecs.length===0) window.alert("All values are unchanged!");
        else {
            setInProgress(true);
            const newprofile = {};
            changedrecs.forEach(el => {
                if(el!=="address") {
                    const $inputField = document.querySelector(".input_"+el);
                    newprofile[$inputField.id] = $inputField.value;
                }
                else{
                    const $inputFields = document.querySelectorAll(".input_"+el);
                    const newaddress = {};
                    $inputFields.forEach(field => {
                        newaddress[field.id] = field.value;
                    })
                    newprofile["address"] = newaddress;
                }
            })
            setNewprofile(newprofile).then(() => setInProgress(false));
            getuserdetails();
            setChangerecs([]);
        }
    }

    const handleUpload = () => {
        const $uploadfile = document.getElementById("input_upload").files[0];
        if($uploadfile!==undefined) addProfilepic(`${userID}/user-pics/`, $uploadfile);
        else window.alert("Please select a file to upload");
    }

    const handleReload = () => {
        setAction(false);
        toggle();
        getuserdetails();
    }
 
    return (<div>
        <Card>
            <CardBody>
                {isLoading && <PageSpinner />}
                {!isLoading && (
                    <div style={{padding: "10px 50px", backgroundColor: "#f7f7f7"}}>
                        <Row>
                            <Col style={{width: "30%", textAlign: "center"}}>
                                <img src={picurl} width="200" height="200" className="rounded-circle"/><br />
                                <Button style={{marginTop: "6px"}} onClick={toggle}>Change</Button>
                            </Col>
                            <Col style={{maxWidth: "30%"}}>
                                <FormGroup>
                                    <Label>Name<span style={{color: "red"}}>*</span></Label>
                                    <Input type="text" id="name" className="input_name"
                                        defaultValue={userdata["name"]} 
                                        onChange={() => changethis("name")} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>E-Mail<span style={{color: "red"}}>*</span></Label>
                                    <Input readOnly type="email" id="email" className="input_email" value={userdata["email"]} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Phone No.<span style={{color: "red"}}>*</span></Label>
                                    <Input type="text" id="phone" className="input_phone"
                                        defaultValue={userdata["phone"]} 
                                        onChange={() => changethis("phone")} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button className="float-right" color="primary" onClick={handleSubmit}>Save Changes</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>GST No.<span style={{color: "red"}}>*</span></Label>
                                    <Input type="text" id="gst" className="input_gst"
                                        defaultValue={userdata["gst"]} 
                                        onChange={() => changethis("gst")} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Company Name<span style={{color: "red"}}>*</span></Label>
                                    <Input type="text" id="company" className="input_company" value={userdata["company"]} readOnly />
                                </FormGroup>
                                <Card>
                                    <CardHeader>Registered Address<span style={{color: "red"}}>*</span></CardHeader>
                                    <CardBody className="bg-light">
                                        {Object.keys(displaylabels).map(key => 
                                            <FormGroup key={key}>
                                                <Label>{displaylabels[key]}</Label>
                                                <Input 
                                                    className="input_address"
                                                    id={key}
                                                    type="text" 
                                                    defaultValue={userdata["address"]?userdata["address"][key]:""} 
                                                    onChange={() => changethis("address")} />
                                            </FormGroup>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </CardBody>
        </Card>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalBody>
                    <Input type="file" accept="image/*,.pdf" id="input_upload"/><br />
                    <Button onClick={handleUpload}>Save</Button>{' '}
                    <Button color="info" onClick={toggle}>Cancel</Button>
                </ModalBody>
            </Modal>
        {inProgress && (
            <Modal isOpen={true} backdrop="static" centered={true}>
                <center>
                    <img src={placementLoader} alt="placement loader gif" width="180" height="180" />
                    <h4>Please wait...</h4>
                </center>
            </Modal>
        )}
        {isFinished && (
            <Modal isOpen={actionOngoing} backdrop="static" centered={true}>
                <center>
                    <img src={placementSuccess} alt="placement success gif" width="180" height="180" />
                    <h4>Success!</h4>
                </center>
                <Button onClick={handleReload}>Go back</Button>
            </Modal>
        )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userID: state.auth.uid,
    userdata: state.profile.userdata,
    picurl: state.profile.userpic,
    progress: state.queries.progress
});

const mapDispatchToProps = (dispatch) => ({
    getuserdetails: () => dispatch(getUserdata()),
    setNewprofile: (profdata) => dispatch(setProfile(profdata)),
    addProfilepic: (location, newpic) => dispatch(setProfilepic(location, newpic))
  });

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

// fixes
/* 
  user id not to be passed to each action, instead pick uid from auth state
*/
import React, { useEffect } from 'react';
import Slider from '@material-ui/core/Slider';

export default function RangeSelector({heading, description, sendValueToStore, storeVariable}) {
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        if(storeVariable) {
            setValue(storeVariable / 1000);
        }
    }, [storeVariable]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sendValueToStore(newValue * 1000);
    };

    return (
        <div className="question-container">
            <h3 className="question-heading">{heading}</h3>
            <p className="question-description">{description}</p>

            <Slider
                value={value}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={10}
                marks
                min={10}
                max={100}
                onChange={handleChange}
                name="AudienceSize"
            />

            <div style={{
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <label style={{
                    marginBottom: '0'
                }} htmlFor="size">Size: </label>
                <input className="question-input" style={{
                    marginLeft: '12px',
                    border: 'none',
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                    width: '150px'
                }} value={value ? value + ',000 people' : ''} id="size" type="text" disabled/>
            </div>
        </div>
    );
}
import React, {useEffect} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import BasicDetails from './basic-details-component';
import ChatbotDetails from './chatbot-details';
import { connect } from 'react-redux';

const UserModal = ({isModalOpen, toggle, manychat_data}) => {

    const isLoading = manychat_data=={};
    const handleOpenChat = () => window.open(manychat_data.live_chat_url)

    return (
        <Modal isOpen={isModalOpen} toggle={toggle} centered size='lg' className="claim-modal">
            <ModalHeader toggle={toggle}>User details</ModalHeader>
            <ModalBody className="claims-modal-body">
                {isLoading? <Spinner /> : (
                    <>
                    <div className="img-button">
                        <img className="user-img" src={manychat_data['profile_pic']} />
                        <button className="start-chat" onClick={handleOpenChat}>
                            Start Chat
                        </button>
                    </div>
                    <div className="user-details">
                        <BasicDetails />
                        <div className="divider" />
                        <ChatbotDetails data={manychat_data.custom_fields} />
                    </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter className="claims-modal-footer"></ModalFooter>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    manychat_data: state.stats.activeUserDetails
})

export default connect(mapStateToProps, null)(UserModal);
export const setIsLastOuterSlider = (state) => {
    return {
        type: 'SET_IS_LAST_OUTER_SLIDER',
        payload: state
    }
}

export const setIsMarketingSlide = (state) => {
    return {
        type: 'SET_IS_MARKETING_SLIDE',
        payload: state
    }
}

export const setIncompleteData = (incompleteData) => {
    return {
        type: 'SET_INCOMPLETE_DATA',
        payload: incompleteData
    }
}

export const setSubmissionLoading = (state) => {
    return {
        type: 'SET_SUBMISSION_LOADING',
        payload: state
    }
}

export const resetGlobalCacStates = () => {
    return {
        type: 'RESET_GLOBAL_CAC_STATES',
        payload: null
    }
}
import React, { useState, useEffect, useMemo } from "react";
import InputQuestionComponent from "../PredefinedSlides/InputQuestionComponent";
import { predefinedData } from "../predefinedData";
import InnerSlider from "../PredefinedSlides/InnerSlider";
import { connect } from 'react-redux';
import { setCampaignName } from '../../../../redux/cac/campaignDetails/campaignDetails.actions';
import { setCampaignObjectives } from '../../../../redux/cac/campaignDetails/campaignDetails.actions';
import { setSamplingModule } from '../../../../redux/cac/campaignDetails/campaignDetails.actions';
import { setSKUDistribution } from '../../../../redux/cac/campaignDetails/campaignDetails.actions';
import { setLaunchSubOptions } from '../../../../redux/cac/campaignDetails/campaignDetails.actions';
import { resetMarketingData } from '../../../../redux/cac/marketing/marketing.actions';
import { getCampaigns } from '../../../../redux/campaigns/campaigns.actions';
import QuestionWithOptions from "../PredefinedSlides/QuestionWithOptions";
import { useParams } from 'react-router-dom';

const CampaignDetails = (props) => {
    const { uid,
        campaignList,
        setCampaignName,
        getCampaigns,
        setCampaignObjectives,
        setSamplingModule,
        setSKUDistribution,
        resetMarketingData
    } = props;

    const {
        CampaignName,
        SamplingObjectives,
        SamplingModules,
        SKUDistribution
    } = props.campaignDetails;

    const params = useParams();

    useEffect(() => {
        getCampaigns(uid);
    }, [getCampaigns]);

    const validateForSameName = (value) => {
        let error = false;
        campaignList && Object.keys(props.campaignList).forEach((name) => {
            if (name === value) {
                error = true;
            }
        })
        return error;
    }

    return (
        <InnerSlider>
            {/* Campaign name */}
            <InputQuestionComponent
                heading="Name your Campaign"
                description="Provide a unique name for your campaign with us."
                placeholder="Enter your campaign name here"
                center
                validateFunction={!params.name ? validateForSameName : null}
                sendValueToStore={setCampaignName}
                required
                errorText='Campaign with the same name already exists'
                storeVariable={CampaignName}
            />

            {/* Campaign objectives */}
            <QuestionWithOptions
                heading="Objectives of sampling"
                description="What are your main objectives of sampling?"
                options={predefinedData.optionButtonsObjectives}
                sendValueToStore={setCampaignObjectives}
                multiple
                icons
                storeVariable={SamplingObjectives}
            />

            {/* Sampling module */}
            <QuestionWithOptions
                heading="Select your preferred Sampling Module"
                description="Select the sampling module that you would like to sign-up for"
                options={predefinedData.optionButtonsModules}
                sendValueToStore={setSamplingModule}
                storeVariable={SamplingModules}
                icons
            />

            {/* SKU distribution */}
            {
                SamplingObjectives.length !== 0 && SamplingObjectives.includes('Flavour Preference Analysis') ? (
                    <QuestionWithOptions
                        heading="SKU distribution"
                        description="How would you like to distribute SKUs to your customers?"
                        options={predefinedData.optionButtonsDistributionIfFlavourPreference}
                        sendValueToStore={setSKUDistribution}
                        storeVariable={SKUDistribution}
                        icons
                    />
                ) : (
                        <QuestionWithOptions
                            heading="SKU distribution"
                            description="How would you like to distribute SKUs to your customers?"
                            options={predefinedData.optionButtonsDistributionOther}
                            sendValueToStore={setSKUDistribution}
                            storeVariable={SKUDistribution}
                            icons
                        />
                    )
            }
        </InnerSlider>
    );
};

const mapStateToProps = (state) => ({
    campaignDetails: state.cac.campaignDetails,
    uid: state.auth.uid,
    campaignList: state.campaigns.campaignslist
});

const mapDispatchToProps = (dispatch) => ({
    setCampaignName: (name) => dispatch(setCampaignName(name)),
    getCampaigns: (id) => dispatch(getCampaigns(id)),
    setCampaignObjectives: (objectives) => dispatch(setCampaignObjectives(objectives)),
    setSamplingModule: (module) => dispatch(setSamplingModule(module)),
    setSKUDistribution: (skuDistribution) => dispatch(setSKUDistribution(skuDistribution)),
    setLaunchSubOptions: (launchSubOptions) => dispatch(setLaunchSubOptions(launchSubOptions)),
    resetMarketingData: () => dispatch(resetMarketingData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Collapse,
    NavItem,
    NavLink as BSNavLink,
} from 'reactstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';

import bn from '../../../utils/bemnames';
import './sidebar-collapse-item.styles.scss';

const bem = bn.create('sidebar');

const SidebarCollapseItem = ({ item: { SidebarIcon, title, items, open } }) => {
    const [collapseState, setCollapseState] = useState(open);

    const handleClick = () => {
        setCollapseState(!collapseState);
    };

    useEffect(() => {
        setCollapseState(open)
    }, [open]);

    return (
        <div className={`sidebar-dropdown ${collapseState && 'open'}`}>
            <NavItem
                className={bem.e('nav-item')}
                onClick={handleClick}
            >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                        <SidebarIcon className={bem.e('nav-item-icon')} />
                        <span className="nav-item-name">{title}</span>
                    </div>
                    <MdKeyboardArrowDown
                        className={bem.e('nav-item-icon-dropdown')}
                        style={{
                            padding: 0,
                            transform: collapseState
                                ? 'rotate(0deg)'
                                : 'rotate(-90deg)',
                            transitionDuration: '0.3s',
                            transitionProperty: 'transform',
                        }}
                    />
                </BSNavLink>
            </NavItem>
            <Collapse isOpen={collapseState}>
                {items.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                            id={`navItem-${name}-${index}`}
                            className="collapsible-item-container"
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                        >
                            <Icon className={bem.e('nav-item-icon')} />
                            <span className="nav-item-name">{name}</span>
                        </BSNavLink>
                    </NavItem>
                ))}
            </Collapse>
        </div>
    );
};

export default SidebarCollapseItem;
import CLOUD_FUNCTIONS_URLS from '../../../../firebase/cf';

export const fetchTargetingSpecs = (targetingSpecs, token) => {
    return new Promise((resolve, reject) => {
        fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnFetchTargetingSpecs}?targeting_specs=${JSON.stringify(targetingSpecs)}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                return res.json();
            }).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
    })
}
import Page from '../components/page/Page';
import ShowCampaigns from '../components/campaigns/Campaigns.component';
import CampaignDetails from '../components/campaign-details/CampaignDetails.component';
import React from 'react';
import { connect } from 'react-redux';

const CampaignsPage = ({ selectedcamp }) => {
    let breadcrumbs = [
        { name: 'Campaigns', active: false }
    ];

    if (selectedcamp === "") {
        breadcrumbs.push({ name: 'All campaigns', active: true });
    }
    else {
        breadcrumbs.push({ name: 'All campaigns', active: false });
        breadcrumbs.push({ name: selectedcamp, active: true });
    }
    return (
        <Page
            title={selectedcamp === "" ? 'All Campaigns' : selectedcamp.toUpperCase()}
            breadcrumbs={breadcrumbs}
        >
            {selectedcamp === "" && <ShowCampaigns />}
            {selectedcamp !== "" && <CampaignDetails />}
        </Page>
    )
}

const mapStateToProps = (state) => ({
    selectedcamp: state.campaigns.selectedCampaign
});

export default connect(mapStateToProps, null)(CampaignsPage);
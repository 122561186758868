const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {

        case 'SET_PROGRESS_CHART':
            return {
                ...state,
                chartData: action.data
            }
        default:
            return state;
    }
}
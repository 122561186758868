import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { CardColumns, Card } from 'react-bootstrap'
import './all-graphics-styles.scss'
import { IoIosImages } from 'react-icons/io'
import $ from 'jquery'
import { connect } from 'react-redux';

import GraphicsNotFoundComponent from './graphics-not-found-component';
import Page from '../../../../page/Page';

const AllGraphics = ({ graphics }) => {
    const [notFound, setNotFound] = useState(false);
    const location = useRouteMatch();

    useEffect(() => {
        setTimeout(() => {
            setNotFound(true);
        }, 5000);
    });

    const handleScroll = () => {
        var scrollTop = $('#all-graphics-scroll').scrollTop();

        if (scrollTop > 0) {
            $("#all-graphics-heading").addClass("shadow-giving-class");
        }
        else {
            $("#all-graphics-heading").removeClass("shadow-giving-class");
        }
    }

    return (
        <Page breadcrumbs={[{ name: `Graphic Approvals`, active: true }]}>

            <div className="all-graphics-grid">
                <div className="section">
                    <div id="all-graphics-heading" className="section-heading">
                        <h1><IoIosImages className="graphic" />Choose graphic</h1>
                    </div>

                    <div className="section-body" id="all-graphics-scroll" onScroll={handleScroll} style={
                        graphics ? (null) : ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        })
                    }>
                        {
                            graphics ? (
                                <CardColumns>
                                    {
                                        Object.entries(graphics).map((graphic) => {
                                            let versionArray = graphic[1].versions ? Object.entries(graphic[1].versions) : [];

                                            let versionKey = versionArray[0] ? versionArray[0][0] : null;
                                            let versionImage = versionArray[0] ? versionArray[0][1].image : null;

                                            if (versionKey) {
                                                return (
                                                    <Card key={graphic[0]}>
                                                        <Link to={`${location.path}/graphicDetail/${graphic[0]}/version/${versionKey}`}>
                                                            <Card.Header>{graphic[1].name}</Card.Header>
                                                            <Card.Img variant="top" src={versionImage} />
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    Campaign: {graphic[1].campaign}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                )
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                    }
                                </CardColumns>
                            ) : (
                                    notFound ? (
                                        <GraphicsNotFoundComponent />
                                    ) : (
                                            <div className="animation-container">
                                                <lottie-player
                                                    src="https://assets9.lottiefiles.com/datafiles/dCoEZJcl8sFV0r4/data.json" background="transparent" speed="0.7" style={{ width: '300px', height: '300px' }} loop autoplay >
                                                </lottie-player>
                                            </div>
                                        )
                                )
                        }
                    </div>

                </div>

            </div>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    uid: state.auth.uid,
    graphics: state.graphics.graphics
});

export default connect(mapStateToProps)(AllGraphics);
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
// import { Progress } from 'reactstrap'
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

const InnerSlider = (props) => {
    const [first, setFirst] = useState(true);
    const [last, setLast] = useState(false);
    const slider = useRef();
    const nextButtonRef = useRef();

    const next = () => {
        // if (this.validate()) {
        slider.current.slickNext();
        // }
    }

    const previous = () => {
        slider.current.slickPrev();
    }

    const nexSlide = () => {
        // console.log(this.nextButtonRef.current);
        document.getElementById('next-slide').click();
    }

    const handleSubmit = () => {
        // console.log(document.getElementById("button-sbm").disabled);
        document.getElementById('submit-btn').click();
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: false,
        swipe: false,
        // customPaging: i => (
        //     <div>
        //         <h1>{i}: {this.slider}</h1>
        //     </div>
        // ),
        // afterChange: i => (
        //     console.log(i)
        // ),
        beforeChange: (oI, nI) => {
            if (nI > 0) {
                setFirst(false);
                // this.setState({
                //     first: false,
                //     slideNumber: nI
                // })
            } else {
                setFirst(true);
                // this.setState({
                //     first: true,
                //     slideNumber: nI
                // })
            }

            if (nI === props.children.length - 1) {
                setLast(true);
                // this.setState({
                //     last: true,
                //     slideNumber: nI
                // })
                nextButtonRef.current.classList.remove('hidden');
            } else {
                setLast(false);
                // this.setState({
                //     last: false,
                //     slideNumber: nI
                // })
                nextButtonRef.current.classList.add('hidden');
            }
        }
    };

    return (
        <div className="inner-slider">
            {/* <Progress value={(this.state.slideNumber / (this.props.children.length - 1)) * 100}/> */}
            <Slider ref={slider} {...settings}>
                {
                    // <div className="slider-view" style={{
                    //     overflowY: 'auto'
                    // }}>
                    //     {this.props.children}
                    // </div>
                    React.Children.map(props.children, (child, idx) => {
                        return (
                            <div className="slider-view" key={idx} style={{
                                overflowY: 'auto'
                            }}>
                                {child}
                            </div>
                        )
                    })
                }
            </Slider>
            <div style={{ textAlign: "center" }} className="nav-buttons">
                {
                    !first && (
                        <button id="inner-slider-prev-btn" className="button prev" onClick={previous}>
                            <IoIosArrowRoundBack />Back
                        </button>
                    )
                }

                {
                    !last && React.Children.count(props.children) > 1 && (
                        <button id="inner-slider-next-btn" className="button next" onClick={next}>
                            Next <IoIosArrowRoundForward />
                        </button>
                    )
                }
            </div>

            {
                props.isLastOuterSlider ? (
                    <button ref={nextButtonRef} id="button-sbm" className='hidden' onClick={handleSubmit}>
                        {
                            props.isSubmissionLoading ? <Spinner size="sm" color="#00163c" /> : <span>Submit</span>
                        }
                    </button>
                ) :
                    <button ref={nextButtonRef} id="button-next" className="hidden" onClick={nexSlide}>
                        Let's go!
                    </button>
            }

            {/* <Progress color="success" value={this.state.value} onClick={() => this.setState((prevState) => ({value: prevState.value + 10}))} style={{
                    height: '10px',
                    width: '100px',
                    // transform: 'rotate(-90deg)',
                    position: 'absolute',
                    // margin: '0 auto',
                    top: '0',
                    
                    // zIndex: '100'
                }} /> */}

        </div >
    )
}

const mapStateToProps = (state) => ({
    isLastOuterSlider: state.cac.globalCacStates.isLastOuterSlider,
    isSubmissionLoading: state.cac.globalCacStates.isSubmissionLoading
});

export default connect(mapStateToProps)(InnerSlider);
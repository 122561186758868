import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import Axios from 'axios';

import inventoryActionTypes from './inventory.types';
import { 
        fetchInventorySuccess, 
        fetchInventoryFailure, 
        inventoryScheduleSuccess, 
        inventoryScheduleFailure, 
        fetchInventoryStatisticsSuccess, 
        fetchInventoryStatisticsFailure 
    } from './inventory.actions';
import { selectAuthUid } from '../auth/auth.selectors';
import { selectFirebaseToken } from '../firebase/firebase.selectors';
import CLOUD_FUNCTIONS_URLS from '../../firebase/cf';

export function* fetchInventoryAsync() {
    try {
        const token = yield select(selectFirebaseToken);
        const client_id = yield select(selectAuthUid);
        const { data } = yield Axios({
            method: 'GET',
            url: CLOUD_FUNCTIONS_URLS.dbInventoryManagementOnReadClientInventory,
            params: {
                client_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        yield put(fetchInventorySuccess(data));
    } catch (err) {
        yield put(fetchInventoryFailure(err.message));
    }
}

export function* fetchInventoryStatisticsAsync() {
    try {
        const client_id = yield select(selectAuthUid);
        const token = yield select(selectFirebaseToken);

        const { data } = yield Axios({
            method: 'GET',
            url: CLOUD_FUNCTIONS_URLS.dbInventoryManagementOnReadClientInventoryStatistics,
            params: {
                client_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        yield put(fetchInventoryStatisticsSuccess(data));
    } catch (err) {
        yield put(fetchInventoryStatisticsFailure(err.message));
    }
}

export function* inventoryScheduleStartAsync({ payload }) {
    try {
        const client_id = yield select(selectAuthUid);
        const token = yield select(selectFirebaseToken);

        const { data } = yield Axios({
            method: 'POST',
            url: CLOUD_FUNCTIONS_URLS.dbInventoryManagementOnInventorySchedule,
            data: {
                client_id,
                formData: payload
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });        
        yield put(inventoryScheduleSuccess(data));
    } catch (err) {
        yield put(inventoryScheduleFailure(err.message));
    }
}

export function* fetchInventoryStart() {
    yield takeLatest(
        inventoryActionTypes.FETCH_INVENTORY_START,
        fetchInventoryAsync
    );
}

export function* fetchInventoryStatisticsStart() {
    yield takeLatest(
        inventoryActionTypes.FETCH_INVENTORY_STATISTICS_START,
        fetchInventoryStatisticsAsync
    );
}

export function* inventoryScheduleStart() {
    yield takeLatest(
        inventoryActionTypes.INVENTORY_SCHEDULE_START,
        inventoryScheduleStartAsync
    );
}

export function* inventorySagas() {
    yield all([
        call(fetchInventoryStart),
        call(fetchInventoryStatisticsStart),
        call(inventoryScheduleStart)
    ])
}
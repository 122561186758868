import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../../assets/img/cac/box.svg';
import Campaign from '../../../../assets/img/cac/campaign.svg';
import Marketing from '../../../../assets/img/cac/marketing.svg';
import Product from '../../../../assets/img/cac/product.svg';
import Slider from 'react-slick';
import { Progress } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setIsLastOuterSlider } from '../../../../redux/cac/globalCacStates/globalCacStates.actions';
import { setIsMarketingSlide } from '../../../../redux/cac/globalCacStates/globalCacStates.actions';
import { pushCacDataToFirbase } from '../../../../redux/cac/cac.actions';
import { setIncompleteData } from '../../../../redux/cac/globalCacStates/globalCacStates.actions';
import { setSubmissionLoading } from '../../../../redux/cac/globalCacStates/globalCacStates.actions';
import { connect } from 'react-redux';
import SubmitErrorModal from '../components/SubmitErrorModal';
import { useParams } from 'react-router-dom';
import emailNotificationUtil from '../../../../utils/emailNotification';

const OuterSlider = ({
    children,
    setIsLastOuterSlider,
    setIsMarketingSlide,
    campaignDetails,
    productDetails,
    marketing,
    responsibilities,
    samplingModule,
    pushCacDataToFirbase,
    setIncompleteData,
    incompleteData_Prop,
    campaignList,
    setSubmissionLoading,
    name
}) => {
    const slider = useRef();
    const [slides, setSlides] = useState([
        campaignDetails,
        productDetails,
        marketing,
        responsibilities
    ]);
    const [incompleteCampaignError, setIncompleteCampaignError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [sameNameError, setSameNameError] = useState(false);
    const history = useHistory();
    const params = useParams();

    const next = () => {
        // console.log(this.props['Slide'+this.state.slideNumber]);
        slider.current.slickNext();
    }

    const validate = (object) => {
        // console.log(object);
        let isValid = true;
        let incompleteData = incompleteData_Prop;
        Object.entries(object).forEach(value => {
            // console.log(value, value[1], value[1] === "");
            // console.log(incompleteData.includes(value[0]));

            if (value[1] === "") {
                // console.log("Come here bitch!");
                if (!incompleteData.includes(value[0])) {
                    // incompleteData.filter(i => i !== value[0]);
                    incompleteData.push(value[0]);
                }
                isValid = false;
            }
            else {
                // console.log('fuck!');
                incompleteData = incompleteData.filter(i => {
                    // console.log(i, value[0], i !== value[0]);
                    return (i !== value[0]);
                });
            }
        });

        // console.log(incompleteData); 

        setIncompleteData(incompleteData);
        return isValid;
    }

    const validateForSameName = (value) => {
        let error = false;
        campaignList && Object.keys(campaignList).forEach((name) => {
            if (name === value) {
                error = true;
            }
        })
        return error;
    }

    const handleSubmit = () => {
        // console.log(document.getElementById("button-sbm").disabled);

        if (!campaignDetails.CampaignName) {
            setNameError(true);
            return;
        }

        if (!params.name && validateForSameName(campaignDetails.CampaignName)) {
            setSameNameError(true);
            return;
        }

        setSubmissionLoading(true);
        let toSubmit = true;
        slides.forEach((slide, idx, arr) => {
            if (!validate(slide)) {
                // console.log("What the actual fuck is happening here?!")
                setIncompleteCampaignError(true);
                toSubmit = false;
            }

            if (idx === arr.length - 1) {
                if (toSubmit) {
                    pushCacDataToFirbase('pending', () => {
                        history.push('/new/submit');
                        let to = 'konark@freestand.in';
                        let subject = `${name} created a campaign`
                        let body = `${name} just created a new campaign on our platform.\n\nHead over to the admin panel to take further actions regarding it.\n\nSome basic details are as follows:\nCampaign name: ${campaignDetails.CampaignName.toString()}\nObjective: ${campaignDetails.SamplingObjectives.toString()}\nModule: ${campaignDetails.SamplingModules.toString()}\nSelected SKU: ${productDetails.SelectedSKU.name.toString()}\nPackaging: ${responsibilities.PrimaryPackaging.toString()}\nWarehousing: ${responsibilities.PackAndDelivery.toString()}\nLaunch: ${responsibilities.Launch.toString()}\n \nFor all the details, head over to the admin panel.`
                        emailNotificationUtil(to, subject, body);
                    });
                }
                setSubmissionLoading(false);
            }
        });
    }


    useEffect(() => {
        let filteredMarketing = [];
        switch (samplingModule) {
            case "Social Media Targeting Campaign":
                filteredMarketing = {
                    Cities: marketing.Cities,
                    AgeRange: marketing.AgeRange,
                    Gender: marketing.Gender,
                    SelectedNumberOfSamples: marketing.SelectedNumberOfSamples,
                    AudienceConnect: marketing.AudienceConnect,
                    Size: marketing.Size
                };
                setSlides([
                    campaignDetails,
                    productDetails,
                    filteredMarketing,
                    responsibilities
                ]);
                break;
            case "Influencer Driven Campaign":
                filteredMarketing = {
                    Size: marketing.Size,
                    SelectedNumberOfSamples: marketing.SelectedNumberOfSamples,
                    AudienceConnect: marketing.AudienceConnect
                };
                setSlides([
                    campaignDetails,
                    productDetails,
                    filteredMarketing,
                    responsibilities
                ]);
                break;
            case "FS Community":
                filteredMarketing = {
                    SelectedFSAudience: marketing.SelectedFSAudience,
                    Size: marketing.Size,
                    SelectedNumberOfSamples: marketing.SelectedNumberOfSamples,
                    AudienceConnect: marketing.AudienceConnect
                };
                setSlides([
                    campaignDetails,
                    productDetails,
                    filteredMarketing,
                    responsibilities
                ]);
                break;
            case "Your Existing Community":
                filteredMarketing = {
                    AgeRange: marketing.AgeRange,
                    Gender: marketing.Gender,
                    Size: marketing.Size,
                    reach: marketing.reach,
                    Cities: marketing.Cities,
                    SelectedNumberOfSamples: marketing.SelectedNumberOfSamples,
                    AudienceConnect: marketing.AudienceConnect
                };
                setSlides([
                    campaignDetails,
                    productDetails,
                    filteredMarketing,
                    responsibilities
                ]);
                break;
            default:
                setSlides([
                    campaignDetails,
                    productDetails,
                    marketing,
                    responsibilities
                ]);
        }
    }, [campaignDetails, productDetails, marketing, responsibilities]);

    const listOfHeading = [
        {
            title: 'Campaign Details',
            Icon: Campaign
        },
        {
            title: 'Product Details',
            Icon: Product
        },
        {
            title: 'Marketing',
            Icon: Marketing
        },
        {
            title: 'Responsibilities',
            Icon: Box
        }
    ]

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
        appendDots: dots => (
            <div
                style={{
                    backgroundImage: "linear-gradient(to right, #052762, #0B5394)",
                    position: "absolute",
                    top: "0",
                    width: "100%",
                }}
            >
                <ul id="tabs-list" style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <img className="icon" src={listOfHeading[i].Icon} alt="box" style={{
                    position: 'relative',
                    zIndex: '1'
                }} />
                <span className="nav-title" style={{
                    position: 'relative',
                    zIndex: '1',
                }}>{listOfHeading[i].title}</span>
            </div>
        ),
        beforeChange: (oI, nI) => {
            Array.from(document.getElementById('tabs-list').children).forEach(tab => {
                tab.children[0].classList.remove('current');
            })
            document.getElementById('tabs-list').children[nI].children[0].classList.add('current');

            if (validate(slides[oI])) {
                document.getElementById('tabs-list').children[oI].children[0].classList.add('complete');
                document.getElementById('tabs-list').children[oI].children[0].classList.remove('incomplete');
            }
            else {
                document.getElementById('tabs-list').children[oI].children[0].classList.remove('complete');
                document.getElementById('tabs-list').children[oI].children[0].classList.add('incomplete');
            }

            if (nI === 2) {
                setIsMarketingSlide(true);
            }
            else {
                setIsMarketingSlide(false);
            }
        },
        afterChange: (nI) => {
            if (nI === listOfHeading.length - 1) {
                setIsLastOuterSlider(true);
            }
            else {
                setIsLastOuterSlider(false);
            }
        }
    };

    return (
        <div className="outer-slider">
            <SubmitErrorModal
                incompleteCampaignError={incompleteCampaignError}
                nameError={nameError}
                handleIncompleteCampaignToggle={setIncompleteCampaignError}
                handleNameErrorToggle={setNameError}
                sameNameError={sameNameError}
                handleSameNameError={setSameNameError}
            />
            <Slider ref={slider} {...settings} style={{ paddingTop: '15%' }}>
                {
                    children
                }
            </Slider>
            <button style={{ display: 'none' }} id="next-slide" onClick={next}>
                next
            </button>
            <button style={{ display: 'none' }} id="submit-btn" onClick={handleSubmit}>
                Submit
            </button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    campaignDetails: state.cac.campaignDetails,
    samplingModule: state.cac.campaignDetails.SamplingModules[0],
    productDetails: state.cac.productDetails,
    marketing: state.cac.marketing,
    responsibilities: state.cac.responsibilities,
    incompleteData_Prop: state.cac.globalCacStates.incompleteData,
    campaignList: state.campaigns.campaignslist,
    name: state.profile && state.profile.userdata && state.profile.userdata.name
})

const mapDispatchToProps = (dispatch) => ({
    setIsLastOuterSlider: (state) => dispatch(setIsLastOuterSlider(state)),
    setIsMarketingSlide: (state) => dispatch(setIsMarketingSlide(state)),
    pushCacDataToFirbase: (status, callback) => dispatch(pushCacDataToFirbase(status, callback)),
    setIncompleteData: (incompleteData) => dispatch(setIncompleteData(incompleteData)),
    setSubmissionLoading: (state) => dispatch(setSubmissionLoading(state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OuterSlider);
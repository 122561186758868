import React from 'react'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion';

const CampaignSubmit = (props) => {
    return (
        <div id="campaign-submit">
            <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_94HTw9.json" background="transparent" speed="1" style={{
                width: '450px',
                height: '450px',
                // marginTop: '40px'
            }} autoplay></lottie-player>
            {/* <div className="heading">
                Voila!
            </div> */}
            <div className="sub-heading">
                Your campaign has been created!
            </div>
            <Link to='/campaigns'>
                <motion.div
                    className="buttons"
                    initial={{
                        opacity: 0,
                        scale: 0
                    }}
                    animate={{
                        opacity: 1,
                        scale: 1
                    }}
                    transition={{
                        duration: 0.5,
                        type: "spring",
                        damping: 12,
                    }}
                >
                    <button>View your campaign</button>
                </motion.div>
            </Link>
        </div>
    )
}

export default CampaignSubmit;
import Page from '../components/page/Page';
import React, { useEffect, useState, useRef } from 'react';
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   // Table,
//   // Button,
//   FormGroup,
//   Label,
//   // Input,
//   InputGroup,
//   InputGroupAddon,
//   ButtonGroup

// } from 'reactstrap';
import { Link } from 'react-router-dom';
import { startGetAds } from '../redux/ads/ads.actions'
import { connect } from 'react-redux';
import '../styles/components/_ads-page.scss';
import { Table, Tag, Space, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { jsonToCSV } from 'react-papaparse';

const fileDownload = require('js-file-download');

const Ads = ({ ads_data, startGetAd, keyword }) => {

  useEffect(() => {
    startGetAd()
  }, [])

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef();

  const toggle = () => {
    setmodalIsOpen(false);
  }

  const handleCancel = () => {
    setmodalIsOpen(false)

  }
  const handleDownload = () => {
    let data = {};
    Object.keys(ads_data).filter(adKey => ads_data[adKey]['campaign_name'].toLowerCase().includes(keyword)).map((key, idx) => {
      data[idx] = ads_data[key];
      // Object.assign(data, {...data, ...ads_data[idx]});
    });
    fileDownload(JSON.stringify(data), 'ads_data.json');
    console.log(jsonToCSV(data));
  }
  const handleMore = (ads) => {

    const initialization = [
      setmodalIsOpen(true)
    ]
    console.log(ads)
    Promise.all(initialization)
      .then(() => {
        document.getElementById('campaign').innerText = ads['campaign_id']
        document.getElementById('spend').innerText = parseFloat(ads['spend']).toFixed(2)
        document.getElementById('Frequency').innerText = parseFloat(ads['frequency']).toFixed(2)
        document.getElementById('CPM').innerText = parseFloat(ads['cpm']).toFixed(2)
        document.getElementById('CTR').innerText = parseFloat(ads['ctr']).toFixed(2)
        document.getElementById('CPC').innerText = parseFloat(ads['cpc']).toFixed(2)
        document.getElementById('clicks').innerText = ads['clicks']
      }).catch(err => console.log(err));
  }


  const handleCost = (ads) => {
    if (ads['objective'] == "MESSAGES") {
      try {
        for (var i = 0; i < ads['cost_per_unique_action_type'].length; i++) {

          if (ads['cost_per_unique_action_type'][i]['action_type'] == "onsite_conversion.messaging_conversation_started_7d") {
            return parseFloat(ads['cost_per_unique_action_type'][i]['value']).toFixed(2);
          }
        }
        return "----"
      } catch (e) {
        return "----";
      }

    }
    else if (ads['objective'] == "LINK_CLICKS") {
      try {
        for (var i = 0; i < ads['cost_per_unique_action_type'].length; i++) {

          if (ads['cost_per_unique_action_type'][i]['action_type'] == "link_click") {
            return parseFloat(ads['cost_per_unique_action_type'][i]['value']).toFixed(2);
          }
        }
        return "----"
      } catch (e) {
        return "----";
      }


    }
    else {
      return "----"
    }

  }




  const handleResult = (ads) => {
    if (ads['objective'] == "MESSAGES") {
      try {
        for (var i = 0; i < ads['actions'].length; i++) {

          if (ads['actions'][i]['action_type'] == "onsite_conversion.messaging_conversation_started_7d") {
            return ads['actions'][i]['value']
          }
        }
        return "----"
      } catch (e) {
        return "----";
      }

    }
    else if (ads['objective'] == "LINK_CLICKS") {
      try {
        for (var i = 0; i < ads['actions'].length; i++) {

          if (ads['actions'][i]['action_type'] == "link_click") {
            return ads['actions'][i]['value']
          }
        }
        return "----"
      } catch (e) {
        return "----";
      }


    }
    else {
      return "----"
    }
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Campaign Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ...getColumnSearchProps('name'),
      fixed: 'left',
      ellipsis: true
    },
    {
      title: 'Objective',
      key: 'objectives',
      dataIndex: 'objectives',
      ellipsis: true,
      width: 170,
      render: tags => (
        <>
          {tags.map(tag => {
            let color = 'grey';
            if (tag === 'MESSAGES') {
              color = 'green';
            }
            if (tag === 'LINK_CLICKS') {
              color = 'blue';
            }
            if (tag === 'LEAD_GENERATION') {
              color = 'purple';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      filters: [
        {
          text: 'Messages',
          value: 'MESSAGES',
        },
        {
          text: 'Link clicks',
          value: 'LINK_CLICKS',
        },
        {
          text: 'Lead generation',
          value: 'LEAD_GENERATION',
        }
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => {
        // console.log(value);
        // console.log(record.objectives[0]);
        return value === record.objectives[0];
      }
    },
    {
      title: 'Results',
      dataIndex: 'results',
      // width: 150,
      ellipsis: true,
      key: 'results',
    },
    {
      title: 'Reach',
      // width: 150,
      dataIndex: 'reach',
      ellipsis: true,
      key: 'reach',
    },
    {
      title: 'Impressions',
      // width: 150,
      dataIndex: 'impressions',
      ellipsis: true,
      key: 'impressions',
    },
    {
      title: 'Clicks',
      // width: 150,
      dataIndex: 'clicks',
      ellipsis: true,
      key: 'clicks',
    },
    {
      title: 'CPC',
      // width: 150,
      dataIndex: 'cpc',
      ellipsis: true,
      key: 'cpc',
    },
    {
      title: 'CPM',
      // width: 150,
      dataIndex: 'cpm',
      ellipsis: true,
      key: 'cpm',
    },
    {
      title: 'CTR',
      // width: 150,
      dataIndex: 'ctr',
      ellipsis: true,
      key: 'ctr',
    },
    {
      title: 'Frequency',
      // width: 150,
      dataIndex: 'frequency',
      ellipsis: true,
      key: 'frequency',
    },
    {
      title: 'Cost Per Result',
      // width: 150,
      dataIndex: 'costPerResult',
      ellipsis: true,
      key: 'costPerResult',
    },
    {
      title: 'Amount Spent',
      // width: 150,
      dataIndex: 'amount',
      ellipsis: true,
      key: 'amount',
      fixed: 'right'
    }

  ];

  let data;

  if (ads_data) {
    if (!ads_data.error) {
      data = Object.keys(ads_data).filter(adKey => ads_data[adKey]['campaign_name'].toLowerCase().includes(keyword)).map((ads, idx) => {
        console.log(ads)
        return (
          {
            key: idx,
            name: ads_data[ads]['campaign_name'],
            objectives: [ads_data[ads]['objective']],
            results: handleResult(ads_data[ads]),
            reach: ads_data[ads]['reach'],
            impressions: ads_data[ads]['impressions'],
            clicks: ads_data[ads]['clicks'],
            cpc: parseFloat(ads_data[ads]['cpc']).toFixed(2),
            cpm: parseFloat(ads_data[ads]['cpm']).toFixed(2),
            ctr: parseFloat(ads_data[ads]['ctr']).toFixed(2),
            frequency: parseFloat(ads_data[ads]['frequency']).toFixed(2),
            costPerResult: handleCost(ads_data[ads]),
            amount: ads_data[ads]['spend']
          }
        )
      });
    }
  }

  return (
    <Page breadcrumbs={[
      { name: `Analytics`, active: false },
      { name: `Ads Insights`, active: true }
    ]} title='Ads Insights'
      className="TablePage">

      <div style={{ position: 'relative', width: '100%', height: '50px' }}>
        <Button color="warning" onClick={() => handleDownload()} style={{ position: 'absolute', right: '0' }}>Download</Button>
      </div>

      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: '12',
          hideOnSinglePage: true
        }}
        loading={ads_data ? false : true}
        scroll={{ x: '120%' }}
      />

      {
        !data && <p style={{marginTop: '20px'}}>Please connect your facebook ads account to view ads insights here by going to the <Link to='integrations'>integrations panel</Link></p>
      }


    </Page>
  );
};

const mapStateToProps = (state) => ({
  ads_data: state.ads.ad,
  keyword: state.ads.keyword
});

const mapDispatchToProps = (dispatch) => {
  return {
    startGetAd: () => dispatch(startGetAds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
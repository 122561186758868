import database from '../../firebase/firebase';

export const getAnalyticsConfig = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/chart-data/to_track`);
        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_ANALYTICS_CONFIG',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_ANALYTICS_CONFIG',
                    payload: {}
                });
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setAnalyticsConfig = (campaign, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        console.log({ uid, campaign, newData });

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/chart-data/to_track`);
        dbRef.set({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getAnalyticsConfig(campaign));
                callback(true);
            }
        });
    }
}

export const getClientConfig = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/config`);
        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_CLIENT_CONFIG',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_CLIENT_CONFIG',
                    payload: {
                        'client_name': '',
                        'client_prefix': ''
                    }
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setClientConfig = (newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/config`);
        dbRef.set({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });
    }
}

export const getVendorIds = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/cac/campaigns/${campaign}/data/delivery/vendors`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_VENDOR_IDS',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_VENDOR_IDS',
                    payload: []
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const getCities = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/cac/campaigns/${campaign}/data/marketing/Cities`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_CITIES',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_CITIES',
                    payload: {}
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setVendorIds = (campaign, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        let activeVendors = {};
        Object.values(newData).filter((vendor, idx, arr) => arr.indexOf(vendor) === idx).forEach((vendor) => {
            activeVendors[vendor] = true;
        });

        const dbRef = database.ref(`users/${uid}/cac/campaigns/${campaign}/data/delivery/vendors`);

        dbRef.set({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });

        const activeRef = database.ref(`users/${uid}/active/vendor_ids`);

        activeRef.set({
            ...activeVendors
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
            }
            else {
                dispatch(getClientConfig());
            }
        });
    }
}

export const getManychatConfig = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/manychat_config`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_MANYCHAT_CONFIG',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_MANYCHAT_CONFIG',
                    payload: {
                        'Addresses_reconfirmation_flow_id': '',
                        'Feedback_flow_id': '',
                        'Manychat_token': '',
                        'PhoneNumber_reconfirmation_flow_id': '',
                        'Transit_flow_id': ''
                    }
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setManychatConfig = (campaign, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/manychat_config`);

        dbRef.set({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });
    }
}

export const getRegionConfig = (campaign, vendor, region) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/region_config/${vendor}/${region}`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_REGION_CONFIG',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_REGION_CONFIG',
                    payload: {
                        'email': '',
                        'vendor_address_1': '',
                        'vendor_address_2': '',
                        'vendor_name': '',
                        'vendor_phone_number': '',
                        'vendor_pincode': ''
                    }
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const getActiveVendors = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/active/vendor_ids`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_ACTIVE_VENDORS',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_ACTIVE_VENDORS',
                    payload: {}
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const getActiveRegions = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/active/regions`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_ACTIVE_REGIONS',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_ACTIVE_REGIONS',
                    payload: {}
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setRegionConfig = (campaign, vendor, region, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/region_config/${vendor}/${region}`);

        dbRef.set({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });
    }
}

export const getSKUConfig = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/cac/campaigns/${campaign}/data/productDetails/SelectedSKU/payload`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                const { weight, dimension } = snapshot.val();
                dispatch({
                    type: 'GET_SKU_CONFIG',
                    payload: {
                        weight,
                        dimension,
                        amount: 100
                    }
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_SKU_CONFIG',
                    payload: {
                        'weight': '',
                        'diemnsion': '',
                        'amount': ''
                    }
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setSKUConfig = (campaign, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/cac/campaigns/${campaign}/data/productDetails/SelectedSKU/payload`);

        dbRef.update({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });
    }
}

export const getSocialMediaConfig = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/social/social_config`);

        dbRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                dispatch({
                    type: 'GET_SOCIAL_CONFIG',
                    payload: snapshot.val()
                })
            }
            else {
                console.log('Data does not exist');
                dispatch({
                    type: 'GET_SOCIAL_CONFIG',
                    payload: {
                        'access_token': '',
                        'page_id': ''
                    }
                })
            }
        })
            .catch((err) => {
                console.log('Could not fetch data from DB', err);
            })
    }
}

export const setSocialMediaConfig = (campaign, newData, callback) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const dbRef = database.ref(`users/${uid}/campaigns/${campaign}/social/social_config`);

        dbRef.update({
            ...newData
        }, (err) => {
            if (err) {
                console.log('There was an error in setting new data', err);
                callback(false);
            }
            else {
                dispatch(getClientConfig());
                callback(true);
            }
        });
    }
}
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Button, Input } from 'antd';
import { Row, Col } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import './inventory-table.styles.scss';
import { useEffect } from 'react';
import { filterInventory } from '../../redux/inventory/inventory.util';

const InventoryTable = ({ inventoryStatus, filters }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInventory, setFilteredInventory] = useState(null);
  const [focussedInventory, setFocussedInventory] = useState(null);

  useEffect(() => {
    setFilteredInventory(filterInventory({ inventoryStatus, filters }));
  }, [inventoryStatus, filters]);
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });
    
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const handleScheduleRequest = e => {
    
  }

  const columns = React.useMemo(
    () => [    
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'id',
            render: (tag, record) => {
              let color;
              if (tag === 'inbound') {
                color = 'green';
              } else if (tag === 'outbound') {
                color = 'blue';
              } else if (tag === 'rto') {
                color = 'purple';
              } else if (tag === 'damaged') {
                color = 'red';
              } else if (tag === 'scheduled') {
                color = 'yellow';
              } else {
                color = 'grey';
              }
              return (
                <React.Fragment>
                  <Row>
                    <Col xl={6}>
                      <Tag color={color} key={tag} className="mr-4">
                        {tag === 'TBS' ? 'To Be Scheduled' : tag.toUpperCase()}
                      </Tag>
                    </Col>
                    <Col xl={6}>
                      {tag === 'TBS' && (
                        <Link to={`/inventory/scheduler?id=${record.id}`}><Button className="bg-primary text-white">Schedule</Button></Link>
                      )}
                    </Col>
                  </Row>
                </React.Fragment>
              );
            },
            filters: [
              {
                text: 'Inbound',
                value: 'inbound',
              },
              {
                text: 'Outbound',
                value: 'outbound',
              },
              {
                text: 'RTO',
                value: 'rto',
              },
              {
                text: 'Damaged',
                value: 'damaged',
              },
              {
                text: 'Scheduled',
                value: 'scheduled',
              },
              {
                text: 'To Be Scheduled',
                value: 'TBS',
              }                  
            ],
            onFilter: (value, record) => value === record.status
        },
        {
          title: 'CAMPAIGN',
          dataIndex: 'campaign',
          key: 'campaign'
        },
        {
          title: 'CITY',
          dataIndex: 'city',
          key: 'city'
        }, 
        {
          title: 'WAREHOUSE RESPONSIBILITY',
          dataIndex: 'whr',
          key: 'whr'
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
        }
    ],
    [filteredInventory]
  );  
    
  const data = React.useMemo(
    () => filteredInventory,
    [filteredInventory]
  );

    
  return (
    <Table
      bordered
      columns={columns}
      dataSource={data} 
      rowKey="id"
      responsive
      pagination={{
        pageSize: '10',
        hideOnSinglePage: true
      }}
    />        
  );
};

export default InventoryTable;
import database from '../../firebase/firebase';

//GET_CAMPAIGNS
export const setCampaigns = (campaignslist = null) => {
    return ({
        type: 'GET_CAMPAIGNS',
        campaignslist
    })
}

export const getCampaigns = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`/users/${clientID}/cac/campaigns/`).once('value').then(snapshot => {
        if (snapshot.exists()) {
            dispatch(setCampaigns(snapshot.val()));   
        } else {
            dispatch(setCampaigns({}))
        }
    })
    .catch(err => console.log(err));
    }
}

export const getActiveCampaigns = () => {
    return (dispatch, getState) => {
        const clientId = getState().auth.uid;
        const campaignsRef = database.ref(`users/${clientId}/active/campaigns`);

        campaignsRef.on('value', snapshot => {
            dispatch({
                type: 'GET_ACTIVE_CAMPAIGNS',
                campaigns: snapshot.val()?Object.keys(snapshot.val()):[]
            })
        })
    }
}

//SET_SELECTED
export const setSelectedCamp = (selected) => ({
    type: 'SET_SELECTED',
    selected
})
const INITIAL_STATE = {
    analytics_config: {},
    client: {
        'client_name': '',
        'client_prefix': ''
    },
    vendor_ids: [],
    manychat_config: {
        'Addresses_reconfirmation_flow_id': '',
        'Feedback_flow_id': '',
        'Manychat_token': '',
        'PhoneNumber_reconfirmation_flow_id': '',
        'Transit_flow_id': ''
    },
    region_config: {
        'email': '',
        'vendor_address_1': '',
        'vendor_address_2': '',
        'vendor_name': '',
        'vendor_phone_number': '',
        'vendor_pincode': ''
    },
    sku_config: {
        'weight': '',
        'diemnsion': '',
        'amount': ''
    },
    social_media_config: {
        'access_token': '',
        'page_id': ''
    },
    cityList: {},
    activeVendors: {},
    activeRegions: {}
}

const settingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ANALYTICS_CONFIG':
            return {
                ...state,
                analytics_config: action.payload
            }
        case 'GET_CLIENT_CONFIG':
            return {
                ...state,
                client: action.payload
            }
        case 'GET_VENDOR_IDS':
            return {
                ...state,
                vendor_ids: action.payload
            }
        case 'GET_MANYCHAT_CONFIG':
            return {
                ...state,
                manychat_config: action.payload
            }
        case 'GET_REGION_CONFIG':
            return {
                ...state,
                region_config: action.payload
            }
        case 'GET_SKU_CONFIG':
            return {
                ...state,
                sku_config: action.payload
            }
        case 'GET_SOCIAL_CONFIG':
            return {
                ...state,
                social_media_config: action.payload
            }
        case 'GET_CITIES':
            return {
                ...state,
                cityList: action.payload
            }
        case 'GET_ACTIVE_VENDORS':
            return {
                ...state,
                activeVendors: action.payload
            }
        case 'GET_ACTIVE_REGIONS':
            return {
                ...state,
                activeRegions: action.payload
            }
        default:
            return state;
    }
}

export default settingsReducer;
import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 700;

const defaultStyle = {
    transition: `max-height ${duration}ms ease-in-out`,
    maxHeight: '0px',
    height: 'fit-content',
    overflow: 'hidden',
}

const transitionStyles = {
    entering: { maxHeight: '600px' },
    entered: { maxHeight: '600px' },
    exiting: { maxHeight: '0px' },
    exited: { maxHeight: '0px' },
};

const TransitionComponentWrapper = ({children, conditionField, conditionValue}) => {
    return (
        <Transition in={conditionField.includes(conditionValue)} timeout={duration}>
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    {
                        children
                    }
                </div>
            )}
        </Transition>
    )
}

export default TransitionComponentWrapper;
import React from 'react';
import { connect } from 'react-redux';
import SocialMediaSlides from '../PredefinedSlides/SocialMediaSlides';
import InfluencerDrivenSlides from '../PredefinedSlides/InfluencerDrivenSlides';
import FSCommunitySlides from '../PredefinedSlides/FSCommunitySlides';
import ExistingCommunitySlides from '../PredefinedSlides/ExistingCommunitySlides';
import InnerSlider from '../PredefinedSlides/InnerSlider';

const Marketing = ({ samplingModule }) => {
    return (
        <>
            {
                !samplingModule && <InnerSlider>
                    <h2>
                        Please select a sampling module first
                    </h2>
                </InnerSlider>
            }
            {
                samplingModule === 'Social Media Targeting Campaign' && <SocialMediaSlides />
            }
            {
                samplingModule === 'Influencer Driven Campaign' && <InfluencerDrivenSlides />
            }
            {
                samplingModule === 'FS Community' && <FSCommunitySlides />
            }
            {
                samplingModule === 'Your Existing Community' && <ExistingCommunitySlides />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    samplingModule: state.cac.campaignDetails.SamplingModules[0]
})

export default connect(mapStateToProps)(Marketing);
const INITIAL_STATE = {
    sku: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'GET_SKU' :
            return{
                ...state,
                sku: action.sku
            }

        default:
            return state;
    }
}
export const setProductCategory = (category) => {
    return {
        type: 'SET_PRODUCT_CATEGORY',
        payload: category
    }
}

export const setSelectedSKU = (sku) => {
    return {
        type: 'SET_SELECTED_SKU',
        payload: sku
    }
}

export const getProductDetails = (data) => {
    return {
        type: 'GET_PRODUCT_DETAILS',
        payload: data
    }
}

export const resetProductDetails = () => {
    return {
        type: 'RESET_PRODUCT_DETAILS',
        payload: null
    }
}
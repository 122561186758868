import { firebase } from '../../firebase/firebase';
import database from '../../firebase/firebase';
import { history } from '../../routers/AppRouter';

export const login = (uid) => ({
    type: 'LOGIN',
    uid
});

export const startLogin = (email, password) => {
    return () => {
        firebase.auth().signInWithEmailAndPassword(email, password)
        .catch(err => alert('Login Failed'));
    };
};

export const logout = () => ({
    type: 'LOGOUT'
});

export const startLogout = () => {
    return () => {
        return firebase.auth().signOut();
    };
};

export const setUid = (uid) => ({
    type: 'LOGIN',
    uid
});

export const setclient = () => {
    return (dispatch, getState) => {
        const agencyID = getState().agency.uid;
        database.ref(`agencies/${agencyID}/active`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setUid(snapshot.val()));
        }).catch(err => console.log(err));
    }
};


export const selectClient = (client_id="") => {
    return (dispatch, getState) => {
        const agencyID = getState().agency.uid;
        if (client_id!=""){
            database.ref(`agencies/${agencyID}`).update({"active":client_id})
            .then(() => {
                dispatch(setUid(client_id));
                if (history.location.pathname==="/campaigns") window.location.reload();
                else history.push('/campaigns');
            })
            .catch(err => console.log(err));
        }
    }
};
import React, { useEffect } from 'react';
import InnerSlider from './InnerSlider';
import SlideWrapper from './SlideWrapper';
import { connect } from 'react-redux';

import { setAudienceConnect } from '../../../../redux/cac/marketing/marketing.actions';
import { setAgeRange } from '../../../../redux/cac/marketing/marketing.actions';
import { setGender as setGenderMarketing } from '../../../../redux/cac/marketing/marketing.actions';
import { setSize } from '../../../../redux/cac/marketing/marketing.actions';
import { setAgeMax } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { setAgeMin } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { setAudienceSizeFetched } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { setGender as setGenderTargeting } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import QuestionWithOptions from './QuestionWithOptions';
import { predefinedData } from '../predefinedData';
import AgeQuestion from './AgeQuestion';
import { loadTargetingSpecs } from '../../../../redux/cac/remote/remote.actions';

import PopoverComponent from '../components/PopoverComponent';
import LocationComponent from '../components/LocationQuestionComponent';
import InterestComponent from '../components/InterestsQuestionComponent';
import NumberOfSamplesQuestion from '../CustomSlides/NumberOfSamplesQuestion';

const SocialMediaSlides = (props) => {
    const {
        setAudienceConnect,
        setAgeRange,
        setGenderMarketing,
        setAgeMax,
        setAgeMin,
        setGenderTargeting,
        setAudienceSizeFetched,
        setSize,
        loadTargetingSpecs
    } = props;

    const {
        targeting_specs,
        size,
        isMarketingSlide
    } = props;

    const {
        AgeRange,
        Gender,
        AudienceConnect,
    } = props.marketing;

    useEffect(() => {
        loadTargetingSpecs(targeting_specs);
    }, [targeting_specs]);

    const handleAgeChanges = (ageRange) => {
        setAgeRange(ageRange);
        setAgeMax(ageRange.to);
        setAgeMin(ageRange.from);
    }

    const handleGenderChanges = (gender) => {
        setGenderMarketing(gender);
        setGenderTargeting(gender);
    }

    return (
        <InnerSlider>
            {/* Slide 1 */}
            <SlideWrapper
                heading='Target Audience'
                description="Since you selected social media driven campaign"
            >
                { isMarketingSlide && <PopoverComponent size={size} /> }

                <LocationComponent />

                <InterestComponent />

                {/* Age */}
                <AgeQuestion
                    heading="Age"
                    description="Select the range of age in which your audience lies in"
                    sendValueToStore={handleAgeChanges}
                    storeVariable={AgeRange}
                />

                {/* Gender */}
                <QuestionWithOptions
                    heading="Gender"
                    description="Select gender which is more dominant in your audience. Select all if they're equal."
                    options={predefinedData.optionButtonsGender}
                    sendValueToStore={handleGenderChanges}
                    size='small'
                    storeVariable={Gender}
                />
            </SlideWrapper>

            {/* Slide 2 */}
            <SlideWrapper>
                {/* Number of samples */}
                <NumberOfSamplesQuestion />

                {/* Audience connection */}
                <QuestionWithOptions
                    heading="Audience interaction"
                    description="How would you like to connect with your audience?"
                    options={predefinedData.optionButtonsAudienceConnect}
                    sendValueToStore={setAudienceConnect}
                    icons={false}
                    storeVariable={AudienceConnect}
                    // size="small"
                />
            </SlideWrapper>
        </InnerSlider>
    )
}

const mapStateToProps = (state) => ({
    targeting_specs: state.cac.socialMediaTargeting.targeting_specs,
    size: state.cac.socialMediaTargeting.AudienceSizeFetched,
    isMarketingSlide: state.cac.globalCacStates.isMarketingSlide,
    marketing: state.cac.marketing
})

const mapDispatchToProps = (dispatch) => ({
    setAudienceConnect: (audienceConnect) => dispatch(setAudienceConnect(audienceConnect)),
    setAgeRange: (ageRange) => dispatch(setAgeRange(ageRange)),
    setGenderMarketing: (gender) => dispatch(setGenderMarketing(gender)),
    setAgeMax: (ageMax) => dispatch(setAgeMax(ageMax)),
    setAgeMin: (ageMin) => dispatch(setAgeMin(ageMin)),
    setGenderTargeting: (gender) => dispatch(setGenderTargeting(gender)),
    setAudienceSizeFetched: (audienceSizeFetched) => dispatch(setAudienceSizeFetched(audienceSizeFetched)),
    setSize: (size) => dispatch(setSize(size)),
    loadTargetingSpecs: (targetingSpecs) => dispatch(loadTargetingSpecs(targetingSpecs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaSlides);
import React from 'react';
import NoData from '../../../../../assets/img/NoData.jpg'
import Page from '../../../../page/Page';

const GraphicsNotFoundComponent = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            fontSize: '2.5rem',
            fontFamily: 'Ubuntu',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={NoData} alt="no-data" style={{
                width: '40%',
                objectFit: 'contain'
            }} />
            {/* <a href="https://www.freepik.com/free-photos-vectors/data" style={{
                    fontSize: '0.7rem',
                    color: 'grey',
                    marginBottom: '3rem',
                    textAlign: 'right'
                }}>Data vector created by stories - www.freepik.com</a> */}
                <p>Seems like no graphics have been uploaded</p> 
        </div>
    )
}

export default GraphicsNotFoundComponent;
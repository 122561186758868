import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Button } from 'reactstrap';

import placementFail from '../../assets/img/placement-fail.gif';

import './modal-failure.styles.scss';

const ModalFail = ({ closeFunc, children }) => {
    return (
        <ModalBody>
            <div className="modal-failure">
                <img 
                src={placementFail}
                alt="placement failure gif"
                width="180"
                height="180"
                />
            </div>
            <p className="text-danger"><b>Looks like we ran into some trouble. Please try again later!</b></p>
            { children }
            <Button color="danger" outline onClick={closeFunc}>Close</Button>
        </ModalBody>
    );
};

ModalFail.propTypes = {
    closeFunc: PropTypes.func.isRequired
}

export default ModalFail;
import database from '../../firebase/firebase';
import CLOUD_FUNCTIONS_URLS from '../../firebase/cf';

export const getads = (ads = {}, keyword) => {
    return (({
        type: 'GET_ADS',
        payload: { ads, keyword }
    }));
};

export const startGetAds = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const token = getState().firebase.auth_token;

        database.ref(`/users/${uid}/integrations/facebookAdsAccount`).on('value', (snapshot) => {
            const data = snapshot.val();

            if(data) {
                const id = String(data.data);
                const keyword = String(data.keyword);
                
                fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnGetAdsInsight}?value=${id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
                ).then(res => {
                    return res.json()
                }).then(data => {
                dispatch(getads(data['data'], keyword))
                }
                ).catch(err => {
                    console.log(err)
                })
            }
            else {
                dispatch(getads({error: 'No ads found'}))
            }
    })
    
    }
}


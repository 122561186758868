import database, { storage, firebase } from '../../firebase/firebase';
import { addTask, addNotif } from '../tasks/tasks.actions';
// import emailjs from 'emailjs-com';
import emailNotification from '../../utils/emailNotification';

//SET_PROGRESS
export const setProgress = (progress) => ({
    type: 'SET_PROGRESS',
    progress
})

//ADD_RESPONSE
export const addResponse = (response, location, admin) => {
    return (dispatch) => {
        database.ref(location).push({
                "response":response,
                "sender":admin?"admin":"client",
                "time": firebase.database.ServerValue.TIMESTAMP
            }).then(() => {
                    const $tdate = new Date();
                    const notifdata = {
                        "name": "Query Text message",
                        "description": `The query has a text response from the client. Please check the help panel for more details.`,
                        "category": "Help & Support",
                        "campaign": "",
                        "priority": "high",
                        "date": $tdate.getTime(),
                        "sticky": false,
                        "byline": "Freestand"
                    }
                    dispatch(addNotif(notifdata));
                    dispatch(setProgress("FINISHED"));
                }
        ).catch(err => console.log(err));
    }
}

//GET_FILE
export const getFile = (storageLocation, name) => {
    return () => {
        storage.ref(storageLocation).child(name).getDownloadURL().then(url => window.open(url)
        ).catch(err => console.log(err));
    }
}

//GET_CAMPS
export const setCamps = (campslist) => ({
    type: 'SET_CAMPS',
    campslist
})

export const getCamps = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`/users/${clientID}/cac/campaigns/`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setCamps(Object.keys(snapshot.val())))
        }).catch(err => console.log(err));
    }
}

//ADD_QUERY
export const addQuery = (querydata, response, filedata="", callback) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const name = getState().profile.userdata.name;
        let rootRef = "";
        rootRef = `/users/${clientID}/queries/`;
        const myID = database.ref(rootRef).push().key;
        querydata['time'] = firebase.database.ServerValue.TIMESTAMP;
        database.ref(rootRef + myID).set(querydata).then(() => {
            dispatch(setProgress("New Query Added."));
            database.ref(rootRef + myID + `/responses/`).push({
                "response":response,
                "sender":"client",
                "time": firebase.database.ServerValue.TIMESTAMP
                }).then(() => {
                    dispatch(setProgress("Created Responses."));
                    if(filedata!==""){
                        const responsedata ={
                            "name": "Attachment - " + filedata["name"],
                            "location": rootRef + `${myID}/responses/`,
                            "admin": false
                        }
                        dispatch(uploadFile(filedata, `${clientID}/queries/${querydata["id"]}/`, responsedata));
                    }
                    else dispatch(setProgress("FINISHED"));

                    callback();

                    // Sending email to admin
                    let emailDetail = {
                        to: 'konark@freestand.in',
                        subject: `Query generated: ${querydata["id"]}`,
                        body: `Our client, ${name} raised a query regarding the campaign, ${querydata['campaign']}.\n\nDetails are as follows:-\nTicket-ID: ${querydata["id"]}\nSubject: ${querydata["question"]}\nQuery: ${response}`
                    }

                    emailNotification(emailDetail.to, emailDetail.subject, emailDetail.body);

                    //CODE FOR EMAIL ENDS.
                    const $tdate = new Date();
                    const taskdata ={
                        "name": "Query Recorded",
                        "description": `Your query (#${querydata["id"]}) has been recorded. Please check the help panel for more details.`,
                        "category": "Help & Support",
                        "campaign": querydata["campaign"]==="nocampaign"?"":querydata["campaign"],
                        "priority": "low",
                        "date": $tdate.getTime(),
                        "sticky":false,
                        "byline": "Freestand"
                    }
                    dispatch(addTask(taskdata));
                    const notifdata ={
                        "name": "New Query Submitted",
                        "description": `New query (#${querydata["id"]}) has been recorded. Please check the panel for more details.`,
                        "category": "Help & Support",
                        "priority": "high",
                        "date": $tdate.getTime(),
                        "sticky":false
                    }
                    dispatch(addNotif(notifdata));
                }
            ).catch(err => console.log(err));}
        ).catch(err => console.log(err));
    }
}

//UPLOAD_FILE
export const uploadFile = (filetoupload, storageLocation, responsedata) => {
    return (dispatch) => {
        const uploadRef = storage.ref(storageLocation).child(filetoupload.name);
        const uploadTask = uploadRef.put(filetoupload);
        uploadTask.on('state_changed', () => {
            dispatch(setProgress("Uploading..."));
        }, (err) => {
            console.log(err);
        }, () => {
            dispatch(setProgress("UPLOADED!"));
            dispatch(addResponse(responsedata["name"], responsedata["location"], responsedata["admin"]));
        });
    }
}

//GET_QUERIES
export const setQueries = (existingqueries) => ({
    type: 'GET_QUERIES',
    existingqueries
})

export const getQueries = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const existingqueries = {};
        database.ref(`/users/${clientID}/queries/`).once('value').then(snapshot => {
            if (snapshot.exists()) {
                const queriesall = snapshot.val();
                Object.keys(queriesall).forEach(queryid => {
                    existingqueries[queryid] = queriesall[queryid];
                }); 
            }
            dispatch(setQueries(existingqueries));
        }).catch(err => console.log(err));
    }
}

//MARK_UNSOLVED
export const markTicket = (current, queryID) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const updateobject = {};
        if(current==="unsolved") updateobject["status"] = "solved";
        else updateobject["status"] = "unsolved";
        database.ref(`/users/${clientID}/queries/${queryID}`).update(updateobject).then(() => {
            dispatch(setProgress("FINISHED"));
            const $tdate = new Date();
            const taskdata ={
                "name": "Ticket Re-opened",
                "description": `Your query has been marked unsolved. Our team is on it and will get back to you at the earliest.`,
                "category": "Help & Support",
                "campaign": "",
                "priority": "low",
                "date": $tdate.getTime(),
                "sticky":false,
                "byline": "Freestand"
            }
            dispatch(addTask(taskdata));
        }).catch(err => console.log(err));
    }
}





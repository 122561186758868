import React from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';

const ErrorModal = ({isOpen, setIsOpen, status}) => {
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => setIsOpen(false)}>
                {status}
            </ModalHeader>
            <ModalBody style={{padding: '50px 30px'}}>
            {
                (status === 'CONNECTED' || status === 'CONFIRMED') ? (
                    <div className="connection-status">
                        <lottie-player src="https://assets7.lottiefiles.com/datafiles/s2s8nJzgDOVLOcz/data.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px", margin: 'auto auto'}} autoplay></lottie-player>
                        <p style={{
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>The connection has been successfully done</p>
                    </div>
                ) : (
                    <div className="connection-status">
                        <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_97sa2r.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px", margin: 'auto auto'}} autoplay></lottie-player>
                        <p style={{
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>Please accept our request on your page</p>
                    </div>
                )
            }
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => setIsOpen(false)} style={{
                    padding: '0.7em 2.1em'
                }}>
                    Ok
                </Button>
            </ModalFooter>
            </Modal>
    )
}

export default ErrorModal;
import React, { useEffect, useState } from 'react';
import {
    Table,
    Collapse,
    Col,
    Row,
    Alert,
    Button,
    Card,
    CardBody
  } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { getBillingdata, getFile} from '../../redux/billing/billing.actions';
import { connect } from 'react-redux';
import PageSpinner from '../page/PageSpinner';
import activeicon from '../../assets/img/active.png';
import warningicon from '../../assets/img/warning.png';
import pendingicon from '../../assets/img/pending.png';


const Billing = ({ billingdata, getdata, openFile }) => {

    const [collapse, setCol] = useState("");
    const toggle = (colID) => {
        if(collapse!==colID) setCol(colID);
        else setCol("")
    }

    const isLoading = billingdata===undefined;
    const isEmpty = billingdata==null;

    useEffect(() => {
        if(isLoading) getdata();
    }, [isLoading, getdata])

    const getColor = (cID) => {
        console.log(isEmpty);
        switch(billingdata[cID]["status"]["status"])
        {
            case "active": return activeicon;
            case "warning": return warningicon;
            case "pending": return pendingicon; 
        }
    }

    const handleOpen = (e) => {
        openFile(e.target.value, e.target.parentNode.className);
    }

    const getcaption = (cID) => {
        switch(billingdata[cID]["status"]["status"])
        {
            case "active": return "The campaign is up and running smoothly.";
            case "warning": return "The samples are almost over.";
            case "pending": return "The payment is pending and campaign samples are over."; 
        }
    }

    const scrollSidebar = () => {
        document.querySelector('.cr-sidebar__content').scrollBy(0, 100);
    }

    return(
        <div>
            {(isLoading) && <PageSpinner />}
            {(isEmpty && !isLoading) && <Alert color="danger">
                    Your campaigns are yet to be billed! Please check back later.
                </Alert>
            }
            {(!isLoading && !isEmpty) && (
                <div>
                    <div className="text-right">
                        <Link to="/help"><Button color="primary" onClick={scrollSidebar}>Contact Us</Button></Link>
                    </div><br />
                <Card className="p-8">
                    <CardBody>
                    <Row className="headings">
                        <Col xl="4" lg="4">Campaign Name</Col>
                        <Col xl="7" lg="7">Status</Col>
                        <Col xl="1" lg="1"></Col>
                    </Row>
                    {Object.keys(billingdata).map(campaign =>
                        <div key={campaign}>
                            <Row className="row-data" onClick={() => toggle(campaign)}>
                                <Col xl="4" lg="4">{campaign}</Col>
                                <Col xl="7" lg="7">
                                    <img height="25px" width="25px" src={getColor(campaign)} />
                                    <span style={{marginLeft: "10px"}}>{getcaption(campaign)}</span>
                                </Col>
                                <Col xl="1" lg="1">
                                    {collapse===campaign && <BsChevronUp />}
                                    {collapse!==campaign && <BsChevronDown />}
                                </Col>
                            </Row>
                            <Row>
                                <Collapse className="docs" isOpen={collapse===campaign}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Document Name</th>
                                                <th>Document Type</th>
                                                <th>Dated</th>
                                                <th></th>
                                            </tr>
                                                {Object.keys(billingdata[campaign]["documents"]).map(doc => 
                                                    <tr key={doc}>
                                                        <td>{billingdata[campaign]["documents"][doc]["name"]}</td>
                                                        <td>{billingdata[campaign]["documents"][doc]["type"]}</td>
                                                        <td>{billingdata[campaign]["documents"][doc]["date"]}</td>
                                                        <td className={campaign}>
                                                            <Button 
                                                                color="secondary"
                                                                value={billingdata[campaign]["documents"][doc]["file"]} 
                                                                onClick={handleOpen}
                                                            >Open</Button>
                                                        </td>
                                                    </tr>
                                                )}
                                        </thead>
                                    </Table>
                                </Collapse>
                            </Row>
                        </div>
                    )}
                    </CardBody>
                </Card>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    billingdata: state.billing.billingdata
});

const mapDispatchToProps = (dispatch) => ({
    getdata: () => dispatch(getBillingdata()),
    openFile: (filename, campID) => dispatch(getFile(filename, campID))
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);

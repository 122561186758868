const INITIAL_STATE = {
    SelectedFSAudience: "",
    Gender: "",
    MoreInfoCommunity: "",
    Cities: "",
    reach: "",
    AgeRange: "",
    Size: "",
    AudienceConnect: "",
    SelectedNumberOfSamples: "",
    CustomNumberOfSamples: ""
}

const marketingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CITIES':
            return {
                ...state,
                Cities: action.payload
            }
        case 'SET_AGE_RANGE':
            return {
                ...state,
                AgeRange: action.payload
            }
        case 'SET_GENDER':
            return {
                ...state,
                Gender: action.payload
            }
        case 'SET_SIZE':
            return {
                ...state,
                Size: action.payload
            }
        case 'SET_AUDIENCE_REACH_OPTIONS':
            return {
                ...state,
                reach: action.payload
            }
        case 'MORE_INFO':
            return {
                ...state,
                MoreInfoCommunity: action.payload
            }
        case 'SET_AUDIENCE_CONNECT':
            return {
                ...state,
                AudienceConnect: action.payload
            }
        case 'SET_FS_AUDIENCE':
            return {
                ...state,
                SelectedFSAudience: action.payload
            }
        case 'SET_SELECTED_NUMBER_OF_SAMPLES':
            return {
                ...state,
                SelectedNumberOfSamples: action.payload
            }
        case 'SET_CUSTOM_NUMBER_OF_SAMPLES':
            return {
                ...state,
                CustomNumberOfSamples: action.payload
            }
        case 'GET_MARKETING':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_MARKETING_DATA':
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}

export default marketingReducer;
import React from 'react';
import { TreeSelect } from 'antd';
import '../../styles/components/_tree_select.scss';
import { FormGroup, Label, Input } from 'reactstrap';
const { TreeNode } = TreeSelect;


const categories = [
  {
    id: 'personal-care',
    text: 'Personal Care',
    description: 'Personal Care',
    subOptions: [
      {
        id: 'oral-care',
        text: 'Oral Care',
        description: 'Oral Care',
      },
      {
        id: 'hair-care',
        text: 'Hair Care',
        description: 'Hair Care',
      },
      {
        id: 'skin-care',
        text: 'Skin Care',
        description: 'Skin Care',
      },
      {
        id: 'personal-wash',
        text: 'Personal Wash',
        description: 'Personal Wash',
      },
      {
        id: 'cosmetic-toiletries',
        text: 'Cosmetics & Toiletaries',
        description: 'Cosmetics & Toiletaries',
      },
      {
        id: 'feminine-hygiene',
        text: 'Feminine Hygiene',
        description: 'Feminine Hygiene',
      },
      {
        id: 'paper-product',
        text: 'Paper Product',
        description: 'Paper Product',
      },
    ]
  },
  {
    id: 'household',
    text: 'Household Care',
    description: 'Household Care',
    subOptions: [
      {
        id: 'fabric-wash',
        text: 'Fabric Wash',
      },
      {
        id: 'household-cleaners',
        text: 'Household Cleaners',
      },
    ]
  },
  {
    id: 'food-bevarages',
    text: 'Food & Beverages',
    description: 'Food & Beverages',
    subOptions: [
      {
        id: 'health-beverages',
        text: 'Health Beverages',
        description: 'Health Beverages',
      },
      {
        id: 'soft-drinks',
        text: 'Soft Drinks',
        description: 'Soft Drinks',
      },
      {
        id: 'staples-cereals',
        text: 'Stapes/Cereals',
        description: 'Stapes/Cereals',
      },
      {
        id: 'bakery-products',
        text: 'Bakery Products',
        description: 'Bakery Products',
      },
      {
        id: 'snack-food',
        text: 'Snack Food',
        description: 'Snack Food',
      },
      {
        id: 'chocolate',
        text: 'Chocolate',
        description: 'Chocolate',
      },
      {
        id: 'ice-cream',
        text: 'Ice Cream',
        description: 'Ice Cream',
      },
      {
        id: 'tea',
        text: 'Tea',
        description: 'Tea',
      },
      {
        id: 'coffee',
        text: 'Coffee',
        description: 'Coffee',
      },
      {
        id: 'processed-fruits-vegetables',
        text: 'Processed fruits, vegetables',
        description: 'Processed fruits, vegetables',
      },
      {
        id: 'dairy-products',
        text: 'Dairy Products',
        description: 'Dairy Products',
      },
      {
        id: 'bottled-water',
        text: 'Bottled Water',
        description: 'Bottled Water',
      },
      {
        id: 'branded-flour',
        text: 'Branded Flour',
        description: 'Branded Flour',
      },
      {
        id: 'branded-rice-sugar-juices',
        text: 'Branded Rice, Sugar, Juices',
        description: 'Branded Rice, Sugar, Juices',
      },
    ]
  }
]

class SKUSelect extends React.Component {

  onChange = value => {
    this.props.setSkuCategory(value);
  };

  onSelect = (value, node, extra) => {
    console.log(value, node, extra);
  };

  onTreeExpand = (expandedKeys) => {
    console.log(expandedKeys);
  }

  render() {
    return (
      <>
        <TreeSelect
          showSearch
          style={{ width: '100%' }}
          value={this.props.skuCategory}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Category"
          allowClear
          onChange={this.onChange}
          onSelect={this.onSelect}
          onTreeExpand={this.onTreeExpand}
          id="category"
        >
          {
            categories.map(category => (
              <TreeNode key={category.text} id={category.id} value={category.text} title={category.text}>
                {
                  category.subOptions.map(subOption => (
                    <TreeNode key={`${category.text}: ${subOption.text}`} id={subOption.id} value={`${category.text}: ${subOption.text}`} title={subOption.text} />
                  ))
                }
              </TreeNode>
            ))
          }
          <TreeNode key='Other' id='other' value='Other' title='Other' />
        </TreeSelect>
        {/* {
          this.props.skuCategory && this.props.skuCategory.includes('Other') && (
            <FormGroup style={{marginTop: '14px'}}>
              <Label for="name">Please specify</Label>
              <Input type="text" id="other_sku" placeholder="Specify here" name="Other SKU"/>
            </FormGroup>
          )
        } */}
      </>
    );
  }
}

export default SKUSelect;
import database from '../../firebase/firebase';

export const setData = (data) => ({
    type: 'SET_MAP_DATA',
    data
})

export const getMapData = (campaign) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`/users/${clientID}/campaigns/${campaign}/mapdata/`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setData(snapshot.val()))
        }).catch(err => console.log(err));
    }
}
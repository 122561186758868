const defaultState = {
   
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case "SET_BILLINGDATA":
            return {
                ...state,
                billingdata: action.billingdata
            }
        case "SET_BILLCAMPS":
            return {
                ...state,
                billcamps: action.campslist
            }
            
        default:
            return state;
    }
}
import { all, call } from 'redux-saga/effects';
import { inventorySagas } from '../inventory/inventory.sagas';
import firebaseSaga from '../firebase/firebase.sagas';
import { remoteSagas } from '../cac/remote/remote.sagas';

export default function* rootSaga() {
    yield all([
        call(inventorySagas),
        firebaseSaga(),
        remoteSagas()
    ]);
}
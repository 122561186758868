const INITIAL_STATE = {
    ads: {},
    keyword: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'GET_ADS' :
            return{
                ...state,
                ad: action.payload.ads,
                keyword: action.payload.keyword
            }

        default:
            return state;
    }
}
import _ from 'lodash';

export const filterInventoryStats = ({stats, filters: { campaign, city }}) => {
    if (!stats) return [];
    if (city === '') {
        return Object.keys(stats[campaign].overall).map(key => ({ label: key, value: stats[campaign].overall[key] }));
    } else {
        return Object.keys(stats[campaign][city]).map(key => ({ label: key, value: stats[campaign][city][key] }));
    }
}

export const filterInventory = ({ inventoryStatus, filters: { campaign, city } }) => {
    if (city === '') {
        return _.filter(inventoryStatus, { campaign });
    } else {
        return _.filter(inventoryStatus, { campaign, city })
    }
}
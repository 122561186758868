import { Spinner } from 'reactstrap';
import React from 'react';

const ChatbotKeys = ({detailKey, value}) => {
    return (
        <div className="chatbot-keys" title={`${detailKey}: ${value}`}>
            <span className="detailKey">{detailKey}</span>: <span className="value">{value}</span>
        </div>
    )
}

const ChatbotDetails = ({ data }) => {

    const formatVal = (item) => {
        switch(item.type) {
            case 'boolean':
                return item.value?'Yes':'No'
            case 'datetime':
                const d = new Date(item.value);
                return d.toLocaleDateString()
            default:
                return item.value
        }
    }

    return (
        <div className="chatbot-details">
            <h4>Chatbot Details</h4>
            {data && data.map(val => 
                <ChatbotKeys 
                    key={val.id} 
                    detailKey={val.name} 
                    value={formatVal(val)} 
                />
            )}
            {!data && <Spinner />}
        </div>
    )
}

export default ChatbotDetails;
import database from '../../firebase/firebase';

export const setClientlist = (clientslist) => ({
    type: 'SET_CLIENTS',
    clientslist
})

export const getAllClients = () => {
    return (dispatch, getState) => { 
        const agencyID = getState().agency.uid;
        database.ref(`agencies/${agencyID}/clients`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setClientlist(snapshot.val()));
            else dispatch(setClientlist(null));
        }).catch(err => console.log(err));
    }
}

export const agencylogin = (uid) => ({
    type: 'SET_AGENCY',
    uid
})
import React from 'react';
import { ModalBody } from 'reactstrap';

import placementLoader from '../../assets/img/placement-loader.gif';

import './modal-loading.styles.scss';

export default () => {

    return (
        <ModalBody>
            <div className="modal-gif">
                <img
                    src={placementLoader}
                    alt="Processing Order GIF"
                    width="180"
                    height="180"
                />
            </div>
        </ModalBody>
    );
}
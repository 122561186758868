import React, { useState, useEffect } from 'react'
import Carousel from '../carousel/carousel-component'
import Comments from '../comments/comments-component'
import './graphic-details-styles.scss'
import ChooseGraphic from '../choose-graphic/choose-graphic-component'
import { FiChevronRight } from 'react-icons/fi'
import Page from '../../../../page/Page';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { startSetGraphics } from '../../../../../redux/clientinfo/graphics.actions';
// import firebase from '../../firebase.utils'

const GraphicDetail = ({ graphics }) => {
    const [selectedGraphic, setSelectedGraphic] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState();
    const [isCommentsOpen, setIsCommentsOpen] = useState();
    const [isVersionsOpen, setIsVersionsOpen] = useState();

    const { graphicId } = useParams();

    useEffect(() => {
        if (graphics) {
            setSelectedGraphic(graphics[graphicId]);
        }
    }, [graphicId]);

    const handleSidebarToggle = (state) => {
        setIsSidebarOpen(state);
    }

    const handleCommentsToggle = (state) => {
        if (state !== undefined) {
            setIsCommentsOpen(state);
        }
        else {
            setIsCommentsOpen(!isCommentsOpen);
        }
    }

    const handleVersionsToggle = (state) => {
        setIsVersionsOpen(state);
    }

    return (
        <Page breadcrumbs={[
            { name: `Graphic Approvals`, active: false },
            { name: !selectedGraphic ? "" : selectedGraphic.name, active: true }
        ]}>
            <div className="carousal-comment-container">
                <div className="show-button" onClick={() => {
                    handleSidebarToggle(true)
                    handleCommentsToggle(false)
                    handleVersionsToggle(false)
                }}>
                    <FiChevronRight />
                </div>
                <ChooseGraphic
                    isSidebarOpen={isSidebarOpen}
                    isCommentsOpen={isCommentsOpen}
                    isVersionsOpen={isVersionsOpen}
                    handleSidebarToggle={handleSidebarToggle}
                    handleCommentsToggle={handleCommentsToggle}
                    handleVersionsToggle={handleVersionsToggle}
                />
                <Carousel
                    isSidebarOpen={isSidebarOpen}
                    isCommentsOpen={isCommentsOpen}
                    isVersionsOpen={isVersionsOpen}
                    handleSidebarToggle={handleSidebarToggle}
                    handleCommentsToggle={handleCommentsToggle}
                    handleVersionsToggle={handleVersionsToggle}
                />
                <Comments
                    isSidebarOpen={isSidebarOpen}
                    isCommentsOpen={isCommentsOpen}
                    isVersionsOpen={isVersionsOpen}
                    handleSidebarToggle={handleSidebarToggle}
                    handleCommentsToggle={handleCommentsToggle}
                    handleVersionsToggle={handleVersionsToggle}
                />
            </div>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    graphics: state.graphics.graphics
});

export default connect(mapStateToProps)(GraphicDetail);
import { combineReducers } from 'redux';

import authReducer from '../auth/auth.reducer';
import clientInfo from '../clientinfo/clientInfo.reducer';
import stats from '../clientinfo/stats.reducer';
import skuReducer from '../sku/sku.reducer';
import queryReducer from '../queries/queries.reducer';
import campaignReducer from '../campaigns/campaigns.reducer';
import profileReducer from '../profile/profile.reducer';
import billingReducer from '../billing/billing.reducer';
import tasksReducer from '../tasks/tasks.reducer';
import chartReducer from '../ads/chart.reducer';
import manychatReducer from '../manychat/manychat.reducer';
import mapboxReducer from '../mapbox/mapbox.reducer';
import adsReducer from "../ads/ads.reducer";
import userStats from '../clientinfo/user-stats.reducer';
import inventoryReducer from '../inventory/inventory.reducer';
import graphicsReducer from '../clientinfo/graphics.reducer';
import CACReducer from '../cac/cac.rootReducer';
import agencyReducer from '../agency/clientlist.reducer';
import settingsReducer from '../settings-page/settings.reducers';
import firebaseReducer from '../firebase/firebase.reducers';
import remoteReducer from '../cac/remote/remote.reducers';

const rootReducer = combineReducers({
    auth: authReducer,
    clientInfo: clientInfo,
    manychat: manychatReducer,
    stats: stats,
    skus: skuReducer,
    queries: queryReducer,
    campaigns: campaignReducer,
    profile: profileReducer,
    billing: billingReducer,
    chart : chartReducer,
    ads : adsReducer,
    userStats: userStats,
    inventory: inventoryReducer,
    mapbox: mapboxReducer,
    tasks: tasksReducer,
    graphics: graphicsReducer,
    cac: CACReducer,
    agency: agencyReducer,
    settings: settingsReducer,
    firebase: firebaseReducer,
    remote: remoteReducer
});

//TODO Add persist here if need be

export default (state, action) => 
    rootReducer(action.type==='LOGOUT' ? undefined:state, action);
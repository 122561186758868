const defaultState = {
   tasksnum: 0,
   notifsnum: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case "SET_TASKS":
            return {
                ...state,
                tasklist: action.tasklist
            }

        case "SET_TASKSNUM":
            return {
                ...state,
                tasksnum: action.tnum
            }
        case "SET_NOTIFSNUM":
            return {
                ...state,
                notifsnum: action.nnum
            }
            
        default:
            return state;
    }
}
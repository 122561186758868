import React, { useState } from 'react';
import { connect } from 'react-redux';
import { startLogin } from '../../redux/auth/auth.actions';
import fs from '../../assets/img/logo/fs.png';
import {
    FormGroup,
    Spinner
} from 'reactstrap'
import FormInput from '../form-input/FormInput.component';
import CustomButtonComponent from '../custom-button/CustomButton.component';

const Signin = ({ startLogin }) => {
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: ''
    });

    const [spin, setSpin] = useState(false);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setLoginCredentials({
            ...loginCredentials,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        setSpin(true);
        e.preventDefault();
        const { email, password } = loginCredentials;
        startLogin(email, password);
    }

    return (
        <div className="sign-in">
            <form onSubmit={handleSubmit}>
            <div>
            <img
              src={fs}
              className="rounded"
              style={{ width: '60%', height: 60, cursor: 'pointer' }}
              alt="logo"
            />
          </div>
          <FormGroup>
                <FormInput
                 name="email"
                 type="email" 
                 label="Email"
                 value={loginCredentials.email} 
                 handleChange={handleChange}
                 required 
                />
                </FormGroup>
                <FormGroup>
                <FormInput
                 name="password" 
                 type="password" 
                 label="Password"
                 value={loginCredentials.password} 
                 handleChange={handleChange}
                 required
                />
                </FormGroup>
                <hr />
                {!spin &&
                    <CustomButtonComponent type="submit"> Sign in </CustomButtonComponent>
                }
                {spin && <Spinner color="primary"/>}
            </form>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    startLogin: (email, password) => dispatch(startLogin(email, password))
});

export default connect(undefined, mapDispatchToProps)(Signin);
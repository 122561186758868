export const setGenderHelper = (gender, state) => {
    switch(gender) {
        case 'Male':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    genders: [1]
                }
            }
        case 'Female':
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    genders: [2]
                }
            }
        default:
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    genders: []
                }
            }
    }
}


// convert layer information from state.mapbox redux to geojson
export const jsonToGeojson = (data) => {

    let geojson = {
        type: "FeatureCollection",
        features: [],
    };


    for (const obj of Object.keys(data)) {
        geojson.features.push({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [data[obj].lon, data[obj].lat]
            },
            "properties": data[obj]
        });
    }

    return geojson;

}
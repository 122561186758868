import database from '../../firebase/firebase';

//GET_ALL_TASKS
export const setTasklist = (tasklist) => ({
    type: 'SET_TASKS',
    tasklist
})

export const getAllTasks = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`users/${clientID}/tasks/taskslist`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setTasklist(snapshot.val()));
            else dispatch(setTasklist(null));
        }).catch(err => console.log(err));
    }
}

//ADD_TASK OR ADD_NOTIFICATION (CLIENT SIDE)
export const addTask = (taskdata) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const rootRef = `users/${clientID}/tasks/taskslist/`;
        const myID = database.ref(rootRef).push().key;
        database.ref(rootRef + myID).update(taskdata).then(() => {
            if(taskdata["sticky"]) dispatch(updateNum(1, "", false));
            else dispatch(updateNum());
            dispatch(getAllTasks());
        }).catch((err => console.log(err)))
    }
}

//ADD_NOTIFICATION (ADMIN SIDE)
export const addNotif = (notifdata) => {
    return (dispatch, getState) => {
        const rootRef = `admin/data/tasks/taskslist/`;
        const myID = database.ref(rootRef).push().key;
        notifdata['client'] = getState().profile.userdata["name"];
        database.ref(rootRef + myID).update(notifdata).then(() => {
            dispatch(updateNum(1, "admin/data"));
        }).catch((err => console.log(err)))
    }
}

//UPDATE_NUMBER
export const updateNum = (increm=1, clientRef="", notif=true) => {
    return (dispatch, getState) => {
        if(clientRef==="") clientRef = "users/"+getState().auth.uid;
        let mynum = 0;
        const subRef = notif?"newnotifs":"newtasks";
        database.ref(`${clientRef}/tasks/${subRef}/`).once('value').then(snapshot => {
            if (snapshot.exists()) {
                mynum=snapshot.val();
                mynum += increm;
            }
            const updateobj = {};
            updateobj[subRef] = mynum;
            database.ref(`${clientRef}/tasks/`).update(updateobj).then(() => {
                dispatch(getTasksNum())
            }).catch((err => console.log(err)))
        }).catch(err => console.log(err));
    }
}

//GET_NUMBER
export const setTasksNum = (tnum) => ({
    type: 'SET_TASKSNUM',
    tnum
})

export const setNotifsNum = (nnum) => ({
    type: 'SET_NOTIFSNUM',
    nnum
})

export const getTasksNum = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`users/${clientID}/tasks/newtasks/`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setTasksNum(snapshot.val()));
        }).catch(err => console.log(err));
        database.ref(`users/${clientID}/tasks/newnotifs/`).once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setNotifsNum(snapshot.val()));
        }).catch(err => console.log(err));
    }
}

//REMOVE_TASK
export const removeTask = (taskID) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        database.ref(`users/${clientID}/tasks/taskslist/${taskID}/`).set({"del":null}).then(() => {
            dispatch(updateNum(-1));
        }).catch((err => console.log(err)))
    }
}

//REMOVE_ALL
export const removeAll = (tasklist) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        tasklist.forEach(task => {
            database.ref(`users/${clientID}/tasks/taskslist/${task}/`).set({"del":null});
        });
        database.ref(`users/${clientID}/tasks/`).update({"newnotifs":0}).then(() => {
            dispatch(getTasksNum())
        }).catch((err => console.log(err)))
    }
}
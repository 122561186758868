import React from 'react';
import { Row, Col, CardBody, CardTitle } from 'reactstrap';

import './inventory-stats.styles.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { filterInventoryStats } from '../../redux/inventory/inventory.util';
import { Card } from 'antd';

const InventoryStats = ({ stats, filters }) => {
    const [filteredStats, setFilteredStats] = useState(null);

    useEffect(() => {
        if (filters.campaign !== '') {
            setFilteredStats(filterInventoryStats({stats, filters}));
        }
    }, [stats, filters]);

    return (
        <Row>
            {!!filteredStats && filteredStats.map((stat, idx) => (
                <Col className="col-lg mb-4" key={idx} {...stat.attrs}>
                    <Card className="stat-card">
                        <CardBody className="pt-0">
                            <CardTitle className="d-flex justify-content-center text-uppercase">{stat.label}</CardTitle>
                            <CardBody className="d-flex justify-content-center">
                                <h1>{stat.value}</h1>
                            </CardBody>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default InventoryStats;
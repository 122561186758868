import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Alert,
    Button,
    Card,
    CardBody,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
  } from 'reactstrap';
import { getAllTasks, removeTask, removeAll } from '../../redux/tasks/tasks.actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageSpinner from '../page/PageSpinner';

import { MdDashboard, MdHelp, MdOpenInNew, MdAccountCircle, MdKeyboardArrowDown, MdKeyboardArrowUp, MdDoneAll} from 'react-icons/md';
import { FaDollarSign, FaWindowClose } from 'react-icons/fa';
import { AiFillPicture } from 'react-icons/ai';
import { IoMdMap } from 'react-icons/io';
import prRed from '../../assets/img/red.png';
import prYlw from '../../assets/img/yellow.png';
import prGrn from '../../assets/img/green.png';

const Tasks = ({ taskslist, tasksnum, notifsnum, getTasks, deleteTask, deleteAll}) => {

    const isLoading = taskslist===undefined;
    const isEmpty = taskslist==null;

    const [sortedtasks, setSortedtasks] = useState({'notifs':{},'tasks':{}});
    const [btnSpinner, setSpinner] = useState(false);
    const [clpse, setClpse] = useState(false);

    const toggle = () => {
        setClpse(!clpse);
        if(!clpse)
        {
            document.getElementById("trigger").classList.add("when-show");
            document.getElementById("trigger").classList.remove("when-hide");
        }
        else 
        {
            document.getElementById("trigger").classList.add("when-hide");
            document.getElementById("trigger").classList.remove("when-show");
        }
    }

    const [activeTab, setActiveTab] = useState('notifs');
    const togTab = (tab) => {if(activeTab!==tab) setActiveTab(tab)}

    useEffect(() => {
        if(isLoading) getTasks();
        else if (!isEmpty) {
            sortTasks();
            setSpinner(false);
        }
    }, [getTasks, isLoading, sortTasks, taskslist, setSpinner])

    const sortTasks = () => {
        let seentasks = [];
        let seennotifs = [];
        const myfinallist = {
            'notifs':{},
            'tasks':{}
        };
        const mysortedlist = {};
        let mytemplist = [];

        Object.keys(taskslist).forEach(task => mytemplist.push(task));

        for(let i=0; i<mytemplist.length; i++){
            for(let j=i+1; j<mytemplist.length; j++){
                if(taskslist[mytemplist[i]]["date"]<taskslist[mytemplist[j]]["date"]) 
                {
                    const temp = mytemplist[i];
                    mytemplist[i] = mytemplist[j];
                    mytemplist[j] = temp;
                }
            }
            mysortedlist[mytemplist[i]] = taskslist[mytemplist[i]]; 
        }

        Object.keys(mysortedlist).forEach(task => {
            const categ = taskslist[task]["category"]; 
            if (taskslist[task]["sticky"])
            {
                if (seentasks.includes(categ)) myfinallist['tasks'][categ][task] = taskslist[task];
                else {
                    myfinallist['tasks'][categ] = {};
                    myfinallist['tasks'][categ][task] = taskslist[task];
                    seentasks.push(categ);
                }
            }
            else
            {
                if (seennotifs.includes(categ)) myfinallist['notifs'][categ][task] = taskslist[task];
                else {
                    myfinallist['notifs'][categ] = {};
                    myfinallist['notifs'][categ][task] = taskslist[task];
                    seennotifs.push(categ);
                }
            }
        });
        setSortedtasks(myfinallist);
    }

    const getCatcolor = (c) => {
        switch(c)
        {
            case "Campaign Creation":   return "green";
            case "Campaign Activation": return "sea";
            case "Graphic Approvals":   return "brown";
            case "Billing":             return "orange";
            case "Help & Support":      return "pink";
            case "SKUs":                return "red";
            case "Profile":             return "blue";
        }
    }

    const getIcon = (c) => {
        switch(c)
        {
            case "Campaign Creation":   return <MdDashboard />;
            case "Campaign Activation":   return <MdDashboard />;
            case "Graphic Approvals":   return <AiFillPicture />;
            case "Billing":             return <FaDollarSign />
            case "Help & Support":      return <MdHelp />;
            case "SKUs":                return <IoMdMap />;
            case "Profile":             return <MdAccountCircle />;
        }
    }

    const getLink = (cat, task, camp) => {
        switch(cat)
        {
            case "Campaign Creation":
                //if(task==="Complete your Campaign") return `/complete/${camp}`;
                return "/campaigns";
            case "Graphic Approvals":   return "/graphics-approval-system";
            case "Billing":             return "/billing";
            case "Help & Support":      return "/help";
            case "SKUs":                return "/sku";
            case "Profile":             return "/profile";
        }
    }

    const mysubtasks = {
        "inventory": {
            "name":"Schedule your inventory",
            "desc":"To move further towards activating your campaign, let's head over to the Inventory Scheduler.",
            "link":"/inventory/scheduler"
        },
        "integrations": {
            "name":"Complete Integrations",
            "desc":"The next step is assigning roles and responsibilities, this can be easily done through the integrations tab.",
            "link":"/integrations"
        }
    };

    const getDate = (timestamp) => {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        let compDate = new Date(timestamp);
        let diff = today.getTime() - timestamp;
        let disdate = "";
        if (diff<=0) disdate = "Today, ";
        else if (diff<=(24 * 60 * 60 *1000)) disdate = "Yesterday, ";
        else disdate = compDate.toDateString().replace(/^.{4}/,"")+', ';
        disdate += compDate.toLocaleTimeString().replace(/:\d{2}\s/," ");
        return disdate;
    }

    const getPrimg = (p) => {
        switch(p)
        {
            case 'high': return prRed;
            case 'medium': return prYlw;
            case 'low': return prGrn;
        }
    }

    const getPrcolor = (p) => {
        switch(p)
        {
            case 'high': return "txt-red";
            case 'medium': return "txt-ylw";
            case 'low': return "txt-grn";
        }
    }

    const checkCamp = (c) => {
        if(c==="") return '-';
        else return c.toUpperCase();
    }

    const handleDismiss = (e) => {
        setSpinner(true);
        deleteTask(e.currentTarget.value);
        getTasks();
    }

    const clearAll = () => {
        setSpinner(true);
        let taskstodelete = [];
        Object.keys(taskslist).forEach(task => {
            if(!taskslist[task]["sticky"]) taskstodelete.push(task)
        })
        deleteAll(taskstodelete);
        getTasks();
    }

    const checkNull = (tbid) => {
        // console.log(tbid, sortedtasks[tbid], Boolean(sortedtasks[tbid]), Boolean({}), Boolean(null));
        // if(sortedtasks[tbid]==={}) return false;
        // else return true;
        return false;
    }

    return(
        <Card id="tasks-container">
            {isLoading && <PageSpinner />}
            {isEmpty && !isLoading && <Alert>Nothing here, Please check back later!!</Alert>}
            {(!isLoading && !isEmpty) && (
                <CardBody>
                    <Nav tabs id="tasks-tabs">
                        <NavItem>
                            <NavLink className={activeTab==='tasks'?"active":""} onClick={() => togTab('tasks')}>
                                Tasks <span className="mybadge task-b">{tasksnum}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab==='notifs'?"active":""} onClick={() => togTab('notifs')}>
                                Updates <span className="mybadge notif-b">{notifsnum}</span>
                            </NavLink>
                        </NavItem>
                        {(activeTab==='notifs' && notifsnum>1) && (
                            <div className="dismiss-container">
                                <Button color="danger" size="sm" onClick={clearAll}>
                                    <FaWindowClose /> Dismiss All
                                </Button>
                            </div>
                        )}
                    </Nav>
                    {['notifs', 'tasks'].map(tabID =>
                    <div key={tabID}>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={tabID}>
                                {checkNull(tabID) && (
                                    <Alert>
                                        {tabID==='notifs'?"You have no new Notifications!":"No pending tasks found!"}
                                    </Alert>
                                )}
                                {!checkNull(tabID) && (
                                <div>
                                    <Row className="header-bar">
                                        <Col className="main-heading categ-heading">CATEGORY</Col>
                                        <Col className="main-heading task-heading">TASK</Col>
                                        <Col className="main-heading custom-heading">Campaign</Col>
                                        <Col className="main-heading custom-heading">Created by</Col>
                                        <Col className="main-heading custom-heading">Created on</Col>
                                        <Col className="main-heading pr-heading">Priority</Col>
                                    </Row>
                                    {Object.keys(sortedtasks[tabID]).map(categ => 
                                    <Row key={categ} className={"categ-wrapper bdr-"+getCatcolor(categ)}>
                                        <Col className={"category-column txt-"+getCatcolor(categ)}>
                                            <div className="categ-name">
                                                {getIcon(categ)} {categ}
                                            </div>
                                        </Col>
                                        <Col className="data-column">
                                        {Object.keys(sortedtasks[tabID][categ]).map(task =>
                                            <div key={task}>
                                                <Row className={"data-wrapper1"}>
                                                    <Col className="task-name">{sortedtasks[tabID][categ][task]["name"]}</Col>
                                                    <Col className="task-data">
                                                        {checkCamp(sortedtasks[tabID][categ][task]["campaign"])}
                                                    </Col>
                                                    <Col className="task-data">{sortedtasks[tabID][categ][task]["byline"]}</Col>
                                                    <Col className="task-data">
                                                        <span className="date-stamp">{getDate(sortedtasks[tabID][categ][task]["date"])}</span>
                                                    </Col>
                                                    <Col className="task-pr">
                                                        <img className="pr-img" src={getPrimg(sortedtasks[tabID][categ][task]["priority"])} />
                                                        <span className={"pr-text "+getPrcolor(sortedtasks[tabID][categ][task]["priority"])}>
                                                            {sortedtasks[tabID][categ][task]["priority"]}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row 
                                                    className={sortedtasks[tabID][categ][task]["category"]==="Campaign Activation"?
                                                        "spcl-wrapper when-hide":"data-wrapper2"}
                                                    id={sortedtasks[tabID][categ][task]["category"]==="Campaign Activation"?"trigger":""}
                                                >
                                                    <Col className="desc-column">{sortedtasks[tabID][categ][task]["description"]}</Col>
                                                    <Col className="btn-group">
                                                        {btnSpinner && <PageSpinner />}
                                                        {!btnSpinner && (
                                                            <div>
                                                            {sortedtasks[tabID][categ][task]["category"]!=="Campaign Activation" && (
                                                                <div>
                                                                    <Link to={getLink(sortedtasks[tabID][categ][task]["category"], sortedtasks[tabID][categ][task]["name"], sortedtasks[tabID][categ][task]["campaign"])}>
                                                                        <Button size="sm" color="success">
                                                                            <MdOpenInNew /> Open Panel
                                                                        </Button>
                                                                    </Link>
                                                                    {!sortedtasks[tabID][categ][task]["sticky"] && (
                                                                        <Button 
                                                                            value={task} 
                                                                            size="sm"
                                                                            color="danger"
                                                                            onClick={handleDismiss}
                                                                            style={{marginLeft: "4px"}}
                                                                        > <FaWindowClose /> Dismiss
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {sortedtasks[tabID][categ][task]["category"]==="Campaign Activation" && (
                                                                <div>
                                                                    {!clpse && (
                                                                        <Button size="sm" block color="success" onClick={toggle}>
                                                                            Show <MdKeyboardArrowDown />
                                                                        </Button>
                                                                    )}
                                                                    {clpse && (
                                                                        <Button size="sm" block color="danger" onClick={toggle}>
                                                                            Hide <MdKeyboardArrowUp />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            </div>
                                                        )}
                                                    </Col>
                                                    {sortedtasks[tabID][categ][task]["category"]==="Campaign Activation" && (
                                                    <Collapse isOpen={clpse}>
                                                        {Object.keys(sortedtasks[tabID][categ][task]["subtasks"]).map(st => 
                                                            <Row className="data-wrapper2 spcl">
                                                                <Col className="desc-column">
                                                                    <b>{mysubtasks[st]["name"]}: </b>{mysubtasks[st]["desc"]}
                                                                </Col>
                                                                <Col className="btn-group">
                                                                    {sortedtasks[tabID][categ][task]["subtasks"][st] && (
                                                                        <Link to={mysubtasks[st]["link"]}>
                                                                            <Button size="sm" color="success">
                                                                                <MdOpenInNew /> Go
                                                                            </Button> 
                                                                        </Link>
                                                                    )}
                                                                    {!sortedtasks[tabID][categ][task]["subtasks"][st] && (
                                                                        <div className="completed-badge">
                                                                            <MdDoneAll style={{fontSize: "1.5em"}}/> Completed
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Collapse>
                                                    )}
                                                </Row>
                                            </div>
                                        )}
                                        </Col>
                                    </Row>
                                    )}
                                </div>
                                )}
                            </TabPane>
                        </TabContent>
                    </div>
                    )}
                </CardBody>
            )}
        </Card>
    )
}

const mapStateToProps = (state) => ({
    taskslist: state.tasks.tasklist,
    tasksnum: state.tasks.tasksnum,
    notifsnum: state.tasks.notifsnum
});

const mapDispatchToProps = (dispatch) => ({
    getTasks: () => dispatch(getAllTasks()),
    deleteTask: ($task) => dispatch(removeTask($task)),
    deleteAll: ($mylist) => dispatch(removeAll($mylist))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);

import Page from '../components/page/Page';
import Billing from '../components/billing/Billing.component';
import React from 'react';

const BillingPage = () => {
    return(
        <Page breadcrumbs={[{name: 'Billings & Agreement', active: true }]} title='Billings & Agreement'>
            <Billing />
        </Page>
    )
}

export default BillingPage;
import React from 'react';
import Exclamation from '../../assets/img/error-exclamation.svg';
import { Link } from 'react-router-dom';
import './error-styles.scss';

const ErrorComponent = () => {
    return (
        <div className="error-component-container">
            <div className="error-component">
                <h1 className="error-component-heading">Something went wrong!</h1>
                <p className="error-component-text">
                    We hate this too! We are working our best to fix all the issues.<br />
                    If it persists please report an error or contact the Freestand Admin.
                </p>
                <img src={Exclamation} alt="Exclamation icon depicting error" className="error-exclamation" />
                <div className="error-component-footer">
                    <Link to='/help'>
                        <button className="error-component-button">
                            Report an error
                        </button>
                    </Link>
                    <Link to='/campaigns'>
                        <button className="error-component-button primary">
                            Go to home
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorComponent;
import Page from '../components/page/Page';
import Profile from '../components/profile/Profile.component';
import React from 'react';

const ProfilePage = () => {
    return(
        <Page breadcrumbs={[{name: 'Profile', active: true }]} title='Profile'>
            <Profile />
        </Page>
    )
}

export default ProfilePage;
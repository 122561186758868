import Axios from 'axios';
import { store } from '../redux/store/configureStore';
import CLOUD_FUNCTIONS_URLS from '../firebase/cf';

const emailNotificationUtil = (to, subject, body) => {
    const token = store.getState().firebase.auth_token
    
    return Axios.post(CLOUD_FUNCTIONS_URLS.dbEmailNotificationsOnEmailNotification, {to, subject, body}, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
};

export default emailNotificationUtil;
import React, { useState, useEffect } from "react";
import { Col } from 'reactstrap';
import SuboptionButton from "./SuboptionButton";

const OptionButtonWithSuboptions = ({ button, handleChange, handleHoverIn, handleHoverOut, checked, multiple, storeVariable }) => {
    const [selection, setSelection] = useState([]);
    const { text, id, description, icon } = button;
    const [withInput, setWithInput] = useState(false);

    useEffect(() => {
        if (!checked) {
            setSelection([]);
        }
    }, [checked]);

    useEffect(() => {
        if (storeVariable) {
            // console.log(storeVariable);
            storeVariable.forEach((option) => {
                if (typeof option === 'object') {
                    if (option.option === button.text) {
                        setSelection(option.subOptions);
                    }
                }
            })
        }
    }, [storeVariable]);

    useEffect(() => {
        if(button.subOptions[0].id.includes('other')) {
            setWithInput(true);
        }
    }, []);

    const handleSuboptionChange = (event) => {
        const { value, checked } = event.target;

        let newSelection = [];

        if (checked) {
            if (multiple) {
                newSelection = [...selection, value];
            }
            else {
                newSelection = [value];
            }
        }
        else {
            if (multiple) {
                newSelection = selection.filter(i => i !== value);
            }
            else {
                newSelection = [];
            }
        }

        // sendValueToStore(newSelection);
        setSelection(newSelection);
        handleChange({
            target: {
                value: {
                    option: button.text,
                    subOptions: newSelection
                },
                checked: checked
            }
        })
    }

    return (
        <Col>
            <div
                className=/* {`option-button ${this.props.size}-label`} */"option-button"
                style={{ width: "100%", minWidth: "130px" }}
                onMouseOver={() => handleHoverIn(icon, description)}
                onMouseLeave={handleHoverOut}
            >
                <input
                    value={text}
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={handleChange}
                />
                <label
                    className="with-suboptions"
                    htmlFor={id}
                >
                    {text}
                </label>

                <div className="subOptions">
                    {button.subOptionsText && <p style={{ marginTop: '6px', fontSize: '0.9rem', color: '#6f6e6e' }}>{button.subOptionsText}</p>}
                    {
                        button.subOptions.map((subButton, idx) => {
                            let subChecked;
                            if (selection.includes(subButton.text)) {
                                subChecked = true;
                            }
                            else {
                                subChecked = false;
                            }

                            if(withInput) {
                                let render;
                                if (!selection[0]) {
                                    render = true;
                                }
                                else {
                                    if (subButton.text.toLowerCase().includes(selection[0].toLowerCase())) {
                                        render = true;
                                    }
                                    else {
                                        render = false;
                                    }
                                }
    
                                if (subButton.id.includes('other')) {
                                    render = true;
                                }

                                return (
                                    render && <SuboptionButton value={selection[0]} handleChange={handleSuboptionChange} key={idx} text={subButton.text} id={subButton.id} subChecked={subChecked} />
                                )
                            }

                            else {
                                return (
                                    <SuboptionButton value={selection[0]} handleChange={handleSuboptionChange} key={idx} text={subButton.text} id={subButton.id} subChecked={subChecked} />
                                )
                            }

                        })
                    }
                </div>

            </div>
        </Col>
    );
};

export default OptionButtonWithSuboptions;

import React, { useState } from 'react';

const SuboptionButton = ({ text, id, handleChange, subChecked, value }) => {
    // const [otherOptionText, setOtherOptionText] = useState('');

    const handleTextChange = (event) => {
        // setOtherOptionText(event.target.value);
        handleChange({
            target: {
                value: event.target.value,
                checked: true
            }
        })
    }

    return (
        <div className="sub-option-button">
            {
                id.includes('other') ? (
                    <>
                        <input value={value} placeholder="Enter here" type='text' id={id} onChange={handleTextChange} />
                        {/* <input placeholder={text} value={value} type='text'  id={subButton.id} /> */}
                    </>
                ) : (
                        <>
                            <input value={text} type='checkbox' id={id} onChange={handleChange} checked={subChecked} />
                            <label htmlFor={id}>{text}</label>
                        </>
                    )
            }
        </div>
    )
}

export default SuboptionButton;
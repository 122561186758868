const warehouseCollection = {
    "dtdc_delhi": {
        "vendor_address_1": "DTDC 3PL & Fulfillment Ltd. Sahib Warehousing and Logistics Mama Compound",
        "vendor_address_2": "Saravali Village, Opp to Vatika Hotel, Nasik Highway _ NH3, Bhiwandi, Delhi",
        "city": "delhi",
        "vendor_pincode": "110058",
        "vendor_name": "Free Stand Sampling Solutions Pvt. Ltd.",
        "vendor_phone_number": "8130656526",
        "email": "shashwat824@gmail.com"
    },
    "delhivery_delhi": {
        "vendor_address_1": "DTDC 3PL & Fulfillment Ltd. Sahib Warehousing and Logistics Mama Compound",
        "vendor_address_2": "Saravali Village, Opp to Vatika Hotel, Nasik Highway _ NH3, Bhiwandi, Delhi",
        "city": "delhi",
        "vendor_pincode": "110058",
        "vendor_name": "Free Stand Sampling Solutions Pvt. Ltd.",
        "vendor_phone_number": "8130656526",
        "email": "shashwat824@gmail.com"
    },
    "dtdc_haryana": {
        "vendor_address_1": "DTDC 3PL & Fulfillment Ltd. Sahib Warehousing and Logistics Mama Compound",
        "vendor_address_2": "Saravali Village, Opp to Vatika Hotel, Nasik Highway _ NH3, Bhiwandi, Delhi",
        "city": "haryana",
        "vendor_pincode": "110058",
        "vendor_name": "Free Stand Sampling Solutions Pvt. Ltd.",
        "vendor_phone_number": "8130656526",
        "email": "shashwat824@gmail.com"
    },
    "dtdc_mumbai": {
        "vendor_address_1": "DTDC 3PL & Fulfillment Ltd. Sahib Warehousing and Logistics Mama Compound",
        "vendor_address_2": "Saravali Village, Opp to Vatika Hotel, Nasik Highway _ NH3, Bhiwandi, Delhi",
        "city": "mumbai",
        "vendor_pincode": "110058",
        "vendor_name": "Free Stand Sampling Solutions Pvt. Ltd.",
        "vendor_phone_number": "8130656526",
        "email": "shashwat824@gmail.com"
    }
};

export default warehouseCollection;
import { firebase } from '../../firebase/firebase';

export const getChatflowData = (name) => {
    return (dispatch, getState) => {
        const userId = getState().auth.uid;
        const chatflowRef = firebase.database().ref(`/users/${userId}/chatflow/${name}`);
        chatflowRef.once('value').then((snapshot) => {
            const data = snapshot.val().data;
            console.log(data === "No data");
            if (data === "No data") {
                // console.log(data.chatflow);
                dispatch({
                    type: 'GET_DATA',
                    payload: null
                })
            }
            else {
                let dataJson = JSON.parse(data.chatflow);
                dispatch({
                    type: 'GET_DATA',
                    payload: dataJson
                })
            }
        })
        .catch(err => {
            console.log('Waiting for uid', userId);
        });
    }
}

export const saveData = (data, name, callback) => {
    return (dispatch, getState) => {
        const userId = getState().auth.uid;
        const dataJson = JSON.stringify(data);
        console.log(data); // YKGkX62vo6gElyxw2lbQdTEd7aB2
        const chatflowRef = firebase.database().ref(`/users/${userId}/chatflow/${name}/data`);
        chatflowRef.set({
            chatflow: dataJson
        }, (error) => {
            if (error) {
                callback(false);
            } else {
                callback(true);
            }
        });
    }
}

export const changeFlow = (flow) => {
    return ({
        type: 'CHANGE_FLOW',
        payload: flow
    })
}

export const addFlowSet = (name, campaign, callback) => {
    return (dispatch, getState) => {
        const userId = getState().auth.uid;
        const chatflowRef = firebase.database().ref(`/users/${userId}/chatflow`);
        // console.log(name, campaign);
        chatflowRef.update({
            [name]: {
                campaign,
                data: "No data"
            }
        }, (error) => {
            if (error) {
                callback(false);
            } else {
                callback(true);
            }
        });
    }
}

export const getAllFlows = () => {
    return (dispatch, getState) => {
        const userId = getState().auth.uid;
        const chatflowRef = firebase.database().ref(`/users/${userId}/chatflow`);
        chatflowRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
                const flowList = snapshot.val();
                dispatch({
                    type: 'GET_ALL_FLOWS',
                    payload: flowList
                });
            }
        });
    }
}

export const resetChatflowData = () => ({
    type: 'GET_DATA',
    payload: null
})

export const resetFlowList = () => ({
    type: 'GET_ALL_FLOWS',
    payload: null
})
import React from 'react';
import ReactDOM from 'react-dom';
import { firebase } from './firebase/firebase';
import { store } from './redux/store/configureStore';
import { startSetClientSocials } from './redux/clientinfo/clientInfo.actions';
import { setclient, logout } from './redux/auth/auth.actions';
import { agencylogin } from './redux/agency/clientlist.actions';
import { loadFirebaseAuthToken } from './redux/firebase/firebase.actions';
import { startSetCampaignStats, startSetDataStudioStats } from './redux/clientinfo/stats.actions';
import { Provider } from 'react-redux';

import AppRouter, { history } from './routers/AppRouter';
import LoadingPage from './pages/LoadingPage';
import * as Sentry from "@sentry/react";

// TODO: put this in .env file
Sentry.init({ dsn: "https://bffd287271974137b4aa73bda98ac3ee@o524776.ingest.sentry.io/5637805" });


const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('root'));

firebase.auth().onAuthStateChanged(user => {
    if (user) {
        user.getIdTokenResult().then((res) => {
            if(res.claims.agency)
            {
                store.dispatch(agencylogin(user.uid));
                store.dispatch(setclient());
                // set user profile here if exists setup initialization in *then* if not then create a catch block and give a login failed alert 
                const initialization = [
                    store.dispatch(startSetClientSocials()),
                    store.dispatch(startSetCampaignStats()),
                    store.dispatch(startSetDataStudioStats()),
                    // This action starts the firebase auth token fetching flow
                    store.dispatch(loadFirebaseAuthToken())
                ]
                Promise.all(initialization)
                .then(() => {
                    renderApp();
                    if (history.location.pathname === '/') {
                        history.push('/campaigns'); //this will be conditional based on the fact if campaigns exists or not, if not then push to 'new'
                    }
                })
            }
            else window.alert("Permission Denied")
        });
    } else {
        store.dispatch(logout());
        renderApp();
        history.push('/');   
    }
})
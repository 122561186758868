import CLOUD_FUNCTIONS_URLS from '../../../../firebase/cf';

export const fetchInterests = (value, token) => {
    return new Promise((resolve, reject) => {
        fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnFetchInterests}?value=${value}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(body => {
                if (!body.data.length) {
                    reject('Could not find any interests, please check that you have spelled it correctly');
                }
                const data = body.data.filter((dataItem) => dataItem.type === 'interests' || dataItem.type === 'behaviors').map(dataItem => ({
                    value: [dataItem.name, dataItem.type, dataItem.id, dataItem.audience_size],
                }));
                resolve(data);
            })
            .catch((error) => {
                reject('Could not fetch interests. Please contact Freestand admin.')
            });
    })
}
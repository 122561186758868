import React from 'react';
import { Popover } from 'reactstrap';
import Warning from '../../../../assets/img/cac/warning-icon.svg';
import Users from '../../../../assets/img/cac/users-icon.svg';

const PopoverComponent = ({ size }) => {
    return (
        <>
            {
                size !== '' && (
                    size === null ? (
                        <Popover placement="right" isOpen={true} target="campaign-creator-container">
                            <div className="audience-size-container">
                                <div className="info p-2" style={{ width: "220px", height: "135px" }}>
                                    <div className="icon" style={{
                                        marginBottom: '5px'
                                    }}>
                                        <center> <img width="50px" height="50px" src={Warning} alt="warning" /> </center>{/* Change this */}
                                    </div>
                                    <span style={{
                                        textAlign: 'center'
                                    }}>
                                        We were unable to get estimates for selected parameters. Fill all the fields marked with * to get an estimate
                                </span>
                                </div>
                            </div>
                        </Popover>
                    ) : (
                            <Popover placement="right" isOpen={true} target="campaign-creator-container">
                                <div className="audience-size-container">
                                    <div className="info p-2" style={{ width: "220px", height: "135px" }}>
                                        <div className="icon" style={{
                                            marginBottom: '5px'
                                        }}>
                                            <center><img src={Users} width="50px" height="50px" alt="users" /></center> {/* Change this */}
                                        </div>
                                        <p className="heading" style={{
                                            textAlign: 'center',
                                            marginBottom: '5px'
                                        }}>
                                            Potential reach:
                                                </p>
                                        <p className="users" style={{
                                            textAlign: 'center',
                                            fontSize: '2rem',
                                            fontWeight: 'bolder'
                                        }}>
                                            {size}
                                        </p>
                                    </div>
                                </div>
                            </Popover>
                        )
                )
            }
        </>
    )
}

export default PopoverComponent;
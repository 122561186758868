import database from '../../firebase/firebase';

// GET_GRAPHICS
export const setGraphics = (data = {}) => ({
    type: 'GET_GRAPHICS',
    data
});

export const startSetGraphics = (campaign) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        return database.ref(`/users/${clientID}/graphics-data`).on('value', (snapshot) => {
            const data = snapshot.val();
            // console.log(data)

            dispatch(setGraphics(data));
        })
    }
}
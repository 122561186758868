import Page from '../components/page/Page';
import ShowQueries from '../components/queries/Queries.component';
import React from 'react';

const SupportPage = () => {
    return(
        <Page  title='Help & Support' breadcrumbs={[{name: 'Support', active: true }]}>
            <ShowQueries />
        </Page>
    )
}

export default SupportPage;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchInventoryStart, fetchInventoryStatisticsStart } from '../../redux/inventory/inventory.actions';
import { getCampaigns } from '../../redux/campaigns/campaigns.actions';

import Page from '../../components/page/Page';
import './inventory-status-page.styles.scss';
import InventoryOverviewContainer from '../../components/inventory-overview/inventory-overview.container';

const InventoryStatusPage = ({ fetchCampaignsStart, fetchInventoryStart, fetchInventoryStatisticsStart }) => {
    useEffect(() => {
        fetchCampaignsStart();
        fetchInventoryStart();
        fetchInventoryStatisticsStart();
    }, [fetchCampaignsStart, fetchInventoryStart, fetchInventoryStatisticsStart]);

    return (
        <Page
            title="Inventory Status"
            breadcrumbs={[
                { name: `Inventory Status`, active: true },
            ]}
        >
            <InventoryOverviewContainer />
        </Page>
    );
};

const mapDispatchToProps = dispatch => ({
    fetchCampaignsStart: () => dispatch(getCampaigns()),
    fetchInventoryStart: () => dispatch(fetchInventoryStart()),
    fetchInventoryStatisticsStart: () => dispatch(fetchInventoryStatisticsStart())
});

export default connect(undefined, mapDispatchToProps)(InventoryStatusPage);
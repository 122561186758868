import React, { useState, useRef } from 'react';
import { Table, Space, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './claims.styles.scss';
import UserModal from './userModal';
import { connect } from 'react-redux';

import { getActiveUserDetails, setActiveUser, setActiveUserDetails } from '../../redux/clientinfo/stats.actions';

const AudienceDetails = ({ audience_data, setUserData, setUser, resetActive }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [modalOpen, setOpenModal] = useState(false);
    const searchInput = useRef();

    const toggle = () => {
        setOpenModal(!modalOpen);
        resetActive();
    }

    let colsdata = {
        "Name": "Full_Name",
        "Address": "Address",
        "Delivered": "Delivered"
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const data = Object.keys(audience_data).map(datapoint => {
        const customdata = {};
        Object.values(colsdata).forEach(val => customdata[val] = audience_data[datapoint][val])
        customdata.key = datapoint;
        return customdata
    })

    let columns;

    columns = Object.keys(colsdata).map(colkey => {
        if (colkey === 'Name') {
            return ({
                title: colkey,
                dataIndex: colsdata[colkey],
                key: colsdata[colkey],
                ...getColumnSearchProps('Full_Name')
            })
        }
        if (colkey === 'Address') {
            return ({
                title: colkey,
                dataIndex: colsdata[colkey],
                key: colsdata[colkey],
                ...getColumnSearchProps('Address')
            })
        }
        return ({
            title: colkey,
            dataIndex: colsdata[colkey],
            key: colsdata[colkey]
        })
    })

    const handleShowDetails = (record) => {
        setUser(record.key);
        setOpenModal(true);
        setUserData(audience_data[record.key]['fbid']);
    }

    columns.push({
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <Space size="middle">
                <a
                    style={{
                        color: '#5b5bf0',
                    }}
                    onClick={() => handleShowDetails(record)}
                >
                    View Details
                </a>
            </Space>
        ),
    })

    return (
        <>
            <Table bordered columns={columns} dataSource={data} />
            <UserModal isModalOpen={modalOpen} toggle={toggle} />
        </>
        // <h1>Nice</h1>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (id) => dispatch(setActiveUser(id)),
    setUserData: (fbid) => dispatch(getActiveUserDetails(fbid)),
    resetActive: () => dispatch(setActiveUserDetails({}))
})

export default connect(null, mapDispatchToProps)(AudienceDetails);
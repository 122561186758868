import React, { useState } from 'react';
import {
    Modal,
    ModalBody, ModalHeader, ModalFooter,
    Button,
    Form, FormGroup, Label, Input,
    Spinner,
    FormFeedback
} from 'reactstrap';

import { useSelector } from 'react-redux';

import CLOUD_FUNCTIONS_URLS from '../../firebase/cf';
import { firebase } from '../../firebase/firebase';

const ModalComponent = ({ visible, setVisible, title, integration, userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const token = useSelector(state => state.firebase.auth_token)

    let label;

    switch (integration) {
        case 'facebook-page':
            label = 'Please enter your Facebook Page ID';
            break;
        case 'facebook-ads-account':
            label = 'Please enter your Facebook Ads account ID';
            break;
        case 'agency':
            label = "Please enter Email of your Agency Co-ordinator";
            break;
        default:
            label = 'Please fill the input'
    }

    const handleOk = () => {
        setIsLoading(true);
        // const integrationsRef = firebase.database().ref(`users/${userId}/integrations`);

        switch (integration) {
            case 'facebook-page':
                fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnPageConnectionRequest}?userId=${userId}&value=${value}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(res => res.json())
                    .then(res => {
                        // console.log(res.error);
                        if (res.error) {
                            setError(res.error.message);
                            setIsLoading(false);
                            // setErrorModalIsOpen(true);
                        }
                        else {
                            setIsLoading(false);
                            setVisible(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoading(false);
                        setVisible(false);
                    })
                break;
            case 'facebook-ads-account':
                fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnAdsAccountConnectionRequest}?userId=${userId}&value=${value}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(res => res.json())
                    .then(res => {
                        // console.log(res.error);
                        if (res.error) {
                            setError(res.error.message);
                            setIsLoading(false);
                            // setErrorModalIsOpen(true);
                        }
                        else {
                            setIsLoading(false);
                            setVisible(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoading(false);
                        setVisible(false);
                    });
                break;
                case 'agency':
                    // console.log(userId);
                    fetch(CLOUD_FUNCTIONS_URLS.dbEmailNotificationsOnEmailNotification, {
                        // Adding method type 
                        method: "POST",

                        // Adding body or contents to send 
                        body: JSON.stringify({
                            to: value,
                            subject: 'Freestand',
                            body: 'Trial'
                        }),

                        // Adding headers to the request 
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        console.log(res);
                        if(res.status === 200) {
                            const agencyRef = firebase.database().ref(`users/${userId}/integrations/agency`);
                            agencyRef.set({
                                data: value,
                                status: 'SENT'
                            });
                            setIsLoading(false);
                            setValue('');
                            setVisible(false);
                        }
                        else {
                            setError('Please check that the email is valid');
                            setIsLoading(false);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    break;
            default:
                console.log('Something bad happened');
        }
    };

    const handleCancel = () => {
        setValue('');
        setIsLoading(false);
        setVisible(false);
    };

    let type = "text";
    if(integration === 'agency') {
        type = "email"
    }

    return (
        <div>
            <Modal isOpen={visible}>
                <ModalHeader toggle={() => {
                    setVisible(false);
                }}>{title}</ModalHeader>
                <ModalBody style={{
                    padding: '50px 30px'
                }}>
                    <Form>
                        <FormGroup>
                            <Label style={{
                                marginBottom: '20px'
                            }}>{label}</Label>
                            <Input type={type} value={value} onChange={e => setValue(e.target.value)} placeholder="Enter here" invalid={error && value.length !== 0 ? true : false} />
                            {
                                error && <FormFeedback>{error}</FormFeedback>
                            }
                        </FormGroup>
                        {/* <FormGroup check>
                        <Label check>
                            <Input type="checkbox" />{' '}
                            Check me out
                        </Label>
                    </FormGroup> */}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleOk} style={{
                        padding: '0.7em 2.1em'
                    }}>
                        {
                            isLoading ? <Spinner id='spinner' /> : <span>Connect</span>
                        }
                    </Button>
                    <Button outline color="primary" onClick={handleCancel} style={{
                        padding: '0.7em 2.1em'
                    }}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalComponent;
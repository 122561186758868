import React from 'react';
import InnerSlider from './InnerSlider';
import SlideWrapper from './SlideWrapper';
import RangeSelector from './RangeSelector';
import { connect } from 'react-redux';

import { setSize } from '../../../../redux/cac/marketing/marketing.actions';
import { setAudienceConnect } from '../../../../redux/cac/marketing/marketing.actions';
import QuestionWithOptions from './QuestionWithOptions';
import { predefinedData } from '../predefinedData';
import NumberOfSamplesQuestion from '../CustomSlides/NumberOfSamplesQuestion';

const InfluencerDrivenSlides = (props) => {
    const {
        setSize,
        setAudienceConnect
    } = props;

    const {
        Size,
        AudienceConnect,
    } = props.marketing;

    return (
        <InnerSlider>
            {/* Slide 1 */}
            <SlideWrapper
                heading='Target Audience'
                description="Since you selected influencers driven campaign"
            >
                {/* <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_hbqhhR.json" background="#755BAA" speed="1" style={{
                                width: '250px',
                                height: '250px',
                                margin: '0 auto'
                            }} loop autoplay></lottie-player> */}
                <p style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '20px',
                    alignItems: 'center',
                    width: '90%',
                    margin: '0 auto'
                }}>Since you selected influencer driven sampling module, the audience of the influencer will be your target audience.</p>
            </SlideWrapper>

            {/* Slide 2 */}
            <SlideWrapper>
                {/* Audience size */}
                <RangeSelector
                    heading="Size"
                    description="Mention the size of your audience (in thousands)"
                    sendValueToStore={setSize}
                    storeVariable={Size}
                />

                <NumberOfSamplesQuestion />

                {/* Audience connection */}
                <QuestionWithOptions
                    heading="Audience interaction"
                    description="How would you like to connect with your audience?"
                    options={predefinedData.optionButtonsAudienceConnect}
                    sendValueToStore={setAudienceConnect}
                    icons={false}
                    storeVariable={AudienceConnect}
                    // size="small"
                />
            </SlideWrapper>
        </InnerSlider>
    )
}

const mapStateToProps = (state) => ({
    marketing: state.cac.marketing
});

const mapDispatchToProps = (dispatch) => ({
    setSize: (size) => dispatch(setSize(size)),
    setAudienceConnect: (audienceConnect) => dispatch(setAudienceConnect(audienceConnect))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerDrivenSlides);
export const setCities = (cities) => {
    return {
        type: 'SET_CITIES',
        payload: cities
    }
}

export const setAgeRange = (ageRange) => {
    return {
        type: 'SET_AGE_RANGE',
        payload: ageRange
    }
}

export const setGender = (gender) => {
    return {
        type: 'SET_GENDER',
        payload: gender
    }
}

export const setSize = (size) => {
    return {
        type: 'SET_SIZE',
        payload: size
    }
}

export const setAudienceReachOptions = (audienceReachOptions) => {
    return {
        type: 'SET_AUDIENCE_REACH_OPTIONS',
        payload: audienceReachOptions
    }
}

export const setMoreInfo = (moreInfo) => {
    return {
        type: 'MORE_INFO',
        payload: moreInfo
    }
}

export const setAudienceConnect = (audienceConnect) => {
    return {
        type: 'SET_AUDIENCE_CONNECT',
        payload: audienceConnect
    }
}

export const setFSAudience = (FSAudience) => {
    return {
        type: 'SET_FS_AUDIENCE',
        payload: FSAudience
    }
}

export const setSelectedNumberOfSamples = (selectedNumberOfSamples) => {
    return {
        type: 'SET_SELECTED_NUMBER_OF_SAMPLES',
        payload: selectedNumberOfSamples
    }
}

export const setCustomNumberOfSamples = (customNumberOfSamples) => {
    return {
        type: 'SET_CUSTOM_NUMBER_OF_SAMPLES',
        payload: customNumberOfSamples
    }
}

export const getMarketing = (data) => {
    return {
        type: 'GET_MARKETING',
        payload: data
    }
}

export const resetMarketingData = () => {
    return {
        type: 'RESET_MARKETING_DATA',
        payload: null
    }
}
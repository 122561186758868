import inventoryActionTypes from './inventory.types';

const INITIAL_STATE = {
    stats: null,
    status: null,
    isFetchingInventory: false,
    isFetchingInventoryStatistics: false,
    isSchedulingInventory: false,
    isInventoryScheduled: false,
    errorMessage: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case inventoryActionTypes.FETCH_INVENTORY_START:
            return {
                ...state,
                isFetchingInventory: true
            }
        case inventoryActionTypes.INVENTORY_SCHEDULE_START:
            return {
                ...state,
                isSchedulingInventory: true
            }
        case inventoryActionTypes.FETCH_INVENTORY_STATISTICS_START:
            return {
                ...state,
                isFetchingInventoryStatistics: true,
            }

        case inventoryActionTypes.FETCH_INVENTORY_SUCCESS:
            return {
                ...state,
                isFetchingInventory: false,
                status: action.payload
            }

        case inventoryActionTypes.FETCH_INVENTORY_STATISTICS_SUCCESS:
            return {
                ...state,
                isFetchingInventoryStatistics: false,
                stats: action.payload
            }

        case inventoryActionTypes.INVENTORY_SCHEDULE_SUCCESS:
            return {
                ...state,
                isSchedulingInventory: false,
                isInventoryScheduled: true
            }

        case inventoryActionTypes.FETCH_INVENTORY_FAILURE:
            return {
                ...state,
                isFetchingInventory: false,
                error: action.payload
            }

        case inventoryActionTypes.FETCH_INVENTORY_STATISTICS_FAILURE:
            return {
                ...state,
                isFetchingInventoryStatistics: false,
                error: action.payload
            }
            
        case inventoryActionTypes.INVENTORY_SCHEDULE_FAILURE:
            return {
                ...state,
                isSchedulingInventory: false,
                isInventoryScheduled: false,
                error: action.payload
            }

        default:
            return state;
    }
}
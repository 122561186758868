import React, { useEffect } from 'react'
import { useState } from 'react'

const RenderOptionsInRange = ({ from, to }) => {
    return (
        [...Array(to - from + 1).keys()]
            .map(number => number + from)
            .map((age, idx) => <option key={idx} value={age}>{age}</option>
            )
    )
}

const AgeQuestion = ({ heading, description, required, sendValueToStore, storeVariable }) => {
    const [ageRange, setAgeRange] = useState({
        from: "",
        to: ""
    });

    useEffect(() => {
        sendValueToStore(ageRange);
    }, [ageRange]);

    useEffect(() => {
        if(storeVariable) {
            setAgeRange(storeVariable);
        }
    }, [storeVariable]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (value === '65+') {
            setAgeRange({
                from: '65+',
                to: '65+'
            })
        }
        else {
            setAgeRange({
                ...ageRange,
                [name]: value
            });
        }
    }

    return (
        <div className="question-container">
            <h3 className="question-heading">{heading}
                {
                    required && <span className="red-star">*</span>
                }
            </h3>
            <p className="question-description">{description}</p>
            <div className="age-question-component">
                <select value={ageRange.from || '-1'} className="age-dropdown" name="from" id="age-from" onChange={handleChange}>
                    <option value="-1" disabled>From</option>
                    {
                        <RenderOptionsInRange from={18} to={64} />
                    }
                    <option value="65+">65+</option>
                </select>
                <span className="hyphen">-</span>
                <select value={ageRange.to || '-1'} className="age-dropdown" name="to" id="age-to" style={{ float: 'none' }} onChange={handleChange}>
                    <option value="-1" disabled>To</option>
                    {
                        ageRange.from === '65+' ? (
                            null
                        ) : (
                            // If ageRange's from is defined, use that otherwise use 18. OR operator does that
                            <RenderOptionsInRange from={Number(ageRange.from) || 18} to={64} />
                        )
                    }
                    <option value="65+">65+</option>
                </select>
            </div>
        </div>
    )
}

export default AgeQuestion;
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectCampaignList, selectInhouseManagedCampaignList } from '../../redux/campaigns/campaigns.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import InventoryScheduler from './inventory-scheduler.component';

const mapStateToProps = createStructuredSelector({
    // inHouseManageableCampaignList: selectInhouseManagedCampaignList,
    CampaignList: selectCampaignList,
    isLoading: state => !selectCampaignList(state)
});

const InventorySchedulerContainer = compose(
    connect(mapStateToProps),
    WithSpinner
)(InventoryScheduler);

export default InventorySchedulerContainer;
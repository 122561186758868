export const addGeoLocationHelper = (type, id, state) => {
    let newObject = undefined;

    switch (type) {
        case 'country':
            newObject = id
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        countries: [
                            ...(state.targeting_specs.geo_locations.countries || []),
                            newObject
                        ]
                    }
                }
            }

        case 'city':
            newObject = {
                key: id,
                radius: 10,
                distance_unit: 'mile'
            }
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        cities: [
                            ...(state.targeting_specs.geo_locations.cities || []),
                            newObject
                        ]
                    }
                }
            }

        case 'region':
            newObject = {
                key: id,
            }
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        regions: [
                            ...(state.targeting_specs.geo_locations.regions || []),
                            newObject
                        ]
                    }
                }
            }

        case 'zip':
            newObject = {
                key: id,
            }
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        zips: [
                            ...(state.targeting_specs.geo_locations.zips || []),
                            newObject
                        ]
                    }
                }
            }
    }
}
export default {
    FETCH_INVENTORY_START: 'FETCH_INVENTORY_START',
    FETCH_INVENTORY_SUCCESS: 'FETCH_INVENTORY_SUCCESS',
    FETCH_INVENTORY_FAILURE: 'FETCH_INVENTORY_FAILURE',
    FETCH_INVENTORY_STATISTICS_START: 'FETCH_INVENTORY_STATISTICS_START',
    FETCH_INVENTORY_STATISTICS_SUCCESS: 'FETCH_INVENTORY_STATISTICS_SUCCESS',
    FETCH_INVENTORY_STATISTICS_FAILURE: 'FETCH_INVENTORY_STATISTICS_FAILURE',
    INVENTORY_SCHEDULE_START: 'INVENTORY_SCHEDULE_START',
    INVENTORY_SCHEDULE_SUCCESS: 'INVENTORY_SCHEDULE_SUCCESS',
    INVENTORY_SCHEDULE_FAILURE: 'INVENTORY_SCHEDULE_FAILURE'
};
import React, { useState, useEffect } from "react";
import { predefinedData } from "../predefinedData";
import InnerSlider from "../PredefinedSlides/InnerSlider";
import { connect } from 'react-redux';
import QuestionWithOptions from "../PredefinedSlides/QuestionWithOptions";
import SlideWrapper from "../PredefinedSlides/SlideWrapper";
import { setPrimaryPackaging } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setPromotionalContent } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setPackAndDelivery } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setAdsDesign } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setInteractionDesign } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setLaunch } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setPromotionalContentDesigner } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setOtherPromotionalContent } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { setPackageInhouseManageableCities } from '../../../../redux/cac/responsibilities/responsibilities.actions';
import { Input, Select } from 'antd';
import TransitionComponentWrapper from "../components/TransitionComponentWrapper";
import CitySelect from "../PredefinedSlides/CitySelect";

const { Option } = Select;

const Responsibilities = (props) => {
    const {
        PrimaryPackaging,
        PromotionalContent,
        OtherPromotionalContent,
        PromotionalContentDesigner,
        PackAndDelivery,
        AdsDesign,
        InteractionDesign,
        Launch,
        PackageInhouseManageableCities
    } = props.responsibilities;

    const {
        setPrimaryPackaging,
        setPromotionalContent,
        setPackAndDelivery,
        setAdsDesign,
        setInteractionDesign,
        setLaunch,
        setPromotionalContentDesigner,
        setOtherPromotionalContent,
        cities,
        setPackageInhouseManageableCities,
    } = props;

    const handleDesignerChange = (value) => {
        setPromotionalContentDesigner(value);
    }

    const handlePromotionalContent = (event) => {
        setOtherPromotionalContent(event.target.value);
    }

    return (
        <InnerSlider>
            {/* Primary packaging and promotional content */}
            <SlideWrapper>
                <QuestionWithOptions
                    heading="Primary packaging for your sample"
                    description="How would you like your product to be received by your consumers?"
                    options={predefinedData.optionButtonsPrimaryPackaging}
                    sendValueToStore={setPrimaryPackaging}
                    icons={false}
                    storeVariable={PrimaryPackaging}
                />
                <QuestionWithOptions
                    heading="Promotional Content"
                    description="Would you like to insert any promotional content along with your product sample?"
                    options={predefinedData.optionButtonsPromotionalContent}
                    sendValueToStore={setPromotionalContent}
                    icons={false}
                    storeVariable={PromotionalContent}
                />
                <TransitionComponentWrapper conditionField={PromotionalContent} conditionValue='Other'>
                    <SlideWrapper
                        heading='Please specify'
                        description="Please specify what type of promotional content you want to include"
                    >
                        <Input.Group compact>
                            <Select value={PromotionalContentDesigner || "-1"} dropdownMatchSelectWidth={false} onChange={handleDesignerChange}>
                                <Option value="-1" disabled>Designed by</Option>
                                <Option value="Freestand">Freestand</Option>
                                <Option value="Partner agency">Partner agency</Option>
                                <Option value="In-house">In-house</Option>
                            </Select>
                            <Input
                                style={{
                                    width: '50%'
                                }}
                                value={OtherPromotionalContent}
                                onChange={handlePromotionalContent}
                            />
                        </Input.Group>
                    </SlideWrapper>
                </TransitionComponentWrapper>
            </SlideWrapper>

            {/* Logistics Responsibilities */}
            <SlideWrapper
                heading='Responsibilities'
                description='Please assign responsibilities'
            >
                <QuestionWithOptions
                    heading="Packaging and delivery"
                    description="Packaging Assembly, Warehousing and Dispatch of orders"
                    options={predefinedData.optionButtonsPackAndDelivery}
                    sendValueToStore={setPackAndDelivery}
                    icons={false}
                    storeVariable={PackAndDelivery}
                />
                <TransitionComponentWrapper conditionField={PackAndDelivery} conditionValue='In-house'>
                    <CitySelect
                        heading="Select cities you will be able to manage"
                        description="Select cities you will be able to manage"
                        options={cities}
                        placeholder="Select cities"
                        sendValueToStore={setPackageInhouseManageableCities}
                        allowedSelectAll
                        storeVariable={PackageInhouseManageableCities}
                    />
                </TransitionComponentWrapper>
                <QuestionWithOptions
                    heading="Ad Design"
                    description="We understand some brands have partner agencies who are taking care of their campaigns. Our Campaign requires very specific creatives. Who would you like to give this responsibility to?"
                    options={predefinedData.optionButtonsCreatives}
                    sendValueToStore={setAdsDesign}
                    icons={false}
                    storeVariable={AdsDesign}
                />
                <QuestionWithOptions
                    heading="Chatbot/Webapp interaction design"
                    description="There is a well defined format/template using which a brand can make the best out of their interaction with the consumers. Who would you like to design the chat-flow/webapp?"
                    options={predefinedData.optionButtonsChat}
                    sendValueToStore={setInteractionDesign}
                    icons={false}
                    storeVariable={InteractionDesign}
                />
            </SlideWrapper>

            {/* Launch */}
            <QuestionWithOptions
                heading="Campaign launch"
                description="When are you expecting to launch this campaign?"
                options={predefinedData.optionButtonsCampaignLaunch}
                sendValueToStore={setLaunch}
                icons={false}
                storeVariable={Launch}
                date
            />
        </InnerSlider>
    );
};

const mapStateToProps = (state) => ({
    responsibilities: state.cac.responsibilities,
    cities: state.cac.marketing.Cities,
});

const mapDispatchToProps = (dispatch) => ({
    setPrimaryPackaging: (primaryPackaging) => dispatch(setPrimaryPackaging(primaryPackaging)),
    setPromotionalContent: (promotionalContent) => dispatch(setPromotionalContent(promotionalContent)),
    setPackAndDelivery: (packAndDelivery) => dispatch(setPackAndDelivery(packAndDelivery)),
    setAdsDesign: (adsDesign) => dispatch(setAdsDesign(adsDesign)),
    setInteractionDesign: (interactionDesign) => dispatch(setInteractionDesign(interactionDesign)),
    setLaunch: (launch) => dispatch(setLaunch(launch)),
    setPromotionalContentDesigner: (promotionalContentDesigner) => dispatch(setPromotionalContentDesigner(promotionalContentDesigner)),
    setOtherPromotionalContent: (otherPromotionalContent) => dispatch(setOtherPromotionalContent(otherPromotionalContent)),
    setPackageInhouseManageableCities: (packageInhouseManageableCities) => dispatch(setPackageInhouseManageableCities(packageInhouseManageableCities))
});

export default connect(mapStateToProps, mapDispatchToProps)(Responsibilities);
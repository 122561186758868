import React, { useState, useEffect, useRef } from 'react';

import { getSKUConfig, setSKUConfig } from '../../../redux/settings-page/settings.actions';
import { connect } from 'react-redux';

import EditableTable from '../editable-table';

import { getActiveCampaigns } from '../../../redux/campaigns/campaigns.actions';

import { Select } from 'antd';
import { Button } from 'antd';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../../utils/constants';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineWarning } from 'react-icons/ai';

const { Option } = Select;

const SKUConfig = ({ skuConfig, getSKUConfig, getActiveCampaigns, activeCampaigns, setSKUConfig }) => {
    const [campaign, setCampaign] = useState('');
    const notificationSystem = useRef();

    useEffect(() => {
        getActiveCampaigns();
    }, [getActiveCampaigns]);

    const handleCampaignSelection = (value) => {
        setCampaign(value);
    }

    const fetchConfig = () => {
        // console.log(campaign);
        getSKUConfig(campaign);
    }

    const handlePublish = (data) => {
        // console.log(data);
        if (campaign.length === 0) {
            if (!notificationSystem) {
                return;
            }
            notificationSystem.current.addNotification({
                title: <AiOutlineWarning />,
                message: 'Select all parameters before publishing',
                level: 'info',
            });
        }
        else {
            setSKUConfig(campaign, data, (status) => {
                if (status) {
                    if (!notificationSystem) {
                        return;
                    }
                    notificationSystem.current.addNotification({
                        title: <FiCheck />,
                        message: 'SKU config is published',
                        level: 'info',
                    });
                }
                else {
                    notificationSystem.current.addNotification({
                        title: <AiOutlineWarning />,
                        message: 'SKU config could not be saved',
                        level: 'info',
                    });
                }
            });
        }
    }

    return (
        <>
            <Select defaultValue="Select a campaign" onChange={handleCampaignSelection}>
                <Option value="Select a campaign" disabled>Select a campaign</Option>
                {activeCampaigns && activeCampaigns.map((campaign, idx) => (
                    <Option key={idx} value={campaign}>{campaign}</Option>
                ))}
            </Select>
            <Button onClick={fetchConfig} style={{ marginLeft: '15px', marginBottom: '20px' }}>Fetch</Button>
            { skuConfig ? <EditableTable data={skuConfig} onPublish={handlePublish} /> /* <SetConfigComponent configData={config} bothEditable={true} addable={true} onSave={handleSave} /> */ : <p style={{ marginTop: '15px' }}>Data does not exist</p>}
            {/* <Button size='large' style={{ marginTop: '15px', display: 'block' }} onClick={handleAdd}>Add new</Button> */}
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem}
                style={NOTIFICATION_SYSTEM_STYLE}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    skuConfig: state.settings.sku_config,
    activeCampaigns: state.campaigns.activeCampaigns
});

const mapDispatchToProps = (dispatch) => ({
    getSKUConfig: (campaign) => dispatch(getSKUConfig(campaign)),
    setSKUConfig: (campaign, data, callback) => dispatch(setSKUConfig(campaign, data, callback)),
    getActiveCampaigns: () => dispatch(getActiveCampaigns())
});

export default connect(mapStateToProps, mapDispatchToProps)(SKUConfig);
import { combineReducers } from 'redux';

import campaignDetailsReducer from './campaignDetails/campaignDetails.reducer';
import productDetailsReducer from './productDetails/productDetails.reducer';
import marketingReducer from './marketing/marketing.reducer';
import responsibilitiesReducer from './responsibilities/responsibilities.reducer';
import socialMediaTargetingReducer from './socialMediaTargeting/socialMediaTargeting.reducers';
import globalCacStatesReducer from './globalCacStates/globalCacStates.reducers';
import remoteReducer from './remote/remote.reducers';

const cacRootreducer = combineReducers({
    campaignDetails: campaignDetailsReducer,
    productDetails: productDetailsReducer,
    marketing: marketingReducer,
    responsibilities: responsibilitiesReducer,
    socialMediaTargeting: socialMediaTargetingReducer,
    globalCacStates: globalCacStatesReducer,
    remote: remoteReducer
});

export default cacRootreducer;
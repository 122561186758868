import React from 'react';
import { Modal, Button, Input, InputGroup, Label, FormGroup, InputGroupAddon, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SKUSelect from '../tree-select/TreeSelect.component';


const ModalComponent = (props) => {
    return (
        
        <Modal
            isOpen={props.modalIsOpen}
            backdrop={true}
            toggle={props.toggle}
            centered
        >
            <ModalHeader >SKU Details </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="name">Name of the SKU</Label>
                    <Input type="text" id="name" placeholder="Name" />
                </FormGroup>
                <FormGroup>
                  <Label for="category">Category of the SKU</Label>
                  {/* <Input type="text" id="category" placeholder="Category" /> */}
                  <SKUSelect skuCategory={props.skuCategory} setSkuCategory={props.setSkuCategory} />
                </FormGroup>
                <FormGroup>
                    <Label for="weight">Weight of the SKU</Label>
                    <InputGroup>
                        <Input type="number" id="weight" placeholder="Weight" />
                        <InputGroupAddon addonType="append">
                            <Input type="select" id="weightMetric">
                                <option>grams</option>
                                <option>ounces</option>
                                <option>litres</option>

                            </Input>
                        </InputGroupAddon>

                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="dimensions">Dimensions of the SKU</Label>
                    <InputGroup>
                        <Input type="number" id="length" placeholder="Length" />
                        <Input type="number" id="width" placeholder="Width" />
                        <Input type="number" id="height" placeholder="Height" />
                        <InputGroupAddon addonType="append">

                            <Input type="select" id="dimensionMetric">
                                <option>cm</option>
                                <option>mm</option>
                                <option>in</option>

                            </Input>
                        </InputGroupAddon>

                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="shelfLife">Shelf Life of the SKU</Label>
                    <InputGroup>
                        <Input type="number" id="shelfLife" placeholder="Shelf Life" />
                        <InputGroupAddon addonType="append">

                            <Input type="select" id="shelfLifeMetric">
                                <option>Weeks</option>
                                <option>Months</option>
                                <option>Years</option>

                            </Input>
                        </InputGroupAddon>

                    </InputGroup>
                </FormGroup>

            </ModalBody>
            <ModalFooter>
                
                <Button /* id="save-btn" */ color="success" onClick={props.handleadd}>
                    Save
                </Button>
                <Button /* id="cancel-btn" */ color="secondary" onClick={() => props.handleCancel()}>
                    Cancel
                </Button>
                
            </ModalFooter>
        </Modal>
        
    )
}

export default ModalComponent;
import React, { useState, useEffect } from 'react';
import AddSkuModal from '../../../add-sku-modal/addSkuModal';
import CustomQuestion from '../PredefinedSlides/SlideWrapper';
import { AiOutlineSelect } from 'react-icons/ai'
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { startGetSku } from '../../../../redux/sku/sku.actions';
import { setSelectedSKU } from '../../../../redux/cac/productDetails/productDetails.actions';
import { addSku } from '../../../../redux/sku/sku.actions';

const SkuSlideComponent = ({ uid, startGetSkus, skus, selectedSKU, setSelectedSKU, addSkus }) => {
    const [modalIsOpen, setmodalIsOpen] = useState(false)
    const [skuCategory, setSkuCategory] = useState(undefined);
    const [skuState, setSkuState] = useState('-1');

    const toggle = () => {
        setmodalIsOpen(false);
    }

    const handleCancel = () => {
        setmodalIsOpen(false);
        setSkuCategory(undefined);
    }

    const handleChange = (event) => {
        const { value } = event.target;

        const selection = {
            name: value,
            ...skus[value]
        }

        setSelectedSKU(selection);
    }

    useEffect(() => {
        startGetSkus(uid);
    }, [startGetSkus]);

    useEffect(() => {
        if(selectedSKU) {
            setSkuState(selectedSKU.name);
        }
    }, [selectedSKU]);

    const handleadd = () => {
        const name = document.getElementById('name').value
        const category = skuCategory
        const weight = document.getElementById('weight').value
        const weightMetric = document.getElementById('weightMetric').value
        const length = document.getElementById('length').value
        const width = document.getElementById('width').value
        const height = document.getElementById('height').value
        const dimensionMetric = document.getElementById('dimensionMetric').value
        const shelfLife = document.getElementById('shelfLife').value
        const shelfLifeMetric = document.getElementById('shelfLifeMetric').value

        const key = name || "null";

        const payload = {
            category: category,
            weight: weight + " " + weightMetric,
            dimension: length + 'X' + width + 'X' + height + " " + dimensionMetric,
            shelfLife: shelfLife + " " + shelfLifeMetric
        }

        if (key === "null" || key === "undefined") {
            alert("Name cannot be empty");
        } else {
            toggle();
            addSkus(uid, payload, key);
            setSkuCategory(undefined);
        }
    }

    return (
        <CustomQuestion
            heading="Select SKU"
            description="Please select an SKU for which you are creating a campaign"
        >
            <div className="sku-slide-container">
                <select name="sku" id="sku-dropdown" value={skuState} onChange={handleChange}>
                    <option value="-1" disabled>Select an SKU</option>
                    {
                        skus && Object.entries(skus).map((eachSku) => {
                            return (
                                <option key={eachSku[0]} value={eachSku[0]}>{eachSku[0]}</option>
                            )
                        })
                    }
                </select>

                <button onClick={() => setmodalIsOpen(true)} className="add-btn">Add an SKU</button>
                {/* <Button color="warning" onClick={() => setmodalIsOpen(true) } style = {{marginLeft : "auto"}}>Add New</Button> */}

                <h3 className="question-heading mt-5">Information about selected SKU</h3>
                {
                    selectedSKU ? (
                        <Table bordered style={{
                            marginTop: '25px'
                        }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Property</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Name</td>
                                    <td>{selectedSKU && selectedSKU.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Weight</td>
                                    <td>{selectedSKU && selectedSKU.payload.weight}</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Dimensions</td>
                                    <td>{selectedSKU && selectedSKU.payload.dimension}</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Shelf Life</td>
                                    <td>{selectedSKU && selectedSKU.payload.shelfLife}</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>Category</td>
                                    <td>{selectedSKU && selectedSKU.payload.category}</td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : <p style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '50px',
                        alignItems: 'center'
                    }}><AiOutlineSelect style={{
                        fontSize: '2rem',
                        marginBottom: '10px'
                    }} />Please select an SKU</p>
                }
                <AddSkuModal
                    modalIsOpen={modalIsOpen}
                    toggle={toggle}
                    handleCancel={handleCancel}
                    handleadd={handleadd}
                    setSkuCategory={setSkuCategory}
                    skuCategory={skuCategory}
                />
            </div>
        </CustomQuestion>
    )
}

const mapStateToProps = (state) => ({
    skus: state.skus.sku,
    uid: state.auth.uid,
    selectedSKU: state.cac.productDetails.SelectedSKU
});

const mapDispatchToProps = (dispatch) => {
    return {
        addSkus: (uid, payload, key) => dispatch(addSku(uid, { payload }, { key })),
        startGetSkus: (uid) => dispatch(startGetSku(uid)),
        setSelectedSKU: (sku) => dispatch(setSelectedSKU(sku))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuSlideComponent);

export const deleteInterestHelper = (id, type, state) => {
    const newArray = state.targeting_specs[type].filter(i => i.id !== id);

    return {
        ...state,
        targeting_specs: {
            ...state.targeting_specs,
            [type]: newArray
        }
    }
}

import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AllGraphics from './components/all-graphics/all-graphics-component';
import GraphicDetail from './components/graphic-details/graphic-details-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import { startSetGraphics } from '../../../redux/clientinfo/graphics.actions';

import { connect } from 'react-redux';

const App = ({uid, graphics, startSetGraphics}) => {
  useEffect(() => {
    startSetGraphics();
  }, []);

  const location = useRouteMatch();
  console.log(location);

  // handleFilter = (event) => {
  //   this.setState({
  //     ...this.state,
  //     filter: event.target.value
  //   });
  // }

  return (
    <div>
    <Switch>
      <Route exact path={`${location.path}`} component={AllGraphics} />

      {/* <Route exact path={`${this.props.match.path}/upload`} component={UploadGraphic} /> */}

      <Route exact path={`${location.path}/graphicDetail/:graphicId/version/:versionId`} component={GraphicDetail} />
    </Switch>
  </div>
  );
}

const mapStateToProps = (state) => ({
  uid: state.auth.uid,
  graphics: state.graphics.graphics
})

const mapDispatchToProps = (dispatch) => ({
  startSetGraphics: () => dispatch(startSetGraphics())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth'; 


let firebaseConfig = require('../firebase-config.json').result.sdkConfig;


// Fixing my f'up here, changing default database url
firebaseConfig.databaseURL = firebaseConfig.databaseURL.replace('https://freestand-backend-dev-default-rtdb.europe-west1.firebasedatabase.app', 'https://freestand-backend-dev.firebaseio.com')

firebase.initializeApp(firebaseConfig);

var secondaryAppConfig = {
    apiKey: "AIzaSyCU2VYTHq4cI9xKCEcCWXnFuuRNfEYoMPs",
    authDomain: "freestand-claims.firebaseapp.com",
    databaseURL: "https://freestand-claims.firebaseio.com",
    projectId: "freestand-claims",
    storageBucket: "freestand-claims.appspot.com",
    messagingSenderId: "536350411266",
    appId: "1:536350411266:web:72069743432bd21dfaf51b",
    measurementId: "G-MN0CJJ57YN"
};

var secondaryApp = firebase.initializeApp(secondaryAppConfig, "secondary");

const database = firebase.database()
const storage = firebase.storage();

// firebase.auth().useEmulator("http://localhost:9099");
// database.useEmulator('localhost', 9000);

export { firebase, storage, secondaryApp, database as default };
import React, { useEffect } from 'react';
import {Modal, ModalFooter, ModalBody, Button, Row, Col, Alert} from 'reactstrap';
import { getActiveCampaigns } from '../../redux/campaigns/campaigns.actions';
import { setSocialConfigs } from '../../redux/clientinfo/stats.actions';
import { connect } from 'react-redux';
import './custom-modal.styles.scss';

const CustomLottie = ({success}) => {

    const lottie_success = "https://assets7.lottiefiles.com/datafiles/s2s8nJzgDOVLOcz/data.json";
    const lottie_fail = "https://assets1.lottiefiles.com/packages/lf20_tk68gi62.json";

    return (
        <lottie-player 
            src={success?lottie_success:lottie_fail}  
            background="transparent"  speed="1"  
            style={{width: "300px", height: "300px", margin: 'auto auto'}} 
            autoplay 
        />
    )
}

const CustomModal = ({isOpen, toggle, status, changeStatus, getCamps, activeCampaigns, storeTokens}) => {

    const permissions_list = "pages_show_list,read_insights,pages_read_engagement,instagram_basic,instagram_manage_insights,pages_messaging";

    useEffect(()=>{
        getCamps()
    }, [getCamps])

    const getAccessTokens = (short_token, pages, campaign, uid) => {
        fetch(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=1275638289498645&client_secret=8b5a4679fa8b5c21875df8595f67a472&fb_exchange_token=${short_token}`)
        .then(r1 => r1.json())
        .then(response => {
            //TODO: Check if access to multiple pages has been provided
            fetch(`https://graph.facebook.com/${pages[0].id}?fields=access_token&access_token=${response.access_token}`)
            .then(r2 => r2.json())
            .then(res => {
                fetch(`https://graph.facebook.com/${pages[0].id}?fields=instagram_business_account`)
                .then(r3 => r3.json())
                .then(rr => {
                    const insta_id = (rr["instagram_business_account"] && rr["instagram_business_account"]["id"])||'';
                    storeTokens(campaign, res, uid, response.access_token, insta_id);
                    changeStatus('SUCCESS');
                })
            })
        })
    }

    const handleLogin = async (e) => {
        e.persist(); //To read more about this check out: https://reactjs.org/docs/legacy-event-pooling.html
        console.log(React.version)
        window.FB.login((response) => {
            if (response.authResponse) {
                const uid = response.authResponse.userID;
                const token = response.authResponse.accessToken;
                window.FB.api(`${uid}/accounts`, (res) => {
                    getAccessTokens(token, res.data, e.target.value, uid);
                });
            }
            else {
                changeStatus("FAIL");
            };
        }, {scope: permissions_list});
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody style={{padding: '50px 30px'}}>
            {
                status==='SUCCESS' ? (
                    <div className="connection-status">
                        <CustomLottie success={true}/>
                        <p style={{
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>The connection has been successfully established!</p>
                    </div>
                ) : status==='FAIL'?(
                    <div className="connection-status">
                        <CustomLottie success={false} />
                        <p style={{
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>Authentication failed! Please try again.</p>
                    </div>
                ) : (
                    //TODO: Add a way to know if access has been provided already.
                    //  Also, provide a way to redo it in case a problem arises.
                    activeCampaigns? (
                        <div className="connection-status">
                            {activeCampaigns.map(campaign =>
                                <Row key={campaign} className="campaign-row">
                                    <Col>{campaign}</Col>
                                    <Col className="campaign-sel">
                                        <Button color="success" size="sm" value={campaign} onClick={handleLogin}>
                                            Grant Access
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    ) : <Alert color="warning">No active campaigns found!</Alert>
                )
            }
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} style={{
                    padding: '0.7em 2.1em'
                }}>
                    Close
                </Button>
            </ModalFooter>
            </Modal>
    )
}

const mapStateToProps = (state) => ({
    activeCampaigns: state.campaigns.activeCampaigns
})

const mapDispatchToProps = (dispatch) => ({
    getCamps: () => dispatch(getActiveCampaigns()),
    storeTokens: (c,d,u,t,i) => dispatch(setSocialConfigs(c,d,u,t,i))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
import React from 'react'
// import { graphicIDs } from '../../data'
import { Nav, Card, Spinner } from 'react-bootstrap'
import { IoIosImages } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'

import './choose-graphic-style.scss'
import $ from 'jquery';

import { connect } from 'react-redux';

const ChooseGraphic = ({graphics, isSidebarOpen, handleSidebarToggle}) => {
    const handleScroll = () => {
        var scrollTop = $('.scroll-nav').scrollTop();

        if (scrollTop > 0) {
            $("#choose-heading").addClass("shadow-giving-class");
        }
        else {
            $("#choose-heading").removeClass("shadow-giving-class");
        }
    }

    return (
        <div className={`sidebar ${isSidebarOpen ? 'show' : null}`}>
            <div className="section">
                <div className="section-heading" id="choose-heading">
                    <h1><IoIosImages className="graphic" />Choose graphic</h1>
                    <AiOutlineLeft className="icon" onClick={() => {
                        handleSidebarToggle(false)
                    }}/>
                </div>
                <div className="scroll-nav" onScroll={handleScroll}>
                    <Nav defaultActiveKey="/home" className="flex-column">
                        {
                            graphics ? (
                                Object.entries(graphics).map((graphic) => {
                                    let versionArray = graphic[1].versions ? Object.entries(graphic[1].versions) : [];
                                    let versionKey = versionArray[0] ? versionArray[0][0] : null;
                                    let versionImage = versionArray[0] ? versionArray[0][1].image : null;
                                    
                                    if (versionKey) {
                                        return (
                                            <Link to={`/graphics-approval-system/graphicDetail/${graphic[0]}/version/${versionKey}`} key={graphic[0]}>
                                                <Card className="ad-card" onClick={() => handleSidebarToggle}>
                                                    <Card.Img variant="top" src={versionImage} onClick={() => handleSidebarToggle(false)}/>
                                                    <Card.Body onClick={() => handleSidebarToggle}>
                                                        <Card.Text>
                                                            {graphic[1].name}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                })
                            ) : (
                                <Spinner animation="border" style={{
                                    fontSize: '0.5rem',
                                    height: '20px',
                                    width: '20px',
                                    marginLeft: '10px'
                                }} />
                            )
                        }
                    </Nav>
                </div>
            </div>
            <div className="nav-footer" />
        </div>
    )
}

const mapStateToProps = (state) => ({
    graphics: state.graphics.graphics
});

export default connect(mapStateToProps)(ChooseGraphic);
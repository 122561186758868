import React from 'react';
import PropTypes from 'prop-types';
import { Button, ModalBody } from 'reactstrap';

import placementSuccess from '../../assets/img/placement-success.gif';

import './modal-success.styles.scss';

const ModalSuccess = ({ children, closeFunc }) => {
    return (
        <ModalBody>
            <div className="modal-success">
                <img 
                src={placementSuccess}
                alt="placement success gif"
                width="180"
                height="180"
                />
            </div>
            { children }
            <Button color="danger" outline onClick={closeFunc}>Close</Button>
        </ModalBody>
    );
};

ModalSuccess.propTypes = {
    children: PropTypes.element,
    closeFunc: PropTypes.func.isRequired
};

export default ModalSuccess;
const defaultState = {
    clientlist: {},
    uid: ''
 };
 
 export default (state = defaultState, action) => {
     switch (action.type) {
 
        case "SET_CLIENTS":
            return {
                ...state,
                clientslist: action.clientslist
            }

        case "SET_AGENCY":
            return {
                ...state,
                uid: action.uid
            }
             
         default:
             return state;
     }
 }
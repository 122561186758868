const defaultState = {
    datapoints: {},
    tilesets: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case 'SET_MAP_DATA':
        return {
            ...state,
            datapoints: action.data.datapoints || {},
            tilesets: action.data.tilesets || {}
        }

        default:
            return state;
    }
}
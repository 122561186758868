export const addGeoLocation = (object) => {
    return {
        type: 'ADD_GEO_LOCATION',
        payload: object
    }
}

export const deleteGeoLocation = (object) => {
    return {
        type: 'DELETE_GEO_LOCATION',
        payload: object
    }
}

export const changeLocationType = (locationType) => {
    return {
        type: 'CHANGE_LOCATION_TYPE',
        payload: locationType
    }
}

export const addInterest = (object) => {
    return {
        type: 'ADD_INTEREST',
        payload: object
    }
}

export const deleteInterest = (object) => {
    return {
        type: 'DELETE_INTEREST',
        payload: object
    }
}

export const setAgeMax = (ageMax) => {
    return {
        type: 'SET_AGE_MAX',
        payload: ageMax
    }
}

export const setAgeMin = (ageMin) => {
    return {
        type: 'SET_AGE_MIN',
        payload: ageMin
    }
}

export const setGender = (gender) => {
    return {
        type: 'SET_GENDER',
        payload: gender
    }
}

export const setAudienceSizeFetched = (audienceSizeFetched) => {
    return {
        type: 'SET_AUDIENCE_SIZE_FETCHED',
        payload: audienceSizeFetched
    }
}

export const setLocationsArray = (locationsArray) => {
    return {
        type: 'SET_LOCATIONS_ARRAY',
        payload: locationsArray
    }
}

export const setInterestsArray = (interestsArray) => {
    return {
        type: 'SET_INTERESTS_ARRAY',
        payload: interestsArray
    }
}

export const getSocialMediaTargeting = (data) => {
    return {
        type: 'GET_SOCIAL_MEDIA_TARGETING',
        payload: data
    }
}

export const resetSocialMediaTargeting = () => {
    return {
        type: 'RESET_SOCIAL_MEDIA_TARGETING',
        payload: null
    }
}
import { combineReducers } from 'redux';
import { LOCATIONS, INTERESTS, TARGETING_SPECS } from './remote.types';

const locationsReducer = (
    state = {
        isLocationLoading: false,
        locationsValue: '',
        locations: [],
        locationLoadError: false,
        locationLoadErrorText: ''
    },
    action
) => {
    switch (action.type) {
        case LOCATIONS.LOAD:
            return {
                ...state,
                isLocationLoading: true,
                locationsValue: action.payload,
                locationLoadErrorText: ''
            }
        case LOCATIONS.SUCCESS:
            return {
                ...state,
                isLocationLoading: false,
                locationsValue: '',
                locations: action.payload,
                locationLoadError: false,
                locationLoadErrorText: ''
            }
        case LOCATIONS.FAIL:
            return {
                ...state,
                isLocationLoading: false,
                locationsValue: '',
                locationLoadError: true,
                locationLoadErrorText: action.payload
            }
        default:
            return state;
    }

}

const interestsReducer = (
    state = {
        isInterestsLoading: false,
        interestsValue: '',
        interests: [],
        interestsLoadError: false,
        interestsLoadErrorText: ''
    },
    action
) => {
    switch (action.type) {
        case INTERESTS.LOAD:
            return {
                ...state,
                isInterestsLoading: true,
                interestsValue: action.payload,
                interestsLoadErrorText: ''
            }
        case INTERESTS.SUCCESS:
            return {
                ...state,
                isInterestsLoading: false,
                interestsValue: '',
                interests: action.payload,
                interestsLoadError: false,
                interestsLoadErrorText: ''
            }
        case INTERESTS.FAIL:
            return {
                ...state,
                isInterestsLoading: false,
                interestsValue: '',
                interestsLoadError: true,
                interestsLoadErrorText: action.payload
            }
        default:
            return state;
    }

}

const targetingSpecsReducer = (
    state = {
        isTargetingSpecsLoading: false,
        targetingSpecs: '',
        targetingSpecsError: false,
    },
    action
) => {
    switch (action.type) {
        case TARGETING_SPECS.LOAD:
            return {
                ...state,
                isTargetingSpecsLoading: true,
                targetingSpecs: action.payload
            }
        case TARGETING_SPECS.SUCCESS:
            return {
                ...state,
                isTargetingSpecsLoading: false,
                targetingSpecs: '',
                targetingSpecsError: false
            }
        case TARGETING_SPECS.FAIL:
            return {
                ...state,
                isTargetingSpecsLoading: false,
                targetingSpecsError: action.payload
            }
        default:
            return state;
    }

}

const remoteReducer = combineReducers({
    locations: locationsReducer,
    interests: interestsReducer,
    targetingSpecs: targetingSpecsReducer
})

export default remoteReducer;
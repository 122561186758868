import Page from '../components/page/Page';
import Mapbox from '../components/mapbox/Mapbox';
import React from 'react';

// import { CSVReader } from 'react-papaparse';
// const fileDownload = require('js-file-download');

const MapPage = () => {

    // const buttonRef = React.createRef();

    // const handleOnUpload = (ans) => {
    //     const res = {};
    //     let g0 = "";
    //     let g1 = "";
    //     console.log(ans);
    //     ans['data'].map(vals => {
    //         if(vals[0]!="") g0 = vals[0];
    //         if(vals[1]!="") g1 = vals[1];
    //         const temp = {};
    //         temp[g1] = [vals[2]]
    //         res[g0]?res[g0][g1]?res[g0][g1].push(vals[2]):res[g0][g1]=[vals[2]]:res[g0]=temp;
    //     })
    //     console.log(res);
    //     fileDownload(JSON.stringify(res), 'urban_data.json');
    //   }

    // const customConfigs = {
    //     header: false,
    //     complete: handleOnUpload
    // }

    return(
        <Page breadcrumbs={[{name: 'Location', active: true }]} title='Location'>
            <Mapbox />
            {/* <CSVReader
                ref={buttonRef}
                config={customConfigs}
            /> */}
        </Page>
    )
}

export default MapPage;
const defaultState = {
    userID: "",
    userpic: "",
    progress: ""
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case "SET_PIC":
            return {
                ...state,
                userpic: action.profilepic
            }

        case "SET_DATA":
            return {
                ...state,
                userdata: action.userdata
            }
            
        default:
            return state;
    }
}
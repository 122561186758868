import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import './mapbox.scss';

import { Form, FormGroup, Label, Input } from 'reactstrap';

// import UploadComp from './UploadComponent';
import { jsonToCSV } from 'react-papaparse';
import { jsonToGeojson } from './jsonToGeojson.util';

import { getMapData } from '../../redux/mapbox/mapbox.actions';
import { getActiveCampaigns } from '../../redux/campaigns/campaigns.actions';


const fileDownload = require('js-file-download');

// const STORE_RADIUS = 500; // in meters
// 
// Formula for a mapbox circle in meters
// 
// const metersToPixelsAtMaxZoom = (meters, latitude) => meters / 0.075 / Math.cos(latitude * Math.PI / 180);
// 
// "circle-radius": {
//   stops: [
//     [0, 0],
//     [20, metersToPixelsAtMaxZoom(STORE_RADIUS, 28.635)]
//   ],
//   base: 2
// 
// in shorter form - (For delhi NCR only)
// meters / 0.075002853

// TODO: move these to some mapbox.config file

mapboxgl.accessToken = 'pk.eyJ1Ijoic2FtYXJ0aGp1Z3JhbiIsImEiOiJja2lobXExbDEwZnJ6MnRvZ2NsZ2dodWNpIn0.5JXfKPAPJgH-G6YsJGYbgQ';
const STYLE_URL = 'mapbox://styles/samarthjugran/ckijzpx3w0gz017o3uf8hbmre';
// data points for tilesor different datasets
// const TILESET_SOURCES = {
//   '24-seven': 'samarthjugran.ckiinanwv02hp2bmywo0zo2at-527nq',
//   'big-bazaar': 'samarthjugran.ckihswt9x0vr526lh097dwd9h-1f6j0',
//   'evocus-data': 'samarthjugran.ckihu9pmk2g7s24mmhjopqo3y-3x5hf'
// };


const Mapbox = ({ active, layerProps }) => {
  const activeCampaigns = useSelector(state => state.campaigns.activeCampaigns)

  const mapContainerRef = useRef(null);
  const [layers, setLayers] = useState({}); // TODO: fetch these from campaign data
  const [map, setMap] = useState();
  const [heatmaps, setHeatmaps] = useState([]);
  const [showHeatmap, setShowHeatmap] = useState(null);
	const dispatch = useDispatch();

  const datapoints = useSelector(state => state.mapbox.datapoints);
  const tilesets = useSelector(state => state.mapbox.tilesets);

	useEffect(() => {
		dispatch(getActiveCampaigns());
	}, [])

  // handle complete mapbox canvas lifecycle events
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: STYLE_URL,
      center: [77.225, 28.635],
      zoom: 10.5
    });

    // navigation control 
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    if (active) {
      // resize on active
      map.resize();
    }

    setMap(map);
    // clean up on unmount
    return () => map.remove();

  }, [active]);

  // handle layer lifecycle events
  useEffect(() => {
    if (!map) return;

    let newLayers = {};
    // add source and layer for different data points
    for (const key of Object.keys(tilesets)) {
      map.addSource(key, {
        type: "vector",
        url: "mapbox://" + tilesets[key],
      });
      map.addLayer(layerProps[key]);
      newLayers[key] = true;
    }

    // load and add layers from custom data
    if (datapoints) {
      for (const dataLayer of Object.keys(datapoints)) {
        const geojson = jsonToGeojson(datapoints[dataLayer]);

        map.addSource(dataLayer, {
          type: "geojson",
          data: geojson,
        });

        map.addLayer({
          id: `${dataLayer}`,
          type: "circle",
          source: `${dataLayer}`,
          paint: {
            "circle-radius": {
              stops: [
                [0, 2],
                [20, 6],
              ],
            },
            "circle-color": "#FF0024",
            "circle-stroke-color": "#6A00FF",
            "circle-stroke-width": 2,
            "circle-opacity": 1,
            "circle-stroke-opacity": 0.2,
          },
        });
        newLayers[dataLayer] = true;
      }
    }

    // map.on("click",'layername',  handleMapClick); 
    // use this format to handle events for different layer as
    // such events are auto removed when layer get removed

    // handle points click events
    map.on("click", (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: Object.keys(newLayers),
      });
  
      if (!features.length) {
        return;
      }
      console.log("clicked point", features);
  
      var feature = features[0];
      // TODO: move this to different component
      // pop up in datapoint click
      if (feature.layer.id === "evocus-data") {
        var popup = new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            "<h6>" +
              "<b>Name: </b>" +
              feature.properties.Full_Name +
              "</h6><p>" +
              "<b>Gender: </b>" +
              feature.properties.Gender +
              "<br>" +
              "<b>Feedback: </b>" +
              (feature.properties.Feedback_2 === ""
                ? " - "
                : feature.properties.Feedback_2) +
              "<br>" +
              "</p><p>" +
              "<b>Address: </b>" +
              feature.properties.Address +
              "</p>"
          )
          .addTo(map);
      } else if (feature.layer.id === "24-seven") {
        var popup = new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            "<h6>24-seven Store</h6><p>" +
              "<b>Location: </b>" +
              feature.properties.City +
              "<br>" +
              "<b>Address: </b>" +
              feature.properties.Full_Address +
              "<br>" +
              "<b>Phone: </b>" +
              feature.properties.Phone +
              "</p>"
          )
          .addTo(map);
      } else if (feature.layer.id === "big-bazaar") {
        var popup = new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates)
          .setHTML("<h6>Big Bazaar Store</h6>")
          .addTo(map);
      } else if (feature.layer.id in newLayers) {
        // Dynamic data popup
        var popup = new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h6>${feature.layer.id.replaceAll('_', ' ')}</h6>` +
            `<p><b>Name:</b> ${feature.properties["Full_Name"]}<br>` +
            `<b>Address:</b> ${(feature.properties["address"] || feature.properties["Address"] || '')}<br>  ` +
            `<b>Delivered:</b> ${(feature.properties["Delivered"]?"Yes":"No")}</p>`
          )
          .addTo(map);
        }
    });

    
    setLayers({ ...layers, ...newLayers });
    console.log("layers: ", newLayers);
    
    // reset layers added on click events
    setHeatmaps([]);
    setShowHeatmap(null);
  }, [datapoints, tilesets]);
  
  useEffect(() => {
    if (!map) return;
    
    
  }, [layers]);
  

  const toggleVisible = (layer, visible) => {
    setLayers({ ...layers, [layer]: !visible });

    if (visible) {
      map.setLayoutProperty(layer, 'visibility', 'none');
    }
    else {
      map.setLayoutProperty(layer, 'visibility', 'visible');
    }

  }

  const getVisibleLayers = () => {
    return Object.keys(layers).filter((v) => layers[v]);
  }

  const getCsv = () => {
    let data = [];
    const dataLayers = getVisibleLayers().filter(layer => Object.keys(datapoints).includes(layer));
    console.log('common layers', dataLayers)

    for (let visibleLayer of dataLayers) {
      for (const entry of Object.keys(datapoints[visibleLayer])) {
        // if invalid data -> skip

        if (!datapoints[visibleLayer][entry]["lat"] || datapoints[visibleLayer][entry]["error"]) continue;

        data.push({
          "Name": datapoints[visibleLayer][entry]["Full_Name"] || '',
          "Phone": entry,
          "fbid": datapoints[visibleLayer][entry]["fbid"] || '',
          "Delivered": datapoints[visibleLayer][entry]["Delivered"] || '-',
          "Address": datapoints[visibleLayer][entry]["Address"],
          "Latitude": datapoints[visibleLayer][entry]["lat"],
          "Longitude": datapoints[visibleLayer][entry]["lon"]
        })
      }
    }
    if (data.length === 0)
      return;

    const csv = jsonToCSV({
      "fields": ["Name", "Address","Phone", "fbid","Delivered" ,"Latitude", "Longitude"], // fields from data to save
      "data": data
    });

    // console.log('csv ', csv)
    fileDownload(csv, 'map-points-data.csv');
  }

  const toggleHeatmap = (e) => {
    if(!map) return;

    const heatmapLayer = e.target.value === 'null' ? null : e.target.value;
    console.log('current heatmap', showHeatmap);
    console.log('set heatmap', heatmapLayer);


    // disable previous heatmap first....
    if (showHeatmap){
      map.setLayoutProperty(showHeatmap, 'visibility', 'none');
    }

    if (heatmapLayer){
      if ( !(heatmaps.length > 0 && heatmaps.includes(heatmapLayer + '-heat'))){
        console.log('adding new heatmap layer', heatmapLayer);
        addHeatmap(heatmapLayer);
      }
      else{
        console.log('Toggling heatmap layer', heatmapLayer);
        map.setLayoutProperty(heatmapLayer +'-heat', 'visibility', 'visible');
      }
      
      setShowHeatmap(heatmapLayer + '-heat');
    }
    else{
      setShowHeatmap(null);
    }
  }

  const addHeatmap = (heatmapLayer) => {

    if (!map || !heatmapLayer) return;
    // heatmapLayer = heatmapLayer.slice(0, -6);

    let heatLayerProp = {
      'id': `${heatmapLayer}-heat`,
      'type': 'heatmap',
      'source': `${heatmapLayer}`,
      'source-layer': heatmapLayer
    }

    // map.addLayer(heatLayerProp);
    map.addLayer(heatLayerProp, heatmapLayer); // add layername to place heatmap below that layer

    setHeatmaps([...heatmaps, heatmapLayer + '-heat'])
  }

  const selectCampaign = (e) => {
    const selectedCampaign = e.target.value;

    // remove all heatmaps
    for (const heatmap of heatmaps) {
      console.log('removing heatmap', heatmap)

      map.removeLayer(heatmap);
    }

    // remove all layers and their source
    for (const layer of Object.keys(layers)) {
      console.log('removing', layer)
      map.removeLayer(layer);
      map.removeSource(layer);
      // console.log('removing source ', layer.slice(0, -6) + "-source");
    }

    // // remove click events
    // map.off("click", handleMapClick);

    dispatch(getMapData(selectedCampaign));
    // setCampaign(selectedCampaign);
    setShowHeatmap(null);
    setLayers({});
    setHeatmaps([]);
  };

  return (
    <>
			
			<div className="container px-2">
      <div className="d-flex px-2 justify-content-between">
        {/* <div>
          <button className="ml-2 btn btn-info" onClick={getCsv}>Download Visible Data</button>
        </div> */}


            <div className="ml-2">
            <Form style={{
                  width: '45vw',
                  maxWidth: '400px'
                  }}>
              <FormGroup>
                <Label for="campaignSelect">Select a campaign</Label>
                <Input type="select" name="select" id="campaignSelect" defaultValue="-1" onChange={selectCampaign}>
                  <option disabled value="-1">Select a campaign</option>                
                  {
                    activeCampaigns && activeCampaigns.map((camp) => {
                      return (
                        <option key={camp} value={camp}>{camp}</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
            </Form>
            </div>
            <div className="mr-2">
              <Label for="heatmapSelect" className="text-nowrap">Select Heatmap</Label>
              <Input type="select" name="select" id="heatmapSelect" defaultValue='null' onChange={toggleHeatmap}>
                <option value='null'>None</option>
                {
                  layers && Object.keys(layers).map((layer) => {
                    return (
                      <option key={layer} value={layer}>{layer}</option>
                      )
                    })
                  }
              </Input>
            </div>

        {/* <div>

           <UploadComp /> 
        </div> */}
      </div>


      <div className="container mt-2">

        <div className="map-overlay">
          <div className="map-overlay-inner">
            <label>Show Points</label>

            {layers && Object.entries(layers).map(([layer, visible]) => {
              return (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={visible}
                    onChange={() => toggleVisible(layer, visible)}
                    id={layer + 'checkbox'}
                  />
                  <label className="form-check-label" htmlFor={layer + 'checkbox'}>
                    {layer.replaceAll('-', ' ')}
                  </label>
                </div>
              )
            })}
          </div>
        </div>

        <div className="mapContainer" ref={mapContainerRef} />
      </div>
    </div>
		</>

  )
}

Mapbox.defaultProps = {
  layerProps: {
    '24-seven': {
      'id': '24-seven',
      'type': 'circle',
      'source': '24-seven',
      'source-layer': '24-seven',
      'paint': {
        'circle-radius': {
          stops: [
            [0, 2],
            [20, 8]
          ]
        },
        'circle-color': '#FD8100',
        'circle-stroke-color': '#FCDE00',
        'circle-stroke-width': {
          stops: [
            [0, 0],
            [20, (2000 / 0.075002853)] // >> meters / 0.075002853
          ],
          base: 2
        },
        'circle-opacity': 1,
        'circle-stroke-opacity': 0.2
      }
    },
    'big-bazaar': {
      'id': 'big-bazaar',
      'type': 'circle',
      'source': 'big-bazaar',
      'source-layer': 'big-bazaar',
      'paint': {
        'circle-radius': {
          stops: [
            [0, 2],
            [20, 8]
          ]
        },
        'circle-color': '#FF7900',
        'circle-stroke-color': '#190B60',
        'circle-stroke-width': {
          stops: [
            [0, 0],
            [20, (2000 / 0.075002853)] // >> meters / 0.075002853
          ],
          base: 2
        },
        'circle-opacity': 1,
        'circle-stroke-opacity': 0.2
      }
    },
    'evocus-data': {
      'id': 'evocus-data',
      'type': 'circle',
      'source': 'evocus-data',
      'source-layer': 'evocus-data',
      'paint': {
        'circle-radius': {
          stops: [
            [0, 2],
            [20, 6]
          ]
        },
        'circle-color': '#2C2B2A',
        'circle-stroke-color': '#4BCA73',
        'circle-stroke-width': 2,
        'circle-opacity': 1,
        'circle-stroke-opacity': 0.2
      }
    }
  }
}

export default Mapbox;

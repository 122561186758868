import React, { useEffect } from 'react';
import Page from '../../page/Page';
import OuterSlider from './PredefinedSlides/OuterSlider'

// import 'slick-carousel/slick/slick-theme.scss';
// import 'slick-carousel/slick/slick.scss';
import { connect } from 'react-redux';
import { addNotif, addTask } from '../../../redux/tasks/tasks.actions';
import { useParams } from 'react-router-dom';

// Step components
import CampaignDetails from './StepComponents/CampaignDetails.component';
import ProductDetails from './StepComponents/ProductDetails.components';
import Responsibilities from './StepComponents/Responsibilities.component.jsx';
import Marketing from './StepComponents/Marketing.component';

// All actions
import { getData } from '../../../redux/cac/cac.actions';
import { resetCampaignDetails } from '../../../redux/cac/campaignDetails/campaignDetails.actions';
import { resetProductDetails } from '../../../redux/cac/productDetails/productDetails.actions';
import { resetMarketingData } from '../../../redux/cac/marketing/marketing.actions';
import { resetResponsibilities } from '../../../redux/cac/responsibilities/responsibilities.actions';
import { resetSocialMediaTargeting } from '../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { resetGlobalCacStates } from '../../../redux/cac/globalCacStates/globalCacStates.actions';

import 'antd/dist/antd.css';

const CreateACampaign = ({ getData, resetCampaignDetails, resetProductDetails, resetMarketingData, resetResponsibilities, resetSocialMediaTargeting, resetGlobalCacStates}) => {
    const params = useParams();

    useEffect(() => {
        resetCampaignDetails();
        resetProductDetails();
        resetMarketingData();
        resetResponsibilities();
        resetSocialMediaTargeting();
        resetGlobalCacStates();
        if (params.name) {
            // console.log(params);
            getData(params.name);
        }
    })


    return (
        <Page breadcrumbs={[
            {name: 'Campaigns', active: false },
            {name: 'New campaign', active: true }
        ]}>
            <div id="campaign-creator-container">
                <OuterSlider>
                    <CampaignDetails />
                    <ProductDetails />
                    <Marketing />
                    <Responsibilities />
                </OuterSlider>
            </div>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    UID: state.auth.uid
});

const mapDispatchToProps = (dispatch) => ({
    addClientNotif: ($taskdata) => dispatch(addTask($taskdata)),
    addAdminNotif: ($notifdata) => dispatch(addNotif($notifdata)),
    getData: (name) => dispatch(getData(name)),
    resetCampaignDetails: () => dispatch(resetCampaignDetails()),
    resetProductDetails: () => dispatch(resetProductDetails()),
    resetMarketingData: () => dispatch(resetMarketingData()),
    resetResponsibilities: () => dispatch(resetResponsibilities()),
    resetSocialMediaTargeting: () => dispatch(resetSocialMediaTargeting()),
    resetGlobalCacStates: () => dispatch(resetGlobalCacStates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateACampaign);
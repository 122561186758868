import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, FormGroup, Input, Label, CardColumns, InputGroup, InputGroupAddon, Popover, PopoverBody, Modal, ModalBody } from 'reactstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import warehouseCollection from './warehouse';
import './inventory-scheduler.component.styles.scss';
import ProfileCard from '../profile/ProfileCard';
import CustomButton from '../custom-button/CustomButton.component';
import ModalLoading from '../modal-loading/modal-loading.component';
import ModalSuccess from '../modal-success/modal-success.component';
import ModalFail from '../modal-failure/modal-failure.component';
import { inventoryScheduleStart } from '../../redux/inventory/inventory.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectInventoryError, selectIsSchedulingInventory, selectIsInventoryScheduled } from '../../redux/inventory/inventory.selectors';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const InventorySchedulerComponent = ({ inventoryScheduleStart, isLoading, isInventoryScheduled, errorMessage, focussedInventory }) => {
    const [modal, setModal] = useState(false);
    const [modalError, setModalError] = useState(null);
    let history = useHistory();

    const modalToggle = () => {
        setModal(!modal);
    }

    const [options, setOptions] = useState({
        cities: [],
        warehouses: []
    });

    // useEffect(() => {
    //     setOptions({
    //         cities: CampaignList[0].data.PackageInhouseManageableCities,
    //         warehouses: Object.keys(warehouseCollection)
    //         .filter(key => warehouseCollection[key].city === CampaignList[0].data.PackageInhouseManageableCities[0].toLowerCase())
    //         .map(key => ({
    //             [key]: warehouseCollection[key]
    //         }))
    //     })
    // }, [])

    const [popoverOpen, setPopoverOpen] = useState(false);

    const popoverToggle = () => setPopoverOpen(!popoverOpen);

    // const handleCampaignChange = e => {
    //     const focussedCampaign = e.target.value;
    //     setOptions({
    //         ...options,
    //         cities: _.find(CampaignList, { id: focussedCampaign }).data.PackageInhouseManageableCities,
    //         warehouses: Object.keys(warehouseCollection)
    //         .filter(key => warehouseCollection[key].city === _.find(CampaignList, { id: focussedCampaign }).data.PackageInhouseManageableCities[0].toLowerCase())
    //         .map(key => ({
    //             [key]: warehouseCollection[key]
    //         }))
    //     });
    // }

    // const handleCityChange = e => {
    //     const focussedCity = e.target.value;
    //     setOptions({
    //         ...options,
    //         warehouses: Object.keys(warehouseCollection)
    //         .filter(key => warehouseCollection[key].city === focussedCity.toLowerCase())
    //         .map(key => ({
    //             [key]: warehouseCollection[key]
    //         }))
    //     });
    // }

    useEffect(() => {
        const focussedCity = focussedInventory.city;
        setOptions({
            ...options,
            warehouses: Object.keys(warehouseCollection).filter(key => key.endsWith(focussedCity.toLowerCase())).map(key => ({[key]: warehouseCollection[key]}))
        });
    }, [focussedInventory, warehouseCollection]);

    const handleSubmit = e => {
        e.preventDefault();
        const $inputElements = document.querySelectorAll('input');
        // const $incompleteTextFields = Array.from($inputElements).filter(el => el.value === '');
        // // const $warehouseSelection = !!document.querySelector('input[name="warehouse"]:checked') ? true : false;
        // modalToggle();
        // if ($incompleteTextFields.length !== 0) {
        //     const incompleteFields = $incompleteTextFields.map(field => field.name);
        //     // if (!$warehouseSelection)   incompleteFields.push('warehouse');
        //     setModalError(`The following fields are not filled: ${incompleteFields.join(', ')}`)
        // } else {
        //     setModalError(null);
        //     const formData = {};
        //     $inputElements.forEach(({ name, value }) => formData[name] = value);
        //     formData.warehouse = document.querySelector('input[name="warehouse"]:checked').value;
        //     console.log(formData);
        //     // inventoryScheduleStart(formData);
        // }
        let formData = {};
        $inputElements.forEach(({ name, value }) => formData[name] = value);
        if (focussedInventory.whr !== 'in-house')   formData.warehouse = document.querySelector('input[name="warehouse"]:checked').value;
        formData = {
            ...formData,
            ...focussedInventory
        }
        console.log(formData);
        inventoryScheduleStart(formData);
        setTimeout(() => {
            history.push('/inventory/status');
        }, 800);
    }

    return (
        <div className="inventory-scheduler-form">
            <h3>{focussedInventory.campaign}</h3>
            <h3>{focussedInventory.city}</h3>
            <Form>
                {focussedInventory.whr === 'in-house' ? (
                    <div>
                    <FormGroup>
                    <Label for="name">Name of Warehouse Manager</Label>
                    <Input type="text" name="name" id="name" placeholder=""/>
                  </FormGroup>
                      <FormGroup>
                        <Label for="Email">Email</Label>
                        <Input type="email" name="email" id="Email" placeholder="Assign a Vendor Email Address" />
                      </FormGroup>
                      <FormGroup>
                    <Label for="phone">Phone Number of Warehouse Manager</Label>
                    <Input type="number" name="phone" id="phone" placeholder="Phone Number"/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Address">Address</Label>
                    <Input type="text" name="address" id="Address" placeholder="Location"/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Address2">Address 2</Label>
                    <Input type="text" name="address2" id="Address2" placeholder="City,State"/>
                  </FormGroup>
                      <FormGroup>
                        <Label for="pincode">Pincode of your warehouse</Label>
                        <Input type="number" name="pincode" id="pincode"/>
                      </FormGroup> 
                    </div>
                ) : (
                    <div>
                    <FormGroup tag="fieldset">
                        <p>Warehouse</p>
                        <CardColumns>
                        {options.warehouses.map((warehouse, index) => (
                            <FormGroup className="mb-1" check key={index}>
                                <Label check>
                                <Input required type="radio" name="warehouse" value={Object.keys(warehouse)[0]} />{' '}
                                <p>{Object.keys(warehouse)[0]} <span id={`Popover-${index}`}><AiOutlineInfoCircle /></span></p>
                                <Popover placement="bottom" isOpen={popoverOpen} target={`Popover-${index}`} toggle={popoverToggle}>
                                    <PopoverBody>
                                        <ProfileCard 
                                            header={Object.keys(warehouse)[0]}
                                            data={warehouse[Object.keys(warehouse)[0]]}
                                        />
                                    </PopoverBody>
                                </Popover>
                                </Label>
                            </FormGroup>
                        ))}
                        </CardColumns>
                    </FormGroup>
                    <FormGroup>
                        <Label for="quantity">
                            <p>Number of Samples</p>
                        </Label>
                        <InputGroup>
                            <Input
                                required
                                type="number"
                                name="quantity"
                                id="quantity"
                                placeholder="Number of Samples"
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="master-carton-qty">
                            <p>Number of Master Cartons</p>
                        </Label>
                        <Input
                            required
                            type="number"
                            name="master-carton-qty"
                            id="master-carton-qty"
                            placeholder="Number of Master Cartons"
                        />
                    </FormGroup>            
                    <FormGroup>
                        <Label for="dimensions">
                            <p>Dimensions of Master Carton</p>
                        </Label>
                        <InputGroup>
                        <Input required type="number" id="length" name="length" placeholder="Length" />
                        <Input required type="number" id="width" name="width" placeholder="Width" />
                        <Input required type="number" id="height" name="height" placeholder="Height" />
                        <InputGroupAddon addonType="append">
                            cm
                        </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="weight">
                            <p>Weight of the Master Carton</p>
                        </Label>
                        <InputGroup>
                        <Input required type="number" name="weight" id="weight" placeholder="Weight" />
                        <InputGroupAddon addonType="append">
                                Kgs
                        </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                    <Label for="quantity_per_carton">
                        <p>Samples Per Master Carton</p>
                    </Label>
                    <InputGroup>
                        <Input
                            required
                            type="number"
                            name="quantity_per_carton"
                            id="quantity_per_carton"
                            placeholder="Samples Per Master Carton"
                        />
                        <InputGroupAddon addonType="append">Units</InputGroupAddon>
                    </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="stack_size">
                            <p>Stack Limit</p>
                        </Label>
                        <InputGroup>
                            <Input
                                required
                                type="number"
                                name="stack_size"
                                id="stack_size"
                                placeholder="Stack Size"
                            />
                            <InputGroupAddon addonType="append">Units</InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="date">
                            <p>Date Of Dispatch</p>
                        </Label>
                        <Input
                            required
                            type="date"
                            name="date"
                            id="date"
                            placeholder="Date Of Dispatch"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="carrier_details">
                            <p>Carrier Details</p>
                        </Label>
                        <InputGroup>
                            <Input required type="text" id="tracking_id" name="tracking_id" placeholder="Tracking ID" />
                            <Input required type="text" id="name_of_carrier" name="name_of_carrier" placeholder="Name of Carrier" />
                            <Input required type="text" id="carrier_phone_number" name="carrier_phone_number" placeholder="Phone Number" />
                        </InputGroup>
                </FormGroup>
                    </div>
                )}
                <div className="custom-button-container">
                    {/* <Link to='/inventory/status'> */}
                        <CustomButton type="submit" onClick={handleSubmit}>Schedule Dispatch</CustomButton>
                    {/* </Link> */}
                </div>                
        </Form>
        {modal && (
            <Modal
            isOpen={modal} 
            toggle={modalToggle}
            centered={true}
            backdrop={'static'}
            className="order-creation-panel__modal"
            >
                <ModalBody>
                {isLoading && (
                    <div>
                        <ModalLoading />
                    </div>
                )}
                {isInventoryScheduled && (
                    <ModalSuccess closeFunc={modalToggle}>
                        <p className="text-success ml-2">Inventory Scheduled Successfully.</p>
                    </ModalSuccess>
                )}
                {!!modalError && (
                    <div>
                        <ModalFail closeFunc={modalToggle}>
                            <p>{modalError}</p>
                        </ModalFail>
                    </div>
                )}
                </ModalBody>
            </Modal>
        )}
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsSchedulingInventory,
    isInventoryScheduled: selectIsInventoryScheduled,
    errorMessage: selectInventoryError,
});

const mapDispatchToProps = dispatch => ({
    inventoryScheduleStart: (formData) => dispatch(inventoryScheduleStart(formData))
});

export default connect(mapStateToProps, mapDispatchToProps)(InventorySchedulerComponent);
import { Select } from 'antd';
import React from 'react'
import { useState } from 'react';
// import { listOfCities } from '../city-list';

const CitySelect = ({heading, description, placeholder, options, allowedSelectAll, sendValueToStore, storeVariable}) => {
    const [selectedItems, setSelectedItems] = useState();

    const handleChange = (cities) => {
        if(cities !== "" && cities.includes('all')) {
            setSelectedItems(options);
            sendValueToStore(options);
        }
        else {
            setSelectedItems(cities);
            sendValueToStore(cities);
        }
    }

    return (
        <div id="multi-select" className="question-container">
            < h3 className="question-heading" > {heading}</h3 >
            <p className="question-description">{description}</p>
            <Select
                mode="multiple"
                placeholder={placeholder}
                value={storeVariable || selectedItems}
                onChange={handleChange}
                style={{ width: '70%' }}
            >
                {
                    allowedSelectAll && (
                        <Select.Option key={-1} value='all'>
                            Select all
                        </Select.Option>
                    )
                }

                {options !== "" && options.map((item, idx) => (
                    <Select.Option key={idx} value={item}>
                        {item}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}

export default CitySelect;
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../components/page/Page';
import ClaimsComponent from '../components/audience-claims/claimsComponent'
import _ from 'lodash';
import { getRecentCharts, getPreviousCharts } from '../redux/clientinfo/user-stats.actions';
import { getAllClaims } from '../redux/clientinfo/stats.actions';
import { getActiveCampaigns } from '../redux/campaigns/campaigns.actions';
import { Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane  } from 'reactstrap';
import '../styles/components/_user-stats-charts.scss';
import { Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';
import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css';
import ChartComponent from '../components/charts/ChartComponent';
import '../styles/card.scss';
import { data } from 'jquery';


const UserStats = ({ getPreviousCharts, getActiveCampaigns, activeCampaigns, chartData, getAudience, audience }) => {
    const [campaign, setCampaign] = useState(null);

    const ReactGridLayout = WidthProvider(ResponsiveGridLayout);

    useEffect(() => {
        getPreviousCharts(campaign);
    }, [getPreviousCharts, campaign]);

    useEffect(() => {
        getAudience(campaign);
    }, [getAudience, campaign]);

    useEffect(() => {
        getActiveCampaigns();
    }, [getActiveCampaigns]);

    const handleChange = (event) => {
        // Destroy previous charts
        setCampaign(event.target.value);
    }
    const [activeTab, setActiveTab] = useState('claims');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    let j=0;
    let layout = []
    let layoutSm = []
    
    if (chartData && chartData['misc']) {
        layout = Object.keys(chartData['misc']).map(key => {
            const xx = j%2==0?0:6;
            const yy = Math.floor(j/2)*10;
            j++;
            return {i: key, x: xx, y: yy, w: 6, h: 6*1.4, isResizable: false}
        })

        layoutSm = layout.map(obj => {
            return {...obj, w:obj.w-2, h:obj.h-3}
        })
    }
    
    const ctypes = [
        "horizontalBar",
        "bar",
        "doughnut",
        "pie"
        // "line"
    ]

    // console.log(chartData, layout, layoutSm);


    // if (charts) {
    return (
        <Page
            className="Audience"
            title="Audience"
            breadcrumbs={[
                { name: `Analytics`, active: false },
                { name: `Audience`, active: true }
            ]}
        >
            
            <Form style={{
                width: '45vw',
                maxWidth: '400px'
            }}>
                <FormGroup>
                    <Label for="exampleSelect">Select a campaign</Label>
                    <Input type="select" name="select" id="exampleSelect" defaultValue="-1" onChange={handleChange}>
                        <option disabled value="-1">Select a campaign</option>
                        {
                            activeCampaigns && activeCampaigns.map((c) => {
                                return (
                                    <option key={c} value={c}>{c}</option>
                                )
                            })
                        }
                    </Input>
                </FormGroup>
            </Form>

            {campaign && chartData && (
                <>
                <Nav tabs>
                    <NavItem>
                            <NavLink
                                className={activeTab=='claims'?"active":""}
                                onClick={() => { toggle('claims'); }}>
                                Audience Stats
                            </NavLink>
                        </NavItem>
                    {Object.keys(chartData).length>1? (
                        <>
                        {
                            Object.keys(chartData).filter(key => !['misc','claims'].includes(key)).map(dataKey =>
                                <NavItem key={dataKey}>
                                    <NavLink
                                        className={activeTab==dataKey?"active":""}
                                        onClick={() => { toggle(dataKey); }}>
                                        {dataKey.replace('_', ' ')}
                                    </NavLink>
                                </NavItem>
                            )
                        }
                        </>
                    ) : (
                        <NavItem>
                            <NavLink
                                className={activeTab=='misc'?"active":""}
                                onClick={() => { toggle('misc'); }}>
                                Chatbot Questions
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="claims">
                        <ClaimsComponent audience_data={audience} />
                    </TabPane>
                </TabContent>
                {Object.keys(chartData).map(key => 
                    <TabContent activeTab={activeTab} key={key}>
                        <TabPane tabId={key}>
                            <ReactGridLayout
                                className="layout charts-container"
                                layouts={{
                                    lg: layout,
                                    md: layout,
                                    sm: layoutSm,
                                    xs: layoutSm,
                                    xxs: layoutSm
                                }}
                                draggableCancel=".bar"
                                breakpoints={{ lg: 1080, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                rowHeight={40}
                            >
                                {Object.keys(chartData[key]).map(ques =>
                                    <div key={ques} className="chart-container">
                                        <ChartComponent ckey={key} defaultType={_.sample(ctypes)} chart={ques} />
                                    </div>
                                )}
                            </ReactGridLayout>
                        </TabPane>
                    </TabContent>
                )}
                </>
            )}

        </Page>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        charts: state.userStats.charts && state.userStats.charts[ownProps.chart],
        activeCampaigns: state.campaigns.activeCampaigns,
        chartData :   state.userStats.previousCharts,
        audience: state.stats.audience
    }
}

const mapDispatchToProps = (dispatch) => ({
    getRecentCharts: (campaign) => {
        dispatch(getRecentCharts(campaign));
    },
    getPreviousCharts: (campaign) => {
        dispatch(getPreviousCharts(campaign));
    },
    getActiveCampaigns: () => {
        dispatch(getActiveCampaigns());
    },
    getAudience: (campaign) => { dispatch(getAllClaims(campaign)) }
})

UserStats.defaultProps = {
    smallStats: [
        {
            label: "Impressions",
            value: 45612,
            percentage: "159%",
            increase: true,
            chartLabels: [null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [197, 624, 3721, 17575, 45612]
                }
            ]
        },
        {
            label: "Reach",
            value: 27503,
            percentage: "92%",
            increase: true,
            chartLabels: [null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [154, 316, 3206, 14280, 27503]
                }
            ]
        },
        {
            label: "Profile Views",
            value: 30,
            percentage: "41%",
            increase: false,
            chartLabels: [null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [8, 76, 15, 51, 30]
                }
            ]
        },
        {
            label: "Follower Count",
            value: 5,
            percentage: "37%",
            increase: true,
            chartLabels: [null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [2, 6, 4, 8, 5]
                }
            ]
        },
        {
            label: "Website Clicks",
            value: 2,
            percentage: "100%",
            increase: true,
            // decrease: true,
            chartLabels: [null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [0, 2, 5, 1, 2]
                }
            ]
        }

    ],
    chartStats: [
        {
            labels: [],
            datasets: [
                {
                    label: 'Impressions',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: []
                },
                {
                    label: 'Reach',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(237,206,69,0.4)',
                    borderColor: 'rgba(237,206,69,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(237,206,69,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(237,206,69,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: []
                }
            ]
        }

    ],

    genderStats: [
        {
            datasets: [{
                data: [4034, 1276, 17],
                labels: ["Male", "Female", "Unknown"],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ]
            },
            {
                data: [32, 1439, 2052, 332, 77, 23, 25, 12, 327, 674, 183, 52, 16, 12, 0, 4, 7, 6, 0, 0, 0],
                labels: ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+", "13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+", "13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                ],

            }
            ]
        }

    ],
    chartOptions: [{
        responsive: true,
        // title: {
        //     display : true,
        //     text: "This is Title",
        //     position:'bottom'
        // },
        tooltips: {
            backgroundColor: "grey",
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ': ' + dataset.data[index];
                },
            }
        }

    }]
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
const defaultState = {
    progress: "",
    existingqueries: null,
    campslist: []
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case 'SET_PROGRESS':
        return {
            ...state,
            progress: action.progress
        }

        case 'GET_QUERIES':
        return {
            ...state,
            existingqueries: action.existingqueries
        }

        case 'SET_CAMPS':
        return {
            ...state,
            campslist: action.campslist
        }
            
        default:
            return state;
    }
}
import React, { useState, useEffect } from 'react';
import {
  MdExitToApp,
  MdPersonPin
} from 'react-icons/md';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { AiOutlineUser, AiOutlineLogout } from 'react-icons/ai';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import bn from '../../utils/bemnames';
import { connect } from 'react-redux';
import { getPic, getUserdata } from '../../redux/profile/profile.actions';
import UserCard from '../Card/UserCard';
import { startLogout } from '../../redux/auth/auth.actions';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

const bem = bn.create('header');

const Header = ({ clientSocials, signout, userpic, getProfilepic, userdata, getdata, tasksnum }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  // Again, I don't like this solution very much, please let's change it in the next design
  useEffect(() => {
    // Since the breadcrumb does not lie in the header, but it lies in the page, the opening and closing of the sidebar had to be controlled
    if (isOpen) {
      // Giving classes or styles only when sidebar is open
      document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');

      if(document.querySelector('#header-breadcrumb .breadcrumb')) {
        document.querySelector('#header-breadcrumb .breadcrumb').style.left = '320px';
      }
    }
    else {
      // Giving classes or styles only when sidebar is closed
      document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');

      if(document.querySelector('#header-breadcrumb .breadcrumb')) {
        document.querySelector('#header-breadcrumb .breadcrumb').style.left = '320px';
      }
    }

    /* If we give transition by default, then what happens is that
    if a page is changed when the sidebar is closed, the breadcrumb starts from a little far away and then slides back
    looks funny initially but becomes annoying.
    
    Also without transitions, closing and opening of sidebar makes the breadcrumb to move instantly
    that is why after the breadcrumb is set, we make it transition again. */
    if(document.querySelector('#header-breadcrumb .breadcrumb')) {
      document.querySelector('#header-breadcrumb .breadcrumb').style.transition = 'left 0.2s ease-in-out';
    }
  }, [isOpen, location]);

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen(!isOpen);

    // setIsOpen((prevState) => {
    //   if(prevState) {
    // document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
    // document.querySelector('.cr-header button svg').classList.toggle('sidebar-close');
    // document.querySelector('#header-breadcrumb .breadcrumb').classList.remove('sidebar-close');
    //   }
    //   else {
    //     document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    //     // document.querySelector('.cr-header button svg').classList.toggle('sidebar-close');
    //     document.querySelector('#header-breadcrumb .breadcrumb').classList.add('sidebar-close');
    //   }
    //   setIsOpen(!prevState);
    // });

  };

  const isLoading = userdata === undefined;

  useEffect(() => {
    if (userpic === "") getProfilepic();
    if (isLoading) getdata();
  }, []);

  const [showPopover, setPopover] = useState(false);
  const toggle = () => setPopover(!showPopover);

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button color="secondary" outline onClick={handleSidebarControlButton}>
          {
            isOpen ? (
              <FiChevronLeft size={25} />
            ) : (
                <FiChevronRight size={25} />
              )
          }
        </Button>
      </Nav>
      {/* <Nav navbar className="mr-2">
        <Breadcrumb id="header-breadcrumb">
          <Breadcrumb>
            <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
            <BreadcrumbItem><a href="#">Library</a></BreadcrumbItem>
            <BreadcrumbItem active>Data</BreadcrumbItem>
          </Breadcrumb>
        </Breadcrumb>
      </Nav> */}


      <Nav navbar className={bem.e('nav-right')}>
        <Link to='/tasks'>
          <div className="notification-icon can-click">
            <IoIosNotificationsOutline size={25} />
            <div className="task-badge"></div>
          </div>
        </Link>
        <p className="header-username">
          {!isLoading ? <span>Hi, <strong>{userdata["name"] + "!"}</strong></span> : <span>"Loading..."</span>}
        </p>
        <NavItem className="can-click" >
          <NavLink id="popover">
            <img src={userpic} width="40" height="40" className="rounded-circle" />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={showPopover}
            toggle={toggle}
            trigger="legacy"
            target="popover"
            className="p-0 border-0">
            <PopoverBody className="p-0 border-secondary" style={{ minWidth: "250px" }}>
              <UserCard
                title={!isLoading ? userdata["name"] : "Loading..."}
                subtitle={!isLoading ? userdata["email"] : "Loading..."}
                className="border-secondary"
                avatar={userpic}>
                <ListGroup id="user-card">
                  <ListGroupItem tag="button" action className="border-light" onClick={() => setPopover(false)}>
                    <Link to="/profile">
                      <AiOutlineUser style={{ color: "black", height: "1.5em", width: "1.5em" }} />
                      <span className="card-button-text" style={{ textDecoration: 'none' }}>Profile</span>
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light" onClick={() => { setPopover(false); signout(); }}>
                    <AiOutlineLogout style={{ color: "black", height: "1.5em", width: "1.5em" }} />
                    <span className="card-button-text">Sign out</span>
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  userID: state.auth.uid,
  clientSocials: state.clientInfo.socials,
  userpic: state.profile.userpic,
  userdata: state.profile.userdata,
  tasksnum: state.tasks.tasksnum
});

const mapDispatchToProps = (dispatch) => ({
  getProfilepic: () => dispatch(getPic()),
  getdata: () => dispatch(getUserdata()),
  signout: () => dispatch(startLogout())
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);
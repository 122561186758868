const defaultState = {
    campaignStats: {},
    dataStudioStats: {},
    audience: {},
    activeUser: '',
    activeUserDetails: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_CAMPAIGN_STATS':
            return {
                ...state,
                campaignStats: action.stats
            }

        case 'SET_CLAIMS_STATS':
            return {
                ...state,
                audience: action.claims
            }

        case 'SET_ACTIVE_USER':
            return {
                ...state,
                activeUser: action.payload
            }

        case 'SET_ACTIVE_USER_DETAILS':
            return {
                ...state,
                activeUserDetails: action.payload
            }

        case 'SET_DATASTUDIO_STATS':
            return {
                ...state,
                dataStudioStats: action.dataStudioStats
            }

        default:
            return state;
    }
}
import { createSelector } from 'reselect';

export const selectInventory = state => state.inventory;

export const selectInventoryStatus = createSelector(
    [selectInventory],
    inventory => inventory.status ? Object.keys(inventory.status).map(key => ({ id: key, ...inventory.status[key] })) : []
);

export const selectInventoryStats = createSelector(
    [selectInventory],
    inventory => inventory.stats
);

export const selectIsFetchingInventory = createSelector(
    [selectInventory],
    inventory => inventory.isFetchingInventory
);

export const selectIsFetchingInventoryStatistics = createSelector(
    [selectInventory],
    inventory => inventory.isFetchingInventoryStatistics
);

export const selectIsSchedulingInventory = createSelector(
    [selectInventory],
    inventory => inventory.isSchedulingInventory
);

export const selectIsInventoryScheduled = createSelector(
    [selectInventory],
    inventory => inventory.isInventoryScheduled
);

export const selectInventoryError = createSelector(
    [selectInventory],
    inventory => inventory.errorMessage
);
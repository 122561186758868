import React, {useState, useEffect} from 'react'
import { Button, Comment, Form } from 'semantic-ui-react'
import { GoCommentDiscussion } from 'react-icons/go'
import { FaPenNib } from 'react-icons/fa'
import { firebase, secondaryApp } from '../../../../../firebase/firebase';
import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';

import './comments-style.scss';

const Comments = ({uid, graphics, isCommentsOpen, name, profilePic}) => {
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [comments, setComments] = useState(null);

    let {versionId, graphicId} = useParams();

    useEffect(() => {
        if(graphics) {
            setSelectedVersion(graphics[graphicId].versions[versionId]);
            setComments(graphics[graphicId].versions[versionId].comments)
        }
    }, [graphics, versionId, graphicId]);

    const addComment = () => {
        let time = Date();
        time = time.toString();
        let content = document.querySelector('#commentText').value;

        let graphicRef = firebase.database().ref(`users/${uid}/graphics-data/${graphicId}`);
        let versionRef = graphicRef.child('versions/' + versionId);
        let commentsRef = versionRef.child('comments');

        let newComment = {
            user: name || 'Anonymous',
            content: content,
            time: time,
            profilepic: profilePic
        }

        commentsRef.push(newComment);

        commentsRef.on('value', snapshot => {
            setComments(snapshot.val());
        })

        document.querySelector('#commentText').value = "";
    }

    return (
        <Comment.Group>
            <div className={`comments ${isCommentsOpen ? 'show' : null}`}>

                <div className="section">
                     <div className="section-heading">
                        <h1><GoCommentDiscussion className="graphic" />Comments</h1>
                    </div>
                    <div className="section-body">
                        <div id="comment-list">
                            {
                                comments ? (
                                    Object.entries(comments).map((comment) => {
                                        var moment = require('moment');
                                        var time = moment(comment[1].time).fromNow();
                                        return (
                                            <Comment key={comment[0]}>
                                                <Comment.Avatar src={comment[1].profilepic || 'https://react.semantic-ui.com/images/avatar/small/jenny.jpg'} />
                                                <Comment.Content>
                                                    <Comment.Author as='a'>{comment[1].user}</Comment.Author>
                                                    <Comment.Metadata style={{
                                                        position: 'absolute',
                                                        right: '0'
                                                    }}>
                                                        <div>{time}</div>
                                                    </Comment.Metadata>
                                                    <Comment.Text>{comment[1].content}</Comment.Text>
                                                </Comment.Content>
                                            </Comment>
                                        )
                                    })
                                ) : (
                                    <div className="placeholder-comments">
                                        <p>There are no comments here.</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div id="input-comment" className="section">
                    <div className="section-heading">
                        <h1><FaPenNib className="graphic" />Give your inputs</h1>
                    </div>
                    <div className="section-body comment-vala">
                        <div className="comment-form">
                            <Form reply>
                                <Form.TextArea id="commentText" />
                                <Button id="submitBtn" content='Add Reply' labelPosition='left' icon='edit' primary onClick={addComment} />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Comment.Group>
    )
}

const mapStateToProps = (state) => ({
    name: state.profile.userdata && state.profile.userdata.name,
    uid: state.auth.uid,
    profilePic: state.profile.userpic,
    graphics: state.graphics.graphics
})

export default connect(mapStateToProps)(Comments);
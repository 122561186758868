const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_GRAPHICS':
            return {
                ...state,
                graphics: action.data
            }
        default:
            return state;
    }
}
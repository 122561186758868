import { Select, Spin, Input, Tag } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react'
import SliderWrapper from '../PredefinedSlides/SlideWrapper';
import { connect } from 'react-redux';
import { addGeoLocation } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { deleteGeoLocation } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { changeLocationType } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { setCities } from '../../../../redux/cac/marketing/marketing.actions';
import { setLocationsArray } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { loadLocations } from '../../../../redux/cac/remote/remote.actions';

const { Option } = Select;

const LocationComponent = ({
    addGeoLocation,
    deleteGeoLocation,
    changeLocationType,
    setCities,
    cities,
    setLocationsArray,
    locationType,
    locationsArray,
    loadLocations,
    fetching,
    data,
    error,
    errorText
}) => {
    const [result, setResult] = useState([]);

    const [locationTypeState, setLocationTypeState] = useState([]);

    useEffect(() => {
        if (locationType) {
            setLocationTypeState(locationType);
        }
    }, [locationType]);

    useEffect(() => {
        if (locationsArray) {
            setResult(locationsArray);
        }
    }, [locationsArray]);

    const fetchResultsFunction = (value) => {
        loadLocations(value);
    }

    const fetchResults = debounce(fetchResultsFunction, 800);

    const handleSelect = (value) => {
        setLocationsArray([...result, value]);
        setResult([...result, value]);
        loadLocations('stop-fetching');
        addGeoLocation(value);

        const newCities = [...cities, value[0]];
        setCities(newCities);
    };

    const handleDeselect = (value) => {
        const newResult = result.filter(v => v[2] !== value[2])
        setResult(newResult);
        deleteGeoLocation(value);

        const newCities = cities.filter(c => c !== value[0]);
        setCities(newCities);
    }

    const handleLocationTypeChange = (value) => {
        setLocationTypeState(value);
        changeLocationType(value);
    }

    return (
        <SliderWrapper
            heading='Location'
            description="Select your audience location"
        >
            <Input.Group compact>
                <Select dropdownMatchSelectWidth={false} value={locationTypeState} placeholder="Includes" onChange={handleLocationTypeChange}>
                    <Option value="everyone">Everyone in this location</Option>
                    <Option value="live">Live in this location</Option>
                    <Option value="traveling">Traveling in this location</Option>
                    <Option value="recently-in">Recently in this location</Option>
                </Select>
                <Select
                    style={{
                        minWidth: '50%',
                        maxWidth: '80%',
                    }}
                    mode="multiple"
                    value={result}
                    placeholder="Select location"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={fetchResults}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    tagRender={(props) => tagRender(props, data)}
                    dropdownMatchSelectWidth={false}
                >
                    {data && data.map(d => (
                        <Option key={d.value[2]} id={d.value[2]} value={d.value}>
                            <div className="main-content" style={{
                                fontWeight: 'bolder'
                            }}>
                                {
                                    d.value[1] === 'city' && <span>{`${d.value[0]}, ${d.value[4]}, ${d.value[3]}`}</span>
                                }
                                {
                                    d.value[1] === 'region' && <span>{`${d.value[0]}, ${d.value[3]}`}</span>
                                }
                                {
                                    d.value[1] === 'zip' && <span>{`${d.value[0]}, ${d.value[3]}, ${d.value[4]}`}</span>
                                }
                                {
                                    (d.value[1] === 'country' || d.value[1] === 'country_group' || d.value[1] === 'geo_market') && <span>{d.value[0]}</span>
                                }
                            </div>
                            <div className="info-content" style={{
                                fontSize: '0.7rem',
                                position: 'absolute',
                                right: '5px',
                                top: '5px',
                                color: 'black'
                            }}>
                                {d.value[1]}
                            </div>
                        </Option>
                    ))}
                </Select>
            </Input.Group>
            {
                error && <p style={{
                    fontSize: '12px',
                    color: '#d21515',
                    marginTop: '15px',
                    position: 'relative',
                    left: '2px'
                }}>{typeof(errorText) === "object" ? 'There was an error in fetching locations' : errorText}</p>
            }
        </SliderWrapper>
    )
}

function tagRender(props, data) {
    const { value, closable, onClose } = props;

    return (
        <Tag color='#052762' closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {
                value[1] === 'city' && <span>{`${value[0]}, ${value[4]}, ${value[3]}`}</span>
            }
            {
                value[1] === 'region' && <span>{`${value[0]}, ${value[3]}`}</span>
            }
            {
                value[1] === 'country' && <span>{value[0]}</span>
            }
            {
                value[1] === 'zip' && <span>{value[0]}</span>
            }
        </Tag>
    );
}

const mapStateToProps = (state) => ({
    cities: state.cac.marketing.Cities,
    locationType: state.cac.socialMediaTargeting.LocationType,
    locationsArray: state.cac.socialMediaTargeting.LocationsArray,
    fetching: state.cac.remote.locations.isLocationLoading,
    data: state.cac.remote.locations.locations,
    error: state.cac.remote.locations.locationLoadError,
    errorText: state.cac.remote.locations.locationLoadErrorText
})

const mapDispatchToProps = (dispatch) => ({
    addGeoLocation: (object) => dispatch(addGeoLocation(object)),
    deleteGeoLocation: (object) => dispatch(deleteGeoLocation(object)),
    changeLocationType: (locationType) => dispatch(changeLocationType(locationType)),
    setCities: (cities) => dispatch(setCities(cities)),
    setLocationsArray: (locationsArray) => dispatch(setLocationsArray(locationsArray)),
    loadLocations: (value) => dispatch(loadLocations(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationComponent);
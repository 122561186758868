import database from '../../firebase/firebase';

//SET_USER_PROFILE 
// export const setUserProfile = (profile) => ({
//     type: 'SET_USER_PROFILE',
//     profile
// });

// export const startSetUserProfile = () => {
//     return (dispatch, getState) => {
//         const uid = getState().auth.uid;
//         const userRef = database.ref(`vendors/users/${uid}`);
//         return userRef.once('value').then(snapshot => {
//             dispatch(setUserProfile(snapshot.val()));
//         });
//     }
// }

// SET_CLIENT_SOCIALS
export const setClientSocials = (socials) => ({
    type: 'SET_CLIENT_SOCIALS',
    socials
});

export const startSetClientSocials = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.client_id; 
        return database.ref(`users/${uid}/campaigns/Demo/social`).once('value').then((snapshot) => {
            const socials = {
                facebookPage: '',
                instagramPage: ''
            };
            snapshot.forEach(childSnapshot => {
                if (childSnapshot.key === 'fb_page')    socials.facebookPage = childSnapshot.val();
                if (childSnapshot.key === 'insta_page') socials.instagramPage = childSnapshot.val();
            })
            dispatch(setClientSocials(socials));
        })
        .catch(err => {
            console.log('Waiting for uid', uid);
        });
    };
}

// SET_CLIENT_ASSETS
export const setClientAssets = (assets) => ({
    type: 'SET_CLIENT_ASSETS',
    assets
});

export const startSetClientAssets = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.client_id; 
        return database.ref(`users/${uid}/campaigns/Demo/social`).once('value').then((snapshot) => {
            const assets = {};
            snapshot.forEach(childSnapshot => {
                if (childSnapshot.key === 'box_designs' || childSnapshot.key === 'social_designs')  assets[childSnapshot.key] = childSnapshot.val();
            });
            dispatch(setClientAssets(assets));
        })
        .catch(err => {
            console.log('Waiting for uid', uid);
        });
    }
}
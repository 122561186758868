import Page from '../components/page/Page';
import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table as RTable,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  ButtonGroup

} from 'reactstrap';
import { connect } from 'react-redux';
import { addSku, deleteSku, startGetSku } from '../redux/sku/sku.actions';
import { FiCheck } from 'react-icons/fi';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import SKUSelect from '../components/tree-select/TreeSelect.component';
import { Table, Tag, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';


const { Column, ColumnGroup } = Table;

// const DeleteWarningModal = ({deleteWarningModalState, closeDeleteWarning, handleDelete}) => {
//   <Modal isOpen={deleteWarningModalState} backdrop={false}>
//     <ModalBody style={{
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center'
//     }}>
//       <div className="icon" style={{
//         fontSize: '6rem',
//         fontWeight: 'lighter',
//         color: 'red'
//       }}><IoIosCloseCircleOutline /></div>
//       <div className="heading" style={{
//         fontSize: "2rem",
//         color: '#636363',
//         fontFamily: 'Exo'
//       }}>Are you sure?</div>
//       <div className="description" style={{
//         color: '#B4A19B',
//         fontSize: '1.1rem',
//         textAlign: 'center',
//         width: '80%'
//       }}>Do you really want to delete this SKU? This process cannot be undone.</div>
//       <div className="buttons" style={{
//         width: '35%',
//         display: 'flex',
//         justifyContent: 'space-between',
//         margin: '20px 0'
//       }}>
//         <Button color="primary" onClick={closeDeleteWarning}>Cancel</Button>{' '}
//         <Button color="secondary" onClick={() => {
//           handleDelete(skus);
//           closeDeleteWarning();
//         }} >Delete</Button>
//       </div>
//     </ModalBody>
//   </Modal>
// }

const SkuPage = ({ sku_data, addSkus, deleteSkus, startGetSkus, uid }) => {
  const notificationSystem = useRef();

  useEffect(() => {
    startGetSkus(uid)
  }, [startGetSkus])

  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [modalState, setModalState] = useState(null)
  const [deleteWarningModalState, setDeleteWarningModalState] = useState(false)
  const [skuCategory, setSkuCategory] = useState(undefined);
  const [skuDelete, setSkuDelete] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const searchInput = useRef();

  const toggle = () => {
    setmodalIsOpen(false);
  }

  const handleadd = () => {
    const name = document.getElementById('name').value
    const category = skuCategory
    const weight = document.getElementById('weight').value
    const weightMetric = document.getElementById('weightMetric').value
    const length = document.getElementById('length').value
    const width = document.getElementById('width').value
    const height = document.getElementById('height').value
    const dimensionMetric = document.getElementById('dimensionMetric').value
    const shelfLife = document.getElementById('shelfLife').value
    const shelfLifeMetric = document.getElementById('shelfLifeMetric').value

    if (!isEditing) {
      if (Object.keys(sku_data).includes(name)) {
        alert("You must write a unique name")
        return;
      };
    }
    const key = name || "null";

    const payload = {
      category: category,
      weight: weight + " " + weightMetric,
      dimension: length + 'X' + width + 'X' + height + " " + dimensionMetric,
      shelfLife: shelfLife + " " + shelfLifeMetric
    }
    if (key === "null" || key === "undefined") {
      alert("Name cannot be empty")
    } else {
      toggle();
      addSkus(uid, payload, key, () => {
        if (!notificationSystem) {
          return;
        }
        notificationSystem.current.addNotification({
          title: <FiCheck />,
          message: 'A new SKU has been generated',
          level: 'info',
        });
      });
      setSkuCategory(undefined);
    }
    setModalState(null);
  }

  const handleEdit = (data, id) => {
    setModalState(id)
    setIsEditing(true);

    const initialization = [
      setmodalIsOpen(true)
    ]


    Promise.all(initialization)
      .then(() => {
        document.getElementById('name').value = id
        setSkuCategory(data.category);
        document.getElementById('weight').value = data.weight.split(" ")[0]
        document.getElementById('weightMetric').value = data.weight.split(" ")[1]
        document.getElementById('length').value = data.dimension.split("X")[0]
        document.getElementById('width').value = data.dimension.split("X")[1]
        document.getElementById('height').value = data.dimension.split("X")[2].split(" ")[0]
        document.getElementById('dimensionMetric').value = data.dimension.split(" ")[1]
        document.getElementById('shelfLife').value = data.shelfLife.split(" ")[0]
        document.getElementById('shelfLifeMetric').value = data.shelfLife.split(" ")[1]

      }).catch(err => console.log(err));

  }

  const handleCancel = () => {
    setmodalIsOpen(false)
    console.log(modalState)
    setModalState(null)
  }

  const handleDelete = (id) => {
    deleteSkus(uid, id)
  }

  const openDeleteWarning = () => {
    setDeleteWarningModalState(true);
  }

  const closeDeleteWarning = () => {
    setDeleteWarningModalState(false);
  }

  const data = sku_data && Object.keys(sku_data).map((skus, idx) => (
    {
      name: skus,
      dimension: sku_data[skus]['payload']['dimension'],
      category: [sku_data[skus]['payload']['category']],
      weight: sku_data[skus]['payload']['weight'],
      shelfLife: sku_data[skus]['payload']['shelfLife']
    }
  ));

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  return (
    <Page
      title="SKU"
      breadcrumbs={[{ name: 'SKU', active: true }]}
      className="TablePage"
    >
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
      <Modal
        isOpen={modalIsOpen}
        backdrop={true}
        toggle={toggle}
        centered
      >
        <ModalHeader >SKU Details </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name of the SKU</Label>
            <Input type="text" id="name" placeholder="Name" />
          </FormGroup>
          <FormGroup>
            <Label for="category">Category of the SKU</Label>
            {/* <Input type="text" id="category" placeholder="Category" /> */}
            <SKUSelect skuCategory={skuCategory} setSkuCategory={setSkuCategory} />
          </FormGroup>

          <FormGroup>
            <Label for="weight">Weight of the SKU</Label>
            <InputGroup>
              <Input type="number" id="weight" placeholder="Weight" />
              <InputGroupAddon addonType="append">
                <Input type="select" id="weightMetric">
                  <option>grams</option>
                  <option>ounces</option>
                  <option>litres</option>

                </Input>
              </InputGroupAddon>

            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="dimensions">Dimensions of the SKU</Label>
            <InputGroup>
              <Input type="number" id="length" placeholder="Length" />
              <Input type="number" id="width" placeholder="Width" />
              <Input type="number" id="height" placeholder="Height" />
              <InputGroupAddon addonType="append">

                <Input type="select" id="dimensionMetric">
                  <option>cm</option>
                  <option>mm</option>
                  <option>in</option>

                </Input>
              </InputGroupAddon>

            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="shelfLife">Shelf Life of the SKU</Label>
            <InputGroup>
              <Input type="number" id="shelfLife" placeholder="Shelf Life" />
              <InputGroupAddon addonType="append">

                <Input type="select" id="shelfLifeMetric">
                  <option>Weeks</option>
                  <option>Months</option>
                  <option>Years</option>

                </Input>
              </InputGroupAddon>

            </InputGroup>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleadd}>
            Save
                    </Button>
          <Button color="secondary" onClick={() => handleCancel()}>
            Cancel
                    </Button>
        </ModalFooter>
      </Modal>

      <div style={{ position: 'relative', width: '100%', height: '50px' }}>
        <Button color="warning" onClick={() => setmodalIsOpen(true)} style={{ position: 'absolute', right: '0' }}>Add New</Button>
      </div>

      <Table
        dataSource={data}
        bordered
        pagination={{
          pageSize: '12',
          hideOnSinglePage: true
        }}
      >
        <Column {...getColumnSearchProps('name')} title="Name" dataIndex="name" key="name" />
        <Column title="Dimension" dataIndex="dimension" key="dimension" />
        <Column
          title="Categories"
          dataIndex="category"
          key="category"
          render={tags => (
            <>
              {tags.map(tag => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}
            </>
          )}
        />
        <Column title="Weight" dataIndex="weight" key="weight" />
        <Column title="Shelf life" dataIndex="shelfLife" key="shelfLife" />
        <Column
          title="Action"
          key="action"
          render={(text, record) => {
            // console.log(record.name);
            const payload = sku_data[record.name]['payload']
            // console.log("We made it: ", payload);
            // console.log("Expected: ", sku_data[record.name]['payload']);
            return (
              <Space size="middle">
                {/* <a>Edit</a>
                      <a>Delete</a> */}
                <ButtonGroup   >
                  <Button color="primary" onClick={() => handleEdit(payload, record.name)}>Edit</Button>
                  <Button onClick={() => {
                    openDeleteWarning();
                    setSkuDelete(record.name);
                  }}>Delete</Button>
                </ButtonGroup>

                <Modal isOpen={deleteWarningModalState} backdrop={false}>
                  <ModalBody style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <div className="icon" style={{
                      fontSize: '6rem',
                      fontWeight: 'lighter',
                      color: 'red'
                    }}><IoIosCloseCircleOutline /></div>
                    <div className="heading" style={{
                      fontSize: "2rem",
                      color: '#636363',
                      fontFamily: 'Exo'
                    }}>Are you sure?</div>
                    <div className="description" style={{
                      color: '#B4A19B',
                      fontSize: '1.1rem',
                      textAlign: 'center',
                      width: '80%'
                    }}>Do you really want to delete this SKU? This process cannot be undone.</div>
                    <div className="buttons" style={{
                      width: '35%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '20px 0'
                    }}>
                      <Button color="primary" onClick={closeDeleteWarning}>Cancel</Button>{' '}
                      <Button color="secondary" onClick={() => {
                        // console.log("Deleting: ", skuDelete);
                        handleDelete(skuDelete);
                        closeDeleteWarning();
                      }} >Delete</Button>
                    </div>
                  </ModalBody>
                </Modal>
              </Space>
            )
          }}
        />
      </Table>

    </Page>
  );
};


const mapStateToProps = (state) => ({
  sku_data: state.skus.sku,
  uid: state.auth.uid
});

const mapDispatchToProps = (dispatch) => {
  return {
    addSkus: (uid, payload, key, callback) => dispatch(addSku(uid, { payload }, { key }, callback)),
    deleteSkus: (uid, key) => dispatch(deleteSku(uid, { key })),
    startGetSkus: (uid) => dispatch(startGetSku(uid))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SkuPage);

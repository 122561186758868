import CLOUD_FUNCTIONS_URLS from '../../../../firebase/cf';

export const fetchLocations = (value, token) => {
    return new Promise((resolve, reject) => {
        fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnFetchLocation}?value=${value}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(body => {
                if (!body.data.length) {
                    reject('Could not find any locations, please check that you have spelled it correctly');
                }
                const data = body.data.filter((dataItem) => dataItem.type === 'city' || dataItem.type === 'country' || dataItem.type === 'region' || dataItem.type === 'zip').map(dataItem => {
                    if (dataItem.type === 'city') {
                        return {
                            value: [dataItem.name, dataItem.type, dataItem.key, dataItem.country_code, dataItem.region]
                        }
                    }
                    if (dataItem.type === 'region') {
                        return {
                            value: [dataItem.name, dataItem.type, dataItem.key, dataItem.country_code]
                        }
                    }
                    if (dataItem.type === 'country') {
                        return {
                            value: [dataItem.name, dataItem.type, dataItem.key]
                        }
                    }
                    if (dataItem.type === 'zip') {
                        return {
                            value: [dataItem.name, dataItem.type, dataItem.key, dataItem.region, dataItem.country_name]
                        }
                    }
                    else {
                        return {
                            value: [dataItem.name, dataItem.type, dataItem.key]
                        }
                    }

                });
                resolve(data);
            })
            .catch((error) => {
                reject('Could not fetch locations. Please contact Freestand admin.')
            });
    })
}
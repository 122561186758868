import React from "react";
import { Col } from 'reactstrap';
import { Card, CardDeck, CardImg } from 'reactstrap';

const IconsComponent = ({icon, id, className}) => {
    return (
        <CardDeck
            // id={`${button.id}-img`}
            // key={idx}
            className={className}
        >
            <Card>
                <CardImg
                    top
                    width="100%"
                    src={icon}
                    alt={id}
                />
            </Card>
        </CardDeck>
    );
};

export default IconsComponent;

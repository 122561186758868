import database from '../../firebase/firebase';
import CLOUD_FUNCTIONS_URLS from '../../firebase/cf';

// SET_CAMPAIGN_STATS
export const setCampaignStats = (stats = { claimed: '0', delivered: '0', happy: '0' }) => ({
    type: 'SET_CAMPAIGN_STATS',
    stats
});

export const startSetCampaignStats = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        // yaha par campaign_id bhi state se uthana padega
        return database.ref(`users/${uid}/campaigns/${campaign}/live`).on('value', (snapshot) => {
            const stats = snapshot.val();
            dispatch(setCampaignStats(stats));
        })
    }
}

// SET_CLAIMS_STATS
export const setClaimsStats = (claims) => ({
    type: 'SET_CLAIMS_STATS',
    claims
});

export const getAllClaims = (campaign) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        // TODO: Bhai fix this, create a new seperate stub, don't pick the entire base!
        database.ref(`users/${uid}/campaigns/${campaign}/mapdata/datapoints/`).once('value').then((snapshot) => {
            if(snapshot.exists()){
                const data = snapshot.val();
                let stats = {}
                Object.values(data).forEach(sku => stats={...stats, ...sku})
                dispatch(setClaimsStats(stats));
            }
        }).catch(err => console.log(err))
    }
}

export const setActiveUser = (id) => ({
    type: 'SET_ACTIVE_USER',
    payload: id
})

export const setActiveUserDetails = (data) => {
    // console.log(data);
    return {
        type: 'SET_ACTIVE_USER_DETAILS',
        payload: data
    }
}

// GET_ACTIVE_USER_DETAILS
export const getActiveUserDetails = (fbid) => {
    return (dispatch, getState) => {
        //FIXME: Remove this unnecessary check
        const uid = getState().auth.uid;
        const token = getState().firebase.auth_token;

        fetch(`${CLOUD_FUNCTIONS_URLS.remoteManychatOnFetchManychatDetails}?uid=${uid}&fbid=${fbid}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(res =>  res.json().then(r => dispatch(setActiveUserDetails(r.data))))
        .catch(err => {
            console.log('Manychats CF returned an error', err)
        })
    }
}


//SET_SOCIAL_CONFIGS
export const setSocialConfigs = (campaign, pageConfig, userID, user_token, instaId) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        database.ref(`users/${uid}/campaigns/${campaign}/social/social_config/`)
        .update({
            access_token: pageConfig.access_token,
            page_id: pageConfig.id,
            user_id: userID,
            user_access_token: user_token,
            insta_id: instaId
        }).catch(e => console.log(e))
    }
}


// SET_DATASTUDIO_STATS
export const setDataStudioStats = (dataStudioStats = { sales: '', facebook: '', insta: '', messenger: '', google: '', user: '' }) => ({
    type: 'SET_DATASTUDIO_STATS',
    dataStudioStats
});

export const startSetDataStudioStats = () => {
    return (dispatch, getState) => {
        // campaign id bhi uthao state se
        const uid = getState().auth.uid;
        return database.ref(`users/${uid}/campaigns/Demo/social`).once('value').then(snapshot => {
            const dataStudioStats = {};
            snapshot.forEach(childSnapshot => {
                if (childSnapshot.key === 'facebook' || childSnapshot.key === 'insta' || childSnapshot.key === 'google' || childSnapshot.key === 'messenger' || childSnapshot.key === 'sales' ||  childSnapshot.key === 'user') {
                    dataStudioStats[childSnapshot.key] = childSnapshot.val();
                }
            });
            dispatch(setDataStudioStats(dataStudioStats));
        })
        .catch(err => {
            console.log('Waiting for uid', uid);
        });
    }
}
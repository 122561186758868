import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardColumns,
    Collapse,
    Row,
    Col,
    Alert
  } from 'reactstrap';
import {MdKeyboardArrowLeft, MdKeyboardArrowDown, MdOpenInNew} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllTasks } from '../../redux/tasks/tasks.actions';
import { setSelectedCamp } from '../../redux/campaigns/campaigns.actions';
  
const CampaignDetails = ({selectedcamp, campaignslist, setSelected, getTasks, tasklist }) => {

    const getValue = (arr, norm=true) => {
        let myval = "~";
        if (typeof(arr)==typeof([])) {
            if (norm) {
                arr.map(val => {
                    if (myval==="~") myval = val;
                    else if (typeof(val)===typeof("")) myval += ", " + val;
                });
            }
            else {
                arr.map(val => {
                    if (typeof(val)===typeof({})) {
                        if (myval==="~") myval = val.option + " - " + val.subOptions.join(", ");
                        else myval += ", " + val.option + " - " + val.subOptions.join(", ");
                    }
                });
            }
        }
        return myval;
    }

    const details = {
      campaign: {
        objectives: getValue(campaignslist[selectedcamp]["data"].campaignDetails.SamplingObjectives),
        'sampling objectives sub options': getValue(campaignslist[selectedcamp]["data"].campaignDetails.SamplingObjectives, false),
        modules: campaignslist[selectedcamp]["data"].campaignDetails.SamplingModules,
        skuDistribution: campaignslist[selectedcamp]["data"].campaignDetails.SKUDistribution,
        'selected number of samples': campaignslist[selectedcamp]["data"].marketing.SelectedNumberOfSamples,
      },
      product: {
        category: getValue(campaignslist[selectedcamp]["data"].productDetails.ProductCategory),
        'category sub options': getValue(campaignslist[selectedcamp]["data"].productDetails.ProductCategory, false),
      },
      marketing: {
        'minimum age': campaignslist[selectedcamp]["data"].marketing.AgeRange.from,
        'maximum age': campaignslist[selectedcamp]["data"].marketing.AgeRange.to,
        gender: campaignslist[selectedcamp]["data"].marketing.Gender,
        size: campaignslist[selectedcamp]["data"].marketing.Size,
        'Audience Connect': campaignslist[selectedcamp]["data"].marketing.AudienceConnect,
        cities: campaignslist[selectedcamp]["data"].marketing.Cities
      },
      responsibilities: {
        'primary packaging': campaignslist[selectedcamp]["data"].responsibilities.PrimaryPackaging,
        'promotional content': getValue(campaignslist[selectedcamp]["data"].responsibilities.PromotionalContent),
        'promotional content sub options': getValue(campaignslist[selectedcamp]["data"].responsibilities.PromotionalContent, false),
        'pack and delivery': campaignslist[selectedcamp]["data"].responsibilities.PackAndDelivery,
        'creative design': campaignslist[selectedcamp]["data"].responsibilities.AdsDesign,
        'chat flow design': campaignslist[selectedcamp]["data"].responsibilities.InteractionDesign,
        launch: campaignslist[selectedcamp]["data"].responsibilities.Launch,
      },
      sku: campaignslist[selectedcamp]["data"].SelectedSKU
    }

    useEffect(() => {
        if(campaignslist[selectedcamp]["status"]==="approved")
        {
            if(tasklist===undefined || tasklist==null) getTasks();
            else searchSubtasks();
        }
        //return () => setSelected("")
    }, [tasklist]);

    const [sub, setSub] = useState([]);
    const [col, setCol] = useState(false);
    const toggle = () => setCol(!col);

    const getStatus = (st) => {
        switch(st)
        {
            case 'active': return "ACTIVE";
            case 'approved': return "AWAITING YOUR ACTION";
            case 'pending': return "AWAITING ADMIN APPROVAL";
            case 'incomplete': return "INCOMPLETE";
        }
    };

    const getColor = (st) => {
        switch(st)
        {
            case 'active': return "text-success";
            case 'approved': return "text-warning";
            case 'pending': return "text-secondary";
            case 'incomplete': return "text-danger";
        }
    };

    const mysubtasks = {
        "inventory": {
            "name":"Schedule your inventory",
            "desc":"To move further towards activating your campaign, let's head over to the Inventory Scheduler.",
            "link":"/inventory/scheduler"
        },
        "integrations": {
            "name":"Complete Integrations",
            "desc":"The next step is assigning roles and responsibilities, this can be easily done through the integrations tab.",
            "link":"/integrations"
        }
    };

    const searchSubtasks = () => {
        Object.keys(tasklist).forEach(task => {
            if(tasklist[task]["category"]==="Campaign Activation")
            {
                if(tasklist[task]["campaign"]===selectedcamp)
                {
                    let subtasks = [];
                    Object.keys(tasklist[task]["subtasks"]).forEach(st => {
                        if(tasklist[task]["subtasks"][st]) subtasks.push(st);
                    })
                    setSub(subtasks)
                }
            }
        });
    }

    return(
        <Card>
            <CardHeader className="h4 text-dark" style={{textTransform: "none"}}>
                <Row>
                    <Col className="mb-0">
                        <Button color="primary" onClick={() => setSelected("")}>
                            <MdKeyboardArrowLeft style={{fontSize: "1.5em"}} />Back
                        </Button>
                    </Col>
                    <Col className="text-right mb-0">
                        <span>STATUS: </span>
                        <span className={getColor(campaignslist[selectedcamp]["status"])}>
                            {getStatus(campaignslist[selectedcamp]["status"])}
                            {campaignslist[selectedcamp]["status"]==="approved" && (
                                <Button size="sm" color="warning" onClick={toggle} style={{marginLeft: "10px"}}>
                                    Show <MdKeyboardArrowDown />
                                </Button>
                            )}
                        </span>
                    </Col>
                </Row>
                {campaignslist[selectedcamp]["status"]==="approved" && (
                    <div style={{fontSize: "0.7em"}}>
                        <Collapse isOpen={col} style={{paddingTop: "10px"}}>
                            {sub.length!==0 && sub.map(st =>
                                <Row key={st} style={{padding: "0px 0px 10px 10px"}}>
                                    <Col className="desc-column" style={{flexGrow: "7", alignSelf: "center", margin: "0"}}>
                                        <b>{mysubtasks[st]["name"]}: </b>{mysubtasks[st]["desc"]}
                                    </Col>
                                    <Col className="btn-group" style={{textAlign: "right"}}>
                                        <Link to={mysubtasks[st]["link"]}>
                                            <Button size="sm" color="success">
                                                <MdOpenInNew /> Go
                                            </Button> 
                                        </Link>
                                    </Col>
                                </Row>
                            )}
                            {sub.length===0 && <Alert>All Pending Tasks are in Order! Status will be updated soon</Alert>}
                        </Collapse>
                    </div>
                )}
            </CardHeader>
            <CardBody>
                <CardColumns>
                    {Object.keys(details).filter(el => el !== 'sku').map(heading =>
                        <Card outline color="primary" key={heading}>
                            <CardHeader>
                                <h3>{heading.toUpperCase()} DETAILS</h3>
                            </CardHeader>
                            <CardBody>
                                {Object.keys(details[heading]).map(key => {
                                    if (details[heading][key]!==undefined) {
                                        return (<p key={key}><b>{key.toUpperCase()}:</b> <span>{details[heading][key].toString()}</span></p>);
                                    } else {
                                        return (<p key={key}><b>{key.toUpperCase()}:</b>N/A</p>)
                                    }
                                })}
                            </CardBody>
                        </Card>
                    )}
                </CardColumns>
            </CardBody>
        </Card>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getTasks: () => dispatch(getAllTasks()),
    setSelected: (name) => dispatch(setSelectedCamp(name))
});

const mapStateToProps = (state) => ({
    campaignslist: state.campaigns.campaignslist,
    tasklist: state.tasks.tasklist,
    selectedcamp: state.campaigns.selectedCampaign
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
import React, { useState, useEffect } from "react";
import { predefinedData } from "../predefinedData";
import InnerSlider from "../PredefinedSlides/InnerSlider";
import { connect } from 'react-redux';
import { setProductCategory } from '../../../../redux/cac/productDetails/productDetails.actions';
import QuestionWithOptions from "../PredefinedSlides/QuestionWithOptions";
import SkuSlideComponent from "../CustomSlides/SkuSlideComponent";

const ProductDetails = (props) => {
    const {
        ProductCategory
    } = props.productDetails;

    const {
        setProductCategory
    } = props;

    return (
        <InnerSlider>
            {/* Product cateogry */}
            <QuestionWithOptions
                heading="Category"
                description="Select your Product Category"
                options={predefinedData.optionButtonsCategories}
                sendValueToStore={setProductCategory}
                multiple
                icons
                storeVariable={ProductCategory}
            />

            {/* Select SKU */}
            <SkuSlideComponent />
        </InnerSlider>
    );
};

const mapStateToProps = (state) => ({
    productDetails: state.cac.productDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setProductCategory: (category) => dispatch(setProductCategory(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
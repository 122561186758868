import React, { useState, useEffect, createRef } from 'react';
import { Card } from 'antd';
import Page from '../components/page/Page';
import FacebookAds from '../assets/img/integrations/facebook-ads.svg';
import Facebook from '../assets/img/integrations/facebook.svg';
import Agency from '../assets/img/integrations/agency.svg';
import Chatbot from '../assets/img/integrations/chatbot.svg';

import FBLoginComponent from '../components/fb-login/FBLogin.component';
import ErrorModal from '../components/integrations/errorModal.component';
import ModalComponent from '../components/integrations/modal.component';

import { Button, Spinner, Popover, PopoverHeader, PopoverBody, Row } from 'reactstrap';
import '../styles/components/_integrations.scss';
import { firebase } from '../firebase/firebase';
import { connect, useSelector } from 'react-redux';
import { IoIosClose } from 'react-icons/io';
import CLOUD_FUNCTIONS_URLS from '../firebase/cf';

const IntegrationCard = ({ icon, heading, content, integration, userId, data }) => {
    const [visible, setVisible] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [validationLoading, setValidationLoading] = useState(false);
    const [isErrorModalOpen, setErrorModalIsOpen] = useState(false);
    const [status, setStatus] = useState('');
    // const toggle = () => setPopoverOpen(!popoverOpen);
    const token = useSelector(state => state.firebase.auth_token)

    const validateConnection = () => {
        setValidationLoading(true);

        switch(integration) {
            case 'facebook-page':
                fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnPageConnectionValidation}?userId=${userId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(res => {
                        // console.log(res);
                        return res.json();
                    })
                    .then(data => {
                        setStatus(data.status);
                        setValidationLoading(false);
                        setErrorModalIsOpen(true);
                        setPopoverOpen(false);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    break;
                case 'facebook-ads-account':
                    fetch(`${CLOUD_FUNCTIONS_URLS.remoteCacFacebookCallsOnAdsAccountConnectionValidation}?userId=${userId}`, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(res => {
                            // console.log(res);
                            return res.json();
                        })
                        .then(data => {
                            setStatus(data.status);
                            setValidationLoading(false);
                            setErrorModalIsOpen(true);
                            setPopoverOpen(false);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        break;
                default:
                    console.log('Something bad happened');
        }
    }

    return (
        <div className="integration-card">
            <Card className="integration-card">
                <div className="icon">
                    <img src={icon} alt="" />
                </div>
                <div className="content">
                    <h4>{heading}</h4>
                    <p>{content}</p>
                </div>

                {
                    data ? (
                        <div className="buttons-container">
                            {
                                data.status.toLowerCase() === 'pending' && (
                                    <div className="buttons-container">
                                        <Button
                                            id={integration}
                                            outline
                                            color="secondary"
                                            onMouseEnter={() => setPopoverOpen(true)}
                                            // onMouseLeave={() => setPopoverOpen(false)}
                                            className="connect-button"
                                        >
                                            Request sent
                                        </Button>
                                        <Popover placement="bottom" isOpen={popoverOpen} target={integration}>
                                            <PopoverHeader><span>Request sent</span><IoIosClose className="icon" onClick={() => setPopoverOpen(false)} /></PopoverHeader>
                                            <PopoverBody>
                                                <span>A request has been sent to your </span>
                                                {
                                                    integration === 'facebook-page' && <span>page, </span>
                                                }
                                                {
                                                    integration === 'facebook-ads-account' && <span>ads account, </span>
                                                }
                                                <span>please do the following to complete the connection process: </span>
                                                <br />
                                                <br />
                                                {
                                                    integration === 'facebook-page' && <span>1) Accept the request from freestand on your page</span>
                                                }
                                                {
                                                    integration === 'facebook-ads-account' && <span>1) Accept the request from freestand on your Ad Account Settings</span>
                                                }
                                                
                                                <br />
                                                <br />
                                                <span>2) Click the 'Validate Connection' button below</span>
                                                <br />
                                                <br />
                                                <div className="validate-btn-container">
                                                    <Button
                                                        id="validate-btn"
                                                        outline
                                                        color="primary"
                                                        className="connect-button"
                                                        onClick={validateConnection}
                                                    >
                                                        {
                                                            validationLoading ? <Spinner id='spinner' /> : <span>Validate Connection</span>
                                                        }
                                                    </Button>
                                                </div>
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                )
                            }

                            {
                               ( data.status.toLowerCase() === 'connected'|| data.status.toLowerCase() === 'confirmed') && (
                                    <div className="connected-button-container">
                                        <Button
                                            id={integration}
                                            outline
                                            color="success"
                                            onMouseEnter={() => setPopoverOpen(true)}
                                            onMouseLeave={() => setPopoverOpen(false)}
                                            className="connect-button"
                                        >
                                            Connected
                                        </Button>
                                        <Popover placement="bottom" isOpen={popoverOpen} target={integration}>
                                            <PopoverHeader>Connected</PopoverHeader>
                                            <PopoverBody>
                                                {
                                                    integration === 'facebook-page' && <span>Your facebook page </span>
                                                }
                                                {
                                                    integration === 'facebook-ads-account' && <span>Your facebook ads account </span>
                                                }
                                                <span>is connected </span>
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                )
                            }

                            {
                                data.status.toLowerCase() === 'sent' && (
                                    <div className="connected-button-container">
                                        <Button
                                            id={integration}
                                            outline
                                            color="success"
                                            onMouseEnter={() => setPopoverOpen(true)}
                                            onMouseLeave={() => setPopoverOpen(false)}
                                            className="connect-button"
                                        >
                                            Email sent
                                        </Button>
                                        <Popover placement="bottom" isOpen={popoverOpen} target={integration}>
                                            <PopoverHeader>Connected</PopoverHeader>
                                            <PopoverBody>
                                                <span>An email has been sent to your agency</span>
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                )
                            }

                        </div>
                    ) : (
                            <Button className="connect-button" onClick={() => {
                                setVisible(true);
                            }}>Connect</Button>
                        )
                }
            </Card>

            <ModalComponent
                visible={visible}
                setVisible={setVisible}
                title={heading}
                integration={integration}
                userId={userId}
            />
            <ErrorModal isOpen={isErrorModalOpen} setIsOpen={setErrorModalIsOpen} status={status} />
        </div>
    );
}

const Integrations = ({ userId }) => {
    const [data, setData] = useState({});

    useEffect(() => {
        const integrationsRef = firebase.database().ref(`users/${userId}/integrations`);

        integrationsRef.on('value', snapshot => {
            setData(snapshot.val());
        });
    }, []);

    return (
        <Page
            title="Integrations"
            breadcrumbs={[{ name: 'Integrations', active: true }]}
        >
            <div className="integrations-page">
                <Row>

                    <FBLoginComponent />

                    {/* 100483718403023  */}
                    <IntegrationCard
                    icon={Facebook}
                    heading='Facebook Page'
                    content="Give your facebook page's access to Freestand"
                    integration='facebook-page'
                    data={data && data['facebookPage']}
                    userId={userId}
                    />
                   
                    {/* 61674701 */}
                    
                    <IntegrationCard
                    icon={FacebookAds}
                    heading='Facebook Ads Account'
                    content='Connect your facebook ads account'
                    integration='facebook-ads-account'
                    data={data && data['facebookAdsAccount']}
                    userId={userId}
                />
                    {/* <Col>  <IntegrationCard
                    icon={Chatbot}
                    heading='ChatBot'
                    content='Connect your chatbot'
                    integration='chatbot'
                /></Col> */}


         
                    <IntegrationCard
                    icon={Agency}
                    heading='Agency'
                    content='Connect and collaborate with your Agency'
                    integration='agency'
                    userId={userId}
                    data={data && data['agency']}
                />

                </Row>
              
            </div>

        </Page>
    );
};

const mapStateToProps = (state) => {
    return ({
        userId: state.auth.uid
    })
}

export default connect(mapStateToProps)(Integrations);
import React, { useEffect, useState } from 'react';
import InnerSlider from './InnerSlider';
import { CardDeck, Card, CardBody, CardText, CardHeader, Popover } from 'reactstrap';
import SlideWrapper from './SlideWrapper';
import { connect } from 'react-redux';

import { setFSAudience } from '../../../../redux/cac/marketing/marketing.actions';
import { setSize } from '../../../../redux/cac/marketing/marketing.actions';
import { setAudienceConnect } from '../../../../redux/cac/marketing/marketing.actions';
import { predefinedData } from '../predefinedData';
import QuestionWithOptions from './QuestionWithOptions';
import NumberOfSamplesQuestion from '../CustomSlides/NumberOfSamplesQuestion';

const FSCommunitySlides = (props) => {
    const [selectedAudience, setSelectedAudience] = useState([]);

    const FSAudience = [
        {
            audience: 'Parents',
            size: '10000',
            cities: 'Mumbai'
        },
        {
            audience: 'Posh',
            size: '4000',
            cities: 'Delhi'
        },
        {
            audience: 'Young',
            size: '20000',
            cities: 'Delhi'
        }
    ]

    const {
        setFSAudience,
        setSize,
        setAudienceConnect
    } = props;

    const {
        SelectedFSAudience,
        AudienceConnect
    } = props.marketing;

    useEffect(() => {
        if(SelectedFSAudience) {
            setSelectedAudience(SelectedFSAudience)
        }
    }, [SelectedFSAudience]);

    const handleClick = (event, size) => {

        Array.from(document.getElementsByClassName('audience-select-button')).forEach((button) => {
            button.classList.remove('selected');
        })
        // event.target.classList.add('selected');

        const { value } = event.target;
        setSelectedAudience(value);
        setFSAudience(value);
        setSize(size);
    }

    return (
        <InnerSlider>
            <SlideWrapper
                heading='Target Audience'
                description="Since you selected Freestand community, please select your preference from our existing community"
            >
                {/* Select the FS audience */}
                <CardDeck style={{
                    marginTop: '40px'
                }}>
                    {
                        FSAudience.map((item, idx) => {
                            let className = '';
                            if(selectedAudience.includes(item.audience + '-' + item.cities)) {
                                className = 'selected'
                            }

                            return (
                                <Card key={idx}>
                                    <CardHeader style={{
                                        backgroundColor: '#052762',
                                        color: 'white'
                                    }}>{item.audience}</CardHeader>
                                    <CardBody>
                                        <CardText>Audience: {item.audience}</CardText>
                                        <CardText>Size: {item.size}</CardText>
                                        <CardText>Cities: {item.cities}</CardText>
                                        <button
                                            name='SelectedFSAudience'
                                            value={item.audience + '-' + item.cities}
                                            className={"audience-select-button " + className}
                                            onClick={(event) => handleClick(event, item.size)}
                                        >
                                            Select
                                        </button>
                                    </CardBody>
                                </Card>
                            )
                        })
                    }
                </CardDeck>
            </SlideWrapper>
            <SlideWrapper>
                <NumberOfSamplesQuestion />

                {/* Audience connection */}
                <QuestionWithOptions
                    heading="Audience interaction"
                    description="How would you like to connect with your audience?"
                    options={predefinedData.optionButtonsAudienceConnect}
                    sendValueToStore={setAudienceConnect}
                    icons={false}
                    storeVariable={AudienceConnect}
                    // size="small"
                />
            </SlideWrapper>
        </InnerSlider>
    )
}

const mapStateToProps = (state) => ({
    marketing: state.cac.marketing
});

const mapDispatchToProps = (dispatch) => {
    return {
        setFSAudience: (FSAudience) => dispatch(setFSAudience(FSAudience)),
        setSize: (size) => dispatch(setSize(size)),
        setAudienceConnect: (audienceConnect) => dispatch(setAudienceConnect(audienceConnect))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FSCommunitySlides);
export const deleteGeoLocationHelper = (type, id, state) => {
    let newArray = undefined;
    
    switch (type) {
        case 'country':
            newArray = state.targeting_specs.geo_locations.countries.filter(c => c !== id);
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        countries: newArray
                    }
                }
            }

        case 'city':
            newArray = state.targeting_specs.geo_locations.cities.filter(c => c.key !== id)
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        cities: newArray
                    }
                }
            }

        case 'region':
            newArray = state.targeting_specs.geo_locations.regions.filter(c => c.key !== id)
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        regions: newArray
                    }
                }
            }

        case 'zip':
            newArray = state.targeting_specs.geo_locations.zips.filter(c => c.key !== id)
            return {
                ...state,
                targeting_specs: {
                    ...state.targeting_specs,
                    geo_locations: {
                        ...state.targeting_specs.geo_locations,
                        zips: newArray
                    }
                }
            }
    }
}
const INITIAL_STATE = {
    isLastOuterSlider: false,
    isMarketingSlide: false,
    incompleteData: [],
    isSubmissionLoading: false
}

const globalCacStatesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_IS_LAST_OUTER_SLIDER':
            return {
                ...state,
                isLastOuterSlider: action.payload
            }
        case 'SET_IS_MARKETING_SLIDE':
            return {
                ...state,
                isMarketingSlide: action.payload
            }
        case 'SET_INCOMPLETE_DATA':
            return {
                ...state,
                incompleteData: action.payload
            }
        case 'SET_SUBMISSION_LOADING':
            return {
                ...state,
                isSubmissionLoading: action.payload
            }
        case 'RESET_GLOBAL_CAC_STATES':
            return {
                ...INITIAL_STATE
            }
        default: 
            return state
    }
}

export default globalCacStatesReducer;
import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardDeck,
    CardBody,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    Modal
} from 'reactstrap';

import { GoPackage } from 'react-icons/go';
import { BsChatDotsFill } from 'react-icons/bs';
import { AiOutlineDollar } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { GiCardRandom } from 'react-icons/gi';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { addQuery, getCamps } from '../../redux/queries/queries.actions';
import placementSuccess from '../../assets/img/placement-success.gif';
import placementLoader from '../../assets/img/placement-loader.gif';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

const QueryForm = ({ campslist, getcampslist, addthisQuery, progress, history, userdata }) => {
    const notificationSystem = useRef();

    const [actionOngoing, setAction] = useState(false);
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [campname, setCamp] = useState("nocampaign");

    const NothingSelected = (category === "");
    const DangerSelected = (category === "order");
    const PrimarySelected = (category === "chatbot");
    const WarningSelected = (category === "billing");
    const SuccessSelected = (category === "waybill");
    const InfoSelected = (category === "others");
    const SubSelected = !(subcategory === "");
    const inProgress = (progress !== "FINISHED" && progress !== "");
    const isFinished = progress === "FINISHED";

    const defaultmail = userdata === undefined ? "" : userdata["email"];

    const ticketID = (Math.floor(new Date().getTime() / 1000)).toString(36).toUpperCase();

    const categories = {
        "order": {
            "inventory": "Inventory Issues",
            "refusal": "Refusal by Client",
            "tracking": "Delivery Tracking"
        },
        "chatbot": {
            "message": "Change Messages Copy",
            "options": "Change Options Copy"
        },
        "billing": {
            "previous": "Issue with previous Billing",
            "current": "Get Current billing",
            "invoice": "Get Invoice"
        }
    }

    useEffect(() => {
        getcampslist();
    }, [getcampslist])

    const handleSubmit = () => {
        const validform = document.querySelector('form').reportValidity();
        if (validform) {
            const $inputFields = document.querySelectorAll(".query_input");
            const $response = document.getElementById("response_input").value;
            const $uploadfile = document.getElementById("input_upload").files[0];
            const $querydata = {};
            $querydata["id"] = ticketID;
            $querydata['status'] = 'unsolved';
            $querydata['campaign'] = campname;
            $inputFields.forEach(field => $querydata[field.name] = field.value);
            $querydata["category"] = category + (SubSelected ? "-" + subcategory : "");
            if ($uploadfile !== undefined) {
                addthisQuery($querydata, $response, $uploadfile, () => {
                    if (!notificationSystem) {
                        return;
                    }
                    notificationSystem.current.addNotification({
                        title: <FiCheck />,
                        message: 'Your query has been recorded',
                        level: 'info',
                    });
                });
            }
            else addthisQuery($querydata, $response, "", () => {
                if (!notificationSystem) {
                    return;
                }
                notificationSystem.current.addNotification({
                    title: <FiCheck />,
                    message: 'Your query has been recorded',
                    level: 'info',
                });
            });
            setAction(true);
        }
    }

    const handleSelect = (e) => {
        setSubcategory(e.target.value);
    }

    const handleCampselect = (e) => {
        setCamp(e.target.value);
    }

    const handleCategory = (e) => {
        if (NothingSelected) setCategory(e.target.id);
        else {
            setCategory("");
            setSubcategory("");
        }
    }

    const handleReload = () => {
        setAction(false);
        history.push(`/help`);
    }

    return (
        <div className="bg-white p-4">
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem}
                style={NOTIFICATION_SYSTEM_STYLE}
            />
            <Link to="/help"><Button color="secondary"><MdKeyboardArrowLeft style={{ fontSize: "1.5em" }} />Back</Button></Link>
            <span className="h4 float-right">TICKET ID: {ticketID}</span><br /><br />
            <Form className="bg-light p-4">
                <FormGroup>
                    <Label for="campaign">Campaign</Label>
                    <Input required type="select" name="campaign" defaultValue="nocampaign" onChange={handleCampselect}>
                        <option value="nocampaign" hidden>SELECT A CAMPAIGN</option>
                        {campslist.map(key => <option key={key} value={key}>{key.toUpperCase()}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="email">E-mail<span style={{ color: "red" }}>*</span></Label>
                    <Input readOnly value={defaultmail} type="email" name="email" className="query_input" />
                </FormGroup>
                <FormGroup>
                    <Label for="question">Subject<span style={{ color: "red" }}>*</span></Label>
                    <Input required type="text" name="question" placeholder="What is this query about" className="query_input" />
                </FormGroup>
                <Label>Select A Category</Label>
                <CardDeck>
                    {(NothingSelected || DangerSelected) && (
                        <Card style={{ minWidth: "220px", backgroundColor: "#ff6961" }}>
                            <CardBody id="order" onClick={handleCategory} className="can-click d-flex align-items-center">
                                <span id="order" onClick={handleCategory} style={{ fontSize: NothingSelected ? "1.4em" : "2em" }}>
                                    <GoPackage /> Order&Delivery
                            </span>
                            </CardBody>
                        </Card>)}
                    {(NothingSelected || PrimarySelected) && (
                        <Card style={{ minWidth: "180px", backgroundColor: "#757EFB" }}>
                            <CardBody id="chatbot" onClick={handleCategory} className="can-click d-flex align-items-center">
                                <span id="chatbot" onClick={handleCategory} style={{ fontSize: NothingSelected ? "1.4em" : "2em" }}>
                                    <BsChatDotsFill /> Chat-Bot
                            </span>
                            </CardBody>
                        </Card>)}
                    {(NothingSelected || WarningSelected) && (
                        <Card style={{ minWidth: "160px", backgroundColor: "#ebe534" }}>
                            <CardBody id="billing" onClick={handleCategory} className="can-click d-flex align-items-center">
                                <span id="billing" onClick={handleCategory} style={{ fontSize: NothingSelected ? "1.4em" : "2em" }}>
                                    <AiOutlineDollar /> Billing
                            </span>
                            </CardBody>
                        </Card>)}
                    {(NothingSelected || SuccessSelected) && (
                        <Card style={{ minWidth: "220px", backgroundColor: "#70c33c" }}>
                            <CardBody id="waybill" onClick={handleCategory} className="can-click d-flex align-items-center">
                                <span id="waybill" onClick={handleCategory} style={{ fontSize: NothingSelected ? "1.4em" : "2em" }}>
                                    <FaClipboardList /> Request Waybill
                            </span>
                            </CardBody>
                        </Card>)}
                    {(NothingSelected || InfoSelected) && (
                        <Card style={{ minWidth: "160px", backgroundColor: "#3bc48f" }}>
                            <CardBody id="others" onClick={handleCategory} className="can-click d-flex align-items-center">
                                <span id="others" onClick={handleCategory} style={{ fontSize: NothingSelected ? "1.4em" : "2em" }}>
                                    <GiCardRandom /> Other
                            </span>
                            </CardBody>
                        </Card>)}
                </CardDeck><br />
                {(!NothingSelected && !InfoSelected && !SuccessSelected) && (
                    <FormGroup>
                        <Label for="subcategory">SubCategory</Label>
                        <Input type="select" name="subcategory" defaultValue="" onChange={handleSelect}>
                            <option value="" disabled>Select</option>
                            {Object.keys(categories[category]).map(key => <option key={key} value={key}>{categories[category][key]}</option>)}
                        </Input>
                    </FormGroup>)}
                {(SubSelected || SuccessSelected || InfoSelected) && (
                    <div>
                        <FormGroup>
                            <Label for="response">Query<span style={{ color: "red" }}>*</span></Label>
                            <Input
                                required
                                type="textarea"
                                name="response"
                                placeholder="Please tell us more about your issue"
                                id="response_input"
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="text-dark rounded mb-0 bg-light p-2">
                                Upload Files to help us understand your query better<br />
                                <input type="file" accept="image/*,.pdf" id="input_upload" />
                            </div>
                        </FormGroup>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </div>)}
            </Form>
            {inProgress && (
                <Modal isOpen={true} backdrop="static" centered={true}>
                    <center>
                        <img src={placementLoader} alt="placement loader gif" width="180" height="180" />
                        <h4>Please wait...</h4>
                    </center>
                </Modal>
            )}
            {isFinished && (
                <Modal isOpen={actionOngoing} backdrop="static" centered={true}>
                    <center>
                        <img src={placementSuccess} alt="placement success gif" width="180" height="180" />
                        <h4>Success!</h4>
                    </center>
                    <Button color="dark" onClick={handleReload}>Go back</Button>
                </Modal>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    addthisQuery: ($querydata, $response, $filename, callback) =>
        dispatch(addQuery($querydata, $response, $filename, callback)),

    getcampslist: () => dispatch(getCamps())
});

const mapStateToProps = (state) => ({
    progress: state.queries.progress,
    userdata: state.profile.userdata,
    campslist: state.queries.campslist
});

const RoutedQueryForm = withRouter(QueryForm);

export default connect(mapStateToProps, mapDispatchToProps)(RoutedQueryForm);
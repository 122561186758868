import React from 'react';
import { predefinedData } from "../predefinedData";
import InnerSlider from './InnerSlider';
import CitySelect from './CitySelect';
import { listOfCities } from '../city-list';
import { connect } from 'react-redux';
import { setCities } from '../../../../redux/cac/marketing/marketing.actions';
import SlideWrapper from './SlideWrapper';
import QuestionWithOptions from './QuestionWithOptions';
import AgeQuestion from './AgeQuestion';
import RangeSelector from './RangeSelector';
import InputQuestionComponent from './InputQuestionComponent';
import { setAgeRange } from '../../../../redux/cac/marketing/marketing.actions';
import { setGender } from '../../../../redux/cac/marketing/marketing.actions';
import { setSize } from '../../../../redux/cac/marketing/marketing.actions';
import { setAudienceReachOptions } from '../../../../redux/cac/marketing/marketing.actions';
import { setMoreInfo } from '../../../../redux/cac/marketing/marketing.actions';
import { setAudienceConnect } from '../../../../redux/cac/marketing/marketing.actions';
import NumberOfSamplesQuestion from '../CustomSlides/NumberOfSamplesQuestion';

const ExistingCommunitySlides = (props) => {
    const {
        setCities,
        setAgeRange,
        setGender,
        setSize,
        setAudienceReachOptions,
        setMoreInfo,
        setAudienceConnect
    } = props;

    const {
        AgeRange,
        Gender,
        Size,
        reach,
        MoreInfoCommunity,
        Cities,
        AudienceConnect
    } = props.marketing;

    return (
        <InnerSlider>
            {/* Slide 1 */}
            <SlideWrapper
                heading='Target Audience'
                description="We would like to know about your community so that we can reach them for you."
            >
                {/* Age */}
                <AgeQuestion
                    heading="Age"
                    description="Select the range of age in which your audience lies in"
                    sendValueToStore={setAgeRange}
                    storeVariable={AgeRange}
                />

                {/* Gender */}
                <QuestionWithOptions
                    heading="Gender"
                    description="Select gender which is more dominant in your audience. Select all if they're equal."
                    options={predefinedData.optionButtonsGender}
                    sendValueToStore={setGender}
                    size='small'
                    storeVariable={Gender}
                />

                {/* Audience Size */}
                <RangeSelector
                    heading="Size"
                    description="Mention the size of your audience (in thousands)"
                    sendValueToStore={setSize}
                    storeVariable={Size}
                />

                {/* Reach */}
                <QuestionWithOptions
                    heading="Reach"
                    description="How can we reach your audience"
                    options={predefinedData.reachOptions}
                    sendValueToStore={setAudienceReachOptions}
                    size='small'
                    storeVariable={reach}
                />

                {/* More */}
                <InputQuestionComponent
                    heading="Tell us more"
                    description="Is there anything you want to mention specifically? We are all ears."
                    placeholder="Enter here"
                    sendValueToStore={setMoreInfo}
                    storeVariable={MoreInfoCommunity}
                />
            </SlideWrapper>

            {/* Slide 2 */}
            <SlideWrapper>
                {/* Cities */}
                <CitySelect
                    heading="Select cities"
                    description="Select your audience cities"
                    options={listOfCities}
                    placeholder="Select cities"
                    sendValueToStore={setCities}
                    storeVariable={Cities}
                />

                <NumberOfSamplesQuestion />

                {/* Audience connection */}
                <QuestionWithOptions
                    heading="Audience interaction"
                    description="How would you like to connect with your audience?"
                    options={predefinedData.optionButtonsAudienceConnect}
                    sendValueToStore={setAudienceConnect}
                    icons={false}
                    storeVariable={AudienceConnect}
                    // size="small"
                />
            </SlideWrapper>

        </InnerSlider>
    )
}

const mapStateToProps = (state) => ({
    marketing: state.cac.marketing
});

const mapDispatchToProps = (dispatch) => ({
    setCities: (cities) => dispatch(setCities(cities)),
    setAgeRange: (ageRange) => dispatch(setAgeRange(ageRange)),
    setGender: (gender) => dispatch(setGender(gender)),
    setSize: (size) => dispatch(setSize(size)),
    setAudienceReachOptions: (audienceReachOptions) => dispatch(setAudienceReachOptions(audienceReachOptions)),
    setMoreInfo: (moreInfo) => dispatch(setMoreInfo(moreInfo)),
    setAudienceConnect: (audienceConnect) => dispatch(setAudienceConnect(audienceConnect))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExistingCommunitySlides);
export const LOCATIONS = {
    LOAD: 'LOCATIONS_LOAD',
    FAIL: 'LOCATIONS_FAIL',
    SUCCESS: 'LOCATIONS_SUCCESS'
}

export const INTERESTS = {
    LOAD: 'INTERESTS_LOAD',
    FAIL: 'INTERESTS_FAIL',
    SUCCESS: 'INTERESTS_SUCCESS'
}

export const TARGETING_SPECS = {
    LOAD: 'TARGETING_SPECS_LOAD',
    FAIL: 'TARGETING_SPECS_FAIL',
    SUCCESS: 'TARGETING_SPECS_SUCCESS'
}
import Page from '../components/page/Page';
import QueryForm from '../components/query-form/QueryForm.component';
import React from 'react';

const SupportPage = () => {
    return(
        <Page title='Submit A New Ticket' breadcrumbs={[{name: 'Support / Submit A Ticket', active: true }]}>
            <QueryForm />
        </Page>
    )
}

export default SupportPage;
import { Select, Spin, Input, Tag } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react'
import SliderWrapper from '../PredefinedSlides/SlideWrapper';
import { connect, useSelector } from 'react-redux';

import { addInterest } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { deleteInterest } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { setInterestsArray } from '../../../../redux/cac/socialMediaTargeting/socialMediaTargeting.actions';
import { loadInterests } from '../../../../redux/cac/remote/remote.actions';


const { Option } = Select;

const InterestComponent = ({
    addInterest,
    deleteInterest,
    setInterestsArray,
    interestsArray,
    loadInterests,
    fetching,
    data,
    error,
    errorText
}) => {
    const [result, setResult] = useState([]);

    useEffect(() => {
        if (interestsArray) {
            setResult(interestsArray);
        }
    }, [interestsArray]);


    const fetchResultsFunction = (value) => {
        loadInterests(value);
    }

    const fetchResults = debounce(fetchResultsFunction, 800);

    const handleSelect = (value) => {
        setInterestsArray([...result, value]);
        setResult([...result, value]);
        loadInterests('stop-fetching');
        addInterest(value);
    };

    const handleDeselect = (value) => {
        const newResult = result.filter(v => v[2] !== value[2])
        setResult(newResult);
        deleteInterest(value);
    }

    return (
        <SliderWrapper
            heading='Interest'
            description="Select your audience interests"
        >
            <Select
                style={{
                    minWidth: '50%',
                    maxWidth: '80%',
                }}
                mode="multiple"
                value={result}
                placeholder="Select interests"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={fetchResults}
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                tagRender={(props) => tagRender(props, data)}
                dropdownMatchSelectWidth={false}
            >
                {data && data.map(d => (
                    <Option key={d.value[2]} id={d.value[2]} value={d.value}>
                        <div className="main-content" style={{
                            fontWeight: 'bolder'
                        }}>
                            {d.value[0]} (Size : {d.value[3]})
                            </div>
                        <div className="info-content" style={{
                            fontSize: '0.7rem',
                            position: 'absolute',
                            right: '5px',
                            top: '5px',
                            color: 'black'
                        }}>
                            {d.value[1]}
                        </div>
                    </Option>
                ))}
            </Select>
            {
                error && <p style={{
                    fontSize: '12px',
                    color: '#d21515',
                    marginTop: '15px',
                    position: 'relative',
                    left: '2px'
                }}>{errorText}</p>
            }
        </SliderWrapper>
    )
}

function tagRender(props, data) {
    const { value, closable, onClose } = props;

    return (
        <Tag color='#052762' closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {value[0]}
        </Tag>
    );
}

const mapStateToProps = (state) => ({
    interestsArray: state.cac.socialMediaTargeting.InterestsArray,
    fetching: state.cac.remote.interests.isInterestsLoading,
    data: state.cac.remote.interests.interests,
    error: state.cac.remote.interests.interestsLoadError,
    errorText: state.cac.remote.interests.interestsLoadErrorText
})

const mapDispatchToProps = (dispatch) => ({
    addInterest: (object) => dispatch(addInterest(object)),
    deleteInterest: (object) => dispatch(deleteInterest(object)),
    setInterestsArray: (interestsArray) => dispatch(setInterestsArray(interestsArray)),
    loadInterests: (value) => dispatch(loadInterests(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InterestComponent);